<div class="container">
  <div class="columns is-centered">
    <div class="column is-half">
      <div *ngIf="showFavoriteNotification" class="notification is-info is-light">
        <button class="delete"></button>
        If you don't have an image, use <code>/setfavorites</code> to set yours!
      </div>
    </div>
  </div>
  <ng-container *ngIf="leaderboard">

    <div class="tabs is-centered is-large is-toggle">
      <ul>
        <li [class.is-active]="monthlyTabName == 'monthly'">
          <a [routerLink]="['/view/leaderboard/global/monthly']" [queryParams]="{list: tabName}">
            Monthly
          </a>
        </li>
        <li [class.is-active]="monthlyTabName == 'allTime'">
          <a [routerLink]="['/view/leaderboard/global/allTime']" [queryParams]="{list: tabName}">
            All-time
          </a>
        </li>
      </ul>
    </div>
    <div class="tabs is-centered is-medium">
      <ul>
        <li [class.is-active]="tabName == 'upaScore'">
          <a [routerLink]="['/view/leaderboard/global', monthlyTabName]" [queryParams]="{list: 'upaScore'}">
            Upa Score
          </a>
        </li>
        <li [class.is-active]="tabName == 'gachaOwned'">
          <a [routerLink]="['/view/leaderboard/global', monthlyTabName]" [queryParams]="{list: 'gachaOwned'}">
            Gacha Owned
          </a>
        </li>
        <li [class.is-active]="tabName == 'rolls'">
          <a [routerLink]="['/view/leaderboard/global', monthlyTabName]" [queryParams]="{list: 'rolls'}">
            Rolls
          </a>
        </li>
      </ul>
    </div>

    <div class="columns is-multiline">
      <!-- TODO: The index can be off in subsequent pages/skipped identifiers -->
      <ng-container *ngFor="let l of leaderboard.entries; let i = index">
        <div class="column is-3" *ngIf="l.identifier">
          <div class="card bulma-center-mixin-parent">
            <div class="card-image bulma-center-mixin">
              <figure class="image">

                <ng-container
                  *ngIf="userToFavoriteImages[l.identifier.userUuid!] && userToFavoriteImages[l.identifier.userUuid!].imageAndStates as imgs">
                  <img class="relimage"
                    src="{{ imgs[userToFavoriteImages[l.identifier.userUuid!].shownImageIdx].imageUrl }}"
                    alt="Favorite gacha: {{ l.identifier.favoriteGachas![0].gacha!.name }}">
                  <ng-container *ngFor="let f of imgs">
                    <img class="absimage" [@crossfade]="f.state" src="{{ f.imageUrl }}" alt="Favorite gacha image">
                  </ng-container>
                </ng-container>

                <img *ngIf="!l.identifier.favoriteGachas || l.identifier.favoriteGachas.length == 0"
                  class="placeholder-favorite is-skeleton" src="/assets/empty-card-bg.png" alt="No favorite gacha set">
              </figure>
            </div>
            <div class="card-content">
              <div class="media">
                <div class="media-content">
                  <p class="title is-4">
                    {{ (currentPage*LEADERBOARD_PAGE_SIZE) + 1 + i }}.&nbsp;
                    <a [routerLink]="['/view/user', l.identifier.userUuid!]">
                      {{ l.identifier.displayName }}
                    </a>
                  </p>
                </div>
              </div>

              <hr class="navbar-divider" />

              <div class="content">
                <div class="columns">
                  <div class="column is-4">
                    <p class="subtitle is-7 lower-padding">Upa Score</p>
                    <p class="title is-5">{{ l.value![2]!.value! / 10 | number: '1.0-0' }}</p>
                  </div>
                  <div class="column is-4">
                    <p class="subtitle is-7 lower-padding">Owned</p>
                    <p class="title is-5">{{ l.value![0]!.value! }}</p>
                  </div>
                  <div class="column is-4">
                    <p class="subtitle is-7 lower-padding">Rolls</p>
                    <p class="title is-5">{{ l.value![1]!.value! }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <nav class="pagination" role="navigation" aria-label="pagination">
      <a *ngIf="prevToken" (click)="prevPage()" class="pagination-previous">Previous</a>
      <a *ngIf="nextToken" (click)="nextPage()" class="pagination-next">Next</a>
    </nav>
  </ng-container>
</div>