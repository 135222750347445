<div class="container is-fluid">

  <h1 class="title">giveaways</h1>
  <h2 class="subtitle">Budget used: {{ budgetUsed }} | Budget Left: {{ budgetLeft }}</h2>
  <div class="columns">
    <div class="column is-4">
      <div class="field">
        <label class="label">User ID (right click -> copy user ID in discord)</label>
        <div class="control">
          <input [(ngModel)]="targetUserID" class="input" type="text" placeholder="user ID">
        </div>
      </div>

    </div>
  </div>
  <hr />
  <div class="columns is-7 is-multiline">
    <div class="column is-4">
      <h1 class="title">Stardust</h1>
      <h2 class="subtitle">1,000 stardust = 1 point. Needs to be divisible by 1,000</h2>
      <h2 class="subtitle">Your budget allows for <code>{{ budgetLeft * 1000 }}</code> stardust this week.</h2>
      <div class="field">
        <label class="label">Amount of stardust to give away</label>
        <div class="control">
          <input [(ngModel)]="stardustAmount" class="input" type="number" placeholder="stardust amount"
            style="width: 100px;">
        </div>
      </div>
      <div class="field">
        <div class="control">

          <button
            *ngIf="stardustAmount % 1000 === 0 && stardustAmount <= budgetLeft * 1000 && stardustAmount > 0; else elseStardust"
            (click)="giveawayItem('STARDUST', stardustAmount)" class="primary button is-link">Giveaway
            {{stardustAmount}} stardust!</button>

          <ng-template #elseStardust>
            <button class="primary button is-link" disabled>Stardust must be > 0, below budget, and divisible by
              1000</button>
          </ng-template>
        </div>
      </div>
    </div>

    <div class="column is-4">
      <h1 class="title">Wishlist Token</h1>
      <h2 class="subtitle">1 token = 10 points</h2>
      <h2 *ngIf="budgetLeft >= 10; else notEnoughBudget" class="subtitle">Your budget allows for
        <code>{{ (budgetLeft / 10) | number:'1.0-0' }}</code> tokens this
        week.
      </h2>

      <div class="field">
        <div class="control">
          <button *ngIf="budgetLeft >= 10" (click)="giveawayBackpackItem(giveawayItemType.WISHLIST_TOKEN, 1)"
            class="primary button is-link">Giveaway 1 wishlist
            token</button>
        </div>
      </div>
    </div>

    <div class="column is-4">
      <h1 class="title">Alt Art Token</h1>
      <h2 class="subtitle">5 alt art = 2 points</h2>
      <h2 *ngIf="budgetLeft >= 2; else notEnoughBudget" class="subtitle">Your budget allows for
        <code>{{ ((budgetLeft / 2)*5 | number:'1.0-0') }}</code> tokens this week.
      </h2>
      <div class="field">
        <div class="control">
          <button *ngIf="budgetLeft >= 2" (click)="giveawayBackpackItem(giveawayItemType.ALT_ART_TOKEN, 5)"
            class="primary button is-link">Giveaway 5 alt art
            tokens</button>
        </div>
      </div>
    </div>

    <div class="column is-4">
      <h1 class="title">Booster Pack</h1>
      <h2 class="subtitle">1 booster pack = 5 points. Booster pack is randomly generated with
        <br />
        ⭐⭐⭐⭐⭐: 1<br />
        ⭐⭐⭐⭐: 1<br />
        ⭐⭐⭐: 2<br />
        ⭐⭐: 3<br />
        ⭐: 3<br />
      </h2>
      <h2 *ngIf="budgetLeft >= 5; else notEnoughBudget" class="subtitle">Your budget allows for
        <code>{{ ((budgetLeft / 5) | number:'1.0-0') }}</code> packs this week.
      </h2>
      <div class="field">
        <div class="control">
          <input [(ngModel)]="boosterPackName" class="input" type="text" placeholder="booster pack name"
            style="width: 50%">
        </div>
      </div>
      <div class="field">
        <div class="control">
          <button *ngIf="budgetLeft >= 5" (click)="giveawayBackpackItem(giveawayItemType.BOOSTER_PACK, 1)"
            class="primary button is-link">Giveaway 1 booster
            pack</button>
        </div>
      </div>
    </div>

    <div class="column is-4">
      <h1 class="title">Card Effect</h1>
      <h2 class="subtitle">1 effect = 10 points</h2>
      <h2 *ngIf="budgetLeft >= 10; else notEnoughBudget" class="subtitle">Your budget allows for
        <code>{{ ((budgetLeft / 10) | number:'1.0-0') }}</code> card effects this week.
      </h2>

      <div class="field">
        <div class="select">
          <select [(ngModel)]="selectedCardEffect">
            <option *ngFor="let effect of cardEffectOptions" [ngValue]="effect">{{ translateEffect(effect) }}</option>
          </select>
        </div>
      </div>

      <div class="field">
        <div class="control">
          <button *ngIf="budgetLeft >= 10" (click)="giveawayCardEffect(selectedCardEffect)"
            class="primary button is-link">
            Giveaway {{ translateEffect(selectedCardEffect) }} effect</button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #notEnoughBudget>
  <h2 class="subtitle">Not enough budget this week</h2>
</ng-template>