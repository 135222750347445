<div class="container is-fluid">
  <div class="columns">
    <div class="column is-10">
      <section>
        <div *ngIf="medias" class="field">
          <label class="label">Select Media Franchise</label>
          <div class="control">
            <div class="select">
              <select #s [(ngModel)]="selectedMediaID" (change)="onSelectMedia(s.value)">
                <option *ngFor="let media of medias" [ngValue]="media.id">{{media.name}}</option>
              </select>
            </div>
          </div>
        </div>

        <p *ngIf="altArts" class="has-text-weight-semibold">Total: {{altArts.length}}</p>

        <table class="table is-striped">
          <thead>
            <tr>
              <th>Base Gacha Name</th>
              <th>Name</th>
              <th>Description</th>
              <th>Orientation</th>
              <th>Image</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let altArt of altArts">
              <tr>
                <th class="has-text-weight-light">{{ altArt.gacha.name }}</th>
                <th class="has-text-weight-normal">
                  <ng-container *ngIf="!altArt.isEditing">
                    {{ altArt.altArt.name }}
                  </ng-container>
                  <ng-container *ngIf="altArt.isEditing">
                    <input type="text" class="input is-primary" type="text" [(ngModel)]="altArt.altArt.name" />
                  </ng-container>
                </th>
                <th class="has-text-weight-normal">
                  <ng-container *ngIf="!altArt.isEditing">
                    {{ altArt.altArt.description }}
                  </ng-container>
                  <ng-container *ngIf="altArt.isEditing">
                    <input type="text" class="input is-primary" type="text" [(ngModel)]="altArt.altArt.description" />
                  </ng-container>
                </th>
                <th class="has-text-weight-normal">
                  {{ altArt.altArt.orientation }}
                </th>
                <th class="has-text-weight-light">
                  <figure class="image preview">
                    <img src="{{ altArt.altArt.imageUrl }}">
                  </figure>
                </th>
                <ng-container *ngIf="altArt.isEditing">
                  <label class="checkbox">
                    <input [(ngModel)]="altArt.uploadImageEdit" type="checkbox">
                    Update image
                  </label>
                  <textarea #input placeholder="Paste image here" (paste)="onPaste($event, altArt)"
                    (change)="onFileChange($event, altArt)"></textarea>
                  <image-cropper output="base64" [imageChangedEvent]="altArt.ngxCropperData.imgChangeEvt"
                    [maintainAspectRatio]="true" [imageBase64]="altArt.ngxCropperData.imageBase64String"
                    [aspectRatio]="1 / 1.41421356237" [cropperMinWidth]="384" format="png"
                    (imageCropped)="cropImg($event, altArt)" (imageLoaded)="imgLoad()" (cropperReady)="initCropper()"
                    (loadImageFailed)="imgFailed()" [resizeToWidth]="576" [onlyScaleDown]="true">
                  </image-cropper>
                  <img class="grid-background" [src]="altArt.ngxCropperData.croppedBase64String" />
                </ng-container>
                <th>
                  <ng-container *ngIf="altArt.isEditing">
                    <div class="buttons">
                      <button class="button is-success is-light" (click)="saveEdit(altArt)">Save</button>
                      <button class="button is-danger is-light" (click)="cancelEdit(altArt)">Cancel</button>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="!altArt.isEditing">
                    <button class="button" (click)="startEdit(altArt)">Edit</button>
                  </ng-container>
                </th>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </section>
    </div>
  </div>
</div>