import { Component } from '@angular/core';
// import { AuthService } from '@auth0/auth0-angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Upa';
  constructor(
    // public auth: AuthService,
    public router: Router) { }
}
