<div class="container">
<section class="tos" #tos>
  <h1 class="title">Terms of Service</h1>
  <h2 class="subtitle">Updated 2023/02/24</h2>
  <div class="content">
    <div class="block">
      Thank you for using Upa ("Upa", "services")! Please read these terms of service carefully before using our
      services.
    </div>

    <div class="block">
      Upa is a set of applications and services provided - the Discord Bot ("bot") and the website ("website"). The
      service as a whole is referred to as "services", and the ownership entities as "we".
    </div>

    <div class="block">
      Our services are provided without warranty of any kind. We are not responsible for any damages, including direct,
      indirect, incidental, or consequential damages, arising out of the use or inability to use our services. It is
      provided free of charge and for entertainment purposes only. We do not make guarantees in its functionality,
      availability, or reliability. Our services are provided on an "as is" and "as available" basis. We make no
      warranties, either express or implied, about the accuracy, reliability, or suitability of our services. We are not
      responsible for any damages, including direct, indirect, incidental, or consequential damages, arising out of the
      use or inability to use our services.
    </div>

    <div class="block">
      You agree to use our bot for lawful purposes and in a way that does not violate the rights of others. You may not
      use our bot to engage in any activity that is illegal, harmful, or otherwise objectionable. The following are a
      non exhaustive list of policies we enforce:
      <ul>
        <li>
          You may not use our bot to harass, threaten, or otherwise violate the rights of others.
        </li>
        <li>
          You may not macro, bot, or otherwise automate the use of our bot and services.
        </li>
        <li>
          You may not abuse our bot and services. This includes, but is not limited to, spamming, flooding, replicating
          servers, and bug exploitation.
        </li>
      </ul>
    </div>

    <div class="block">
      We reserve the right to terminate your access to our services at any time and for any reason, without notice. We
      also reserve the right to modify, suspend, or discontinue our services at any time and without notice.
    </div>

    <div class="block">
      Privacy Policy: We take your privacy seriously. Our privacy policy outlines how we collect, use, and protect your
      personal information. By using our bot, you agree to the terms of our privacy policy. Please refer to our privacy
      policy below.
    </div>

    <div class="block">
      These terms of service shall be governed by and construed in accordance with the laws of the jurisdiction in which
      we are located, without giving effect to any principles of conflicts of law.
    </div>

    <div class="block">
      These terms of service constitute the entire agreement between you and us with respect to the use of our services.
      If any provision of these terms of service is held to be invalid or unenforceable, such provision shall be struck
      and the remaining provisions shall be enforced.
    </div>

    <div class="block">
      We reserve the right to modify these terms of service at any time and without notice. By continuing to use our
      services after any such modification, you agree to be bound by the revised terms of service.
    </div>
  </div>
</section>

<hr />

<section class="privacypolicy" #privacypolicy>
  <h1 class="title">Privacy Policy</h1>
  <h2 class="subtitle">Updated 2023/02/24</h2>
  <div class="content">
    <div class="block">
      We at Upa are committed to protecting your privacy and data. This Privacy Policy outlines how we collect, use, and
      protect your personal information when using our services.
    </div>

    <div class="block">
      We collect the following automated information when you use our services:
      <ul>
        <li>
          Your Discord ID, username, display name, and avatar.
        </li>
        <li>
          Your Discord server ID and name.
        </li>
        <li>
          IP addresses when accessing our web services.
        </li>
      </ul>
    </div>

    <div class="block">
      We derive information from your access, such as, but not limited to:
      <ul>
        <li>
          Discord activity for the purpose of providing our core services.
        </li>
        <li>
          Discord activity for the purpose of anti-abuse.
        </li>
        <li>
          Logging for the purpose of debugging and improving our services.
        </li>
      </ul>
    </div>

    <div class="block">
      We do not share nor sell your personal information to third parties. We may share your personal information with
      law enforcement or other government agencies if required by law.
    </div>

    <div class="block">
      For data removal requests, please direct your request to our support server at <a
        [routerLink]="['/server']">https://upa.moe/server</a>.
    </div>

    <div class="block">
      We reserve the right to modify this privacy policy at any time and without notice. By continuing to use our
      services after any such modification, you agree to be bound by the revised privacy policy.
    </div>
  </div>
</section>
</div>