<section>
  <div class="notification is-primary is-light">
    Please fill out the "name" and "description" fields with the following guidelines:
    <br />
    Name: Include the name of the character with the name of the alt art. For example, "Shiny Charizard" or "Christmas
    Mayuri".
    You do <strong>not</strong> need to include the full character name.
    <br />
    Description: Describe the alt art, where it comes from (e.g. a skin from the game, special event etc.) If this is
    fan art, include the original source
    (e.g. artist name, twitter, pixiv etc.)
  </div>
  <h2 class="title">Stage Alt Art</h2>
  <div *ngIf="medias.length > 0" class="field">
    <label class="label">Select Media Franchise</label>
    <div class="control">
      <div class="select">
        <select #s [(ngModel)]="selectedMediaID" (change)="onSelectMedia(s.value)">
          <option *ngFor="let media of medias" [ngValue]="media.id">{{media.name}}</option>
        </select>
      </div>
    </div>
  </div>

  <div *ngIf="gachas">
    <table class="table">
      <thead>
        <tr>
          <th></th>
          <th>ID</th>
          <th>Name</th>
          <th>Rarity</th>
          <th>Image</th>
          <th>Add alt art</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let gacha of gachas">
          <tr>
            <th class="has-text-weight-light"><a [routerLink]="['/view/gacha', gacha.gacha.id]">Link</a></th>
            <th class="has-text-weight-light">{{ gacha.gacha.id }}</th>
            <th class="has-text-weight-normal">{{ gacha.gacha.name }}</th>
            <th class="has-text-weight-normal">{{ gacha.gacha.rarity }}</th>
            <th class="has-text-weight-normal">
              <figure class="image preview">
                <img src="{{ gacha.gacha.imageUrl }}">
              </figure>
            </th>
            <th>
              <ng-container *ngIf="!gacha.isEditing">
                <button class="button is-success is-light" (click)="startCreateAltArt(gacha)">create alt art</button>
              </ng-container>

              <ng-container *ngIf="gacha.isEditing">
                <input type="text" class="input is-primary" type="text" [(ngModel)]="gacha.altArtName"
                  placeholder="name of alt art" />
                <input type="text" class="input is-primary" type="text" [(ngModel)]="gacha.altArtDescription"
                  placeholder="description of alt art" />
                <div class="field">
                  <label class="label">Select Orientation</label>
                  <div class="control">
                    <div *ngFor="let o of orientationOptions">
                      <label class="radio">
                        <input type="radio" name="radioorientation" id="radio-{{o}}"
                          [(ngModel)]="gacha.altArtOrientation" [value]="o" />
                        {{ o }}
                      </label>
                    </div>
                  </div>
                </div>
                <textarea #input placeholder="Paste image here" (paste)="onPaste($event, gacha)"
                  (change)="onFileChange($event, gacha)"></textarea>
                <image-cropper [imageChangedEvent]="gacha.ngxCropperData.imgChangeEvt" [maintainAspectRatio]="true"
                  output="base64" [imageBase64]="gacha.ngxCropperData.imageBase64String"
                  [aspectRatio]="calcAspectRatio(gacha.altArtOrientation)"
                  [cropperMinWidth]="calcMinWidth(gacha.altArtOrientation)" format="png"
                  (imageCropped)="cropImg($event, gacha)" (imageLoaded)="imgLoad()" (cropperReady)="initCropper()"
                  (loadImageFailed)="imgFailed()">
                </image-cropper>
                <img class="grid-background" [src]="gacha.ngxCropperData.croppedBase64String" />
                <div class="buttons">
                  <button class="button is-success is-light" (click)="saveAltArt(gacha)">Save</button>
                  <button class="button is-danger is-light" (click)="cancelAltArt(gacha)">Cancel</button>
                </div>
              </ng-container>

            </th>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</section>