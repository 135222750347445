import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-enhancement-component',
  templateUrl: './enhancement-component.component.html',
  styleUrl: './enhancement-component.component.scss'
})
export class EnhancementComponentComponent {
  @Input({ required: true })
  imageURL!: string;

  @Input({ required: true })
  name!: string;

  @Input({ required: true })
  description!: string;

  constructor() {
  }
}
