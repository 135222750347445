/* tslint:disable */
/* eslint-disable */
import { Origin } from './origin';

/**
 * Each possible value of `Origin`
 */
export const ORIGIN: Origin[] = [
  'UNDEFINED_ORIGIN',
  'GAME',
  'MANGA',
  'ANIME',
  'VISUAL_NOVEL',
  'H'
];