import { inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ResolveFn } from '@angular/router';
import { map, mergeMap, tap } from 'rxjs';
import { GachaServiceService, MediaServiceService } from 'src/app/openapi/services';

export const resolveBaseAltArtLink: ResolveFn<boolean> = (route, state) => {
  const m = inject(Meta);
  m.removeTag('name="title"');
  m.removeTag('name="description"');
  m.removeTag('name="og:title"');
  m.removeTag('name="og:description"');
  m.addTag({ name: 'title', content: `Upa - Alternate Art` })
  m.addTag({ name: 'og:title', content: `Upa - Alternate Art` });
  m.addTag({ name: 'description', content: `Browse the beautiful alternate art available for your favorite Upa gacha cards` });
  m.addTag({ name: 'og:description', content: `Browse the beautiful alternate art available for your favorite Upa gacha cards` });

  return true;
};

export const resolveSpecificMedia: ResolveFn<boolean> = (route, state) => {
  const a = inject(GachaServiceService);
  const mS = inject(MediaServiceService)
  const m = inject(Meta);

  return a.gachaServiceGetGachaWithAltArt()
    .pipe(
      mergeMap(gachas => mS.mediaServiceGetMedia({ mediaId: route.params['mediaID'] })
        .pipe(
          tap(mediaRes => {
            const media = mediaRes.media!;
            const mediaID = route.params['mediaID'];
            const gachasForGivenMediaWithAltArt = gachas.gacha!.
              filter(g => g.mediaId === mediaID && g.altArts && g.altArts.length > 0);

            // Pick a random gacha and random alt art within that gacha
            const randomGacha = gachasForGivenMediaWithAltArt[Math.floor(Math.random() * gachasForGivenMediaWithAltArt.length)];
            const randomAltArt = randomGacha.altArts![Math.floor(Math.random() * randomGacha.altArts!.length)];


            m.removeTag('name="title"');
            m.removeTag('name="description"');
            m.removeTag('name="og:title"');
            m.removeTag('name="og:description"');
            m.removeTag('name="og:image"');
            m.removeTag('name="twitter:image:src"');
            m.removeTag('name="twitter:card"');

            m.addTag({ name: 'title', content: `Upa - Alt Art for ${media.name}` })
            m.addTag({ name: 'og:title', content: `Upa - Alt Art for ${media.name}` });
            m.addTag({ name: 'description', content: `Browse the beautiful alternate art available for ${media.name}` });
            m.addTag({ name: 'og:description', content: `Browse the beautiful alternate art available for ${media.name}` });
            m.addTag({ name: 'twitter:card', content: 'summary_large_image' });
            m.addTag({ name: 'og:image', content: `${randomAltArt.imageUrl}` });
            m.addTag({ name: 'twitter:image:src', content: `${randomAltArt.imageUrl}` });
          }),
        )
      ), map(_ => true)
    );

};
