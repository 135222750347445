import { Component, OnInit } from '@angular/core';
import { UpaGuild } from 'src/app/openapi/models';
import { UpaGuildServiceService } from 'src/app/openapi/services';

@Component({
  selector: 'app-view-all-guilds',
  templateUrl: './view-all-guilds.component.html',
  styleUrl: './view-all-guilds.component.scss'
})
export class ViewAllGuildsComponent implements OnInit {
  guilds: UpaGuild[] = [];
  expMapping?: {
    [key: string]: number;
  };

  constructor(
    private upaGuildService: UpaGuildServiceService,
  ) { }

  ngOnInit(): void {
    this.upaGuildService.upaGuildServiceListUpaGuilds().subscribe(guilds => {
      this.guilds = guilds.upaGuilds || [];
      this.expMapping = guilds.upaGuildIdToExp;
    });
  }

}
