/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { GetGachaOwnedByUserWithAltArtResponse } from '../../models/get-gacha-owned-by-user-with-alt-art-response';

export interface AltArtServiceGetGachaOwnedByUserWithAltArt$Params {
  userUuid: string;
  discordId?: string;

/**
 * If set, the gacha will only return those that the user has alt art for.
 */
  onlyIncludeGachaWithOwnedAltArt?: boolean;
  'filterOptions.rarity'?: number;

/**
 * TODO: This is a really bad way to force te http gateway to give us a random param
 *  in order to prevent the http client from caching the rxjs request.
 * Short for cache buster.
 */
  c?: number;
}

export function altArtServiceGetGachaOwnedByUserWithAltArt(http: HttpClient, rootUrl: string, params: AltArtServiceGetGachaOwnedByUserWithAltArt$Params, context?: HttpContext): Observable<StrictHttpResponse<GetGachaOwnedByUserWithAltArtResponse>> {
  const rb = new RequestBuilder(rootUrl, altArtServiceGetGachaOwnedByUserWithAltArt.PATH, 'get');
  if (params) {
    rb.path('userUuid', params.userUuid, {});
    rb.query('discordId', params.discordId, {});
    rb.query('onlyIncludeGachaWithOwnedAltArt', params.onlyIncludeGachaWithOwnedAltArt, {});
    rb.query('filterOptions.rarity', params['filterOptions.rarity'], {});
    rb.query('c', params.c, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<GetGachaOwnedByUserWithAltArtResponse>;
    })
  );
}

altArtServiceGetGachaOwnedByUserWithAltArt.PATH = '/api/v3/gateway/user/{userUuid}/gacha/altart';
