import { Component, Input } from '@angular/core';
import { UpaGuild } from 'src/app/openapi/models';

@Component({
  selector: 'app-view-guild-card',
  templateUrl: './view-guild-card.component.html',
  styleUrl: './view-guild-card.component.scss'
})
export class ViewGuildCardComponent {
  @Input({ required: true }) guild!: UpaGuild;

  constructor() { }

}
