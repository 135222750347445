<div class="container">
  <div class="columns">
    <div class="column">
      <h1 class="is-size-1" *ngIf="displayName">{{displayName}}'s profile</h1>
      <img *ngIf="favoriteMedia && favoriteMedia.bannerImageUrl" class="bg-image"
        src="{{ favoriteMedia.bannerImageUrl }}">
      <div *ngIf="user" class="tags has-addons">
        <span class="tag is-light">last active</span>
        <span [class]="generateUserActivityCssClass(user)" class="tag is-light is-normal">
          {{ generateUserActivity(user) }}</span>
      </div>
    </div>
  </div>
  <!-- Render the achievements in SSR only if they're less than 20ish -->
  <div class="columns is-centered">
    <div class="column has-text-centered">
      <h2 class="is-size-2 is-title">Achievements</h2>
      <div *ngIf="!shownAchievements" class="skeleton-lines">
        <h2 class="subtitle is-skeleton">
          Loading Achievements
        </h2>
      </div>
      <ng-container *ngIf="shownAchievements && shownAchievements.length > 0">
        <div class="columns is-gapless is-multiline collapsible is-unselectable">
          <ng-container *ngFor="let achievement of shownAchievements">
            <div [@collapseAnimation] class="column is-full">
              <ng-container
                *ngIf="achievement.achievement.spaceIdentifier != undefined && $any(achievement.achievement.spaceIdentifier).perMedia != undefined; else noMediaTmpl">
                <a [routerLink]="['/view/media/'+$any(achievement.achievement.spaceIdentifier).perMedia.mediaId]">
                  <!-- <img [@collapseAnimation] class="achievement" [src]="achievement.image_url" [alt]="achievement.name"> -->
                  <div [innerHTML]="achievement.html"></div>
                </a>
              </ng-container>
              <ng-template #noMediaTmpl>
                <!-- <img [@collapseAnimation] class="achievement" [src]="achievement.image_url" [alt]="achievement.name"> -->
                <div [innerHTML]="achievement.html"></div>
              </ng-template>
            </div>
          </ng-container>
          <div *ngIf="shownAchievements.length > 3" class="toggler" (click)="toggleCollapse()">
            <fa-icon [icon]="toggleIcon"></fa-icon>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <hr>
  <div class="columns is-centered" *ngIf="favorites && favorites.length > 0">
    <div class="column has-text-centered">
      <h2 class="is-size-2">Favorites</h2>

      <h2 class="subtitle" *ngIf="loggedInUser && user && loggedInUser.user_uuid === user.userUuid">
        <button *ngIf="!isEditingFavorites" (click)="isEditingFavorites = !isEditingFavorites"
          class="button is-small is-light is-primary">Edit Favorites</button>
        <button *ngIf="isEditingFavorites" (click)="isEditingFavorites = !isEditingFavorites"
          class="button is-small is-light is-primary">Done</button>
      </h2>
      <ng-container *ngFor="let g of favorites; trackBy: trackByFavorites">
        <ng-container
          *ngIf="g.favoriteGacha && g.favoriteGacha.gacha && g.favoriteGacha.gacha.gacha && g.favoriteGacha.media">
          <app-gacha-v2 [gacha]="g.favoriteGacha.gacha.gacha" [media]="g.favoriteGacha.media"
            [copyNumber]="(mintedOutOf && mintedOutOf[g.favoriteGacha.gacha.gacha.id!]) ? mintedOutOf[g.favoriteGacha.gacha.gacha.id!].copy : undefined"
            [outOf]="(mintedOutOf && mintedOutOf[g.favoriteGacha.gacha.gacha.id!]) ? mintedOutOf[g.favoriteGacha.gacha.gacha.id!].outOf : undefined"
            [datePulled]="datePulled ? datePulled[g.favoriteGacha.gacha.gacha!.id!] : undefined"
            [starpower]="g.favoriteGacha.gacha.starpower" [userUUID]="user ? user.userUuid! : undefined"
            [gachaInstanceId]="g.favoriteGacha.gacha.gachaInstanceId" [showFavoritesEdit]="isEditingFavorites"
            (favoriteRemoved)="removeFavorite(g.favoriteGacha.priority!)"></app-gacha-v2>
        </ng-container>
        <ng-container
          *ngIf="g.emptySlot && isEditingFavorites && loggedInUser && loggedInUser.user_uuid && user && user.userUuid && loggedInUser.user_uuid === user.userUuid">
          <app-empty-edit-favorite-slot [userUUID]="loggedInUser.user_uuid" [priority]="g.emptySlot"
            (setFavoriteSlot)="setFavoriteSlot($event)"></app-empty-edit-favorite-slot>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <hr *ngIf="favorites && favorites.length > 0">
  <div class="columns is-centered is-multiline" infiniteScroll [infiniteScrollDistance]="2" (scrolled)="onScrollDown()"
    (scrolledUp)="onScrollUp()">
    <div class="column is-vcentered has-text-centered">
      <h2 class="is-size-2" id="collection" #collection>Collection</h2>

      <div class="tabs is-centered is-boxed">
        <ul>
          <li [class.is-active]="currentTab == 'gacha'">
            <a (click)="selectTab('gacha')">Gacha</a>
          </li>
          <li [class.is-active]="currentTab == 'altArt'">
            <a (click)="selectTab('altArt')">Alt Art</a>
          </li>
        </ul>
      </div>
      <app-view-user-owned-alt-art *ngIf="currentTab == 'altArt'"
        [userUUID]="user ? user.userUuid : undefined"></app-view-user-owned-alt-art>

      <ng-container *ngIf="currentTab == 'gacha'">
        <div class="has-text-left">
          <span (click)="toggleFilterCollapse()" class="clickable-filter-icon">
            <fa-icon [icon]="filterIcon"></fa-icon>
            Show Filters
          </span>
          <span class="collapsible-filter">

            <progress #loading style="visibility: hidden;" class="progress is-small is-info" max="100">15%</progress>
            <div class="field is-horizontal is-narrow">
              <div class="select">
                <select #s (change)="onSelectMediaFilter(s.value)">
                  <option value="reset">Choose Franchise</option>
                  <option *ngFor="let media of allMedia" [value]="media.id">{{media.name}}</option>
                </select>
              </div>
              <div style="margin-left:2em;" class="field has-addons small-input">
                <p class="control">
                  <a class="button is-static white-font">
                    Mint >=
                  </a>
                </p>
                <p class="control">
                  <input (change)="onChangeLowerBoundMint($event)" class="input" type="number" placeholder="#">
                </p>
              </div>
              <div class="field has-addons small-input">
                <p class="control">
                  <a class="button is-static white-font">
                    Mint <= </a>
                </p>
                <p class="control">
                  <input (change)="onChangeUpperBoundMint($event)" class="input" type="number" placeholder="#">
                </p>
              </div>
            </div>
            <div class="field is-horizontal is-grouped-multiline">
              <div class="field-body">
                <div class="field is-narrow">
                  <div class="control">
                    <ng-container *ngFor="let item of [].constructor(6); let i = index">
                      <label class="checkbox">
                        <input type="checkbox" [(ngModel)]="selectedRarities[i+1]" name="rarity" [value]="i+1"
                          (click)="onSelectRarityFilter(i+1)">
                        {{ i + 1 }}⭐
                      </label>
                    </ng-container>
                  </div>
                </div>
                <div class="field is-grouped">
                  <div class="control">
                    <div class="select">
                      <select #s2 (change)="onSelectShowOpenForTradeFilter(s2.value)">
                        <option value="reset">Tradeable Status</option>
                        <option value="TRADEABLE">Open for Trade</option>
                        <option value="KINDA_TRADEABLE">Kinda Tradeable</option>
                        <option value="UNTRADEABLE">Not for Trade</option>
                      </select>
                    </div>
                  </div>
                  <div *ngIf="auth.user$ | async as user" class="control">
                    <label class="checkbox">
                      <input type="checkbox" (click)="onSelectShowUnownedFilter($event)">
                      Unowned
                    </label>
                  </div>
                  <div class="control">
                    <label class="checkbox">
                      <input type="checkbox" (click)="onSelectShowSpecialEffectFilter($event)">
                      Has Special Effect
                    </label>
                  </div>
                </div>

              </div>

            </div>

          </span>
        </div>

        <ng-container *ngFor="let g of gachas; trackBy: trackBy">
          <app-gacha-v2 *ngIf="g.gacha !== undefined" [gacha]="g.gacha" [media]="g.media"
            [tradeableStatus]="markedForTrade ? markedForTrade[g.gacha!.id!] : undefined"
            [copyNumber]="mintedOutOf ? mintedOutOf[g.gacha!.id!].copy : undefined"
            [outOf]="mintedOutOf ? mintedOutOf[g.gacha!.id!].outOf : undefined"
            [datePulled]="datePulled ? datePulled[g.gacha.id!] : undefined" [starpower]="g.starpower!"
            [gachaInstanceId]="g.gachaInstanceId" [userUUID]="user ? user.userUuid! : undefined"
            [showEdit]="loggedInUser && user && loggedInUser.user_uuid === user.userUuid"
            [allowedGachaEnhancements]="g.gachaEnhancements"></app-gacha-v2>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>