/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { SetAltArtResponse } from '../../models/set-alt-art-response';

export interface AltArtServiceSetAltArt$Params {
  userUuid: string;
  gachaId: string;
  altArtId: number;
  discordId?: string;
}

export function altArtServiceSetAltArt(http: HttpClient, rootUrl: string, params: AltArtServiceSetAltArt$Params, context?: HttpContext): Observable<StrictHttpResponse<SetAltArtResponse>> {
  const rb = new RequestBuilder(rootUrl, altArtServiceSetAltArt.PATH, 'post');
  if (params) {
    rb.path('userUuid', params.userUuid, {});
    rb.path('gachaId', params.gachaId, {});
    rb.path('altArtId', params.altArtId, {});
    rb.query('discordId', params.discordId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<SetAltArtResponse>;
    })
  );
}

altArtServiceSetAltArt.PATH = '/api/v3/gateway/user/{userUuid}/gacha/{gachaId}/altart/{altArtId}';
