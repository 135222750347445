import { Component, Inject, NgZone, OnInit, PLATFORM_ID, afterRender } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, map, mergeMap, of } from 'rxjs';
import { animate, style, transition, trigger, state } from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';
import { GachaOrientation, Leaderboard } from 'src/app/openapi/models';
import { LeaderboardServiceService } from 'src/app/openapi/services';
import { LeaderboardServiceGenerateGlobalLeaderboard$Params } from 'src/app/openapi/fn/leaderboard-service/leaderboard-service-generate-global-leaderboard';

interface shownImageIdxAndImageAndState {
  shownImageIdx: number;
  prevInterval?: any;
  imageAndStates: imageAndState[];
}

interface imageAndState {
  imageUrl: string;
  state: string;
  orientation: GachaOrientation;
}

@Component({
  selector: 'app-view-leaderboard',
  templateUrl: './view-leaderboard.component.html',
  styleUrls: ['./view-leaderboard.component.scss'],
  animations: [
    trigger('crossfade', [
      state('in', style({ 'opacity': '1' })),
      state('out', style({ 'opacity': '0' })),
      transition('* <=> *', [
        animate(750)
      ])
    ])
  ],
})
export class ViewLeaderboardComponent implements OnInit {

  showFavoriteNotification = false;

  // Declared in class to be accessible to template
  readonly LEADERBOARD_PAGE_SIZE = 60;
  leaderboard?: Leaderboard;
  tabName: string = "gachaOwned";
  monthlyTabName: string = "monthly";

  nextToken?: string;
  prevToken?: string;
  currentPage: number = 0;

  userToFavoriteImages: { [key: string]: shownImageIdxAndImageAndState } = {};

  constructor(private leaderboardApi: LeaderboardServiceService, private route: ActivatedRoute, private ngZone: NgZone,
    @Inject(PLATFORM_ID) private platformId: Object) {
    afterRender(() => {
      (document.querySelectorAll('.notification .delete') || []).forEach(($delete) => {
        const $notification = $delete.parentNode;

        $delete.addEventListener('click', () => {
          $notification!.parentNode?.removeChild($notification!);
          localStorage.setItem('showFavoriteNotification', 'false');
        });
      });
    });
  }

  ngOnInit(): void {

    if (isPlatformBrowser(this.platformId)) {
      this.route.queryParams.pipe(
        map(p => p['list']),
        mergeMap(p => combineLatest([of(p), this.route.params.pipe(map(p => p['selector']))])),
      ).subscribe(([list, selector]) => {
        switch (list) {
          case "upaScore":
          case "rolls":
          case "gachaOwned":
            this.loadByTab(list, selector === 'monthly');
            break;
          default:
            this.loadByTab('upaScore', selector === 'monthly');
            break;
        }
      });
      if (localStorage.getItem('showFavoriteNotification') === null ||
        localStorage.getItem('showFavoriteNotification') === 'true') {
        this.showFavoriteNotification = true;
        localStorage.setItem('showFavoriteNotification', 'true');
      }

    }
  }

  loadByTab(tab: string, monthly: boolean): void {
    this.tabName = tab;
    this.monthlyTabName = monthly ? 'monthly' : 'allTime';
    if (monthly) {
      this.monthlyTabName = 'monthly';
    } else {
      this.monthlyTabName = 'allTime';
    }
    let sortType: sortType = "LEADERBOARD_UPA_SCORE";
    switch (this.tabName) {
      case "upaScore":
        sortType = "LEADERBOARD_UPA_SCORE";
        break;
      case "gachaOwned":
        sortType = "LEADERBOARD_GACHA_OWNED";
        break;
      case "rolls":
        sortType = "LEADERBOARD_ROLL_COUNT";
        break;
      default:
        sortType = "LEADERBOARD_UPA_SCORE";
        break;
    }
    const req: LeaderboardServiceGenerateGlobalLeaderboard$Params = {
      "paginationOptions.pageSize": this.LEADERBOARD_PAGE_SIZE,
      "paginationOptions.sortType": sortType,
      "paginationOptions.orderBy": "DESC",
      "month.month": monthly ? new Date().getMonth() + 1 : undefined,
      "month.year": monthly ? new Date().getFullYear() : undefined,
    }
    if (monthly) {
      req.currentTime = true;
      req['month.month'] = undefined;
      req['month.year'] = undefined;
    }
    this.leaderboardApi.leaderboardServiceGenerateGlobalLeaderboard(req).subscribe(l => {
      this.leaderboard = l.leaderboard;
      this.leaderboard?.entries?.forEach(e => {
        if (!e.identifier) {
          return;
        }
        const id = e.identifier.userUuid;
        if (id && e.identifier.favoriteGachas) {
          if (this.userToFavoriteImages[id]?.prevInterval) {
            clearInterval(this.userToFavoriteImages[id].prevInterval);
          }

          this.userToFavoriteImages[id] = { shownImageIdx: 0, imageAndStates: [] };
          this.userToFavoriteImages[id].imageAndStates = e.identifier.favoriteGachas
            .filter(f => f.gacha !== undefined)
            .map(f =>
            ({
              imageUrl: f.gacha!.imageUrl!,
              state: 'out',
              orientation: f.gacha!.orientation!,
            })
            );
          // As a hack to fix the wide image doing weird stuff, we'll force the shownImageIdx to equal the first
          // non-WIDE orientation gacha - unless, all the images are WIDE, then we just use 0
          const imgIdx = this.userToFavoriteImages[id].imageAndStates.findIndex(i => i.orientation !== 'WIDE');
          if (imgIdx >= 0) {
            this.userToFavoriteImages[id].shownImageIdx = imgIdx;
          }
          if (this.userToFavoriteImages[id].imageAndStates.length > 0) {
            this.userToFavoriteImages[id].imageAndStates[this.userToFavoriteImages[id].shownImageIdx].state = "in";
            const t = setInterval(() => {
              this.userToFavoriteImages[id].imageAndStates[this.userToFavoriteImages[id].shownImageIdx].state = "out";
              this.userToFavoriteImages[id].shownImageIdx =
                (this.userToFavoriteImages[id].shownImageIdx + 1) % this.userToFavoriteImages[id].imageAndStates.length;
              this.userToFavoriteImages[id].imageAndStates[this.userToFavoriteImages[id].shownImageIdx].state = "in";
            }, this.randomNumberInRange(3000, 7500));
            this.userToFavoriteImages[id].prevInterval = t;
          }
        }
      });
    });
  }

  randomNumberInRange(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  loadAllTime(): void {

  }

  loadMonthly(): void {
    let sortType: sortType = "LEADERBOARD_UPA_SCORE";
  }

  nextPage(): void {
    this.leaderboardApi.leaderboardServiceGenerateGlobalLeaderboard({
      "paginationOptions.pageSize": this.LEADERBOARD_PAGE_SIZE,
      "paginationOptions.page": this.nextToken,
    }).subscribe(l => {
      this.leaderboard = l.leaderboard;

      this.currentPage++;
    });
  }

  prevPage(): void {
    this.leaderboardApi.leaderboardServiceGenerateGlobalLeaderboard({
      "paginationOptions.pageSize": this.LEADERBOARD_PAGE_SIZE,
      "paginationOptions.page": this.prevToken,
    }).subscribe(l => {
      this.leaderboard = l.leaderboard;
      this.currentPage--;
    });
  }
}

type sortType = 'UNDEFINED' | 'ALPHABETICAL' | 'GACHA_RARITY_AND_ALPHABETICAL' | 'LEADERBOARD_ROLL_COUNT' | 'LEADERBOARD_GACHA_OWNED' | 'LEADERBOARD_UPA_SCORE' | 'USER_GACHA_MINT' | 'USER_GACHA_DATE_ACQUIRED';