<div class="container is-fluid">

  <h1 class="title">abuse shit</h1>
  <div class="columns">
    <div class="column is-4">
      <div class="field">
        <label class="label">User ID or Long User ID (looks like
          <code>6ff88070-be76-41e0-86b6-aefb046212b3</code>)</label>
        <div class="control">
          <input [(ngModel)]="targetUserIDOrUUID" class="input" type="text" placeholder="user ID">
        </div>
      </div>
      <div class="field">
        <div class="control">
          <button (click)="loadUser()" class="primary button is-link">lookup</button>
        </div>
      </div>
      <div *ngIf="response && response.user">
        <h2 class="is-title-2">Summary for {{ response.user.displayName }}</h2>
        <button *ngIf="userCanBan" (click)="showShadowbanModal()" class="button is-danger">Shadowban</button>
      </div>
    </div>
  </div>
  <div class="columns">
    <div class="column is-6">
      <table class="table">
        <thead>
          <tr>
            <th>Trade ID</th>
            <th>User 1</th>
            <th>Sent</th>
            <th>Sent</th>
            <th>User 2</th>
            <th>date</th>
          </tr>
        </thead>
        <tbody *ngIf="response && response.tradeHistory">
          <ng-container *ngFor="let trade of response.tradeHistory">
            <tr>
              <td>{{ trade.id }}</td>
              <td *ngIf="userCache[trade.traderOne!.traderUserId!] !== undefined">
                <a [routerLink]="['/view', 'user', userCache[trade.traderOne!.traderUserId!]!.userUuid]">
                  {{ userCache[trade.traderOne!.traderUserId!]!.displayName }}
                </a>
              </td>
              <td>
                <p [innerHtml]="formatUserItems(trade.traderOne!)"></p>
              </td>
              <td>
                <p [innerHtml]="formatUserItems(trade.traderTwo!)"></p>
              </td>
              <td *ngIf="userCache[trade.traderTwo!.traderUserId!] !== undefined">
                <a [routerLink]="['/view', 'user', userCache[trade.traderTwo!.traderUserId!]!.userUuid]">
                  {{ userCache[trade.traderTwo!.traderUserId!]!.displayName }}
                </a>
              </td>
              <td>
                {{ trade.createdAt | date: 'medium' }}
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>

    <div class="column is-6">
      <table class="table">
        <thead>
          <tr>
            <th>Original User Owner</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody *ngIf="response && response.ownedGachaByOriginalUser">
          <ng-container *ngFor="let stat of response.ownedGachaByOriginalUser">
            <tr>
              <td>
                <a [routerLink]="['/view', 'user', stat.originalRoller!.userUuid!]">
                  {{ stat.originalRoller!.displayName! }}
                </a>
              </td>
              <td>
                <p [innerHtml]="formatOwnedGacha(stat)"></p>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="modal" #shadowbanModal>
  <div class="modal-background"></div>
  <div class="modal-content">
    <div class="box">
      <div class="field">
        <label class="label">Ban reason</label>
        <div class="control">
          <textarea [(ngModel)]="banReason" class="textarea"
            placeholder="Ban reason. If banning because of alts, list the alt usernames here"></textarea>
        </div>
      </div>

      <div class="field">
        <div class="field-label is-normal">
          <label class="label">Expiration Date</label>
          <input [(ngModel)]="banExpirationDate" type="date" id="expiration-date" value="{{ today() }}">
        </div>
      </div>

      <div class="field">
        <label class="checkbox">
          <input [(ngModel)]="permanentBan" type="checkbox">
          Permanent ban
        </label>
      </div>

      <div class="field is-grouped">
        <p class="control">
          <button (click)="shadowban()" class="button is-danger">
            Ban
          </button>
        </p>
        <p class="control">
          <button class="button is-light" (click)="closeShadowbanModal()">
            Cancel
          </button>
        </p>
      </div>
    </div>
  </div>
  <button (click)="closeShadowbanModal()" class="modal-close is-large" aria-label="close"></button>
</div>