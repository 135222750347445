/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ClearUserFavoriteGachaSlotResponse } from '../../models/clear-user-favorite-gacha-slot-response';

export interface UserServiceClearUserFavoriteGachaSlot$Params {
  userUuid: string;
  priority: number;
  discordId?: string;
}

export function userServiceClearUserFavoriteGachaSlot(http: HttpClient, rootUrl: string, params: UserServiceClearUserFavoriteGachaSlot$Params, context?: HttpContext): Observable<StrictHttpResponse<ClearUserFavoriteGachaSlotResponse>> {
  const rb = new RequestBuilder(rootUrl, userServiceClearUserFavoriteGachaSlot.PATH, 'delete');
  if (params) {
    rb.path('userUuid', params.userUuid, {});
    rb.path('priority', params.priority, {});
    rb.query('discordId', params.discordId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ClearUserFavoriteGachaSlotResponse>;
    })
  );
}

userServiceClearUserFavoriteGachaSlot.PATH = '/api/v3/gateway/user/{userUuid}/favorite/gacha/{priority}';
