import { inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ResolveFn } from '@angular/router';
import { Subject, forkJoin, map, mergeMap, takeUntil, tap } from 'rxjs';
import { Gacha } from 'src/app/openapi/models';
import { GachaServiceService, MediaServiceService } from 'src/app/openapi/services';

export const resolveGacha: ResolveFn<boolean> = (route, state) => {
  const m = inject(Meta);
  const a = inject(GachaServiceService);
  const mS = inject(MediaServiceService);
  return a.gachaServiceGetGacha2({ gachaIds: route.params['gachaID'] })
    .pipe(
      mergeMap(gacha => mS.mediaServiceGetMedia({ mediaId: gacha.gacha![0].mediaId! })
        .pipe(
          tap(media => {
            const g = gacha.gacha![0];
            m.removeTag('name="title"');
            m.removeTag('name="description"');
            m.removeTag('name="og:title"');
            m.removeTag('name="og:description"');
            m.removeTag('name="og:image"');
            m.removeTag('name="twitter:image:src"');
            m.removeTag('name="twitter:card"');
            m.removeTag('name="theme-color"');

            m.addTag({ name: 'title', content: `Upa - ${g.name}` })
            m.addTag({ name: 'og:title', content: `Upa - ${g.name}` });
            m.addTag({ name: 'description', content: `View in-depth details about the gacha ${g.name}, a ${renderStars(g.rarity!)} gacha from ${media.media!.name!}` });
            m.addTag({ name: 'og:description', content: `View in-depth details about the gacha ${g.name}, a ${renderStars(g.rarity!)} gacha from ${media.media!.name!}` });
            m.addTag({ name: 'og:image', content: `${g.imageUrl}` });
            m.addTag({ name: 'twitter:image:src', content: `${g.imageUrl}` });
            m.addTag({ name: 'twitter:card', content: 'summary_large_image' });
            m.addTag({ name: 'theme-color', content: `${buildRarityColor(g)}` });
          })
        )
      ),
      map(_ => true),
    );
};

function renderStars(count: number): string {
  let stars = '';
  for (let i = 0; i < count; i++) {
    stars += '⭐';
  }
  return stars;
}

function buildRarityColor(gacha: Gacha): string {
  switch (gacha.rarity) {
    case 1:
      return "#c9c9c9";
    case 2:
      return "#7ab800";
    case 3:
      return "#4542e3";
    case 4:
      return "#9018db";
    case 5:
      return "#e3bb42";
    default:
      return "#c4c4c4";
  }
}