/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ApiHttpBody } from '../models/api-http-body';
import { BulkGetUserFavoriteGachasResponse } from '../models/bulk-get-user-favorite-gachas-response';
import { ClearUserFavoriteGachaSlotResponse } from '../models/clear-user-favorite-gacha-slot-response';
import { DeleteUserFavoriteMediaResponse } from '../models/delete-user-favorite-media-response';
import { GetMeResponse } from '../models/get-me-response';
import { GetUserFavoriteGachasResponse } from '../models/get-user-favorite-gachas-response';
import { GetUserFavoriteMediaResponse } from '../models/get-user-favorite-media-response';
import { GetUserGachaDatesResponse } from '../models/get-user-gacha-dates-response';
import { GetUserGachaMintedOutOfResponse } from '../models/get-user-gacha-minted-out-of-response';
import { GetUserGachasPaginatedResponse } from '../models/get-user-gachas-paginated-response';
import { GetUserResponse } from '../models/get-user-response';
import { GetUserSettingsResponse } from '../models/get-user-settings-response';
import { SetUserFavoriteGachasResponse } from '../models/set-user-favorite-gachas-response';
import { SetUserFavoriteMediaResponse } from '../models/set-user-favorite-media-response';
import { SetUserGachaEnhancementResponse } from '../models/set-user-gacha-enhancement-response';
import { UnsetUserGachaEnhancementResponse } from '../models/unset-user-gacha-enhancement-response';
import { UpdateUserSettingsResponse } from '../models/update-user-settings-response';
import { userServiceBulkGetUserFavoriteGachas } from '../fn/user-service/user-service-bulk-get-user-favorite-gachas';
import { UserServiceBulkGetUserFavoriteGachas$Params } from '../fn/user-service/user-service-bulk-get-user-favorite-gachas';
import { userServiceClearUserFavoriteGachaSlot } from '../fn/user-service/user-service-clear-user-favorite-gacha-slot';
import { UserServiceClearUserFavoriteGachaSlot$Params } from '../fn/user-service/user-service-clear-user-favorite-gacha-slot';
import { userServiceDeleteUserFavoriteMedia } from '../fn/user-service/user-service-delete-user-favorite-media';
import { UserServiceDeleteUserFavoriteMedia$Params } from '../fn/user-service/user-service-delete-user-favorite-media';
import { userServiceGenerateUserGachaImage } from '../fn/user-service/user-service-generate-user-gacha-image';
import { UserServiceGenerateUserGachaImage$Params } from '../fn/user-service/user-service-generate-user-gacha-image';
import { userServiceGetMe } from '../fn/user-service/user-service-get-me';
import { UserServiceGetMe$Params } from '../fn/user-service/user-service-get-me';
import { userServiceGetUser } from '../fn/user-service/user-service-get-user';
import { UserServiceGetUser$Params } from '../fn/user-service/user-service-get-user';
import { userServiceGetUser2 } from '../fn/user-service/user-service-get-user-2';
import { UserServiceGetUser2$Params } from '../fn/user-service/user-service-get-user-2';
import { userServiceGetUserFavoriteGachas } from '../fn/user-service/user-service-get-user-favorite-gachas';
import { UserServiceGetUserFavoriteGachas$Params } from '../fn/user-service/user-service-get-user-favorite-gachas';
import { userServiceGetUserFavoriteMedia } from '../fn/user-service/user-service-get-user-favorite-media';
import { UserServiceGetUserFavoriteMedia$Params } from '../fn/user-service/user-service-get-user-favorite-media';
import { userServiceGetUserGachaDates } from '../fn/user-service/user-service-get-user-gacha-dates';
import { UserServiceGetUserGachaDates$Params } from '../fn/user-service/user-service-get-user-gacha-dates';
import { userServiceGetUserGachaDates2 } from '../fn/user-service/user-service-get-user-gacha-dates-2';
import { UserServiceGetUserGachaDates2$Params } from '../fn/user-service/user-service-get-user-gacha-dates-2';
import { userServiceGetUserGachaMintedOutOf } from '../fn/user-service/user-service-get-user-gacha-minted-out-of';
import { UserServiceGetUserGachaMintedOutOf$Params } from '../fn/user-service/user-service-get-user-gacha-minted-out-of';
import { userServiceGetUserGachaMintedOutOf2 } from '../fn/user-service/user-service-get-user-gacha-minted-out-of-2';
import { UserServiceGetUserGachaMintedOutOf2$Params } from '../fn/user-service/user-service-get-user-gacha-minted-out-of-2';
import { userServiceGetUserGachasPaginated } from '../fn/user-service/user-service-get-user-gachas-paginated';
import { UserServiceGetUserGachasPaginated$Params } from '../fn/user-service/user-service-get-user-gachas-paginated';
import { userServiceGetUserSettings } from '../fn/user-service/user-service-get-user-settings';
import { UserServiceGetUserSettings$Params } from '../fn/user-service/user-service-get-user-settings';
import { userServiceSetUserFavoriteGachas } from '../fn/user-service/user-service-set-user-favorite-gachas';
import { UserServiceSetUserFavoriteGachas$Params } from '../fn/user-service/user-service-set-user-favorite-gachas';
import { userServiceSetUserFavoriteMedia } from '../fn/user-service/user-service-set-user-favorite-media';
import { UserServiceSetUserFavoriteMedia$Params } from '../fn/user-service/user-service-set-user-favorite-media';
import { userServiceSetUserGachaEnhancement } from '../fn/user-service/user-service-set-user-gacha-enhancement';
import { UserServiceSetUserGachaEnhancement$Params } from '../fn/user-service/user-service-set-user-gacha-enhancement';
import { userServiceSetUserGachaEnhancement2 } from '../fn/user-service/user-service-set-user-gacha-enhancement-2';
import { UserServiceSetUserGachaEnhancement2$Params } from '../fn/user-service/user-service-set-user-gacha-enhancement-2';
import { userServiceUnsetUserGachaEnhancement } from '../fn/user-service/user-service-unset-user-gacha-enhancement';
import { UserServiceUnsetUserGachaEnhancement$Params } from '../fn/user-service/user-service-unset-user-gacha-enhancement';
import { userServiceUnsetUserGachaEnhancement2 } from '../fn/user-service/user-service-unset-user-gacha-enhancement-2';
import { UserServiceUnsetUserGachaEnhancement2$Params } from '../fn/user-service/user-service-unset-user-gacha-enhancement-2';
import { userServiceUpdateUserSettings } from '../fn/user-service/user-service-update-user-settings';
import { UserServiceUpdateUserSettings$Params } from '../fn/user-service/user-service-update-user-settings';

@Injectable({ providedIn: 'root' })
export class UserServiceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `userServiceBulkGetUserFavoriteGachas()` */
  static readonly UserServiceBulkGetUserFavoriteGachasPath = '/api/v3/gateway/bulk/user/gacha';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userServiceBulkGetUserFavoriteGachas()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userServiceBulkGetUserFavoriteGachas$Response(params: UserServiceBulkGetUserFavoriteGachas$Params, context?: HttpContext): Observable<StrictHttpResponse<BulkGetUserFavoriteGachasResponse>> {
    return userServiceBulkGetUserFavoriteGachas(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userServiceBulkGetUserFavoriteGachas$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userServiceBulkGetUserFavoriteGachas(params: UserServiceBulkGetUserFavoriteGachas$Params, context?: HttpContext): Observable<BulkGetUserFavoriteGachasResponse> {
    return this.userServiceBulkGetUserFavoriteGachas$Response(params, context).pipe(
      map((r: StrictHttpResponse<BulkGetUserFavoriteGachasResponse>): BulkGetUserFavoriteGachasResponse => r.body)
    );
  }

  /** Path part for operation `userServiceGetUser2()` */
  static readonly UserServiceGetUser2Path = '/api/v3/gateway/user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userServiceGetUser2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userServiceGetUser2$Response(params: UserServiceGetUser2$Params, context?: HttpContext): Observable<StrictHttpResponse<GetUserResponse>> {
    return userServiceGetUser2(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userServiceGetUser2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userServiceGetUser2(params: UserServiceGetUser2$Params, context?: HttpContext): Observable<GetUserResponse> {
    return this.userServiceGetUser2$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetUserResponse>): GetUserResponse => r.body)
    );
  }

  /** Path part for operation `userServiceGetMe()` */
  static readonly UserServiceGetMePath = '/api/v3/gateway/user/s/me';

  /**
   * Specifically for HTTP gateway API, using the user ID extension to retrieve the user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userServiceGetMe()` instead.
   *
   * This method doesn't expect any request body.
   */
  userServiceGetMe$Response(params?: UserServiceGetMe$Params, context?: HttpContext): Observable<StrictHttpResponse<GetMeResponse>> {
    return userServiceGetMe(this.http, this.rootUrl, params, context);
  }

  /**
   * Specifically for HTTP gateway API, using the user ID extension to retrieve the user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userServiceGetMe$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userServiceGetMe(params?: UserServiceGetMe$Params, context?: HttpContext): Observable<GetMeResponse> {
    return this.userServiceGetMe$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetMeResponse>): GetMeResponse => r.body)
    );
  }

  /** Path part for operation `userServiceGetUserFavoriteGachas()` */
  static readonly UserServiceGetUserFavoriteGachasPath = '/api/v3/gateway/user/{userUuid}/favorite/gacha';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userServiceGetUserFavoriteGachas()` instead.
   *
   * This method doesn't expect any request body.
   */
  userServiceGetUserFavoriteGachas$Response(params: UserServiceGetUserFavoriteGachas$Params, context?: HttpContext): Observable<StrictHttpResponse<GetUserFavoriteGachasResponse>> {
    return userServiceGetUserFavoriteGachas(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userServiceGetUserFavoriteGachas$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userServiceGetUserFavoriteGachas(params: UserServiceGetUserFavoriteGachas$Params, context?: HttpContext): Observable<GetUserFavoriteGachasResponse> {
    return this.userServiceGetUserFavoriteGachas$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetUserFavoriteGachasResponse>): GetUserFavoriteGachasResponse => r.body)
    );
  }

  /** Path part for operation `userServiceSetUserFavoriteGachas()` */
  static readonly UserServiceSetUserFavoriteGachasPath = '/api/v3/gateway/user/{userUuid}/favorite/gacha';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userServiceSetUserFavoriteGachas()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userServiceSetUserFavoriteGachas$Response(params: UserServiceSetUserFavoriteGachas$Params, context?: HttpContext): Observable<StrictHttpResponse<SetUserFavoriteGachasResponse>> {
    return userServiceSetUserFavoriteGachas(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userServiceSetUserFavoriteGachas$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userServiceSetUserFavoriteGachas(params: UserServiceSetUserFavoriteGachas$Params, context?: HttpContext): Observable<SetUserFavoriteGachasResponse> {
    return this.userServiceSetUserFavoriteGachas$Response(params, context).pipe(
      map((r: StrictHttpResponse<SetUserFavoriteGachasResponse>): SetUserFavoriteGachasResponse => r.body)
    );
  }

  /** Path part for operation `userServiceClearUserFavoriteGachaSlot()` */
  static readonly UserServiceClearUserFavoriteGachaSlotPath = '/api/v3/gateway/user/{userUuid}/favorite/gacha/{priority}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userServiceClearUserFavoriteGachaSlot()` instead.
   *
   * This method doesn't expect any request body.
   */
  userServiceClearUserFavoriteGachaSlot$Response(params: UserServiceClearUserFavoriteGachaSlot$Params, context?: HttpContext): Observable<StrictHttpResponse<ClearUserFavoriteGachaSlotResponse>> {
    return userServiceClearUserFavoriteGachaSlot(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userServiceClearUserFavoriteGachaSlot$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userServiceClearUserFavoriteGachaSlot(params: UserServiceClearUserFavoriteGachaSlot$Params, context?: HttpContext): Observable<ClearUserFavoriteGachaSlotResponse> {
    return this.userServiceClearUserFavoriteGachaSlot$Response(params, context).pipe(
      map((r: StrictHttpResponse<ClearUserFavoriteGachaSlotResponse>): ClearUserFavoriteGachaSlotResponse => r.body)
    );
  }

  /** Path part for operation `userServiceGetUserFavoriteMedia()` */
  static readonly UserServiceGetUserFavoriteMediaPath = '/api/v3/gateway/user/{userUuid}/favorite/media';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userServiceGetUserFavoriteMedia()` instead.
   *
   * This method doesn't expect any request body.
   */
  userServiceGetUserFavoriteMedia$Response(params: UserServiceGetUserFavoriteMedia$Params, context?: HttpContext): Observable<StrictHttpResponse<GetUserFavoriteMediaResponse>> {
    return userServiceGetUserFavoriteMedia(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userServiceGetUserFavoriteMedia$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userServiceGetUserFavoriteMedia(params: UserServiceGetUserFavoriteMedia$Params, context?: HttpContext): Observable<GetUserFavoriteMediaResponse> {
    return this.userServiceGetUserFavoriteMedia$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetUserFavoriteMediaResponse>): GetUserFavoriteMediaResponse => r.body)
    );
  }

  /** Path part for operation `userServiceSetUserFavoriteMedia()` */
  static readonly UserServiceSetUserFavoriteMediaPath = '/api/v3/gateway/user/{userUuid}/favorite/media';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userServiceSetUserFavoriteMedia()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userServiceSetUserFavoriteMedia$Response(params: UserServiceSetUserFavoriteMedia$Params, context?: HttpContext): Observable<StrictHttpResponse<SetUserFavoriteMediaResponse>> {
    return userServiceSetUserFavoriteMedia(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userServiceSetUserFavoriteMedia$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userServiceSetUserFavoriteMedia(params: UserServiceSetUserFavoriteMedia$Params, context?: HttpContext): Observable<SetUserFavoriteMediaResponse> {
    return this.userServiceSetUserFavoriteMedia$Response(params, context).pipe(
      map((r: StrictHttpResponse<SetUserFavoriteMediaResponse>): SetUserFavoriteMediaResponse => r.body)
    );
  }

  /** Path part for operation `userServiceDeleteUserFavoriteMedia()` */
  static readonly UserServiceDeleteUserFavoriteMediaPath = '/api/v3/gateway/user/{userUuid}/favorite/media';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userServiceDeleteUserFavoriteMedia()` instead.
   *
   * This method doesn't expect any request body.
   */
  userServiceDeleteUserFavoriteMedia$Response(params: UserServiceDeleteUserFavoriteMedia$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteUserFavoriteMediaResponse>> {
    return userServiceDeleteUserFavoriteMedia(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userServiceDeleteUserFavoriteMedia$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userServiceDeleteUserFavoriteMedia(params: UserServiceDeleteUserFavoriteMedia$Params, context?: HttpContext): Observable<DeleteUserFavoriteMediaResponse> {
    return this.userServiceDeleteUserFavoriteMedia$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteUserFavoriteMediaResponse>): DeleteUserFavoriteMediaResponse => r.body)
    );
  }

  /** Path part for operation `userServiceGetUserGachasPaginated()` */
  static readonly UserServiceGetUserGachasPaginatedPath = '/api/v3/gateway/user/{userUuid}/gacha';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userServiceGetUserGachasPaginated()` instead.
   *
   * This method doesn't expect any request body.
   */
  userServiceGetUserGachasPaginated$Response(params: UserServiceGetUserGachasPaginated$Params, context?: HttpContext): Observable<StrictHttpResponse<GetUserGachasPaginatedResponse>> {
    return userServiceGetUserGachasPaginated(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userServiceGetUserGachasPaginated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userServiceGetUserGachasPaginated(params: UserServiceGetUserGachasPaginated$Params, context?: HttpContext): Observable<GetUserGachasPaginatedResponse> {
    return this.userServiceGetUserGachasPaginated$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetUserGachasPaginatedResponse>): GetUserGachasPaginatedResponse => r.body)
    );
  }

  /** Path part for operation `userServiceGetUserGachaDates2()` */
  static readonly UserServiceGetUserGachaDates2Path = '/api/v3/gateway/user/{userUuid}/gacha/dates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userServiceGetUserGachaDates2()` instead.
   *
   * This method doesn't expect any request body.
   */
  userServiceGetUserGachaDates2$Response(params: UserServiceGetUserGachaDates2$Params, context?: HttpContext): Observable<StrictHttpResponse<GetUserGachaDatesResponse>> {
    return userServiceGetUserGachaDates2(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userServiceGetUserGachaDates2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userServiceGetUserGachaDates2(params: UserServiceGetUserGachaDates2$Params, context?: HttpContext): Observable<GetUserGachaDatesResponse> {
    return this.userServiceGetUserGachaDates2$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetUserGachaDatesResponse>): GetUserGachaDatesResponse => r.body)
    );
  }

  /** Path part for operation `userServiceGetUserGachaDates()` */
  static readonly UserServiceGetUserGachaDatesPath = '/api/v3/gateway/user/{userUuid}/gacha/dates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userServiceGetUserGachaDates()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userServiceGetUserGachaDates$Response(params: UserServiceGetUserGachaDates$Params, context?: HttpContext): Observable<StrictHttpResponse<GetUserGachaDatesResponse>> {
    return userServiceGetUserGachaDates(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userServiceGetUserGachaDates$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userServiceGetUserGachaDates(params: UserServiceGetUserGachaDates$Params, context?: HttpContext): Observable<GetUserGachaDatesResponse> {
    return this.userServiceGetUserGachaDates$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetUserGachaDatesResponse>): GetUserGachaDatesResponse => r.body)
    );
  }

  /** Path part for operation `userServiceSetUserGachaEnhancement2()` */
  static readonly UserServiceSetUserGachaEnhancement2Path = '/api/v3/gateway/user/{userUuid}/gacha/instance/{gachaInstanceId}/enhancement';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userServiceSetUserGachaEnhancement2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userServiceSetUserGachaEnhancement2$Response(params: UserServiceSetUserGachaEnhancement2$Params, context?: HttpContext): Observable<StrictHttpResponse<SetUserGachaEnhancementResponse>> {
    return userServiceSetUserGachaEnhancement2(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userServiceSetUserGachaEnhancement2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userServiceSetUserGachaEnhancement2(params: UserServiceSetUserGachaEnhancement2$Params, context?: HttpContext): Observable<SetUserGachaEnhancementResponse> {
    return this.userServiceSetUserGachaEnhancement2$Response(params, context).pipe(
      map((r: StrictHttpResponse<SetUserGachaEnhancementResponse>): SetUserGachaEnhancementResponse => r.body)
    );
  }

  /** Path part for operation `userServiceUnsetUserGachaEnhancement2()` */
  static readonly UserServiceUnsetUserGachaEnhancement2Path = '/api/v3/gateway/user/{userUuid}/gacha/instance/{gachaInstanceId}/enhancement';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userServiceUnsetUserGachaEnhancement2()` instead.
   *
   * This method doesn't expect any request body.
   */
  userServiceUnsetUserGachaEnhancement2$Response(params: UserServiceUnsetUserGachaEnhancement2$Params, context?: HttpContext): Observable<StrictHttpResponse<UnsetUserGachaEnhancementResponse>> {
    return userServiceUnsetUserGachaEnhancement2(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userServiceUnsetUserGachaEnhancement2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userServiceUnsetUserGachaEnhancement2(params: UserServiceUnsetUserGachaEnhancement2$Params, context?: HttpContext): Observable<UnsetUserGachaEnhancementResponse> {
    return this.userServiceUnsetUserGachaEnhancement2$Response(params, context).pipe(
      map((r: StrictHttpResponse<UnsetUserGachaEnhancementResponse>): UnsetUserGachaEnhancementResponse => r.body)
    );
  }

  /** Path part for operation `userServiceGetUserGachaMintedOutOf()` */
  static readonly UserServiceGetUserGachaMintedOutOfPath = '/api/v3/gateway/user/{userUuid}/gacha/minted';

  /**
   * Get the "minted # out of total population" for every gacha a user has. If a list of gachaIDs is provided,
   * we use that instead. This makes pagination an ideal for frontend clients.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userServiceGetUserGachaMintedOutOf()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userServiceGetUserGachaMintedOutOf$Response(params: UserServiceGetUserGachaMintedOutOf$Params, context?: HttpContext): Observable<StrictHttpResponse<GetUserGachaMintedOutOfResponse>> {
    return userServiceGetUserGachaMintedOutOf(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the "minted # out of total population" for every gacha a user has. If a list of gachaIDs is provided,
   * we use that instead. This makes pagination an ideal for frontend clients.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userServiceGetUserGachaMintedOutOf$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userServiceGetUserGachaMintedOutOf(params: UserServiceGetUserGachaMintedOutOf$Params, context?: HttpContext): Observable<GetUserGachaMintedOutOfResponse> {
    return this.userServiceGetUserGachaMintedOutOf$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetUserGachaMintedOutOfResponse>): GetUserGachaMintedOutOfResponse => r.body)
    );
  }

  /** Path part for operation `userServiceGetUserGachaMintedOutOf2()` */
  static readonly UserServiceGetUserGachaMintedOutOf2Path = '/api/v3/gateway/user/{userUuid}/gacha/minted/{gachaIds}';

  /**
   * Get the "minted # out of total population" for every gacha a user has. If a list of gachaIDs is provided,
   * we use that instead. This makes pagination an ideal for frontend clients.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userServiceGetUserGachaMintedOutOf2()` instead.
   *
   * This method doesn't expect any request body.
   */
  userServiceGetUserGachaMintedOutOf2$Response(params: UserServiceGetUserGachaMintedOutOf2$Params, context?: HttpContext): Observable<StrictHttpResponse<GetUserGachaMintedOutOfResponse>> {
    return userServiceGetUserGachaMintedOutOf2(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the "minted # out of total population" for every gacha a user has. If a list of gachaIDs is provided,
   * we use that instead. This makes pagination an ideal for frontend clients.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userServiceGetUserGachaMintedOutOf2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userServiceGetUserGachaMintedOutOf2(params: UserServiceGetUserGachaMintedOutOf2$Params, context?: HttpContext): Observable<GetUserGachaMintedOutOfResponse> {
    return this.userServiceGetUserGachaMintedOutOf2$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetUserGachaMintedOutOfResponse>): GetUserGachaMintedOutOfResponse => r.body)
    );
  }

  /** Path part for operation `userServiceSetUserGachaEnhancement()` */
  static readonly UserServiceSetUserGachaEnhancementPath = '/api/v3/gateway/user/{userUuid}/gacha/{gachaId}/enhancement';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userServiceSetUserGachaEnhancement()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userServiceSetUserGachaEnhancement$Response(params: UserServiceSetUserGachaEnhancement$Params, context?: HttpContext): Observable<StrictHttpResponse<SetUserGachaEnhancementResponse>> {
    return userServiceSetUserGachaEnhancement(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userServiceSetUserGachaEnhancement$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userServiceSetUserGachaEnhancement(params: UserServiceSetUserGachaEnhancement$Params, context?: HttpContext): Observable<SetUserGachaEnhancementResponse> {
    return this.userServiceSetUserGachaEnhancement$Response(params, context).pipe(
      map((r: StrictHttpResponse<SetUserGachaEnhancementResponse>): SetUserGachaEnhancementResponse => r.body)
    );
  }

  /** Path part for operation `userServiceUnsetUserGachaEnhancement()` */
  static readonly UserServiceUnsetUserGachaEnhancementPath = '/api/v3/gateway/user/{userUuid}/gacha/{gachaId}/enhancement';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userServiceUnsetUserGachaEnhancement()` instead.
   *
   * This method doesn't expect any request body.
   */
  userServiceUnsetUserGachaEnhancement$Response(params: UserServiceUnsetUserGachaEnhancement$Params, context?: HttpContext): Observable<StrictHttpResponse<UnsetUserGachaEnhancementResponse>> {
    return userServiceUnsetUserGachaEnhancement(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userServiceUnsetUserGachaEnhancement$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userServiceUnsetUserGachaEnhancement(params: UserServiceUnsetUserGachaEnhancement$Params, context?: HttpContext): Observable<UnsetUserGachaEnhancementResponse> {
    return this.userServiceUnsetUserGachaEnhancement$Response(params, context).pipe(
      map((r: StrictHttpResponse<UnsetUserGachaEnhancementResponse>): UnsetUserGachaEnhancementResponse => r.body)
    );
  }

  /** Path part for operation `userServiceGenerateUserGachaImage()` */
  static readonly UserServiceGenerateUserGachaImagePath = '/api/v3/gateway/user/{userUuid}/gacha/{gachaId}/image';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userServiceGenerateUserGachaImage()` instead.
   *
   * This method doesn't expect any request body.
   */
  userServiceGenerateUserGachaImage$Response(params: UserServiceGenerateUserGachaImage$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiHttpBody>> {
    return userServiceGenerateUserGachaImage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userServiceGenerateUserGachaImage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userServiceGenerateUserGachaImage(params: UserServiceGenerateUserGachaImage$Params, context?: HttpContext): Observable<ApiHttpBody> {
    return this.userServiceGenerateUserGachaImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiHttpBody>): ApiHttpBody => r.body)
    );
  }

  /** Path part for operation `userServiceGetUserSettings()` */
  static readonly UserServiceGetUserSettingsPath = '/api/v3/gateway/user/{userUuid}/settings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userServiceGetUserSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  userServiceGetUserSettings$Response(params: UserServiceGetUserSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<GetUserSettingsResponse>> {
    return userServiceGetUserSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userServiceGetUserSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userServiceGetUserSettings(params: UserServiceGetUserSettings$Params, context?: HttpContext): Observable<GetUserSettingsResponse> {
    return this.userServiceGetUserSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetUserSettingsResponse>): GetUserSettingsResponse => r.body)
    );
  }

  /** Path part for operation `userServiceUpdateUserSettings()` */
  static readonly UserServiceUpdateUserSettingsPath = '/api/v3/gateway/user/{userUuid}/settings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userServiceUpdateUserSettings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userServiceUpdateUserSettings$Response(params: UserServiceUpdateUserSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateUserSettingsResponse>> {
    return userServiceUpdateUserSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userServiceUpdateUserSettings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userServiceUpdateUserSettings(params: UserServiceUpdateUserSettings$Params, context?: HttpContext): Observable<UpdateUserSettingsResponse> {
    return this.userServiceUpdateUserSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateUserSettingsResponse>): UpdateUserSettingsResponse => r.body)
    );
  }

  /** Path part for operation `userServiceGetUser()` */
  static readonly UserServiceGetUserPath = '/api/v3/gateway/user/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userServiceGetUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  userServiceGetUser$Response(params: UserServiceGetUser$Params, context?: HttpContext): Observable<StrictHttpResponse<GetUserResponse>> {
    return userServiceGetUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userServiceGetUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userServiceGetUser(params: UserServiceGetUser$Params, context?: HttpContext): Observable<GetUserResponse> {
    return this.userServiceGetUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetUserResponse>): GetUserResponse => r.body)
    );
  }

}
