<ng-container *ngFor="let entry of altArt | keyvalue">
  <ng-container *ngFor="let altArtEntry of entry.value.altArtOwnershipDetails!">
    <app-view-alt-art-card
      *ngIf="gachas && gachas.get(entry.key)  && findAltArtForGachaId(entry.key, altArtEntry.altArtId!)"
      [gacha]="gachas.get(entry.key)!" [altArt]="findAltArtForGachaId(entry.key, altArtEntry.altArtId!)!"
      [altArtSetState]="gachaIsSet && gachaIsSet[entry.key] ? gachaIsSet[entry.key][altArtEntry.altArtId!] ? 'SET' : 'NOT_SET' : 'NOT_OWNED'"
      (setRefresh)="loadAltArts()"></app-view-alt-art-card>
    <!-- <button class="button" *ngIf="!altArtEntry.isCurrentlySetAltArt">Set Alt Art</button> -->
  </ng-container>
</ng-container>
<h1 class="title" *ngIf="!altArt">No alt art owned!</h1>