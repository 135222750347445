import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, lastValueFrom, map, Observable, switchMap, tap } from 'rxjs';
import { AuthService, canViewDeleteListing } from 'src/app/auth/auth.service';
import { FilterUserGachaAgainstResponse, GachaMintOutOf, GetMarketplaceRepresentedMediaResponseMediaMetadata, GetMeResponse, MarketplaceListing, Media } from 'src/app/openapi/models';
import { MarketplaceServiceService, UserServiceService } from 'src/app/openapi/services';
import { of } from 'rxjs';

@Component({
  selector: 'app-view-marketplace',
  templateUrl: './view-marketplace.component.html',
  styleUrl: './view-marketplace.component.scss'
})
export class ViewMarketplaceComponent implements OnInit {

  availableMedias: Media[] = [];
  mediaMetadata: { [key: string]: GetMarketplaceRepresentedMediaResponseMediaMetadata } = {};

  marketplaceListings?: MarketplaceListing[];
  shownMarketplaceListings?: MarketplaceListing[];
  mintedMap: { [key: string]: GachaMintOutOf } = {};

  userUUID?: string;

  gachaRarities = [1, 2, 3, 4, 5, 6];

  marketplaceAdminMode = false;
  canViewDeleteListing = canViewDeleteListing;

  stardustBalance?: number;

  constructor(
    private marketplaceService: MarketplaceServiceService,
    public authService: AuthService,
    private userService: UserServiceService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.authService.user$.pipe(
      tap(u => {
        this.userUUID = u?.user_uuid;
      }),
    ).subscribe();
    this.reloadStardustBalance().subscribe();
    this.route.params.pipe(
      switchMap(p => {
        if (p['listingID'] === undefined) {
          return this.marketplaceService.marketplaceServiceGetMarketplace();
        }
        return this.marketplaceService.marketplaceServiceGetMarketplace({ "filterOptions.listingId": p['listingID'] });
      })
    ).subscribe(marketplace => {
      this.marketplaceListings = marketplace.marketplaceListings || [];
      this.shownMarketplaceListings = this.marketplaceListings;
      this.mintedMap = marketplace.gachaMintOutOf || {};
    });
    this.marketplaceService.marketplaceServiceGetMarketplaceRepresentedMedia().subscribe(medias => {
      this.availableMedias = medias.media?.sort((a, b) => {
        return (a.name || '').localeCompare(b.name || '');
      }) || [];
      this.mediaMetadata = medias.mediaMetadata || {};
    });
  }

  reloadStardustBalance(): Observable<GetMeResponse> {
    return this.authService.user$.pipe(
      filter(u => u !== undefined),
      switchMap(_ => {
        return this.userService.userServiceGetMe().pipe(
          tap(me => {
            if (!me.user?.attributes?.stardust) {
              return;
            }
            this.stardustBalance = +me.user.attributes.stardust;
          })
        );
      }),
    );
  }

  reloadMarketplaceIndividualCard(listingId: string) {
    // Reload a single card, and then replace the current card within marketplaceListings
    this.marketplaceService.marketplaceServiceGetMarketplace({ "filterOptions.listingId": listingId }).subscribe(marketplace => {
      const listing = marketplace.marketplaceListings?.[0];
      if (listing === undefined) {
        // Listing not found, so it might have been removed.
        // Remove the listing from the list
        const index = this.marketplaceListings?.findIndex(l => l.listingId === listingId);
        if (index === undefined || index === -1) {
          return;
        }
        this.marketplaceListings?.splice(index, 1);
        this.shownMarketplaceListings = this.marketplaceListings;
        return;
      }
      let index = this.marketplaceListings?.findIndex(l => l.listingId === listingId);
      if (index === undefined || index === -1 || !this.marketplaceListings) {
        return;
      }
      this.marketplaceListings[index] = listing;
      this.shownMarketplaceListings = this.marketplaceListings;
    });
    this.reloadStardustBalance().subscribe();
  }

  selectedMediaOrAll: string | "allMedias" = "allMedias";
  loadMarketplaceForMedia() {
    if (this.selectedMediaOrAll === undefined) {
      this.reloadMarketplace();
      return;
    }
    if (this.selectedMediaOrAll === 'allMedias') {
      this.reloadMarketplace();
      return;
    }
    this.marketplaceService.marketplaceServiceGetMarketplace({ "filterOptions.mediaId": this.selectedMediaOrAll }).subscribe(marketplace => {
      this.marketplaceListings = marketplace.marketplaceListings || [];
      this.shownMarketplaceListings = this.marketplaceListings;
      this.mintedMap = marketplace.gachaMintOutOf || {};
    });
  }

  loadMarketplaceForMyself() {
    if (this.userUUID === undefined) {
      return;
    }
    this.marketplaceService.marketplaceServiceGetMarketplace({ "filterOptions.userUuid": this.userUUID }).subscribe(marketplace => {
      this.marketplaceListings = marketplace.marketplaceListings || [];
      this.shownMarketplaceListings = this.marketplaceListings;
      this.mintedMap = marketplace.gachaMintOutOf || {};
    });
  }

  reloadMarketplace(resetFilters: boolean = false) {
    this.marketplaceService.marketplaceServiceGetMarketplace().subscribe(marketplace => {
      this.marketplaceListings = marketplace.marketplaceListings || [];
      this.shownMarketplaceListings = this.marketplaceListings;
      this.mintedMap = marketplace.gachaMintOutOf || {};
      this.router.navigate(['/view/marketplace'], { replaceUrl: true });
      if (resetFilters) {
        this.activeFilters = [];
        this.selectedSort = "DEFAULT";
      } else {
        this.applyFrontendFilters(this.activeFilters);
        this.applyFrontendSort();
      }
    });
    this.reloadStardustBalance().subscribe();
  }

  toggleRarityFilter(r: number) {
    this.toggleFilter(`RARITY_${r}` as enumPossibleFilters);
  }

  toggleFilter(filter: enumPossibleFilters) {
    if (this.activeFilters.includes(filter)) {
      this.activeFilters = this.activeFilters.filter(f => f !== filter);
    } else {
      this.activeFilters.push(filter);
    }
    this.applyFrontendFilters(this.activeFilters);
  }

  activeFilters: enumPossibleFilters[] = [];
  // Temporary ways to just do frontend filters
  async applyFrontendFilters(filters: enumPossibleFilters[]) {
    if (this.marketplaceListings === undefined) {
      return;
    }
    if (filters.length === 0) {
      this.shownMarketplaceListings = this.marketplaceListings;
      return;
    }
    let activeFiltersFns = [];
    if (filters.includes("NOT_OWNED_BY_ME")) {
      activeFiltersFns.push(this.filterNotOwnedByMe());
    }
    {
      let filterForRarities: number[] = [];
      // Put each rarity filtered for, RARITY_1, RARITY_2, etc as the number
      // this shit sucks but whatever
      if (filters.includes("RARITY_1")) filterForRarities.push(1);
      if (filters.includes("RARITY_2")) filterForRarities.push(2);
      if (filters.includes("RARITY_3")) filterForRarities.push(3);
      if (filters.includes("RARITY_4")) filterForRarities.push(4);
      if (filters.includes("RARITY_5")) filterForRarities.push(5);
      if (filters.includes("RARITY_6")) filterForRarities.push(6);

      if (filterForRarities.length > 0) {
        activeFiltersFns.push(filterForRarity(filterForRarities));
      }
    }

    // Apply each filter as a chain of promises
    this.shownMarketplaceListings = this.marketplaceListings;
    if (activeFiltersFns.length === 0) {
      return;
    }

    for (let filter of activeFiltersFns) {
      this.shownMarketplaceListings = await filter(this.marketplaceListings || []);
    }

    // var generateFilteredGachaIDs: Observable<FilterUserGachaAgainstResponse> = of({
    //   gacha: this.shownMarketplaceListings?.filter(l => l.item?.gachaInstance?.gacha)
    //     .map(l => l.item!.gachaInstance!.gacha!) || []
    // });

    // if (filters.includes("NOT_OWNED_BY_ME")) {
    //   generateFilteredGachaIDs =
    //     this.marketplaceService.marketplaceServiceFilterUserGachaAgainst({
    //       body: {
    //         gachaIds: this.marketplaceListings!.filter(l => l.item?.gachaInstance?.gacha?.id)
    //           .map(l => l.item!.gachaInstance!.gacha!.id!),
    //         userUuid: this.userUUID!
    //       }
    //     });
    // }

    // generateFilteredGachaIDs.subscribe(res => {
    //   const goodGachaIDs = res.gacha?.map(g => g.id) || [];
    //   this.shownMarketplaceListings = this.marketplaceListings!.filter(listing => {
    //     if (filters.includes("NOT_OWNED_BY_ME")) {
    //       return goodGachaIDs.includes(listing.item?.gachaInstance?.gacha?.id);
    //     }
    //     if (filters.includes("RARITY_1") && listing.item?.gachaInstance?.gacha?.rarity !== 1) {
    //       return false;
    //     }
    //     if (filters.includes("RARITY_2") && listing.item?.gachaInstance?.gacha?.rarity !== 2) {
    //       return false;
    //     }
    //     if (filters.includes("RARITY_3") && listing.item?.gachaInstance?.gacha?.rarity !== 3) {
    //       return false;
    //     }
    //     if (filters.includes("RARITY_4") && listing.item?.gachaInstance?.gacha?.rarity !== 4) {
    //       return false;
    //     }
    //     if (filters.includes("RARITY_5") && listing.item?.gachaInstance?.gacha?.rarity !== 5) {
    //       return false;
    //     }
    //     if (filters.includes("RARITY_6") && listing.item?.gachaInstance?.gacha?.rarity !== 6) {
    //       return false;
    //     }
    //     return true;
    //   });
    // });
  }

  selectedSort: enumPossibleSort = "DEFAULT";
  applyFrontendSort() {
    if (this.shownMarketplaceListings === undefined) {
      return;
    }
    if (this.selectedSort === "DEFAULT") {
      this.shownMarketplaceListings = this.marketplaceListings;
    } else if (this.selectedSort === "PRICE_ASC") {
      this.shownMarketplaceListings = this.shownMarketplaceListings.sort((a, b) => {
        // Sort by rarity from high to low, then by stardustPrice low to high
        if (a.item!.gachaInstance!.gacha!.rarity !== b.item!.gachaInstance!.gacha!.rarity) {
          return (b.item!.gachaInstance!.gacha!.rarity!) - (a.item!.gachaInstance!.gacha!.rarity!);
        }
        return a.stardustPrice! - b.stardustPrice!;
      });
    } else if (this.selectedSort === "CREATED_AT_DESC") {
      this.shownMarketplaceListings = this.shownMarketplaceListings.sort((a, b) => {
        return new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime();
      });
    }
  }

  filterNotOwnedByMe(): (input: MarketplaceListing[]) => Promise<MarketplaceListing[]> {
    return (input: MarketplaceListing[]) => {
      let filtered = this.marketplaceService.marketplaceServiceFilterUserGachaAgainst({
        body: {
          gachaIds: this.marketplaceListings!.filter(l => l.item?.gachaInstance?.gacha?.id)
            .map(l => l.item!.gachaInstance!.gacha!.id!),
          userUuid: this.userUUID!
        }
      }).pipe(
        map(res => {
          const goodGachaIDs = res.gacha?.map(g => g.id) || [];
          return input.filter(listing => {
            return goodGachaIDs.includes(listing.item?.gachaInstance?.gacha?.id);
          });
        })
      );
      return lastValueFrom(filtered);
    }
  }
}

function filterForRarity(rarities: number[]): (input: MarketplaceListing[]) => Promise<MarketplaceListing[]> {
  return (input: MarketplaceListing[]) => {
    return new Promise((resolve, reject) => {
      resolve(input.filter(listing => {
        if (rarities.includes(6)) {
          if (listing.item?.gachaInstance?.gacha?.category === "DUO") {
            return true;
          }
        }
        return rarities.includes(listing.item?.gachaInstance?.gacha?.rarity || 0);
      }));
    });
  }
}

type enumPossibleFilters = "RARITY_1" | "RARITY_2" | "RARITY_3" | "RARITY_4" | "RARITY_5" | "RARITY_6" |
  "NOT_OWNED_BY_ME";
type enumPossibleSort = "DEFAULT" | "PRICE_ASC" | "CREATED_AT_DESC";