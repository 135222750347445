<ng-container *ngIf="banner">
  <div class="card">
    <div *ngIf="banner.imageStates.length > 0" class="card-image">
      <figure class="image parent">
        <img class="relimage" src="{{ banner.imageStates[0].imageURL }}" alt="Banner gacha image">
        <ng-container *ngFor="let i of banner.imageStates;">
          <img class="absimage" [@crossfade]="i.state" src="{{ i.imageURL }}" alt="Banner gacha image">
        </ng-container>
      </figure>
    </div>
    <div class="card-content">
      <div class="media">
        <div class="media-content">
          <p class="title is-4">{{ banner.banner.name }}</p>
          <p class="subtitle is-6">
            {{ tmplToDate(banner.banner.startTime!) | date: 'shortDate' }} {{ tmplToDate(banner.banner.startTime!) |
            date:
            'shortTime' }}
            -
            {{ tmplToDate(banner.banner.endTime!) | date: 'shortDate' }} {{ tmplToDate(banner.banner.endTime!) | date:
            'shortTime'
            }}
          </p>
        </div>
      </div>
      <div class="content">
        {{ banner.banner.description! }}
      </div>
      <hr style="height:1px;color:gray;background-color:gray">
      <div class="content">
        <p class="subtitle is-6">Rate-up bonus for each star</p>
        <ng-container *ngFor="let k of banner.banner.rateUpGachas | keyvalue; let i = index;">
          <ng-container *ngIf="hasGachaForRarity(Number(k.key), banner.banner.id!)">
            {{k.key}}⭐| {{ k.value.rateUpPercentage! * 100 }}%
            <ng-container *ngFor="let g of getGachasForRarity(Number(k.key), banner.banner.id!); let isLast=last">
              <a [routerLink]="['/view/gacha/', g.gachaID]">{{ g.gachaName }}</a>{{isLast ? '' : ', '}}
            </ng-container>
            <br>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>