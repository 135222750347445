import { DatePipe } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, filter, map, of, shareReplay, take, tap } from 'rxjs';
import { getProtoFieldNumberForUserAccessRights } from 'src/app/auth/auth-guard';
import { AuthService } from 'src/app/auth/auth.service';
import { AbuseServiceShadowbanUser$Params } from 'src/app/openapi/fn/abuse-service/abuse-service-shadowban-user';
import { Trade, TraderAndOffer, User, ViewUserSummaryResponse, ViewUserSummaryResponseGachaAndClaimerData } from 'src/app/openapi/models';
import { GACHA_ACQUISITION_METHOD } from 'src/app/openapi/models/gacha-acquisition-method-array';
import { AbuseServiceService, UserServiceService } from 'src/app/openapi/services';

@Component({
  selector: 'app-abuse',
  templateUrl: './abuse.component.html',
  styleUrl: './abuse.component.scss'
})
export class AbuseComponent {
  targetUserIDOrUUID: string = "";
  guaranteedUserID: string = "";

  response?: ViewUserSummaryResponse;
  userCanBan: boolean = false;
  permanentBan: boolean = false;
  banReason?: string;
  banExpirationDate: string = this.today();

  @ViewChild('shadowbanModal') shadowbanModal!: ElementRef;

  constructor(
    private auth: AuthService,
    private abuseService: AbuseServiceService,
    private userService: UserServiceService,
    private toastr: ToastrService) { }

  loadUser() {
    this.toastr.info("Loading user details... (shit might take a while)");
    this.auth.user$.pipe(
      take(1),
      filter(u => !!u),
      filter(u => u!.rights.includes(getProtoFieldNumberForUserAccessRights("ABUSE_VIEWER")) || u!.rights.includes(getProtoFieldNumberForUserAccessRights("SUPERUSER"))),
      tap(u => {
        if (u!.rights.includes(getProtoFieldNumberForUserAccessRights("BAN_POWER")) ||
          u!.rights.includes(getProtoFieldNumberForUserAccessRights("SUPERUSER"))) {
          this.userCanBan = true;
        }
      }),
    ).subscribe(u => {
      this.abuseService.abuseServiceViewUserSummary({ targetId: this.targetUserIDOrUUID }).subscribe(r => {
        this.toastr.success('Loaded :D');
        this.guaranteedUserID = r.userId!;
        // If traderOne ID != userID, then swap them
        if (!r.tradeHistory) {
          this.toastr.info('no trade hsitory')
          return;
        }
        for (let i = 0; i < r.tradeHistory.length; i++) {
          if (r.tradeHistory[i].traderOne?.traderUserId !== this.guaranteedUserID) {
            let temp = r.tradeHistory[i].traderOne;
            r.tradeHistory[i].traderOne = r.tradeHistory[i].traderTwo;
            r.tradeHistory[i].traderTwo = temp;
          }
        }
        // Filter no item trades
        r.tradeHistory = r.tradeHistory.filter(t => !this.noItemTrade(t));
        this.response = r;
        this.response.tradeHistory?.forEach(t => {
          this.loadUserDetails(t.traderOne!.traderUserId!);
          this.loadUserDetails(t.traderTwo!.traderUserId!);
        });
      });
    }, err => this.toastr.error(`errored loading: ${err}`));
  }

  userCache: { [key: string]: User } = {};
  userRequests: { [key: string]: Subject<User> } = {}; // Track in-flight requests

  loadUserDetails(userID: string): Observable<User> {
    if (this.userCache[userID]) {
      return of(this.userCache[userID]); // Return from cache
    }

    if (!this.userRequests[userID]) {
      this.userRequests[userID] = new Subject<User>(); // Create a request tracker

      this.userService.userServiceGetUser2({ body: { discordId: userID } })
        .pipe(
          tap(u => this.userCache[userID] = u.user!),
          map(response => response.user!), // Map to 'User' type
          shareReplay(1), // Cache the latest emission 
          take(1) // Ensures that the upstream gets unsubscribed after the first emission
        )
        .subscribe(this.userRequests[userID]); // Fulfill in-flight request
    }

    return this.userRequests[userID].asObservable(); // Return observable of the request
  }

  noItemTrade(t: Trade): boolean {
    return this.formatUserItems(t.traderOne!) === 'no items!' && this.formatUserItems(t.traderTwo!) === 'no items!';
  }

  formatUserItems(items: TraderAndOffer): string {
    if (!items || !items.items) {
      return 'no items!';
    }
    var str = '';
    for (let item of items.items) {
      if (item.backpackItem && item.backpackItem.backpackItem) {
        const i = item.backpackItem.backpackItem;
        if (i.altArtMaterial) {
          str += `Alt Art Material: ${i.quantity}<br/>`;
        }
        if (i.boosterPack) {
          str += `Booster Pack: ${i.boosterPack.name} ${i.quantity}<br/>`;
        }
        if (i.cardEffect) {
          str += `Card Effect: ${i.cardEffect} ${i.quantity}<br/>`;
        }
        if (i.wishlistToken) {
          str += `Wishlist Token: ${i.quantity}<br/>`;
        }
      }
      if (item.gacha) {
        const g = item.gacha.gacha!;
        str += `Gacha: ${g.gacha?.name} ${g.gacha?.rarity}⭐<br/>`;
      }
      if (item.stardust) {
        str += `Stardust: ${item.stardust.stardustAmount}<br/>`;
      }
    }
    return str;
  }

  formatOwnedGacha(stat: ViewUserSummaryResponseGachaAndClaimerData): string {
    if (!stat.gachaAcquisitionCounts) {
      return 'no gacha!';
    }
    var str = '';
    for (const [acquisitionMethod, count] of Object.entries(stat.gachaAcquisitionCounts)) {
      str += `${this.fmtAcquisitionMethod(Number(acquisitionMethod))}: ${count}<br/>`;
    }
    return str;
  }

  fmtAcquisitionMethod(i: number): string {
    const ac = GACHA_ACQUISITION_METHOD[i];
    switch (ac) {
      case "UNDEFINED_ACQUISITION_METHOD":
        return "Undefined";
      case "ACQUISITION_BOOSTER_PACK":
        return "Booster Pack";
      case "ACQUISITION_CLAIM":
        return "Claim";
      case "ACQUISITION_DROP":
        return "Drop";
      case "ACQUISITION_SACRIFICE":
        return "Sacrifice";
      case "ACQUISITION_SELECTOR_TICKET":
        return "Selector Ticket";
      case "ACQUISITION_TRADE":
        return "Trade";
      default:
        return "Unknown";
    }
  }

  shadowban() {
    if (!this.userCanBan) {
      this.toastr.error("You do not have the power to ban!");
      return;
    }

    var expirationDate: Date = new Date(this.banExpirationDate);
    var expirationDateStr = this.xformDateForProto(expirationDate);
    if (this.permanentBan) {
      const d = new Date();
      d.setFullYear(d.getFullYear() + 100);
      expirationDateStr = this.xformDateForProto(d);
      expirationDate = d;
    }

    this.auth.user$.pipe(
      take(1),
      filter(u => !!u),
      tap(u => {
        var req: AbuseServiceShadowbanUser$Params = {
          userId: this.guaranteedUserID,
          body: {
            reason: this.banReason,
            expirationDate: expirationDateStr,
            bannerUserId: u!.id,
          }
        };

        this.abuseService.abuseServiceShadowbanUser(req).subscribe(() => {
          this.toastr.success(`Shadowbanned ${this.response?.user?.displayName} until ${expirationDate}!`);
          this.closeShadowbanModal();
        }, err => {
          this.toastr.error(`Failed to shadowban: ${err.toString()}`);
          this.closeShadowbanModal();
        });
      })).subscribe();
  }

  xformDateForProto(d: Date): string {
    const datePipe: DatePipe = new DatePipe('en-US');
    return datePipe.transform(d, 'yyyy-MM-ddTHH:mm:ss.SSS\'Z\'') as string;
  }

  xformDate(d: Date): string {
    const datePipe: DatePipe = new DatePipe('en-US');
    return datePipe.transform(d, 'yyyy-MM-dd') as string;
  }

  today(): string {
    const d = new Date();
    return this.xformDate(new Date());
  }

  showShadowbanModal() {
    this.shadowbanModal.nativeElement.classList.add('is-active');
  }

  closeShadowbanModal() {
    this.shadowbanModal.nativeElement.classList.remove('is-active');
  }
}
