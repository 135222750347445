/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AdminServiceUpdateMediaImageRequest } from '../../models/admin-service-update-media-image-request';
import { UpdateMediaImageResponse } from '../../models/update-media-image-response';

export interface AdminServiceUpdateMediaImage$Params {

/**
 * We don't have environments for media so this edits prod images
 */
  mediaId: string;
      body: AdminServiceUpdateMediaImageRequest
}

export function adminServiceUpdateMediaImage(http: HttpClient, rootUrl: string, params: AdminServiceUpdateMediaImage$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateMediaImageResponse>> {
  const rb = new RequestBuilder(rootUrl, adminServiceUpdateMediaImage.PATH, 'post');
  if (params) {
    rb.path('mediaId', params.mediaId, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<UpdateMediaImageResponse>;
    })
  );
}

adminServiceUpdateMediaImage.PATH = '/api/v3/gateway/admin/media/{mediaId}/image';
