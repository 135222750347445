import { inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ResolveFn } from '@angular/router';
import { forkJoin, map, mergeMap, tap } from 'rxjs';
import { GachaServiceService, MediaServiceService } from 'src/app/openapi/services';


export const resolveMedia: ResolveFn<boolean> = (route, state) => {
  const m = inject(Meta);
  const a = inject(MediaServiceService);
  const g = inject(GachaServiceService);
  if (route.params['mediaID'] === undefined) {
    return resolveAllMedia(route, state);
  }
  return a.mediaServiceGetMedia({ mediaId: route.params['mediaID'] })
    .pipe(
      mergeMap(mediaRes => g.gachaServiceGetAllGachaForMedia({ mediaId: mediaRes.media!.id! })
        .pipe(
          tap(allGachaRes => {
            const allGacha = allGachaRes.gachas || [];
            const media = mediaRes.media!;
            m.removeTag('name="title"');
            m.removeTag('name="description"');
            m.removeTag('name="og:title"');
            m.removeTag('name="og:description"');
            m.removeTag('name="og:image"');
            m.removeTag('name="twitter:image:src"');
            m.removeTag('name="twitter:card"');

            m.addTag({ name: 'title', content: `Upa - ${media.name}` })
            m.addTag({ name: 'og:title', content: `Upa - ${media.name}` });
            m.addTag({ name: 'description', content: `View all ${allGacha.length} gacha for ${media.name} on Upa, your next favorite Discord collection bot!` });
            m.addTag({ name: 'og:description', content: `View all ${allGacha.length} gacha for ${media.name} on Upa, your next favorite Discord collection bot!` });
            m.addTag({ name: 'twitter:card', content: 'summary_large_image' });
            if (media.bannerImageUrl) {
              m.addTag({ name: 'og:image', content: `${media.bannerImageUrl}` });
              m.addTag({ name: 'twitter:image:src', content: `${media.bannerImageUrl}` });
            }
          })
        )
      ),
      map(_ => true)
    );
};

export const resolveAllMedia: ResolveFn<boolean> = (route, state) => {
  const m = inject(Meta);
  m.removeTag('name="title"');
  m.removeTag('name="description"');
  m.removeTag('name="og:title"');
  m.removeTag('name="og:description"');
  m.addTag({ name: 'title', content: `Upa - All Media` })
  m.addTag({ name: 'og:title', content: `Upa - All Media` });
  m.addTag({ name: 'description', content: `Browse all our media franchises on Upa, your next favorite Discord collection bot!` });
  m.addTag({ name: 'og:description', content: `Browse all our media franchises on Upa, your next favorite Discord collection bot!` });
  return true;
}