<ng-container>
  <section>
    <div class="field">
      <label class="label">Select Media Franchise</label>
      <div class="control">
        <div class="select">
          <select #s [(ngModel)]="selectedMediaID" (change)="onSelectMedia(s.value)">
            <option *ngFor="let media of medias" [ngValue]="media.id">{{media.name}}</option>
          </select>
        </div>
      </div>
    </div>

    <p *ngIf="shownAltArts" class="has-text-weight-semibold">Total: {{calcAltArtsCount()}}</p>

    <table class="table">
      <thead>
        <tr>
          <th>Gacha Name</th>
          <th>Alt Art Name</th>
          <th style="max-width: 150px">Alt Art Description</th>
          <th>Media</th>
          <th>Orientation</th>
          <th>Image</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let a of shownAltArts; trackBy: gachaTrackBy">
          <ng-container *ngFor="let art of a.altArt.altArts; trackBy: altArtTrackBy">
            <tr>
              <th class="has-text-weight-normal">{{ a.altArt.gacha!.name }}</th>
              <ng-container *ngIf="!a.isEditing.get(art.artId!); else editFields">
                <th class="has-text-weight-normal">{{ art.name }}</th>
                <th class="has-text-weight-normal">{{ art.description }}</th>
              </ng-container>

              <ng-template #editFields>
                <th class="has-text-weight-normal">
                  <textarea class="textarea is-primary" type="text" [(ngModel)]="art.name"></textarea>
                </th>
                <th class="has-text-weight-normal">
                  <textarea class="textarea is-primary" type="text" [(ngModel)]="art.description"></textarea>
                </th>
              </ng-template>

              <th class="has-text-weight-normal">{{ getMediaName(a.altArt.gacha!.mediaId!) }}</th>

              <th class="has-text-weight-normal">{{ art.orientation }}</th>

              <th class="has-text-weight-normal">
                <figure class="image preview max-width-384">
                  <img src="{{ art.imageUrl }}">
                </figure>
              </th>

              <th>
                <ng-container *ngIf="!a.isEditing.get(art.artId!); else editButtons">
                  <div>
                    <button class="button is-info is-light" (click)="startEditAltArt(a, art)">Edit</button>
                  </div>
                  <hr>
                  <div class="buttons">
                    <button class="button is-success is-light"
                      (click)="enableAltArt(a.altArt.gacha!, art)">Enable</button>
                    <button class="button is-danger is-light"
                      (click)="showDeleteAlert(a.altArt.gacha!, art)">Delete</button>
                  </div>
                </ng-container>

                <ng-template #editButtons>
                  <div class="buttons">
                    <button class="button is-success is-light" (click)="saveAltArt(a, art)">Save</button>
                    <button class="button is-warning is-light" (click)="cancelEditAltArt(a, art)">Cancel</button>
                  </div>
                </ng-template>


              </th>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </section>
</ng-container>