import { inject, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MultiUploadFormComponent } from './admin/multi-upload-form/multi-upload-form.component';
import { UploadFormComponent } from './admin/upload-form/upload-form.component';
import { ViewGachaComponent as AdminViewGacha } from './admin/view-gacha/view-gacha.component';
import { ViewGachaComponent as PublicViewGacha } from './view/view-gacha/view-gacha.component';
import { resolveGacha as ViewPublicGachaComponentResolver } from './view/view-gacha/resolve.resolver';
import { ViewUserComponent } from './view/view-user/view-user.component';
import { resolveUser as ViewUserGachaComponentResolver } from './view/view-user/resolve.resolver';
import { ViewMediaComponent } from './view/view-media/view-media.component';
import { resolveMedia as ViewMediaComponentResolver, resolveAllMedia as ViewAllMediaComponentResolver } from './view/view-media/resolve.resolver';
import { HomepageComponent } from './view/homepage/homepage.component';
import { InviteRedirectGuard } from './inviteredirect.guard';
import { ViewBannersComponent } from './view/view-banners/view-banners.component';
import { HelpComponent } from './view/help/help.component';
import { resolveHelp as ViewGuideComponentResolver } from './view/help/resolve.resolver';
import { AuthGuard } from './auth/auth-guard';
import { ViewStagingComponent } from './admin/view-staging/view-staging.component';
import { resolveLeaderboard as ViewLeaderboardComponentResolver } from './view/view-leaderboard/resolve.resolver';
import { ViewLeaderboardComponent } from './view/view-leaderboard/view-leaderboard.component';
import { PoliciesComponent } from './view/policies/policies.component';
import { BannerManagementComponent } from './admin/banner-management/banner-management.component';
import { AbuseComponent } from './admin/abuse/abuse.component';
import { CreateComponent } from './admin/banner-management/create/create.component';
import { UpdateComponent } from './admin/banner-management/update/update.component';
import { GenComponent } from './achievements/gen/gen.component';
import { ViewAllMediaGachaComponent } from './view/view-all-media-gacha/view-all-media-gacha.component';
import { UploadEquipmentComponent } from './admin/equipment/upload-equipment/upload-equipment.component';
import { ViewStagingEquipmentComponent } from './admin/equipment/view-staging-equipment/view-staging-equipment.component';
import { ViewAltArtComponent } from './admin/view-alt-art/view-alt-art.component';
import { StageAltArtComponent } from './admin/stage-alt-art/stage-alt-art.component';
import { SettingsComponent } from './view/settings/settings.component';
import { ViewAllAltArtGachasComponent } from './view/view-all-alt-art-gachas/view-all-alt-art-gachas.component';
import { resolveBaseAltArtLink as ViewAltArtAllComponentResolver, resolveSpecificMedia as ViewAltArtSpecificComponentResolver } from './view/view-all-alt-art-gachas/resolve.resolver';
import { ViewCommandsComponent } from './view/view-commands/view-commands.component';
import { resolveAllCommands as ViewCommandsComponentResolver, resolveSpecificCommand as ResolveSpecificCommandComponentResolver } from './view/view-commands/resolve.resolver';
import { UploadMediaComponent } from './admin/upload-media/upload-media.component';
import { ViewEditMediaComponent } from './admin/view-edit-media/view-edit-media.component';
import { ViewGuildComponent } from './view/view-guild/view-guild.component';
import { resolveGuild as ResolveGuildComponentResolver } from './view/view-guild/resolve.resolver';
import { resolveAllGuilds as ResolveAllGuildComponentResolver } from './view/view-all-guilds/resolve.resolver';
import { UserAccessRights } from './openapi/models';
import { ViewAdminAllAltArtComponent } from './admin/view-admin-all-alt-art/view-admin-all-alt-art.component';
import { ViewAllGuildsComponent } from './view/view-all-guilds/view-all-guilds.component';
import { HelpV2Component } from './view/help-v2/help-v2.component';
import { MeRedirectGuard } from './me-redirect.guard';
import { LiveRollsComponent } from './view/components/live-rolls/live-rolls.component';
import { ViewAllEnhancementsComponent } from './view/view-all-enhancements/view-all-enhancements.component';
import { GiveawayItemFormComponent } from './admin/giveaway-item-form/giveaway-item-form.component';
import { ViewMarketplaceComponent } from './view/view-marketplace/view-marketplace.component';
import { resolveMarketplace as ViewMarketplaceComponentResolver } from './view/view-marketplace/resolver.resolver';

const routes: Routes = [
  { path: '', component: HomepageComponent },
  {
    path: 'admin/gacha/upload', component: UploadFormComponent,
    canActivate: [() => inject(AuthGuard).canActivateOr("GACHA_EDITOR")],
  },
  {
    path: 'admin/media/upload', component: UploadMediaComponent,
    canActivate: [() => inject(AuthGuard).canActivateOr("GACHA_EDITOR")],
  },
  {
    path: 'admin/media/view', component: ViewEditMediaComponent,
    canActivate: [() => inject(AuthGuard).canActivateOr("GACHA_EDITOR")],
  },
  {
    path: 'admin/gacha/view', component: AdminViewGacha,
    canActivate: [() => inject(AuthGuard).canActivateOr("GACHA_EDITOR", "GACHA_APPROVER", "BANNER_EDITOR")]
  },
  {
    path: 'admin/gacha/multiupload', component: MultiUploadFormComponent,
    canActivate: [() => inject(AuthGuard).canActivateOr("GACHA_EDITOR")]
  },
  {
    path: 'admin/gacha/staging', component: ViewStagingComponent,
    canActivate: [() => inject(AuthGuard).canActivateOr("GACHA_EDITOR", "GACHA_APPROVER")]
  },
  {
    path: 'admin/altart/staging', component: ViewAltArtComponent,
    canActivate: [() => inject(AuthGuard).canActivateOr("GACHA_EDITOR", "ALT_ART_EDITOR")]
  },
  {
    path: 'admin/altart/view', component: ViewAdminAllAltArtComponent,
    canActivate: [() => inject(AuthGuard).canActivateOr("GACHA_EDITOR", "ALT_ART_EDITOR")]
  },
  {
    path: 'admin/altart/upload', component: StageAltArtComponent,
    canActivate: [() => inject(AuthGuard).canActivateOr("GACHA_EDITOR", "ALT_ART_EDITOR")]
  },
  {
    path: 'admin/banners', component: BannerManagementComponent,
    canActivate: [() => inject(AuthGuard).canActivateOr("BANNER_EDITOR")]
  },
  {
    path: 'admin/banners/create', component: CreateComponent,
    canActivate: [() => inject(AuthGuard).canActivateOr("BANNER_EDITOR")]
  },
  {
    path: 'admin/banners/update/:bannerID', component: UpdateComponent,
    canActivate: [() => inject(AuthGuard).canActivateOr("BANNER_EDITOR")]
  },
  {
    path: 'admin/equipment/upload', component: UploadEquipmentComponent,
    canActivate: [() => inject(AuthGuard).canActivateOr("EQUIPMENT_EDITOR")]
  },
  {
    path: 'admin/equipment/staging', component: ViewStagingEquipmentComponent,
    canActivate: [() => inject(AuthGuard).canActivateOr("EQUIPMENT_EDITOR", "EQUIPMENT_APPROVER")]
  },
  {
    path: 'admin/abuse', component: AbuseComponent,
    canActivate: [() => inject(AuthGuard).canActivateOr("ABUSE_VIEWER")]
  },
  {
    path: 'admin/giveaway', component: GiveawayItemFormComponent,
    canActivate: [() => inject(AuthGuard).canActivateOr("GIVEAWAY_MANAGER")]
  },
  {
    path: 'view/user/:userUUID', component: ViewUserComponent, resolve: {
      data: ViewUserGachaComponentResolver,
    }
  },
  {
    path: 'view/gacha/:gachaID', component: PublicViewGacha, resolve: {
      data: ViewPublicGachaComponentResolver,
    },
  },
  {
    path: 'view/altart/all', component: ViewAllAltArtGachasComponent, resolve: {
      data: ViewAltArtAllComponentResolver,
    }
  },
  {
    path: 'view/altart/all/:mediaID', component: ViewAllAltArtGachasComponent, resolve: {
      data: ViewAltArtSpecificComponentResolver,
    }
  },
  {
    path: 'view/guilds/all', component: ViewAllGuildsComponent, resolve: {
      data: ResolveAllGuildComponentResolver,
    }
  },
  {
    path: 'view/guild/:guildID', component: ViewGuildComponent, resolve: {
      data: ResolveGuildComponentResolver,
    }
  },
  {
    path: 'view/media/all', component: ViewAllMediaGachaComponent, resolve: {
      data: ViewAllMediaComponentResolver,
    }
  },
  {
    path: 'view/media/all/:mediaID', component: ViewAllMediaGachaComponent, resolve: {
      data: ViewMediaComponentResolver,
    }
  },
  {
    path: 'view/media/:mediaID', component: ViewMediaComponent,
    resolve: {
      data: ViewMediaComponentResolver,
    },
  },
  {
    path: 'view/marketplace', component: ViewMarketplaceComponent,
    resolve: {
      data: ViewMarketplaceComponentResolver,
    }
  },
  {
    path: 'view/marketplace/listing/:listingID', component: ViewMarketplaceComponent,
    resolve: {
      data: ViewMarketplaceComponentResolver,
    }
  },
  { path: 'view/banners', component: ViewBannersComponent },
  {
    path: 'view/leaderboard/global/:selector', component: ViewLeaderboardComponent,
    resolve: {
      data: ViewLeaderboardComponentResolver,
    },
  },
  { path: 'view/enhancements', component: ViewAllEnhancementsComponent },
  {
    path: 'guide', component: HelpV2Component, resolve: {
      data: ViewGuideComponentResolver,
    },
  },
  {
    path: 'live', component: LiveRollsComponent,
  },
  {
    path: 'commands', component: ViewCommandsComponent, resolve: {
      data: ViewCommandsComponentResolver,
    }
  },
  {
    path: 'commands/:command', component: ViewCommandsComponent, resolve: {
      data: ResolveSpecificCommandComponentResolver,
    }
  },
  {
    path: 'guild/:guildID', component: ViewGuildComponent, resolve: {
      data: ResolveGuildComponentResolver,
    }
  },
  { path: 'policies', component: PoliciesComponent },
  { path: 'gen/achievement', component: GenComponent },
  { path: 'settings', component: SettingsComponent },
  /** The redirects for the site */
  {
    path: 'invite', canActivate: [InviteRedirectGuard], component: InviteRedirectGuard, data: {
      externalUrl: 'https://discord.com/oauth2/authorize?client_id=908613787333066773&scope=bot&permissions=19456'
    }
  },
  {
    path: 'server', canActivate: [InviteRedirectGuard], component: InviteRedirectGuard, data: {
      externalUrl: 'https://discord.gg/HkV2GVqJxV'
    }
  },
  {
    path: 'premium', canActivate: [InviteRedirectGuard], component: InviteRedirectGuard, data: {
      externalUrl: 'https://www.patreon.com/upabot'
    }
  },
  {
    path: 'me', canActivate: [MeRedirectGuard], component: HomepageComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
