import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild, isDevMode } from '@angular/core';
import { tap } from 'rxjs';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { AuthService } from 'src/app/auth/auth.service';
import { GetStatisticsResponse, Media, Gacha, LiveGachaFeedResponse } from 'src/app/openapi/models';
import { GachaServiceService } from 'src/app/openapi/services';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements AfterViewInit {

  loadingHTML = '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>';
  stats?: GetStatisticsResponse;
  gacha?: Gacha;
  media?: Media;

  @ViewChild('statistics')
  statsDiv!: ElementRef;

  constructor(private api: GachaServiceService, public auth: AuthService, private renderer: Renderer2) { }

  ngAfterViewInit(): void {
    this.api.gachaServiceGetStatistics().pipe(
      tap(s => this.stats = s),
    ).subscribe(_ => this.renderer.removeClass(this.statsDiv.nativeElement, 'is-skeleton'));
    this.api.gachaServiceGetRandomGacha({ rarity: 5 }).subscribe(g => {
      this.gacha = g.gacha;
      this.media = g.media;
    });
  }
}
