import { Component, Inject } from '@angular/core';
// import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-auth-button',
  templateUrl: './auth-button.component.html',
  // template: `
  //   <ng-container *ngIf="auth.isAuthenticated$ | async; else loggedOut">
  //     <button class="button is-danger" (click)="auth.logout({ returnTo: document.location.origin })">
  //       Log out
  //     </button>
  //   </ng-container>

  //   <ng-template #loggedOut>
  //     <button class="button is-primary" (click)="auth.loginWithRedirect()">Log in</button>
  //   </ng-template>
  // `,
  styleUrls: ['./auth-button.component.scss']
  // styles: [],
})
export class AuthButtonComponent {
  constructor(@Inject(DOCUMENT) public document: Document, public auth: AuthService) { }
}