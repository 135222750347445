<table class="table">
  <thead>
    <tr>
      <th>Name</th>
      <th>Desc</th>
      <th>Category</th>
      <th>Rarity</th>
      <th>Attributes</th>
      <th>image url</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let e of equipments">
      <th class="has-text-weight-normal">{{ e.name }}</th>
      <th class="has-text-weight-normal">{{ e.description }}</th>
      <th class="has-text-weight-normal">{{ stringifyCategory(e.category!) }}</th>
      <th class="has-text-weight-normal">{{ e.rarity }}</th>
      <th class="has-text-weight-normal">{{ stringify(e.attributes!) }}</th>
      <th class="has-text-weight-normal"><a href="{{ e.imageUrl }}" target="_blank">here</a></th>
    </ng-container>

  </tbody>
</table>