import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, Directive, Inject, Input, OnInit, PLATFORM_ID, QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faAnglesDown, faAnglesUp, faMoneyBill, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, filter, map, tap } from 'rxjs';
import { Gacha, Media } from 'src/app/openapi/models';

@Directive({
  selector: "ng-template.[typeTemplate]"
})
export class TypeTemplateDirective {
  @Input({ required: true })
  typeTemplate!: string;

  constructor(public templateRef: TemplateRef<any>) { }
}

declare var require: any;
@Component({
  selector: 'app-help-v2',
  templateUrl: './help-v2.component.html',
  styleUrl: './help-v2.component.scss'
})
export class HelpV2Component implements AfterViewInit {
  faMinimize = faAnglesUp;
  faMaximize = faAnglesDown;
  faMoneyBill = faMoneyBill;
  faEdit = faPenToSquare;
  collapsible?: any;

  @ViewChildren(TypeTemplateDirective) tabContentTemplates: QueryList<TypeTemplateDirective> | undefined;

  selectedTab: string = 'quickstart';


  constructor(private cd: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: Object,
    private route: ActivatedRoute) { }

  @Input()
  set type(newType: string) {
    this.type$.next(newType);
  }

  type$ = new BehaviorSubject<string>('');
  template$ = this.type$.pipe(
    filter(() => !!this.tabContentTemplates),
    map(
      type =>
        this.tabContentTemplates!.find(
          directive => directive.typeTemplate === type
        )?.templateRef || null
    )
  );

  ngAfterViewInit(): void {
    this.route.fragment.subscribe((fragment: string | null) => {
      if (fragment) {
        this.selectTab(fragment);
      } else {
        this.selectTab("quickstart");
      }
    });
    this.type$.next(this.type$.getValue());
    if (isPlatformBrowser(this.platformId)) {
      const collapsible = require('@creativebulma/bulma-collapsible/dist/js/bulma-collapsible.min.js');
      const element = document.getElementById("guide-menu-collapsible") as HTMLDivElement;
      this.collapsible = new collapsible(element);
      if (this.collapsible) {
        this.collapsible.open();
      }
    }
    this.cd.detectChanges();
  }

  selectTab(templateId: string) {
    this.type$.next(templateId);
    this.selectedTab = templateId;
    // Set router fragment to the selected tab
    if (isPlatformBrowser(this.platformId)) {
      window.location.hash = templateId;
    }
  }

  exampleAltArtMedia: Media = {
    id: "3195d245-f3d3-4551-a87f-77f0ed77b131",
    name: "Azur Lane",
    origin: "GAME",
  }

  exampleAltArtGacha: Gacha = {
    id: "1804c995-0d62-43e5-b092-e03e05fe316a",
    name: "Seattle",
    imageUrl: "https://cdn.upa.moe/gacha/3195d245-f3d3-4551-a87f-77f0ed77b131/1804c995-0d62-43e5-b092-e03e05fe316a-SP3_SPARKLE_FALLING_GIF.gif",
    category: "WAIFU",
    rarity: 5,
    mediaId: "3195d245-f3d3-4551-a87f-77f0ed77b131",
  }

  exampleMediaCensored: Media = {
    id: "7d3e602e-56dc-46d7-84b9-b9c4b4a6bcd0",
    name: "Street Fighter",
    origin: "GAME",
  }
  exampleEffectCensored: Gacha = {
    id: "4a151ee6-266d-4c51-acf1-e12ddfaf1f91",
    name: "Juri",
    mediaId: "7d3e602e-56dc-46d7-84b9-b9c4b4a6bcd0",
    imageUrl: "https://cdn.upa.moe/gacha/7d3e602e-56dc-46d7-84b9-b9c4b4a6bcd0/altart/4a151ee6-266d-4c51-acf1-e12ddfaf1f91/3-MOSAIC_CENSOR.png",
    category: "WAIFU",
    rarity: 5,
  }

  exampleEffectAnniversaryBorder: Gacha = {
    id: "1804c995-0d62-43e5-b092-e03e05fe316a",
    name: "Seattle",
    imageUrl: "https://cdn.upa.moe/gacha/3195d245-f3d3-4551-a87f-77f0ed77b131/1804c995-0d62-43e5-b092-e03e05fe316a-FIRST_ANNIVERSARY_BORDER.png",
    category: "WAIFU",
    rarity: 5,
    mediaId: "3195d245-f3d3-4551-a87f-77f0ed77b131",
  }

  exampleRainbowRareGacha: Gacha = {
    id: "c6ce4259-70f3-4257-9da9-8cad6d07631e",
    imageUrl: "https://cdn.upa.moe/gacha/6bd6f47e-cea4-4bed-8fe2-959a62a6cc0a/altart/c6ce4259-70f3-4257-9da9-8cad6d07631e/8-RAINBOW_HOLO.png",
    name: "Asuna",
    category: "WAIFU",
    rarity: 5,
    mediaId: "6bd6f47e-cea4-4bed-8fe2-959a62a6cc0a",
  }

  exampleRainbowRareMedia: Media = {
    id: "6bd6f47e-cea4-4bed-8fe2-959a62a6cc0a",
    name: "Sword Art Online",
    origin: "ANIME",
  }

  exampleEffectFallingSakura: Gacha = {
    id: "805f2554-4b86-4c24-b432-0cae5220b891",
    name: "Stelle",
    imageUrl: "https://cdn.upa.moe/gacha/989a0ad9-6ab8-4a6b-b601-756827e7348b/805f2554-4b86-4c24-b432-0cae5220b891-SAKURA_FALLING_GIF.gif",
    category: "WAIFU",
    rarity: 5,
    mediaId: "989a0ad9-6ab8-4a6b-b601-756827e7348b",
  }

  exampleEffectFallingSakuraMedia: Media = {
    id: "989a0ad9-6ab8-4a6b-b601-756827e7348b",
    name: "Honkai: Star Rail",
    origin: "GAME",
  }
}
