/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { FilterUserGachaAgainstRequest } from '../../models/filter-user-gacha-against-request';
import { FilterUserGachaAgainstResponse } from '../../models/filter-user-gacha-against-response';

export interface MarketplaceServiceFilterUserGachaAgainst$Params {
      body: FilterUserGachaAgainstRequest
}

export function marketplaceServiceFilterUserGachaAgainst(http: HttpClient, rootUrl: string, params: MarketplaceServiceFilterUserGachaAgainst$Params, context?: HttpContext): Observable<StrictHttpResponse<FilterUserGachaAgainstResponse>> {
  const rb = new RequestBuilder(rootUrl, marketplaceServiceFilterUserGachaAgainst.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<FilterUserGachaAgainstResponse>;
    })
  );
}

marketplaceServiceFilterUserGachaAgainst.PATH = '/api/v3/gateway/marketplace/filter_gacha';
