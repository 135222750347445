/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { GetMediasPaginatedResponse } from '../../models/get-medias-paginated-response';

export interface MediaServiceGetMediasPaginated$Params {

/**
 * Optional.
 *
 *  - ALPHABETICAL: Applicable to all sorts.
 *  - GACHA_RARITY_AND_ALPHABETICAL: Applicable to gacha retrieval - both user gacha and just gacha.
 *  - LEADERBOARD_ROLL_COUNT: Applicable to leaderboard retrieval.
 *  - LEADERBOARD_GACHA_OWNED: Applicable to leaderboard retrieval.
 *  - LEADERBOARD_UPA_SCORE: Applicable to leaderboard retrieval.
 *  - USER_GACHA_MINT: Applicable to user gacha retrieval.
 *  - USER_GACHA_DATE_ACQUIRED: Applicable to user gacha retrieval.
 *  - TRADE_DATE: Applicable to trade retrieval.
 *  - UPA_GUILD_SCORE: Applicable to Upa guild retrieval.
 *  - AUCTION_END_DATE: Applicable to Auctions retrieval
 */
  'paginationOptions.sortType'?: 'UNDEFINED' | 'ALPHABETICAL' | 'GACHA_RARITY_AND_ALPHABETICAL' | 'LEADERBOARD_ROLL_COUNT' | 'LEADERBOARD_GACHA_OWNED' | 'LEADERBOARD_UPA_SCORE' | 'USER_GACHA_MINT' | 'USER_GACHA_DATE_ACQUIRED' | 'TRADE_DATE' | 'UPA_GUILD_SCORE' | 'AUCTION_END_DATE';

/**
 * Optional.
 */
  'paginationOptions.page'?: string;

/**
 * Optional.
 */
  'paginationOptions.orderBy'?: 'ASC' | 'DESC';

/**
 * Optional.
 */
  'paginationOptions.pageSize'?: number;
}

export function mediaServiceGetMediasPaginated(http: HttpClient, rootUrl: string, params?: MediaServiceGetMediasPaginated$Params, context?: HttpContext): Observable<StrictHttpResponse<GetMediasPaginatedResponse>> {
  const rb = new RequestBuilder(rootUrl, mediaServiceGetMediasPaginated.PATH, 'get');
  if (params) {
    rb.query('paginationOptions.sortType', params['paginationOptions.sortType'], {});
    rb.query('paginationOptions.page', params['paginationOptions.page'], {});
    rb.query('paginationOptions.orderBy', params['paginationOptions.orderBy'], {});
    rb.query('paginationOptions.pageSize', params['paginationOptions.pageSize'], {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<GetMediasPaginatedResponse>;
    })
  );
}

mediaServiceGetMediasPaginated.PATH = '/api/v3/gateway/media';
