import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, mergeMap, Observable, lastValueFrom, of, combineLatest, merge, switchMap, forkJoin, take, tap } from 'rxjs';
import { Location, ViewportScroller, isPlatformBrowser } from '@angular/common';
import { faAnglesUp, faAnglesDown } from '@fortawesome/free-solid-svg-icons';
import { Gacha, Media } from 'src/app/openapi/models';
import { AltArtServiceService, BackpackServiceService, GachaServiceService, MediaServiceService, UserServiceService } from 'src/app/openapi/services';
import { AuthService } from 'src/app/auth/auth.service';

interface mediaAndAltArts {
  media: Media;
  altArts: Gacha[];
}
declare var require: any;

@Component({
  selector: 'app-view-all-alt-art-gachas',
  templateUrl: './view-all-alt-art-gachas.component.html',
  styleUrls: ['./view-all-alt-art-gachas.component.scss']
})
export class ViewAllAltArtGachasComponent implements OnInit, AfterViewInit {
  faMinimize = faAnglesUp;
  faMaximize = faAnglesDown;
  collapsible?: any;

  mediaAndAltArts: mediaAndAltArts[] = [];
  searchFilter: string = "";
  selectedMediaAndAltArts?: mediaAndAltArts;

  // Mapping of Map<gachaID, Map<altArtID, boolean>> to check if alt art is buyable
  // aka the user doesn't own it and has enough altart tokens
  buyable: Map<string, Map<number, boolean>> = new Map();
  // Convert the above to a dictionary for change detection
  buyableDict: { [gachaID: string]: { [altArtID: number]: boolean } } = {};
  altArtTokens?: number;

  constructor(
    private gachaService: GachaServiceService,
    private mediaService: MediaServiceService,
    private route: ActivatedRoute,
    private viewportScroller: ViewportScroller,
    @Inject(PLATFORM_ID) private platformId: Object,
    private cdr: ChangeDetectorRef,
    private location: Location,
    private altArtService: AltArtServiceService,
    private backpackService: BackpackServiceService,
    private auth: AuthService
  ) {
    this.route.data.subscribe(_ => { });
  }

  filterMedias(): mediaAndAltArts[] {
    if (this.searchFilter === "") {
      return this.mediaAndAltArts;
    }
    return this.mediaAndAltArts.filter(m => m.media.name!.toLowerCase().includes(this.searchFilter.toLowerCase()));
  }

  selectMedia(m: mediaAndAltArts) {
    this.selectedMediaAndAltArts = this.mediaAndAltArts.find(maa => maa.media.id === m.media.id);
    this.viewportScroller.scrollToAnchor('anchor');
    let pos = this.viewportScroller.getScrollPosition();
    this.viewportScroller.scrollToPosition([pos[0], pos[1] - 100]);
    this.location.go(`/view/altart/all/${m.media.id}`);
  }

  canBuy(rarity: number, tokens: number): boolean {
    return rarity <= tokens;
  }

  forceLoadAltArtAndSetBuyableStatus(): void {
    this.loadAltArtAndSetBuyableStatus().subscribe();
  }

  loadAltArtAndSetBuyableStatus(): Observable<any> {
    return this.auth.user$.pipe(
      take(1),
      switchMap(user => {
        if (!user) {
          return of([]);
        }
        return forkJoin([
          this.backpackService.backpackServiceGetUserBackpack({ userUuid: user.user_uuid! }),
          this.altArtService.altArtServiceGetGachaOwnedByUserWithAltArt({
            userUuid: user.user_uuid!,
            onlyIncludeGachaWithOwnedAltArt: false,
            c: (new Date()).getSeconds(),
          })
        ]);
      }),
      map(([backpack, availableAltArts]) => {
        const altArtTokens = backpack.backpack?.items?.filter(i => i.altArtMaterial !== undefined).map(i => i.quantity!).reduce((a, b) => a + b, 0) || 0;
        this.altArtTokens = altArtTokens;
        // Find the gachas and alt art the user already owns so they can't buy it
        // map gachaid -> altartid set
        const processedAltArts = new Map<string, Map<number, boolean>>();
        Object.entries(availableAltArts.altArtOwnershipDetails || {}).forEach(([gachaID, altArts]) => {
          const ownedAltArtForThisGacha = new Map<number, boolean>();
          altArts.altArtOwnershipDetails?.filter(a => a.userOwnsAltArt).forEach(a => ownedAltArtForThisGacha.set(a.altArtId!, false))
          processedAltArts.set(gachaID, ownedAltArtForThisGacha);
        });
        // Now populate it wirh the rest of availableAltArts.userHasGachas!.gachas
        availableAltArts.userHasGachas?.gachas?.forEach(g => {
          if (!processedAltArts.has(g.gacha!.id!)) {
            processedAltArts.set(g.gacha!.id!, new Map<number, boolean>());
          }
          g.gacha?.altArts?.forEach(a => {
            processedAltArts.get(g.gacha!.id!)?.set(a.artId!, processedAltArts.get(g.gacha!.id!)?.get(a.artId!) === undefined ? (true && this.canBuy(g.gacha!.rarity!, altArtTokens)) : false);
          });
        });
        this.buyable = processedAltArts;
        this.buyableDict = {};
        this.buyable.forEach((altArts, gachaID) => {
          this.buyableDict[gachaID] = {};
          altArts.forEach((buyable, altArtID) => {
            this.buyableDict[gachaID][altArtID] = buyable;
          });
        });
      })
    );
  }

  ngOnInit(): void {
    this.gachaService.gachaServiceGetGachaWithAltArt().pipe(
      tap(this.loadAltArtAndSetBuyableStatus()),
      mergeMap(gachas => {
        let medias = this.mediaService.mediaServiceGetAllMedia()
        return combineLatest([of(gachas), medias]);
      }),
      map(([gachas, medias]) => {
        let mediaIDToMedia = new Map<string, Media>();
        let mediaIDToGachas = new Map<string, Gacha[]>();
        medias.media!.forEach(m => mediaIDToMedia.set(m.id!, m));
        gachas.gacha!.forEach(g => {
          if (!mediaIDToGachas.has(g.mediaId!)) {
            mediaIDToGachas.set(g.mediaId!, []);
          }
          mediaIDToGachas.get(g.mediaId!)?.push(g);
        });

        let mediaAndAltArts: mediaAndAltArts[] = [];
        mediaIDToGachas.forEach((gachas, mediaID) => {
          mediaAndAltArts.push({
            media: mediaIDToMedia.get(mediaID) as Media,
            altArts: gachas,
          });
        });
        return mediaAndAltArts;
      }),
    ).subscribe(m => {
      this.mediaAndAltArts = m;
      if (this.route.snapshot.params['mediaID']) {
        this.selectedMediaAndAltArts =
          this.mediaAndAltArts.find(maa => maa.media.id === this.route.snapshot.params['mediaID']);
      } else {
        if (this.mediaAndAltArts.length > 0) {
          this.selectedMediaAndAltArts = this.mediaAndAltArts[0];
        }
      }
    });
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const collapsible = require('@creativebulma/bulma-collapsible/dist/js/bulma-collapsible.min.js');
      const element = document.getElementById("alt-art-franchises-collapsible") as HTMLDivElement;
      this.collapsible = new collapsible(element);
      if (this.collapsible) {
        this.collapsible.open();
      }
      this.cdr.detectChanges();
    }
  }
}
