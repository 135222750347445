/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { GetUpaGuildResponse } from '../models/get-upa-guild-response';
import { ListUpaGuildsResponse } from '../models/list-upa-guilds-response';
import { upaGuildServiceGetUpaGuild } from '../fn/upa-guild-service/upa-guild-service-get-upa-guild';
import { UpaGuildServiceGetUpaGuild$Params } from '../fn/upa-guild-service/upa-guild-service-get-upa-guild';
import { upaGuildServiceListUpaGuilds } from '../fn/upa-guild-service/upa-guild-service-list-upa-guilds';
import { UpaGuildServiceListUpaGuilds$Params } from '../fn/upa-guild-service/upa-guild-service-list-upa-guilds';

@Injectable({ providedIn: 'root' })
export class UpaGuildServiceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `upaGuildServiceListUpaGuilds()` */
  static readonly UpaGuildServiceListUpaGuildsPath = '/api/v3/gateway/upaguild';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upaGuildServiceListUpaGuilds()` instead.
   *
   * This method doesn't expect any request body.
   */
  upaGuildServiceListUpaGuilds$Response(params?: UpaGuildServiceListUpaGuilds$Params, context?: HttpContext): Observable<StrictHttpResponse<ListUpaGuildsResponse>> {
    return upaGuildServiceListUpaGuilds(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `upaGuildServiceListUpaGuilds$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  upaGuildServiceListUpaGuilds(params?: UpaGuildServiceListUpaGuilds$Params, context?: HttpContext): Observable<ListUpaGuildsResponse> {
    return this.upaGuildServiceListUpaGuilds$Response(params, context).pipe(
      map((r: StrictHttpResponse<ListUpaGuildsResponse>): ListUpaGuildsResponse => r.body)
    );
  }

  /** Path part for operation `upaGuildServiceGetUpaGuild()` */
  static readonly UpaGuildServiceGetUpaGuildPath = '/api/v3/gateway/upaguild/{guildId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upaGuildServiceGetUpaGuild()` instead.
   *
   * This method doesn't expect any request body.
   */
  upaGuildServiceGetUpaGuild$Response(params: UpaGuildServiceGetUpaGuild$Params, context?: HttpContext): Observable<StrictHttpResponse<GetUpaGuildResponse>> {
    return upaGuildServiceGetUpaGuild(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `upaGuildServiceGetUpaGuild$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  upaGuildServiceGetUpaGuild(params: UpaGuildServiceGetUpaGuild$Params, context?: HttpContext): Observable<GetUpaGuildResponse> {
    return this.upaGuildServiceGetUpaGuild$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetUpaGuildResponse>): GetUpaGuildResponse => r.body)
    );
  }

}
