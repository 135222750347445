<ng-container *ngIf="gacha && altArt">
  <div class="view-card" [ngStyle]="
  {
    'box-shadow': '10px 10px 24px -4px ' + buildRarityColor(),
    'background-color': buildRarityColor()+'30',
    'border-color': buildRarityColor()+'AA',
    'width': calculateWidth()
  }">
    <div class="view-card-inset">
      <img class="view-card-image" src="{{altArt.imageUrl}}" loading="lazy" [ngStyle]="
      {
        'width': calculateWidth()
      }" />
      <div class="view-card-inset-stars">
        {{buildStars()}}
      </div>
    </div>

    <div [ngStyle]="{'padding-bottom': (buyable || (altArtSetState !== 'NOT_OWNED')) ? '10px' : '0px'}"
      class="view-card-details">
      <a [routerLink]="['/view/gacha/'+gacha.id]" routerLinkActive="router-link-active">
        <p class="view-card-gacha-name is-size-4">{{altArt.name}}</p>
      </a>
      <p *ngIf="altArt.description">
        {{ altArt.description }}
      </p>
    </div>

    <div class="view-alt-art-card-bottom-right" *ngIf="buyable">
      <a>
        <fa-icon (click)="showBuyArtConfirmationModal()" [icon]="faCart"></fa-icon>
      </a>
    </div>
    <div class="view-alt-art-card-bottom-right" *ngIf="altArtSetState === 'SET' && !buyable">
      <a>
        <fa-icon (click)="setAltArt(gacha.id!, altArt.artId!)" [icon]="faSetArt"></fa-icon>
      </a>
    </div>
    <div class="view-alt-art-card-bottom-right" *ngIf="altArtSetState === 'NOT_SET' && !buyable">
      <a>
        <fa-icon (click)="unsetAltArt(gacha.id!, altArt.artId!)" [icon]="faUnsetArt"></fa-icon>
      </a>
    </div>
  </div>
</ng-container>

<div class="modal" #buyAltArtModal>
  <div class="modal-background"></div>
  <div class="modal-content">
    <div class="box">
      <h1 class="is-size-4 is-subtitle">Buy {{ altArt.name }} for {{ calculateCost(gacha, altArt) }} alt art tokens</h1>
      <h1 class="is-size-6 is-subtitle">You have <strong>{{ altArtTokens }}</strong> tokens</h1>

      <div class="buttons" style="justify-content: center;">
        <button class="button is-danger" (click)="closeBuyArtConfirmationModal()">Nevermind</button>
        <button class="button is-success" (click)="buyArt()">Purchase</button>
      </div>
    </div>
  </div>
  <button (click)="closeBuyArtConfirmationModal()" class="modal-close is-large" aria-label="close"></button>
</div>