<ng-container>
  <section>

    <div class="notification is-primary is-light">
      These are all the gacha in the "staging" state. These don't show up in the bot until you submit them.
    </div>
    <div class="notification is-danger is-light">
      Submitting is IRREVERSIBLE because players can roll and claim them!<br />
      Please double check your work before submitting.
    </div>

    <div class="field">
      <label class="label">Select Media Franchise</label>
      <div class="control">
        <div class="select">
          <select #s [(ngModel)]="selectedMediaID" (change)="onSelectMedia(s.value)">
            <option *ngFor="let media of medias" [ngValue]="media.id">{{media.name}}</option>
          </select>
        </div>
      </div>
    </div>

    <p *ngIf="shownGachas" class="has-text-weight-semibold">Total: {{shownGachas.length}}</p>
    <div class="buttons">
      <button class="button is-info" (click)="renderImages = !renderImages">
        {{ renderImages ? "Hide Images" : "Show Images" }}
      </button>
      <button class="button is-info" (click)="renderEditions = !renderEditions">
        {{ renderEditions ? "Show Base Gacha" : "Show Edition Gacha" }}
      </button>
      <button class="button is-success" (click)="showSubmitModal()" [attr.disabled]="!hasSelected() ? '' : null">
        Submit Gacha
      </button>
      <button class="button is-danger" (click)="showDeleteModal()" [attr.disabled]="!hasSelected() ? '' : null">
        Delete Gacha from Staging
      </button>
      <button class="button is-info" (click)="massMarkLGTM()" [attr.disabled]="!hasSelected() ? '' : null">
        Mark Selected as Ready to Go
      </button>
    </div>
    <div>
      <label class="checkbox">
        <input type="checkbox" (change)="onOnlyShowLGTM($event)" /> Only show gacha marked as ready to go
      </label>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th><a (click)="selectAll()"> {{ hasSelected() ? "Unselect All" : "Select All" }}</a></th>
          <th>ID</th>
          <th>Name</th>
          <th>Media</th>
          <th>Rarity</th>
          <th>Category</th>
          <th>Image</th>
          <th>Desc?</th>
          <th>Rollable?</th>
          <th>Tradeable?</th>
          <th>Edition?</th>
          <th>Marked ready to go?</th>
          <th></th>
        </tr>
      </thead>
      <tbody [@listStagger]='shownGachas !== undefined ? shownGachas.length : 0'>
        <ng-container *ngFor="let gacha of shownGachas; trackBy: gachaTrackBy">
          <tr *ngIf="(renderEditions && gacha.gacha.editionDetails !== undefined) || 
            (!renderEditions && gacha.gacha.editionDetails === undefined)" [@savedRow]="!gacha.isEditing">
            <th class="has-text-weight-light"><input type="checkbox" [(ngModel)]="gacha.selected"
                (change)="onSelectGacha(gacha.gacha.id!, $event)">
            </th>
            <th class="has-text-weight-light">{{ gacha.gacha.id }}</th>
            <th class="has-text-weight-normal">
              <ng-container *ngIf="!gacha.isEditing">
                {{ gacha.gacha.name }}
              </ng-container>
              <ng-container *ngIf="gacha.isEditing">
                <input type="text" class="input is-primary" type="text" [(ngModel)]="gacha.gacha.name" />
              </ng-container>
            </th>
            <th class="has-text-weight-normal">{{ gacha.media.name }}</th>

            <th class="has-text-weight-normal">
              <ng-container *ngIf="!gacha.isEditing">
                {{ gacha.gacha.rarity }}
              </ng-container>

              <ng-container *ngIf="gacha.isEditing">
                <div class="control has-icons-right">
                  <div class="select is-info">
                    <select [(ngModel)]="gacha.gacha.rarity">
                      <option *ngFor="let rarity of rarityOptions" [ngValue]="rarity">
                        {{ rarity }}
                      </option>
                    </select>
                  </div>
                  <div class="icon is-small is-right">
                    ⭐
                  </div>
                </div>
              </ng-container>
            </th>

            <th class="has-text-weight-normal">
              <ng-container *ngIf="!gacha.isEditing">
                {{ stringifyCategory(gacha.gacha.category!) }}
                <ng-container *ngIf="gacha.gacha.category && gacha.gacha.category === 'DUO'">
                  <p>
                    <a (click)="showParentGachas(gacha)">Show Parents</a>
                  </p>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="gacha.isEditing">
                <div class="select is-info">
                  <select [(ngModel)]="gacha.gacha.category">
                    <option *ngFor="let category of categories" [ngValue]="category">{{ stringifyCategory(category!) }}
                    </option>
                  </select>
                </div>
              </ng-container>
            </th>
            <th class="has-text-weight-light">
              <ng-container *ngIf="!gacha.isEditing">
                <ng-container *ngIf="!renderImages">
                  <a href="{{ gacha.gacha.imageUrl }}" target="_blank">here</a>
                </ng-container>
                <ng-container *ngIf="renderImages">
                  <figure class="image preview max-width-384">
                    <img src="{{ gacha.gacha.imageUrl }}">
                  </figure>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="gacha.isEditing">
                <input type="checkbox" [(ngModel)]="gacha.ngxCropperData.updateImage"> Update Image On Save
                <textarea #input placeholder="Paste image here" (paste)="onPaste($event, gacha)"
                  (change)="onFileChange($event, gacha)"></textarea>
                <image-cropper output="base64" [imageChangedEvent]="gacha.ngxCropperData.imgChangeEvt"
                  [maintainAspectRatio]="true" [imageBase64]="gacha.ngxCropperData.imageBase64String"
                  [aspectRatio]="1 / 1.41421356237" [cropperMinWidth]="384" format="png"
                  (imageCropped)="cropImg($event, gacha)" (imageLoaded)="imgLoad()" (cropperReady)="initCropper()"
                  (loadImageFailed)="imgFailed()" [resizeToWidth]="576" [onlyScaleDown]="true">
                </image-cropper>
                <img class="grid-background" [src]="gacha.ngxCropperData.croppedBase64String" />
              </ng-container>
            </th>

            <th class="has-text-weight-light">
              {{ gachaDescriptions[gacha.gacha.id!] }}
            </th>

            <th>
              <ng-container *ngIf="rollableGachas[gacha.gacha.id!]; else notRollable">
                <span class="tag is-success">Rollable</span>
              </ng-container>
              <ng-template #notRollable>
                <span class="tag is-danger">Not Rollable</span>
              </ng-template>
            </th>

            <th>
              <ng-container *ngIf="tradeableGachas[gacha.gacha.id!]; else notTradeable">
                <span class="tag is-success">Tradeable</span>
              </ng-container>
              <ng-template #notTradeable>
                <span class="tag is-danger">Not Tradeable</span>
              </ng-template>
            </th>

            <th>
              <ng-container *ngIf="gacha.gacha.editionDetails; else no">
                <a target="_blank" [routerLink]="['/view/gacha', gacha.gacha.editionDetails.baseGacha!.id]">
                  Yes - <br />Base Gacha Name: {{ gacha.gacha.editionDetails.baseGacha!.name }}<br />
                  Edition: {{ gacha.gacha.editionDetails.edition!.name }}
                </a>
              </ng-container>
              <ng-template #no>
                <span class="tag is-danger">Not Edition</span>
              </ng-template>
            </th>

            <th>
              <ng-container *ngIf="lgtmdGachas[gacha.gacha.id!]; else notLGTM">
                <span class="tag is-success">Yes Ready</span>
              </ng-container>
              <ng-template #notLGTM>
                <span class="tag is-danger">Not Ready</span>&nbsp;
                <button class="button is-info is-light is-small" (click)="lgtmGacha(gacha.gacha)">Looks Good</button>
              </ng-template>
            </th>

            <th>
              <ng-container *ngIf="gacha.isEditing">
                <div class="buttons">
                  <button class="button is-success is-light" (click)="saveEdit(gacha)">Save</button>
                  <button class="button is-danger is-light" (click)="cancelEdit(gacha)">Cancel</button>
                </div>
              </ng-container>

              <ng-container *ngIf="!gacha.isEditing">
                <button class="button" (click)="startEdit(gacha)">Edit</button>
              </ng-container>
            </th>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </section>

  <div class="modal" #confirmationSubmit>
    <div class="modal-background"></div>
    <div class="modal-content">
      <h1>Double check the gachas you want to submit!</h1>
      <div class="buttons">
        <button class="button is-info" (click)="renderModalImages = !renderModalImages">
          {{ renderModalImages ? "Hide Images" : "Show Images" }}
        </button>
      </div>
      <div class="box">
        <table class="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Media</th>
              <th>Rarity</th>
              <th>Category</th>
              <th>Image</th>
              <th>Desc?</th>
              <th>Rollable?</th>
              <th>Edition?</th>
              <th>Marked ready to go?</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let gacha of shownGachas">
              <ng-container *ngIf="gacha.selected">
                <tr>
                  <th class="has-text-weight-normal">{{ gacha.gacha.name }}</th>
                  <th class="has-text-weight-normal">{{ gacha.media.name }}</th>
                  <th class="has-text-weight-normal">{{ gacha.gacha.rarity }}</th>
                  <th class="has-text-weight-normal">
                    {{ stringifyCategory(gacha.gacha.category!) }}
                    <ng-container *ngIf="gacha.gacha.category && gacha.gacha.category === 'DUO'">
                      <p>
                        <a (click)="showParentGachas(gacha)">Show Parents</a>
                      </p>
                    </ng-container>
                  </th>
                  <th *ngIf="!renderModalImages" class="has-text-weight-light">
                    <a href="{{ gacha.gacha.imageUrl }}" target="_blank">here</a>
                  </th>
                  <th *ngIf="renderModalImages" class="has-text-weight-light">
                    <figure class="image preview max-width-384">
                      <img src="{{ gacha.gacha.imageUrl }}">
                    </figure>
                  </th>
                  <th class="has-text-weight-light">
                    {{ gachaDescriptions[gacha.gacha.id!] }}
                  </th>
                  <th>
                    {{ rollableGachas[gacha.gacha.id!] ? "Yes" : "No" }}
                  </th>
                  <th>
                    {{ gacha.gacha.editionDetails ? "Yes" : "No" }}
                  </th>
                  <th>
                    <ng-container *ngIf="lgtmdGachas[gacha.gacha.id!]; else notLGTM">
                      <span class="tag is-success">Yes</span>
                    </ng-container>
                    <ng-template #notLGTM>
                      <span class="tag is-danger">No</span>&nbsp;
                    </ng-template>
                  </th>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>

        <div class="buttons">
          <button class="button is-info" (click)="closeModals()">Nevermind</button>
          <button class="button is-success" (click)="executeSubmit()">Submit</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" #confirmationDelete>
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="buttons">
        <button class="button is-info" (click)="renderModalImages = !renderModalImages">
          {{ renderModalImages ? "Hide Images" : "Show Images" }}
        </button>
      </div>
      <div class="box">
        <table class="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Media</th>
              <th>Rarity</th>
              <th>Category</th>
              <th>Image</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let gacha of shownGachas">
              <ng-container *ngIf="gacha.selected">
                <tr>
                  <th class="has-text-weight-normal">{{ gacha.gacha.name }}</th>
                  <th class="has-text-weight-normal">{{ gacha.media.name }}</th>
                  <th class="has-text-weight-normal">{{ gacha.gacha.rarity }}</th>
                  <th class="has-text-weight-normal">
                    {{ stringifyCategory(gacha.gacha.category!) }}
                    <ng-container *ngIf="gacha.gacha.category && gacha.gacha.category === 'DUO'">
                      <p>
                        <a (click)="showParentGachas(gacha)">Show Parents</a>
                      </p>
                    </ng-container>
                  </th>
                  <th *ngIf="!renderModalImages" class="has-text-weight-light">
                    <a href="{{ gacha.gacha.imageUrl }}" target="_blank">here</a>
                  </th>
                  <th *ngIf="renderModalImages" class="has-text-weight-light">
                    <figure class="image preview max-width-384">
                      <img src="{{ gacha.gacha.imageUrl }}">
                    </figure>
                  </th>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>

        <div class="buttons">
          <button class="button is-info" (click)="closeModals()">Nevermind</button>
          <button class="button is-danger" (click)="executeDelete()">Delete</button>
        </div>
      </div>
    </div>
  </div>
</ng-container>