import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AdminServiceDeleteBanner$Params } from 'src/app/openapi/fn/admin-service/admin-service-delete-banner';
import { AdminServiceUpdateBannerVisibility$Params } from 'src/app/openapi/fn/admin-service/admin-service-update-banner-visibility';
import { Banner, DeleteBannerResponseStatus, UpdateBannerVisibilityResponseStatus } from 'src/app/openapi/models';
import { AdminServiceService, GachaServiceService } from 'src/app/openapi/services';

function toDateTime(seconds: number, nanos?: number): Date {
  if (!nanos) {
    nanos = 0;
  }
  var t = new Date(Date.UTC(1970, 0, 1));
  t.setUTCSeconds(seconds + (nanos / 1000000000));
  return t;
}

@Component({
  selector: 'app-banner-management',
  templateUrl: './banner-management.component.html',
  styleUrls: ['./banner-management.component.scss']
})
export class BannerManagementComponent implements OnInit {
  allBanners: Banner[] = [];
  showPastBanners: boolean = false;

  constructor(private adminService: AdminServiceService,
    private gachaService: GachaServiceService,
    private toastr: ToastrService,
    private viewportScroller: ViewportScroller) { }

  ngOnInit(): void {
    this.gachaService.gachaServiceGetBanners({
      onlyGetCurrent: !this.showPastBanners,
      onlyGetVisible: false,
    }).subscribe(banners => {
      this.allBanners = (banners.banners || []).sort(this.sortBanners.bind(this));
    }, err => {
      this.toastr.error(`Failed to retrieve banners: ${err}`);
    });
  }

  showPastBannersChange(event: any) {
    this.showPastBanners = event.target.checked;
    this.gachaService.gachaServiceGetBanners({
      onlyGetCurrent: !this.showPastBanners,
      onlyGetVisible: false,
    }).subscribe(banners => {
      this.allBanners = (banners.banners || []).sort(this.sortBanners.bind(this));
    }, err => {
      this.toastr.error(`Failed to retrieve banners: ${err}`);
    });
  }

  toAnchor(anchor: string) {
    this.viewportScroller.scrollToAnchor(anchor);
    let pos = this.viewportScroller.getScrollPosition();
    this.viewportScroller.scrollToPosition([pos[0], pos[1] - 100]);
  }

  isPresent(b: Banner): boolean {
    const start = new Date(b.startTime!);
    const end = new Date(b.endTime!)
    const now = new Date();
    return start <= now && now <= end;
  }

  isPast(b: Banner): boolean {
    const now = new Date();
    const end = new Date(b.endTime!);
    return end < now;
  }

  isFuture(b: Banner): boolean {
    const start = new Date(b.startTime!);
    const now = new Date();
    return start > now;
  }

  setVisibility(bannerID: string, visible: boolean) {
    const req: AdminServiceUpdateBannerVisibility$Params = {
      bannerId: bannerID,
      visible: visible,
    }
    this.adminService.adminServiceUpdateBannerVisibility(req).subscribe(resp => {
      switch (resp.status) {
        case "SUCCESS":
          break;
        case "BANNER_NOT_FOUND":
          this.toastr.error(`Banner not found`);
          return;
        default:
          this.toastr.error(`Failed to update banner visibility: ${resp.status?.toString()}`);
          return;
      }

      this.toastr.success(`Banner visibility updated`);
      this.allBanners = this.allBanners.map(b => {
        if (b.id == bannerID) {
          b.visible = visible;
        }
        return b;
      }).sort(this.sortBanners.bind(this));
    })
  }

  deleteBanner(b: Banner) {
    if (confirm(`Are you sure you want to delete banner [${b.name}] with ID [${b.id}]?`)) {
      const req: AdminServiceDeleteBanner$Params = {
        bannerId: b.id!,
      }
      this.adminService.adminServiceDeleteBanner(req).subscribe(resp => {
        switch (resp.status) {
          case "BANNER_NOT_FOUND":
            this.toastr.error(`Banner not found for ID ${b.id}`);
            return;
          case "SUCCESS":
            this.toastr.success(`Banner [%s] deleted`, b.name);
            break;
          default:
            this.toastr.error(`Failed to delete banner: ${resp.status?.toString()}`);
            return;
        }
        this.allBanners = this.allBanners.filter(banner => banner.id != b.id).sort(this.sortBanners.bind(this));
      });
    }
  }

  // Sorts all banners by the following criteria:
  // 1. If the banner is current
  // 2. If the banner is upcoming
  // 3. If the banner is past
  // 4. And then by the name of the banner.
  // holy shit this abomination was copilot generated LOL
  // Some bug in the gen forces weird date conversions - basically the proto is serialized instead of a
  // Date object, as a {seconds: number, nanos?: number} object. So we have to convert it back to a Date
  sortBanners(a: Banner, b: Banner) {
    if (this.isPresent(a) && this.isPresent(b)) {
      return a.name!.localeCompare(b.name!);
    }
    if (this.isFuture(a) && this.isFuture(b)) {
      return a.name!.localeCompare(b.name!);
    }
    if (this.isPast(a) && this.isPast(b)) {
      return a.name!.localeCompare(b.name!);
    }
    if (this.isPresent(a) && !this.isPresent(b)) {
      return -1;
    }
    if (this.isPresent(b) && !this.isPresent(a)) {
      return 1;
    }
    if (this.isFuture(a) && !this.isFuture(b)) {
      return -1;
    }
    if (this.isFuture(b) && !this.isFuture(a)) {
      return 1;
    }
    if (this.isPast(a) && !this.isPast(b)) {
      return -1;
    }
    if (this.isPast(b) && !this.isPast(a)) {
      return 1;
    }
    return a.name!.localeCompare(b.name!);
  }

}
