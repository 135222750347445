import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, filter, map, of, shareReplay, take, tap } from 'rxjs';
import { getProtoFieldNumberForUserAccessRights } from 'src/app/auth/auth-guard';
import { AuthService } from 'src/app/auth/auth.service';
import { CommunityServerServiceGiveawayItem$Params } from 'src/app/openapi/fn/community-server-service/community-server-service-giveaway-item';
import { BackpackItem, GachaEnhancementType } from 'src/app/openapi/models';
import { GACHA_ENHANCEMENT_TYPE } from 'src/app/openapi/models/gacha-enhancement-type-array';
import { CommunityServerServiceService } from 'src/app/openapi/services';

// card effect/stardust have special methods
enum GiveawayItemType {
  // STARDUST = "STARDUST",
  WISHLIST_TOKEN = "WISHLIST_TOKEN",
  ALT_ART_TOKEN = "ALT_ART_TOKEN",
  // CARD_EFFECT = "CARD_EFFECT",
  BOOSTER_PACK = "BOOSTER_PACK",
}
@Component({
  selector: 'app-giveaway-item-form',
  templateUrl: './giveaway-item-form.component.html',
  styleUrl: './giveaway-item-form.component.scss'
})
export class GiveawayItemFormComponent implements OnInit {
  targetUserID: string = '';
  budgetUsed: number = 0;
  budgetLeft: number = 0;

  stardustAmount: number = 0;
  wishlistTokenAmount: number = 0;
  selectedCardEffect: GachaEnhancementType = "MOSAIC_CENSOR";
  boosterPackName: string = '';

  cardEffectOptions: GachaEnhancementType[];
  // List of effects we don't want to be able to give out
  cardEffectBlocklist: GachaEnhancementType[] = [
    "SP3_SPARKLE_FALLING_GIF",
    "RAINBOW_HOLO"
  ];

  readonly giveawayItemType = GiveawayItemType;
  constructor(
    private auth: AuthService,
    private communityService: CommunityServerServiceService,
    private toastr: ToastrService) {
    this.cardEffectOptions = [];
    for (const g of GACHA_ENHANCEMENT_TYPE) {
      if (g === "NO_GACHA_ENHANCEMENT" || this.cardEffectBlocklist.includes(g)) {
        continue;
      }
      this.cardEffectOptions.push(g);
    }
  }

  ngOnInit(): void {
    this.communityService.communityServerServiceGetGiveawayBudgetForCurrentTimePeriod().subscribe(budget => {
      this.budgetLeft = budget.budgetLeft ?? 0;
      this.budgetUsed = budget.budgetUsed ?? 0;
    });
  }

  translateEffect(effect: GachaEnhancementType): string {
    switch (effect) {
      case "COMPETITION_1_BORDER_1":
        return "Springtime Memories Border";
      case "COMPETITION_1_BORDER_2":
        return "A Rose by Any Other Name Border";
      case "COMPETITION_1_BORDER_3":
        return "Vineyard Vines Border";
      case "COMPETITION_1_BORDER_4":
        return "Dinomight Border";
      case "COMPETITION_1_BORDER_5":
        return "Succubus' Embrace Border";
      case "COMPETITION_1_EFFECT_1":
        return "Summer Lanterns Effect";
      case "COMPETITION_1_EFFECT_2":
        return "Prismatic Dreams Effect";
      case "MOSAIC_CENSOR":
        return "Mosaic Censorship";
      case "FIRST_ANNIVERSARY_BORDER":
        return "First Anniversary Border";
      case "SAKURA_FALLING_GIF":
        return "Falling Sakura Effect";
      default:
        return effect;
    }
  }

  giveawayCardEffect(effect: GachaEnhancementType): void {
    const b: BackpackItem = {
      cardEffect: effect,
      quantity: 1,
    };
    this.giveawayItem(b);
  }

  giveawayBackpackItem(item: GiveawayItemType, quantity: number): void {
    switch (item) {
      case "WISHLIST_TOKEN":
        const wlToken: BackpackItem = {
          wishlistToken: "WISHLIST_TOKEN",
          quantity: quantity,
        };
        this.giveawayItem(wlToken);
        break;
      case "ALT_ART_TOKEN":
        const altArtToken: BackpackItem = {
          altArtMaterial: "ALT_ART_MATERIAL",
          quantity: quantity,
        };
        this.giveawayItem(altArtToken);
        break;
      case "BOOSTER_PACK":
        const boosterPack: BackpackItem = {
          boosterPack: {
            name: this.boosterPackName,
          },
          quantity: 1,
        };
        this.giveawayItem(boosterPack);
        break;
    }
  }

  giveawayItem(item: BackpackItem | 'STARDUST', stardustQuantity?: number): void {
    this.auth.user$.pipe(
      take(1),
      filter(u => !!u),
      filter(u => u!.rights.includes(getProtoFieldNumberForUserAccessRights("GIVEAWAY_MANAGER")) || u!.rights.includes(getProtoFieldNumberForUserAccessRights("SUPERUSER"))),
    ).subscribe(u => {
      if (this.targetUserID === '') {
        this.toastr.error('No target user ID provided');
        return;
      }
      const req: CommunityServerServiceGiveawayItem$Params = {
        body: {
          userId: this.targetUserID,
        }
      }
      if (item === 'STARDUST') {
        req.body.stardust = stardustQuantity ?? 0;
      } else {
        req.body.backpackItem = item;
      }

      this.communityService.communityServerServiceGiveawayItem(req).subscribe(() => {
        this.toastr.success('Item given away!');
        this.communityService.communityServerServiceGetGiveawayBudgetForCurrentTimePeriod().subscribe(budget => {
          this.budgetLeft = budget.budgetLeft ?? 0;
          this.budgetUsed = budget.budgetUsed ?? 0;
        });
      },
        (err) => this.toastr.error('Failed to give away item: ' + err.error.message));
    })
  }

}
