/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { GiveawayItemRequest } from '../../models/giveaway-item-request';
import { GiveawayItemResponse } from '../../models/giveaway-item-response';

export interface CommunityServerServiceGiveawayItem$Params {
      body: GiveawayItemRequest
}

export function communityServerServiceGiveawayItem(http: HttpClient, rootUrl: string, params: CommunityServerServiceGiveawayItem$Params, context?: HttpContext): Observable<StrictHttpResponse<GiveawayItemResponse>> {
  const rb = new RequestBuilder(rootUrl, communityServerServiceGiveawayItem.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<GiveawayItemResponse>;
    })
  );
}

communityServerServiceGiveawayItem.PATH = '/api/v3/gateway/communityserver/giveaway';
