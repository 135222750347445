import { inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ResolveFn } from '@angular/router';
import { map, tap } from 'rxjs';
import { MarketplaceServiceService } from 'src/app/openapi/services';

export const resolveMarketplace: ResolveFn<boolean> = (route, state) => {
  if (route.params['listingID'] === undefined) {
    return resolveAllMarketplace(route, state);
  }

  const m = inject(Meta);
  const marketService = inject(MarketplaceServiceService);

  return marketService.marketplaceServiceGetMarketplaceListing({ marketplaceListingId: route.params['listingID'] }).pipe(
    tap(listing => {
      m.removeTag('name="title"');
      m.removeTag('name="description"');
      m.removeTag('name="og:title"');
      m.removeTag('name="og:description"');
      m.addTag({ name: 'title', content: `Upa Marketplace - ${listing.marketplaceListing?.item?.gachaInstance?.gacha?.name}` })
      m.addTag({ name: 'og:title', content: `Upa Marketplace - ${listing.marketplaceListing?.item?.gachaInstance?.gacha?.name}` });
      const description =
        `${listing.marketplaceListing?.item?.gachaInstance?.gacha?.name}, a ${buildStars(listing.marketplaceListing?.item?.gachaInstance?.gacha?.rarity || 0)} card mint ${listing.gachaMintOutOf?.mint}/${listing.gachaMintOutOf?.outOf} is being sold for ${listing.marketplaceListing?.stardustPrice} stardust!`;
      m.addTag({ name: 'description', content: description });
      m.addTag({ name: 'og:description', content: description });
    }),
    map(_ => true)
  );
};

const buildStars = (rarity: number | undefined) => {
  return '⭐'.repeat(rarity || 0);
}

const resolveAllMarketplace: ResolveFn<boolean> = (route, state) => {
  const m = inject(Meta);
  m.removeTag('name="title"');
  m.removeTag('name="description"');
  m.removeTag('name="og:title"');
  m.removeTag('name="og:description"');
  m.addTag({ name: 'title', content: `Upa - Marketplace` })
  m.addTag({ name: 'og:title', content: `Upa - Marketplace` });
  m.addTag({ name: 'description', content: `See what cards other players are selling!` });
  m.addTag({ name: 'og:description', content: `See what cards other players are selling!` });
  return true;
};
