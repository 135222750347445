/* tslint:disable */
/* eslint-disable */
import { GachaCategory } from './gacha-category';

/**
 * Each possible value of `GachaCategory`
 */
export const GACHA_CATEGORY: GachaCategory[] = [
  'UNDEFINED_CATEGORY',
  'WAIFU',
  'HUSBANDO',
  'OTHER',
  'DUO'
];