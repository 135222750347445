import { formatDate } from '@angular/common';
import { Component, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { faShareFromSquare, faPenToSquare, faTrash, faCircleCheck, faMoneyBill, faLock, faLockOpen, faKey, faCreditCard, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faSquareCheck } from '@fortawesome/free-regular-svg-icons';
import { Clipboard } from '@angular/cdk/clipboard';
import { isDevMode } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Gacha, GachaEnhancementStatus, GachaEnhancementType, MarketplaceListingReaction, MarketplaceReactionType, Media, TradeableStatus, User } from 'src/app/openapi/models';
import { AuthService, canViewDeleteListing } from 'src/app/auth/auth.service';
import { AdminServiceService, GachaServiceService, MarketplaceServiceService, UserServiceService } from 'src/app/openapi/services';
import { Observable, Subject, filter, map, of, switchMap, take, tap, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { includesAny } from '../../navbar/navbar.component';
import { User as AuthUser } from '../../../auth/auth.service';
import { USER_ACCESS_RIGHTS } from 'src/app/openapi/models/user-access-rights-array';
import { environment } from 'src/environments/environment';

function getApiUrl(): string {
  return environment.apiRoot + "/api/v3";
}

@Component({
  selector: 'app-gacha-v2',
  templateUrl: './gacha-v2.component.html',
  styleUrls: ['./gacha-v2.component.scss']
})
export class GachaV2Component implements OnInit {
  faShareFromSquare = faShareFromSquare;
  faEdit = faPenToSquare;
  faCheck = faSquareCheck;
  removeFavoriteIcon = faTrash;
  setFavoriteIcon = faCircleCheck;
  faMoneyBill = faMoneyBill;
  faCreditCard = faCreditCard;
  faLock = faLock;
  faLockOpen = faLockOpen;
  faKey = faKey;
  faXMark = faXmark;

  // Required - the gacha to render.
  @Input()
  gacha?: Gacha;

  // Required - the media to render.
  @Input()
  media?: Media;

  // Optional - the copy #.
  @Input()
  copyNumber?: number;

  // Optional - the copy # out of.
  @Input()
  outOf?: number;

  // Optional - the date pulled.
  @Input()
  datePulled?: Date;

  // Optional - the starpower level.
  @Input()
  starpower?: number;

  // Optional - locked icon in top left (for trade).
  @Input()
  tradeableStatus?: TradeableStatus;

  // Optional - gacha instance ID to render in order to create a share button
  @Input()
  gachaInstanceId?: string;

  // Optional - the user UUID to render in order to create a share button
  // or current user uuid for cancel listing for marketplace
  @Input()
  userUUID?: string;

  // Optional - If the user can "edit" their gacha (e.g. locked, etc)
  // Should only be shown if the user is the owner of the gacha on their own collection page.
  @Input()
  showEdit?: boolean;  // If we need to reload, for example, a user removes an effect from a gacha
  @Output() forceGachaReload = new Subject<void>();
  // Optional - If the user owns these enhancements, they can modify it.
  @Input()
  allowedGachaEnhancements?: GachaEnhancementStatus[];

  /*******************
   * Marketplace stuff
   *******************/
  // Optional - The price of the card in the marketplace
  @Input()
  marketplacePrice?: number;

  // Optional - The listing ID of the card in the marketplace
  @Input()
  marketplaceListingId?: string;

  // Optional - The seller of the card in the marketplace
  @Input()
  marketplaceSeller?: User;

  // Optional - The reactions for the card
  @Input()
  marketplaceReactions?: MarketplaceListingReaction[];

  // Optional - the enhancements attached to this card
  @Input()
  marketplaceCardEnhancements?: GachaEnhancementStatus[];

  @Input()
  marketplaceAdminMode?: boolean;

  // Proto representation
  @Input()
  marketplaceCardListedAtStr?: string;
  marketplaceCardListedAt?: Date;

  @ViewChild('editEnhancementModal') editEnhancementModal!: ElementRef;
  @ViewChild('marketplaceListingModal') marketplaceListingModal!: ElementRef;
  @ViewChild('marketplaceBuyConfirmationModal') marketplaceBuyConfirmationModal!: ElementRef;
  // TODO: If it gets more complex, consider a state management system like Elf or NgRx
  isCurrentlyEditing: boolean = false;

  // Favorites editing -
  @Input()
  showFavoritesEdit?: boolean;
  @Input()
  showFavoriteSet?: boolean;
  @Input()
  favoritePriority?: number;
  @Output() // Priority
  favoriteRemoved = new Subject<number>();
  @Output() // Gacha ID
  favoriteSet = new Subject<string>();
  removeFavorite(priority: number) {
    this.favoriteRemoved.next(priority);
  }
  setFavorite() {
    this.favoriteSet.next(this.gacha!.id!);
  }

  constructor(private clipboard: Clipboard,
    private toastr: ToastrService,
    public auth: AuthService,
    private router: Router,
    private gachaService: GachaServiceService,
    private adminService: AdminServiceService,
    private userService: UserServiceService,
    private marketplaceService: MarketplaceServiceService) { }

  cachedImageUrls: { [key: string]: string } = {};
  ngOnInit(): void {
    if (this.allowedGachaEnhancements) {
      this.allowedGachaEnhancements.unshift({
        gachaEnhancementType: "NO_GACHA_ENHANCEMENT",
        enabled: this.allowedGachaEnhancements.filter(e => e.enabled).length === 0,
      })

      if (this.gacha && this.gacha.id) {
        const requests: Observable<string>[] = this.allowedGachaEnhancements.map(e =>
          this.gachaService.gachaServiceGetGachaImageUrl({
            gachaId: this.gacha!.id!,
            enhancement: e.gachaEnhancementType!,
          }).pipe(
            filter(res => res.imageUrl !== undefined),
            tap(url => this.cachedImageUrls[e.gachaEnhancementType!] = url.imageUrl!),
            map(res => res.imageUrl!),
          ));
        // We don't care about the results, we just want to cache the image URLs.
        // This is a fire and forget.
        requests.forEach(r => r.subscribe());
      }
    } else {
      this.allowedGachaEnhancements = [{
        gachaEnhancementType: "NO_GACHA_ENHANCEMENT",
        enabled: true,
      }];
    }

    if (this.marketplaceCardListedAtStr) {
      this.marketplaceCardListedAt = new Date(this.marketplaceCardListedAtStr);
    }
  }

  copyImageGen() {
    if (!this.gacha || !this.gachaInstanceId) {
      return;
    }
    const url = `${getApiUrl()}/imagegen/gacha/instance/${this.gachaInstanceId}?${Math.floor(Date.now() / 10000)}`;
    if (this.clipboard.copy(url)) {
      this.toastr.success('Copied image link to clipboard!');
    } else {
      this.toastr.error('Failed to copy image link to clipboard.');
    }
  }

  buildStars(): string {
    const starpowerMapping: Map<number, string> = new Map([
      [1, "⭐"],
      [2, "✨"],
      [3, "🌟"],
    ]);

    const emojiToUser = this.starpower ? starpowerMapping.get(this.starpower || 0) || "⭐" : "⭐";
    let count = this.gacha!.rarity!;
    if (this.gacha!.category === "DUO") {
      count = 6;
    }
    return emojiToUser.repeat(count);
  }

  buildLock(): string {
    switch (this.tradeableStatus) {
      case "TRADEABLE":
        return "";
      case "KINDA_TRADEABLE":
        return "🔒";
      case "UNTRADEABLE":
        return "🔐";
      default:
        return "";
    }
  }

  buildRarityColor(gacha: Gacha | undefined = this.gacha): string {
    if (!gacha) {
      return "#c4c4c4";
    }
    switch (gacha.rarity) {
      case 1:
        return "#c9c9c9";
      case 2:
        return "#7ab800";
      case 3:
        return "#4542e3";
      case 4:
        return "#9018db";
      case 5:
        return "#e3bb42";
      default:
        return "#c4c4c4";
    }
  }

  // Build a text color suitable for the background color of a rarity
  buildRarityTextColor(): string {
    if (!this.gacha) {
      return "#f5f5f5";
    }
    switch (this.gacha.rarity) {
      case 1:
        return "#1a1a1a";
      case 2:
        return "#fff";
      case 3:
        return "#fff";
      case 4:
        return "#fff";
      case 5:
        return "#000";
      default:
        return "#f5f5f5";
    }
  }

  calculateWidth(): string {
    if (!this.gacha) {
      return "256px";
    }
    switch (this.gacha.orientation) {
      case "NORMAL":
        return "256px";
      case "WIDE":
        // We add +40 to the wide orientation to account for the margin it eats
        // as if it had 2 cards side by side (which each have a margin of 20px so 20px*2)
        return "552px";
      default:
        return "256px";
    }
  }

  buildCardDropShadow(): Object {
    if (this.gacha!.duoDetails && this.gacha!.duoDetails.parentGachas && this.gacha!.duoDetails.parentGachas.length > 1) {
      // Just make an assumption that index 0 and 1 are different
      return {
        'box-shadow': '10px 10px 24px -4px ' + this.buildRarityColor(this.gacha?.duoDetails?.parentGachas?.[0]) +
          ', -8px -8px 40px -4px ' + this.buildRarityColor(this.gacha?.duoDetails?.parentGachas?.[1]),
        'background': 'linear-gradient(90deg, ' + this.buildRarityColor(this.gacha?.duoDetails?.parentGachas?.[0]) +
          ', ' + this.buildRarityColor(this.gacha?.duoDetails?.parentGachas?.[1]) + ')',
        'border-color': this.buildRarityColor() + 'AA',
        'width': this.calculateWidth(),
        'border-radius': '10px',
      };
    }

    return {
      'box-shadow': '10px 10px 24px -4px ' + this.buildRarityColor(),
      'background-color': this.buildRarityColor() + '50',
      'border-color': this.buildRarityColor() + 'AA',
      'width': this.calculateWidth()
    }
  }

  buildEnhancementName(enhancement: GachaEnhancementType): string {
    switch (enhancement) {
      case "NO_GACHA_ENHANCEMENT":
        return "Base";
      case "RAINBOW_HOLO":
        return "Rainbow Holo";
      case "SAKURA_FALLING_GIF":
        return "Falling Sakura";
      case "MOSAIC_CENSOR":
        return "Censored";
      case "FIRST_ANNIVERSARY_BORDER":
        return "First Anniversary Border (2024)";
      case "SP3_SPARKLE_FALLING_GIF":
        return "Starpower 3";
      case "COMPETITION_1_BORDER_1":
        return "Springtime Memories";
      case "COMPETITION_1_BORDER_2":
        return "A Rose By Any Other Name";
      case "COMPETITION_1_BORDER_3":
        return "Vineyard Vines";
      case "COMPETITION_1_BORDER_4":
        return "DinoMight";
      case "COMPETITION_1_BORDER_5":
        return "Succubus' Embrace";
      case "COMPETITION_1_EFFECT_1":
        return "Summer Lanterns";
      case "COMPETITION_1_EFFECT_2":
        return "Prismatic Dreams";
      case "COMPETITION_1_EFFECT_3":
        return "Winter Snow";
      default:
        return enhancement;
    }
  }

  buildEnhancementNames(enhancements: GachaEnhancementStatus[]): string {
    return enhancements.filter(e => !!e.gachaEnhancementType)
      .map(e => e.gachaEnhancementType!)
      .map(e => this.buildEnhancementName(e)).join(", ");
  }

  transformDate(date: Date): string {
    return formatDate(date, 'mediumDate', 'en-US');
  }

  readable = (status?: TradeableStatus): string => {
    switch (status || this.tradeableStatus) {
      case "TRADEABLE":
        return "tradeable";
      case "KINDA_TRADEABLE":
        return "kinda tradeable";
      case "UNTRADEABLE":
        return "untradeable";
      default:
        return "tradeable";
    }
  }

  nextTradeableStatus = (): TradeableStatus => {
    switch (this.tradeableStatus) {
      case "TRADEABLE":
        return "KINDA_TRADEABLE";
      case "KINDA_TRADEABLE":
        return "UNTRADEABLE";
      case "UNTRADEABLE":
        return "TRADEABLE";
      default:
        return "TRADEABLE";
    }
  }

  toggleTradeable(status?: TradeableStatus) {
    if (!this.gacha || !this.userUUID || !this.gacha.id || this.tradeableStatus === undefined) {
      return;
    }
    this.gachaService.gachaServiceMarkTradeableStatus({
      gachaId: this.gacha.id!,
      userUuid: this.userUUID!,
      body: {
        tradeStatus: status || this.nextTradeableStatus(),
      }
    }).subscribe(res => {
      switch (res.status) {
        case "SUCCESS":
          this.tradeableStatus = status || this.nextTradeableStatus();
          this.toastr.success(`Marked ${this.gacha?.name} as ${this.readable()}`);
          break;
        case "GACHA_NOT_FOUND":
          this.toastr.error("Gacha not found");
          break;
        case "USER_NOT_FOUND":
          this.toastr.error("User not found");
          break;
        default:
          this.toastr.error("Unknown error occurred. Please try again later.")
          break;
      }
    });
  }

  setCardEffect(enhancement: GachaEnhancementType) {
    if (!this.gacha || !this.gacha.id) {
      return;
    }
    this.auth.user$.pipe(
      tap(user => (user === undefined || user.user_uuid === undefined) ? throwError(() => Error("Not logged in!")) : undefined),
      map(user => user!.user_uuid!),
      switchMap(userUuid => this.userService.userServiceSetUserGachaEnhancement({
        gachaId: this.gacha!.id!,
        userUuid: userUuid,
        body: {
          enhancement: enhancement,
        }
      })
      )).subscribe(res => {
        switch (res.status) {
          case "DOES_NOT_OWN_ENHANCEMENT":
            this.toastr.error("You don't own this effect");
            break;
          case "USER_DOES_NOT_OWN_GACHA":
            this.toastr.error("You don't own this gacha");
            break;
          case "SUCCESS":
            this.toastr.success(`Set your effect for ${this.gacha?.name} to ${this.buildEnhancementName(enhancement)}`);
            this.closeEditEnhancementModal();
            this.forceGachaReload.next();
            break;
        }
      });
  }

  unsetCardEffect() {
    if (!this.gacha || !this.gacha.id) {
      return;
    }
    this.auth.user$.pipe(
      tap(user => (user === undefined || user.user_uuid === undefined) ? throwError(() => Error("Not logged in!")) : undefined),
      map(user => user!.user_uuid!),
      switchMap(userUuid => this.userService.userServiceUnsetUserGachaEnhancement({
        gachaId: this.gacha!.id!,
        userUuid: userUuid,
      })
      )).subscribe(res => {
        switch (res.status) {
          case "USER_DOES_NOT_OWN_GACHA":
            this.toastr.error("You don't own this gacha");
            break;
          case "SUCCESS":
            this.toastr.success(`Removed effect from ${this.gacha?.name}`);
            this.closeEditEnhancementModal();
            this.forceGachaReload.next();
            break;
        }
      });
  }


  renderDuoCardName(): string {
    if (!this.gacha || !this.gacha.duoDetails) {
      return "";
    }

    return this.gacha.duoDetails.parentGachas?.
      map(g => g.name!).
      sort((a, b) => a.localeCompare(b)).
      join(" & ")
      || "";
  }

  showEditEnhancementModal() {
    this.editEnhancementModal.nativeElement.classList.add('is-active');
  }

  closeEditEnhancementModal() {
    this.editEnhancementModal.nativeElement.classList.remove('is-active');
  }

  showMarketplaceListingModal() {
    this.marketplaceListingModal.nativeElement.classList.add('is-active');
  }
  closeMarketplaceListingModal() {
    this.marketplaceListingModal.nativeElement.classList.remove('is-active');
  }

  showMarketplaceBuyConfirmationModal() {
    this.marketplaceBuyConfirmationModal.nativeElement.classList.add('is-active');
  }
  closeMarketplaceBuyConfirmationModal() {
    this.marketplaceBuyConfirmationModal.nativeElement.classList.remove('is-active');
  }

  listOnMarket() {
    if (!this.marketplacePrice || !this.gachaInstanceId) {
      return;
    }
    this.auth.user$.pipe(
      tap(user => (user === undefined || user.user_uuid === undefined) ? throwError(() => Error("Not logged in!")) : undefined),
      map(user => user!.user_uuid!),
      switchMap(userUuid => this.marketplaceService.marketplaceServiceCreateMarketplaceItemListing({
        body: {
          userUuid: userUuid,
          gachaInstanceId: this.gachaInstanceId,
          stardustPrice: this.marketplacePrice!,
        }
      })
      )).subscribe(res => {
        switch (res.status) {
          case "GACHA_INSTANCE_ALREADY_LISTED":
            this.toastr.error("This card is already listed");
            break;
          case "GACHA_INSTANCE_NOT_FOUND":
          case "GACHA_INSTANCE_NOT_OWNED":
            this.toastr.error("You don't own this card");
            break;
          case "UNDEFINED":
            this.toastr.error("An unknown error occurred. Please try again later.");
            break;
          case "GACHA_INSTANCE_NOT_TRADEABLE":
            this.toastr.error("This card is marked as untradeable! Please mark it as tradeable first.");
            break;
          case "GACHA_NOT_TRADEABLE_BY_RULE":
            this.toastr.error("This card is not tradeable.");
            break;
          case "TOO_MANY_LISTINGS":
            this.toastr.error("You have too many listings. Please cancel some listings before trying again.");
            break;
          case "SUCCESS":
            this.toastr.success(
              "Tap here to go to your listing",
              `Successfully listed ${this.gacha?.name} for ${res.marketplaceListing?.stardustPrice} stardust`,
              {
                timeOut: 10000,
                progressBar: true,
                enableHtml: true,
                closeButton: true,
                extendedTimeOut: 7500,
              }).onTap.pipe(
                take(1),
              ).subscribe(() => {
                this.router.navigate(['/view/marketplace/listing', res.marketplaceListing?.listingId]);
                // this.router.navigate(['/view/marketplace']);
              });
            break;
        }
        this.closeMarketplaceListingModal();
      });
  }

  cancelListing() {
    if (!this.marketplaceListingId) {
      return;
    }
    this.auth.user$.pipe(
      tap(user => (user === undefined || user.user_uuid === undefined) ? throwError(() => Error("Not logged in!")) : undefined),
      map(user => user!.user_uuid!),
      switchMap(userUuid => this.marketplaceService.marketplaceServiceDeleteMarketplaceItemListing({
        userUuid: userUuid!,
        marketplaceListingId: this.marketplaceListingId!,
      })
      )).subscribe(res => {
        switch (res.status) {
          case "MARKETPLACE_LISTING_NOT_FOUND":
            this.toastr.error("Marketplace listing not found");
            break;
          case "LISTING_NOT_ACTIVE":
            this.toastr.error("Listing not active - it might have already sold!");
            break;
          case "USER_NOT_FOUND":
          case "UNDEFINED":
          default:
            this.toastr.error("Unknown error occurred. Please try again later.")
            break;
          case "USER_NOT_OWNER":
            this.toastr.error("You don't own this listing");
            break;
          case "SUCCESS":
            this.toastr.success(`Successfully cancelled listing for ${this.gacha?.name}`);
            this.forceGachaReload.next();
            break;
        }
      });
  }

  purchaseGacha() {
    if (!this.marketplacePrice || !this.marketplaceListingId) {
      return;
    }
    this.auth.user$.pipe(
      tap(user => (user === undefined || user.user_uuid === undefined) ? throwError(() => Error("Not logged in!")) : undefined),
      map(user => user!.user_uuid!),
      switchMap(userUuid => this.marketplaceService.marketplaceServicePurchaseMarketplaceListing({
        userUuid: userUuid!,
        marketplaceListingId: this.marketplaceListingId!,
      })
      )).subscribe(res => {
        switch (res.status) {
          case "MARKETPLACE_LISTING_NOT_FOUND":
            this.toastr.error("Marketplace listing not found");
            break;
          case "LISTING_ALREADY_SOLD":
            this.toastr.error("Listing already sold");
            break;
          case "USER_ALREADY_OWNS_GACHA":
            this.toastr.error("You already own this gacha");
            break;
          case "USER_NOT_ENOUGH_STARDUST":
            this.toastr.error("You don't have enough stardust");
            break;
          case "YOUR_LISTING":
            this.toastr.error("You can't buy your own listing");
            break;
          case "SUCCESS":
            this.toastr.success(`Successfully purchased ${this.gacha?.name} for ${res.marketplaceListing?.stardustPrice} stardust`);
            this.forceGachaReload.next();
            break;
          case "USER_NOT_FOUND":
          default:
            this.toastr.error("Unknown error occurred. Please try again later.")
            break;
        }
      });
  }

  reactToListing(reaction: MarketplaceReactionType) {
    if (!this.marketplaceListingId) {
      return;
    }
    this.auth.user$.pipe(
      tap(user => (user === undefined || user.user_uuid === undefined) ? throwError(() => Error("Not logged in!")) : undefined),
      map(user => user!.user_uuid!),
      switchMap(uuid => this.marketplaceService.marketplaceServiceReactToListing({
        userUuid: uuid,
        marketplaceListingId: this.marketplaceListingId!,
        reaction: reaction
      })
      )
    ).subscribe(res => {
      switch (res.status) {
        case "MARKETPLACE_LISTING_NOT_FOUND":
          this.toastr.error("Marketplace listing not found");
          break;
        case "SUCCESS":
          this.forceGachaReload.next();
          break;
        case "CANNOT_REACT_TO_OWN_LISTING":
          this.toastr.error("You can't react to your own listing");
          break;
        case "USER_NOT_FOUND":
        default:
          this.toastr.error("Unknown error occurred. Please try again later.")
          break;
      }
    });
  }

  deleteListing() {
    if (!this.marketplaceListingId) {
      return;
    }
    this.auth.user$.pipe(
      tap(user => (user === undefined || user.user_uuid === undefined) ? throwError(() => Error("Not logged in!")) : undefined),
      map(user => user!.user_uuid!),
      switchMap(_ => this.adminService.adminServiceForceDeleteMarketplaceListing({
        listingId: this.marketplaceListingId!,
      })
      )).subscribe(res => {
        switch (res.status) {
          case "LISTING_NOT_FOUND":
            this.toastr.error("Marketplace listing not found");
            break;
          case "SUCCESS":
            this.toastr.success("Successfully deleted listing");
            this.forceGachaReload.next();
            break;
          default:
            this.toastr.error("Unknown error occurred. Please try again later.")
            break;
        }
      })
  }
  canViewDeleteListing = canViewDeleteListing;
}
