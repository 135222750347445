import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { map, mergeMap, filter, take, tap, combineLatest, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/auth/auth.service';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { AdminServiceService, GachaServiceService, MediaServiceService } from 'src/app/openapi/services';
import { AdminServiceUpdateStagedGacha$Params } from 'src/app/openapi/fn/admin-service/admin-service-update-staged-gacha';
import { AdminServiceUpdateGachaImage$Params } from 'src/app/openapi/fn/admin-service/admin-service-update-gacha-image';
import { AdminServiceEnableGacha$Params } from 'src/app/openapi/fn/admin-service/admin-service-enable-gacha';
import { AdminServiceDeleteStagedGacha$Params } from 'src/app/openapi/fn/admin-service/admin-service-delete-staged-gacha';
import { GACHA_CATEGORY } from 'src/app/openapi/models/gacha-category-array';
import { Gacha } from 'src/app/openapi/models/gacha';
import { Media } from 'src/app/openapi/models/media';
import { GachaCategory } from 'src/app/openapi/models/gacha-category';
import { GetStagedGachaResponseParentGachas } from 'src/app/openapi/models/get-staged-gacha-response-parent-gachas';

export interface imageCropperData {
  imgChangeEvt: string;
  imageBase64String: string;

  croppedBase64String: string;

  updateImage: boolean;
}

interface gachaMediaAndSelection {
  gacha: Gacha;
  media: Media;
  selected: boolean;
  isEditing: boolean;

  ngxCropperData: imageCropperData;
}

@Component({
  selector: 'app-view-staging',
  templateUrl: './view-staging.component.html',
  styleUrls: ['./view-staging.component.scss'],
  animations: [
    trigger('savedRow', [
      transition("false => true", [
        animate('0.5s', style({ backgroundColor: 'green' })),
        animate('0.5s', style({})),
      ]),
    ]),
    // trigger('listStagger', [
    //   transition('* <=> *', [
    //     query(
    //       ':enter',
    //       [
    //         style({ opacity: 0, transform: 'translateY(-5px)' }),
    //         stagger(
    //           '10ms',
    //           animate(
    //             '500ms ease-out',
    //             style({ opacity: 1, transform: 'translateY(0px)' })
    //           )
    //         )
    //       ],
    //       {
    //         optional: true
    //       }
    //     ),
    //     query(':leave',
    //       animate('400ms', style({ opacity: 0, transform: 'translateY(5px)' })),
    //       {
    //         optional: true
    //       })
    //   ])
    // ]),
    // trigger('moveAnimation', [
    //   transition(':enter, * => 0, * => -1', []),
    //   transition(':increment', [
    //     query(':enter', [
    //       style({ opacity: 0, width: 0 }),
    //       stagger(50, [
    //         animate('300ms ease-out', style({ opacity: 1, width: '*' })),
    //       ]),
    //     ], { optional: true })
    //   ]),
    //   transition(':decrement', [
    //     query(':leave', [
    //       stagger(50, [
    //         animate('300ms ease-out', style({ opacity: 0, width: 0 })),
    //       ]),
    //     ])
    //   ]),
    // ]),
  ]
})
export class ViewStagingComponent implements OnInit {
  transform(index: number) {
    return `translateY(${(index + 1) * 100}%)`;
  }
  @ViewChild('confirmationSubmit') confirmationSubmitModal!: ElementRef;
  @ViewChild('confirmationDelete') confirmationDeleteModal!: ElementRef;

  selectedMediaID?: string;

  medias?: Media[];
  shownGachas?: gachaMediaAndSelection[];
  allStagedGachas?: Gacha[];
  lgtmdGachas: { [key: string]: string } = {};

  categories: GachaCategory[];
  rarityOptions = [1, 2, 3, 4, 5];

  renderImages = false;
  renderEditions = false;
  renderModalImages = false;

  rollableGachas: { [key: string]: boolean } = {};
  tradeableGachas: { [key: string]: boolean } = {};
  gachaDescriptions: { [key: string]: string } = {};
  gachaParents: {
    [key: string]: GetStagedGachaResponseParentGachas;
  } = {};

  constructor(private adminService: AdminServiceService,
    private mediaService: MediaServiceService,
    private gachaService: GachaServiceService,
    private toastr: ToastrService,
    private auth: AuthService,
    private http: HttpClient) {
    this.categories = [];
    for (const g of GACHA_CATEGORY) {
      if (g === "UNDEFINED_CATEGORY") {
        continue;
      }
      this.categories.push(g);
    }
  }

  ngOnInit(): void {
    this.loadGachas();

    (document.querySelectorAll('.modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .button') || [])
      .forEach(($close) => {
        const $target = $close.closest('.modal');

        $close.addEventListener('click', () => {
          this.closeModals();
        });
      });
  }

  showParentGachas(baseGacha: gachaMediaAndSelection) {
    const foundParents = this.gachaParents[baseGacha.gacha.id!]?.parentGachas || [];
    if (foundParents.length === 0) {
      this.toastr.error("No parent gachas found, report this to Mcdoogal", "Parent Gachas", {
        timeOut: 10000,
      });
      return;
    }
    this.toastr.info(foundParents.map(g => g.name).join(", "), "Parent Gachas", {
      timeOut: 10000,
    });
  }

  loadGachas() {
    this.adminService.adminServiceGetStagedGacha().pipe(
      tap(res => this.rollableGachas = res.gachaIsRollable || {}),
      tap(res => this.tradeableGachas = res.gachaIsTradeable || {}),
      tap(res => this.gachaDescriptions = res.gachaDescription || {}),
      tap(res => this.gachaParents = res.gachaDuoParent || {}),
      map(res => (res.gachas || [])
        .filter(g => g.mediaId)
        .map(g => g.mediaId)),
      mergeMap(ids => this.mediaService.mediaServiceGetAllMedia().pipe(
        filter(medias => medias.media !== undefined),
        map(medias => medias.media!.filter(m => ids.includes(m.id)))
      ))
    ).subscribe(media => {
      this.medias = media;
      this.adminService.adminServiceGetStagedGacha().pipe(
        tap(res => this.lgtmdGachas = res.gachaHasLgtm || {}),
        map(res => res.gachas),
      ).subscribe(g => {
        this.allStagedGachas = g;
        this.shownGachas = (g || []).map(g => (
          {
            gacha: g,
            media: this.medias?.find(m => g.mediaId === m.id) || {},
            selected: false,
            isEditing: false,
            ngxCropperData: {
              imgChangeEvt: '',
              croppedBase64String: '',
              imageBase64String: '',
              updateImage: false,
            },
          }
        )).sort(this.sortGacha);
      });
    });
  }

  onShowEditionGachaChange(e: any) {
    if (e.target.checked) {
      this.shownGachas = this.shownGachas?.filter(g => g.gacha.editionDetails);
    }
  }

  startEdit(gacha: gachaMediaAndSelection) {
    gacha.isEditing = true;
    this.adminService.adminServiceGetGachaImageData({ gachaId: gacha.gacha.id!, environment: 'STAGING' })
      .subscribe(imageData => gacha.ngxCropperData.imageBase64String = `data:${imageData.contentType};base64,${imageData.data}`);
  }

  saveEdit(gacha: gachaMediaAndSelection) {
    if (!gacha.isEditing) {
      return;
    }
    gacha.isEditing = false;
    const req: AdminServiceUpdateStagedGacha$Params = {
      "gacha.id": gacha.gacha.id!,
      body: {
        gacha: gacha.gacha,
      }
    }
    this.adminService.adminServiceUpdateStagedGacha(req).subscribe(res => {
      switch (res.status) {
        case "SUCCESS":
          if (!res.gacha) {
            this.toastr.error("updated gacha, but didn't retrieve it. Refresh the page to check!");
            return;
          }
          this.toastr.success("Successfully updated gacha!");
          gacha.gacha = res.gacha;
          // Check if the image is updated and update it accordingly
          console.log(`updat eimage? ${gacha.ngxCropperData.updateImage}`);
          if (gacha.ngxCropperData.updateImage) {
            const r: AdminServiceUpdateGachaImage$Params = {
              environment: "STAGING",
              gachaId: gacha.gacha.id!,
              body: {
                imageBlob: gacha.ngxCropperData.croppedBase64String.split(",")[1],
              }
            }
            this.adminService.adminServiceUpdateGachaImage(r).subscribe(res => {
              switch (res.status) {
                case "SUCCESS":
                  this.toastr.success("Successfully updated gacha image!");
                  break;
                case "GACHA_NOT_FOUND":
                  this.toastr.error("Gacha not found!");
                  break;
                case "UNDEFINED":
                  this.toastr.error("Undefined error!");
                  break;
              }
            });
          }
          this.shownGachas?.sort(this.sortGacha);
          return;
        case "GACHA_NOT_FOUND":
          this.toastr.error("Gacha not found!");
          break;
        case "UNDEFINED":
          this.toastr.error("Undefined error!");
          break;
      }
      this.adminService.adminServiceGetStagedGacha().pipe(
        map(res => res.gachas || []),
      ).subscribe(g => {
        const reset = g.find(g => g.id === gacha.gacha.id);
        if (!reset) {
          this.toastr.error("Failed to reset gacha, something bad happened. Refresh the page.");
          return;
        }
        gacha.gacha = reset;
      });
    });


  }

  cancelEdit(gacha: gachaMediaAndSelection) {
    if (!gacha.isEditing) {
      return;
    }
    gacha.isEditing = false;
    this.adminService.adminServiceGetStagedGacha().pipe(
      map(res => res.gachas || []),
    ).subscribe(g => {
      const reset = g.find(g => g.id === gacha.gacha.id);
      if (!reset) {
        this.toastr.error("Failed to reset gacha, something bad happened. Refresh the page.");
        return;
      }
      gacha.gacha = reset;
    });
  }

  onSelectMedia(e: string) {
    const spl = e.split(" ");
    var mediaID = e;
    if (spl.length > 1) {
      mediaID = spl[1];
    }

    this.shownGachas = this.allStagedGachas?.
      filter(g => g.mediaId === mediaID).
      map(g => (
        {
          gacha: g,
          media: this.medias!.find(m => g.mediaId === m.id) || {},
          selected: false,
          isEditing: false,
          ngxCropperData: {
            imgChangeEvt: '',
            croppedBase64String: '',
            imageBase64String: '',
            updateImage: false,
          },
        }
      )).sort(this.sortGacha);
  }

  onSelectGacha(gachaID: string, e: any) {
    const g = this.shownGachas?.find(g => g.gacha.id === gachaID);
  }

  selectAll() {
    const prev = this.hasSelected();
    this.shownGachas?.forEach(g => g.selected = !prev);
  }

  hasSelected(): boolean {
    return this.shownGachas?.some(g => g.selected) ?? false;
  }

  stringifyCategory(g: GachaCategory): string {
    return g.toString();
  }

  showSubmitModal() {
    if (!this.hasSelected()) {
      this.toastr.error("No gachas selected!");
      return;
    }
    this.confirmationSubmitModal.nativeElement.classList.add('is-active');
  }

  showDeleteModal() {
    if (!this.hasSelected()) {
      this.toastr.error("No gachas selected!");
      return;
    }
    this.confirmationDeleteModal.nativeElement.classList.add('is-active');
  }

  closeModals() {
    this.confirmationSubmitModal.nativeElement.classList.remove('is-active');
    this.confirmationDeleteModal.nativeElement.classList.remove('is-active');
  }

  executeSubmit() {
    this.closeModals();
    const selectedGacha = this.shownGachas?.filter(g => g.selected && g.gacha).map(g => g.gacha.id!);
    if (!selectedGacha) {
      this.toastr.error("No gachas selected!");
      return;
    }

    this.auth.user$.subscribe(
      u => {
        if (!u) {
          this.toastr.error("User not logged in!");
          throw new Error("User not logged in!");
        }
        const req: AdminServiceEnableGacha$Params = {
          body: {
            announce: true,
            gachaIds: selectedGacha,
          }
        }

        this.adminService.adminServiceEnableGacha(req).subscribe(_ => {
          this.toastr.success("Successfully enabled gachas!");
          this.allStagedGachas = this.allStagedGachas?.filter(g => !selectedGacha.includes(g.id!));
          this.shownGachas = this.shownGachas?.filter(g => !selectedGacha.includes(g.gacha.id!));
        });
      });
  }

  executeDelete() {
    this.closeModals();
    const selectedGacha = this.shownGachas?.filter(g => g.selected && g.gacha && g.gacha.id).map(g => g.gacha.id!);
    if (!selectedGacha) {
      this.toastr.error("No gachas selected!");
      return;
    }

    const req: AdminServiceDeleteStagedGacha$Params = {
      body: {
        gachaIds: selectedGacha
      }
    }

    this.adminService.adminServiceDeleteStagedGacha(req).subscribe(_ => {
      this.toastr.success("Successfully deleted gachas from staging!");
      this.allStagedGachas = this.allStagedGachas?.filter(g => !selectedGacha.includes(g.id!));
      this.shownGachas = this.shownGachas?.filter(g => !selectedGacha.includes(g.gacha.id!));
    });
  }

  lgtmGacha(gacha: Gacha) {
    this.auth.user$.pipe(
      take(1),
      filter(u => !!u),
      mergeMap(u => combineLatest([this.adminService.adminServiceLgtmStagedGacha({ "body": { "userId": u!.id, "gachaIds": [gacha.id!] } }), of(u)]))
    ).subscribe(res => {
      this.toastr.success(`Marked ${gacha.name} as good to go`);
      this.lgtmdGachas[gacha.id!] = res[1]?.id! || "unknown";
    });
  }

  massMarkLGTM() {
    this.auth.user$.pipe(
      take(1),
      filter(u => !!u),
      mergeMap(u => combineLatest([
        this.adminService.adminServiceLgtmStagedGacha({ "body": { "userId": u!.id, "gachaIds": this.shownGachas?.filter(g => g.selected).map(g => g.gacha.id!) || [] } }),
        of(u),
      ]))
    ).subscribe(res => {
      this.toastr.success(`Marked gachas as good to go`);
      this.shownGachas?.filter(g => g.selected).forEach(g => this.lgtmdGachas[g.gacha.id!] = res[1]?.id! || "unknown");
    });
  }

  onOnlyShowLGTM(e: any) {
    if (e.target.checked) {
      this.shownGachas = this.shownGachas?.filter(g => this.lgtmdGachas[g.gacha.id!]);
    } else {
      this.loadGachas();
    }
  }

  sortGacha(a: gachaMediaAndSelection, b: gachaMediaAndSelection) {
    if (a.isEditing || b.isEditing) {
      return 0;
    }
    if (a.gacha.rarity != b.gacha.rarity) {
      return b.gacha.rarity! - a.gacha.rarity!;
    }

    if (a.media.name != b.media.name) {
      return a.media.name!.localeCompare(b.media.name!);
    }
    return a.gacha.name!.localeCompare(b.gacha.name!);
  }

  gachaTrackBy(index: number, gacha: gachaMediaAndSelection) {
    return gacha.gacha.id;
  }

  /**********
   * Image Editing
   **********/
  onFileChange(event: any, gacha: gachaMediaAndSelection): void {
    gacha.ngxCropperData.imgChangeEvt = event;
  }

  cropImg(e: ImageCroppedEvent, gacha: gachaMediaAndSelection) {
    // this.cropImgPreview = e.base64;
    gacha.ngxCropperData.croppedBase64String = e.base64!;
  }

  imgLoad() {
    // display cropper tool
  }

  initCropper() {
    // init cropper
  }

  imgFailed() { }

  onPaste(e: ClipboardEvent, gacha: gachaMediaAndSelection) {
    let file: File | undefined | null;
    for (let i = 0; i < e.clipboardData?.items.length!; i++) {
      const type = e.clipboardData?.items[i].type;
      if (type === "image/png" || type === "image/jpg") {
        file = e.clipboardData?.items[i].getAsFile();
        break;
      }
    }

    if (!file) {
      this.toastr.error("no image found in clipboard!");
      return;
    }

    var reader = new FileReader();
    reader.onload = (event: any) => {
      gacha.ngxCropperData.imageBase64String = (event.target.result); // event.target.results contains the base64 code to create the image.
    };
    reader.readAsDataURL(file); // Convert the blob from clipboard to base64
  }
}
