import { inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ResolveFn } from '@angular/router';
import { map, tap } from 'rxjs';
import { UpaGuildServiceService } from 'src/app/openapi/services';


export const resolveGuild: ResolveFn<boolean> = (route, state) => {
  const s = inject(UpaGuildServiceService);
  const guildId = route.paramMap.get('guildID');
  const m = inject(Meta);
  if (!guildId) {
    return false;
  }
  return s.upaGuildServiceGetUpaGuild({ guildId: guildId }).pipe(
    map(guild => guild.upaGuild),
    tap(guild => {
      if (!guild) {
        return;
      }

      m.removeTag('name="title"');
      m.removeTag('name="description"');
      m.removeTag('name="og:title"');
      m.removeTag('name="og:description"');
      m.removeTag('name="og:image"');
      m.removeTag('name="twitter:image:src"');
      m.removeTag('name="twitter:card"');

      m.addTag({ name: 'title', content: `Upa - ${guild.name} guild` })
      m.addTag({ name: 'og:title', content: `Upa - ${guild.name} guild` });
      m.addTag({ name: 'description', content: `Check out the guild ${guild.name}, their players, and their accomplishments for Upa!` });
      m.addTag({ name: 'og:description', content: `Check out the guild ${guild.name}, their players, and their accomplishments for Upa!` });
      // m.addTag({
      //   name: 'og:image', content: `${guild.milestoneRewardOptions?.iconImgUrl &&
      //     !(guild.milestoneRewardOptions.iconImgUrl.includes("discordapp")) ? guild.milestoneRewardOptions.iconImgUrl : 'https://cdn.upa.moe/JirachiDice256.png'}`
      // });
    }),
    map(_ => true)
  );
};
