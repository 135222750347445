/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AdminServiceUpdateBannerRequest } from '../../models/admin-service-update-banner-request';
import { UpdateBannerResponse } from '../../models/update-banner-response';

export interface AdminServiceUpdateBanner$Params {
  'banner.id': string;
      body: AdminServiceUpdateBannerRequest
}

export function adminServiceUpdateBanner(http: HttpClient, rootUrl: string, params: AdminServiceUpdateBanner$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateBannerResponse>> {
  const rb = new RequestBuilder(rootUrl, adminServiceUpdateBanner.PATH, 'put');
  if (params) {
    rb.path('banner.id', params['banner.id'], {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<UpdateBannerResponse>;
    })
  );
}

adminServiceUpdateBanner.PATH = '/api/v3/gateway/admin/banner/{banner.id}';
