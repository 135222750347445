<nav class="navbar has-shadow is-transparent is-fixed-top" role="navigation" aria-label="main navigation">
  <div class="navbar-brand">
    <a class="navbar-item" [routerLink]="['/']" routerLinkActive="router-link-active">
      <img src="https://cdn.upa.moe/JirachiDice256.png" width="28" height="28">
    </a>
    <a (click)="toggleHamburger()" [ngClass]="{'is-active': hamburgerToggled}" role="button" class="navbar-burger"
      aria-label="menu" aria-expanded="false" data-target="mainMenu">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div id="mainMenu" [ngClass]="{'is-active': hamburgerToggled}" class="navbar-menu">
    <div class="navbar-start">
      <a [routerLink]="['/view/banners']" class="navbar-item">
        Banners & Events
      </a>
      <a [routerLink]="['/view/media/all']" class="navbar-item">
        Gacha
      </a>
      <a [routerLink]="['/view/altart/all']" class="navbar-item">
        Alternate Art
      </a>
      <a [routerLink]="['/view/enhancements']" class="navbar-item">
        Borders & Effects
      </a>
      <a [routerLink]="['/view/guilds/all']" class="navbar-item">
        Guilds
      </a>
      <a [routerLink]="['/view/marketplace']" class="navbar-item">
        Bazaar
      </a>
      <div class="navbar-item has-dropdown is-hoverable">
        <a class="navbar-link">
          Guide
        </a>
        <div class="navbar-dropdown">
          <a [routerLink]="['/guide']" class="navbar-item" routerLinkActive="router-link-active">
            Guide
          </a>
          <a [routerLink]="['/commands']" class="navbar-item" routerLinkActive="router-link-active">
            Commands
          </a>
        </div>
      </div>
      <a [routerLink]="['/view/leaderboard/global/monthly']" class="navbar-item">
        Leaderboard
      </a>
      <a target="_blank" [routerLink]="['/invite']" class="navbar-item">
        Invite Bot
      </a>
      <a target="_blank" [routerLink]="['/server']" class="navbar-item">
        Join our Server!
      </a>
      <a [routerLink]="['/live']" class="navbar-item">
        Live Rolls
      </a>

      <ng-container *ngIf="auth.user$ | async as user">
        <div *ngIf="canViewAdminTab(user)" class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link">
            Admin
          </a>
          <div class="navbar-dropdown">
            <a *ngIf="canViewUpload(user)" [routerLink]="['/admin/media/upload']" routerLinkActive="router-link-active"
              class="navbar-item">
              Create Media
            </a>
            <a *ngIf="canViewUpload(user)" [routerLink]="['/admin/media/view']" routerLinkActive="router-link-active"
              class="navbar-item">
              View/Edit Media
            </a>
            <hr class="navbar-divider /">
            <a *ngIf="canViewUpload(user)" [routerLink]="['/admin/gacha/upload']" routerLinkActive="router-link-active"
              class="navbar-item">
              Single Gacha Upload
            </a>
            <a *ngIf="canViewUpload(user)" [routerLink]="['/admin/gacha/multiupload']"
              routerLinkActive="router-link-active" class="navbar-item">
              Multi Upload Gacha
            </a>
            <a *ngIf="canViewStaging(user)" [routerLink]="['/admin/gacha/staging']"
              rouuterLinkActive="router-link-active" class="navbar-item">
              View Staging Gacha
            </a>
            <hr class="navbar-divider /">
            <a *ngIf="canViewAltArt(user)" [routerLink]="['/admin/altart/upload']" routerLinkActive="router-link-active"
              class="navbar-item">
              Upload Alt Art
            </a>
            <a *ngIf="canViewAltArt(user)" [routerLink]="['/admin/altart/staging']"
              routerLinkActive="router-link-active" class="navbar-item">
              View Staging Alt Art
            </a>
            <a *ngIf="canViewAltArt(user)" [routerLink]="['/admin/altart/view']" routerLinkActive="router-link-active"
              class="navbar-item">
              View (Live) Alt Art
            </a>
            <hr class="navbar-divider" />
            <a *ngIf="canViewGachaList(user)" [routerLink]="['/admin/gacha/view']" routerLinkActive="router-link-active"
              class="navbar-item">
              View Gacha & Create Editions
            </a>
            <hr class="navbar-divider" />
            <a *ngIf="canViewStagingEquipmentUpload(user)" [routerLink]="['/admin/equipment/upload']"
              routerLinkActive="router-link-active" class="navbar-item">
              Upload Staging Equipment
            </a>
            <a *ngIf="canViewStagingEquipment(user)" [routerLink]="['/admin/equipment/staging']"
              routerLinkActive="router-link-active" class="navbar-item">
              View Staging Equipment
            </a>
            <hr class="navbar-divider" />
            <a *ngIf="canViewBanners(user)" [routerLink]="['/admin/banners']" routerLinkActive="router-link-active"
              class="navbar-item">
              View Banners
            </a>
            <a *ngIf="canViewBanners(user)" [routerLink]="['/admin/banners/create']"
              routerLinkActive="router-link-active" class="navbar-item">
              Create Banner
            </a>
            <hr class="navbar-divider" />
            <a *ngIf="canViewAbuse(user)" [routerLink]="['/admin/abuse']" routerLinkActive="router-link-active"
              class="navbar-item">
              Abuse Checking
            </a>
            <a *ngIf="canViewGiveaway(user)" [routerLink]="['/admin/giveaway']" routerLinkActive="router-link-active"
              class="navbar-item">
              Giveaways
            </a>
          </div>
        </div>
      </ng-container>
    </div>


    <ng-container *ngIf="auth.user$ | async as user">
      <div (click)="toggleNotifications()" [ngClass]="{'is-active': notificationsToggled}"
        class="navbar-item has-dropdown">
        <a class="navbar-item">
          <span class="icon-container">
            <fa-icon [ngClass]="{'with-circle': unreadNotifications.length > 0}" class="with-circle"
              [icon]="faBell"></fa-icon>
          </span>
        </a>
        <div class="navbar-dropdown is-right">
          <ng-container *ngFor="let notification of unreadNotifications">
            <div class="navbar-item">
              <div class="content">
                <p>
                  <strong>{{ notification.title }}</strong>
                  <br>
                  {{ notification.content }}
                  <br>
                  <small class="is-size-7 has-text-weight-light	">{{ notification.createdAt | date: 'medium' }}</small>
                </p>
              </div>
            </div>
            <hr class="navbar-divider">
          </ng-container>
          <ng-container *ngFor="let notification of readNotifications">
            <div class="navbar-item">
              <div class="content">
                <p>
                  <strong>{{ notification.title }}</strong>
                  <br>
                  {{ notification.content }}
                  <br>
                  <small class="is-size-7 has-text-weight-light	">{{ notification.createdAt | date: 'medium' }}</small>
                </p>
              </div>
            </div>
            <hr class="navbar-divider">
          </ng-container>
        </div>
      </div>
    </ng-container>

    <a [routerLink]="['settings']" class="navbar-item">
      <fa-icon [icon]="faCog"></fa-icon>
    </a>
    <app-auth-button></app-auth-button>
  </div>
</nav>