import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import AsyncLock from 'async-lock';
import { switchMap, map, tap, combineLatestWith, take, filter } from 'rxjs/operators';
import { mergeMap, of, combineLatest } from 'rxjs';
import { Gacha, Media } from 'src/app/openapi/models';
import { GachaServiceService, MediaServiceService, UserServiceService } from 'src/app/openapi/services';
import { AuthService } from 'src/app/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';

interface GachaAndCopies {
  gacha: Gacha;
  numCopies: number;
}

@Component({
  selector: 'app-view-media',
  templateUrl: './view-media.component.html',
  styleUrls: ['./view-media.component.scss']
})
export class ViewMediaComponent implements OnInit {
  faSolidStar = faStarSolid;
  faHollowStar = faStar;

  mediaName?: string;
  nextToken?: string;
  prevToken?: string;
  scrollDownLock = new AsyncLock();

  /******************************
   * Manage User Favorite Media *
   ******************************/
  favoriteMedia?: Media;

  gachas?: GachaAndCopies[];
  media?: Media;

  constructor(private gachaApi: GachaServiceService,
    private mediaApi: MediaServiceService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private userService: UserServiceService,
    public auth: AuthService) {
    this.route.data.subscribe(_ => { })
  }

  ngOnInit(): void {
    this.route.params.pipe(
      mergeMap(p => this.gachaApi.gachaServiceGetGachasPaginated({
        mediaId: p['mediaID'],
        "paginationOptions.pageSize": 24,
      })),
      mergeMap(res => {
        const numberOfGacha = this.gachaApi.gachaServiceGetNumberOfGacha({
          body: {
            gachaId: res.gachas!.map(g => g.id!),
          }
        });
        return combineLatest([of(res), numberOfGacha]);
      }),
      map(([res, numberOfGacha]) => {
        this.gachas = res.gachas!.map(g => {
          let numCopies: number = 0;
          if (numberOfGacha.gachaIdToCount) {
            if (numberOfGacha.gachaIdToCount[g.id!]) {
              numCopies = numberOfGacha.gachaIdToCount[g.id!];
            }
          }
          return {
            gacha: g,
            numCopies: numCopies,
          }
        });
        // Check if we even got gacha
        if (!this.gachas || this.gachas.length === 0) {
          throw new Error('No gacha found');
        }
        this.nextToken = res.paginationDetails?.nextPage;
        return this.gachas[0].gacha.mediaId! as string;
      }),
      switchMap(mediaId => this.mediaApi.mediaServiceGetMedia({
        mediaId: mediaId,
      })),
      tap(m => {
        this.media = m.media!;
        this.mediaName = m.media!.name!;
      })
    ).subscribe();
    this.auth.user$.pipe(
      take(1),
      filter(u => u !== undefined),
      mergeMap(u => this.userService.userServiceGetUserFavoriteMedia({ userUuid: u!.user_uuid! })),
    ).subscribe(fav => {
      if (fav.media) {
        this.favoriteMedia = fav.media;
      }
    })
  }

  onScrollDown() {
    this.scrollDownLock.acquire('scroll-down', (done) => {
      if (!this.nextToken) {
        done();
        return;
      }

      this.route.params.pipe(
        switchMap(p => this.gachaApi.gachaServiceGetGachasPaginated({
          mediaId: p['mediaID'],
          "paginationOptions.pageSize": 24,
          "paginationOptions.page": this.nextToken,
        }).pipe(
          mergeMap(res => {
            if (!res.gachas) {
              res.gachas = [];
            }
            const numberOfGacha = this.gachaApi.gachaServiceGetNumberOfGacha({
              body: {
                gachaId: res.gachas.map(g => g.id!),
              }
            });
            return combineLatest([of(res), numberOfGacha]);
          }),
        ))
      ).subscribe(([res, numberOfGacha]) => {
        this.gachas = this.gachas?.concat(res.gachas!.map(g => {
          let numCopies: number = 0;
          if (numberOfGacha.gachaIdToCount) {
            if (numberOfGacha.gachaIdToCount[g.id!]) {
              numCopies = numberOfGacha.gachaIdToCount[g.id!];
            }
          }
          return {
            gacha: g,
            numCopies: numCopies
          }
        }));
        this.nextToken = res.paginationDetails?.nextPage;
        done();
      });
    });
  }

  setFavoriteMedia(mediaID: string) {
    this.auth.user$.pipe(
      take(1),
      filter(u => u !== undefined),
      mergeMap(u => this.userService.userServiceSetUserFavoriteMedia({
        userUuid: u!.user_uuid!,
        body: { mediaId: mediaID }
      })),
    ).subscribe(fav => {
      if (fav.media) {
        this.favoriteMedia = fav.media;
        this.toastr.success(`Set favorite media to ${fav.media.name}`);
      }
    })
  }

  removeFavoriteMedia() {
    this.auth.user$.pipe(
      take(1),
      filter(u => u !== undefined),
      mergeMap(u => this.userService.userServiceDeleteUserFavoriteMedia({
        userUuid: u!.user_uuid!,
      })),
    ).subscribe(res => {
      switch (res.status) {
        case "NOT_FOUND":
          this.toastr.error("No favorite media found");
          break;
        case "SUCCESS":
          this.favoriteMedia = undefined;
          this.toastr.info("Removed favorite media");
          break;
        default:
          this.toastr.error("Unknown error");
      }
    })
  }

  trackBy(index: number, g: GachaAndCopies): string {
    return g.gacha.id!;
  }
}
