import { QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { AfterViewInit } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Gacha, GachaCategory, Media, Origin } from 'src/app/openapi/models';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit, AfterViewInit {

  @ViewChild('quickstart') quickstart!: TemplateRef<any>;
  @ViewChild('rolling') rolling!: TemplateRef<any>;
  @ViewChild('duplicates') duplicates!: TemplateRef<any>;
  @ViewChild('stardustExchange') stardustExchange!: TemplateRef<any>;
  @ViewChild('drops') drops!: TemplateRef<any>;
  @ViewChild('quests') quests!: TemplateRef<any>;
  @ViewChild('trading') trading!: TemplateRef<any>;
  @ViewChild('altart') altart!: TemplateRef<any>;
  @ViewChild('sacrificing') sacrificing!: TemplateRef<any>;

  @ViewChildren(TemplateRef) allTemplates!: QueryList<TemplateRef<any>>;
  selectedTab?: TemplateRef<any>;
  tabName: string = "quickstart";
  constructor(private cdr: ChangeDetectorRef, private route: ActivatedRoute, private router: Router) {

  }

  ngAfterViewInit(): void {
    this.route.fragment.subscribe((fragment: string | null) => {
      if (fragment) {
        this.selectTab(fragment);
      } else {
        this.selectTab("quickstart");
      }
    });
    this.cdr.detectChanges();
  }

  ngOnInit(): void {

  }

  selectTab(tab: string) {
    switch (tab) {
      case "quickstart":
        this.selectedTab = this.quickstart;
        break;
      case "rolling":
        this.selectedTab = this.rolling;
        break;
      case "duplicates":
        this.selectedTab = this.duplicates;
        break;
      case "stardustExchange":
        this.selectedTab = this.stardustExchange;
        break;
      case "drops":
        this.selectedTab = this.drops;
        break;
      case "quests":
        this.selectedTab = this.quests;
        break;
      case "trading":
        this.selectedTab = this.trading;
        break;
      case "altart":
        this.selectedTab = this.altart;
        break;
      case "sacrificing":
        this.selectedTab = this.sacrificing;
        break;
      default:
        return;
    }

    this.tabName = tab;
    this.router.navigate([], { fragment: tab })
  }

  exampleAltArtMedia: Media = {
    id: "3195d245-f3d3-4551-a87f-77f0ed77b131",
    name: "Azur Lane",
    origin: "GAME",
  }

  exampleAltArtGacha: Gacha = {
    id: "1804c995-0d62-43e5-b092-e03e05fe316a",
    name: "Seattle",
    imageUrl: "https://cdn.upa.moe/gacha/3195d245-f3d3-4551-a87f-77f0ed77b131/1804c995-0d62-43e5-b092-e03e05fe316a.png",
    category: "WAIFU",
    rarity: 5,
    mediaId: "3195d245-f3d3-4551-a87f-77f0ed77b131",
  }

  exampleAltArtGachaAltArt: Gacha = {
    id: "1804c995-0d62-43e5-b092-e03e05fe316a",
    name: "Sunfish Spell Seattle",
    imageUrl: "https://cdn.upa.moe/gacha/3195d245-f3d3-4551-a87f-77f0ed77b131/altart/1804c995-0d62-43e5-b092-e03e05fe316a/1.png",
    category: "WAIFU",
    rarity: 5,
    mediaId: "3195d245-f3d3-4551-a87f-77f0ed77b131",
  }
}
