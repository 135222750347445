import { Component, Input, OnInit } from '@angular/core';
import { MarketplaceReactionType } from 'src/app/openapi/models';

@Component({
  selector: 'app-reaction',
  templateUrl: './reaction.component.html',
  styleUrl: './reaction.component.scss'
})
export class ReactionComponent implements OnInit {

  @Input({ required: true })
  reaction!: MarketplaceReactionType;

  @Input({ required: true })
  count?: number;

  reactionEmoji: string = "";

  ngOnInit() {
    this.reactionEmoji = this.renderReactionEmoji();
  }

  renderReactionEmoji(): string {
    switch (this.reaction) {
      case "LIKE_MARKETPLACE_REACTION":
        return "👍";
      case "DISLIKE_MARKETPLACE_REACTION":
        return "👎";
      case "FIRE_MARKETPLACE_REACTION":
        return "🔥";
      case "HEART_MARKETPLACE_REACTION":
        return "❤️";
      case "LAUGH_MARKETPLACE_REACTION":
        return "😂";
      case "UNDEFINED_MARKETPLACE_REACTION_TYPE":
      default:
        return "";
    }
  }
}
