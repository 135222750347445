<div class="container is-fullwidth">
  <div class="tile is-ancestor">
    <div class="tile is-parent">
      <div class="columns">
        <div class="column is-2">
          <aside class="menu">
            <p class="menu-label">
              Current Banners
            </p>
            <ul class="menu-list">
              <ng-container *ngFor="let b of allBanners">
                <ng-container *ngIf="isPresent(b)">
                  <li>
                    <a (click)="toAnchor(b.id!)">
                      {{ b.name }}
                    </a>
                  </li>
                </ng-container>
              </ng-container>
            </ul>
            <p class="menu-label">
              Future Banners
            </p>
            <ul class="menu-list">
              <ng-container *ngFor="let b of allBanners">
                <ng-container *ngIf="isFuture(b)">
                  <li>
                    <a (click)="toAnchor(b.id!)">
                      {{ b.name }}
                    </a>
                  </li>
                </ng-container>
              </ng-container>
            </ul>
            <p class="menu-label">
              Past Banners
            </p>
            <ul class="menu-list">
              <ng-container *ngFor="let b of allBanners">
                <ng-container *ngIf="isPast(b)">
                  <li>
                    <a (click)="toAnchor(b.id!)">
                      {{ b.name }}
                    </a>
                  </li>
                </ng-container>
              </ng-container>
            </ul>
          </aside>
        </div>
      </div>
    </div>



    <div class="columns is-multiline">
      <div class="field">
        <label class="checkbox">
          <input (change)="showPastBannersChange($event)" [checked]="showPastBanners" type="checkbox">
          Show past banners (slow!)
        </label>
      </div>
      <ng-container *ngFor="let b of allBanners">
        <div class="column is-12" id="{{b.id}}">
          <div class="buttons">
            <button [routerLink]="['/admin/banners/update/', b.id]" class="button is-info">
              Edit Banner [{{ b.name }}]
            </button>
            <button *ngIf="b.visible" class="button is-warning" (click)="setVisibility(b.id!, false)">
              Disable Visibility
            </button>
            <button *ngIf="!b.visible" class="button is-success" (click)="setVisibility(b.id!, true)">
              Enable Visibility
            </button>
            <button (click)="deleteBanner(b)" class="button is-danger">
              DELETE Banner [{{ b.name }}]
            </button>
          </div>
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field">
                <label class="label">Banner ID</label>
                <div class="control">
                  <input disabled class="input" type="text" placeholder="Unique, no space ID" value="{{ b.id }}">
                </div>
              </div>
              <div class="field">
                <label class="label">Banner Name</label>
                <div class="control">
                  <input disabled class="input" type="text" placeholder="Banner name" value="{{ b.name }}">
                </div>
              </div>
            </div>
          </div>

          <div class="field">
            <label class="label">Description</label>
            <div class="control">
              <textarea disabled class="textarea" placeholder="Description" value="{{ b.description }}"></textarea>
            </div>
          </div>

          <div class="content">
            <h1 *ngIf="b.startTime!" class="title is-4">Start Date</h1>
            <p>{{ b.startTime! | date: 'long' }}</p>
            <h1 *ngIf="b.endTime!" class="title is-4">End Date</h1>
            <p>{{ b.endTime! | date: 'long' }}</p>
          </div>

          <div class="field">
            <label class="label">Banner Image (We dont' set this right now)</label>
            <div class="control">
              <input class="input" type="text" placeholder="Banner image URL" value="{{ b.imageUrl }}" disabled>
            </div>
          </div>

          <img *ngIf="b.imageUrl" src="{{ b.imageUrl }}" alt="">

          <div class="field">
            <label class="label">Thumbnail Image</label>
            <div class="control">
              <input disabled class="input" type="text" placeholder="Thumbnail image URL" value="{{ b.thumbnailUrl }}">
            </div>
          </div>
          <img *ngIf="b.thumbnailUrl" src="{{ b.thumbnailUrl }}" alt="">

          <div>
            <h1 class="title is-4">Rateup Data</h1>
            <ng-container *ngFor="let k of b.rateUpGachas | keyvalue;">
              <div>
                <h2 class="subtitle is-5">
                  {{ k.key }}⭐: {{ k.value.rateUpPercentage! * 100 }}%
                </h2>
                <ng-container *ngFor="let gachaID of k.value.gachaIds">
                  <ng-container *ngIf="(gachaID | getGachaPipe | async) as gachaAndMedia">
                    <app-gacha-v2 [gacha]="gachaAndMedia.g" [media]="gachaAndMedia.m"></app-gacha-v2>
                  </ng-container>
                </ng-container>
              </div>
              <hr />
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>