<div class="container">
  <div class="columns">
    <div class="column is-2">
      <aside class="menu">
        <p class="menu-label has-text-grey-light">
          Upa Guide
          <a href="#guide-menu-collapsible" class="no-decoration-a white" data-action="collapse">
            <span class="icon">
              <fa-icon *ngIf="collapsible && !collapsible.collapsed()" [icon]="faMinimize">abcd</fa-icon>
              <fa-icon *ngIf="collapsible && collapsible.collapsed()" [icon]="faMaximize">abcdd</fa-icon>
            </span>
          </a>
        </p>
        <div class="is-collapsible" id="guide-menu-collapsible">
          <ul class="menu-list">
            <li><a (click)="selectTab('quickstart')" [class.is-active]="selectedTab == 'quickstart'">Quickstart</a></li>
          </ul>
          <p class="menu-label has-text-grey-light">
            Upa Basics
          </p>
          <ul class="menu-list">
            <li>
              <a (click)="selectTab('rolling')" [class.is-active]="selectedTab == 'rolling'">Rolling for Gacha</a>
              <ul>
                <li><a (click)="selectTab('hourly-limits')" [class.is-active]="selectedTab == 'hourly-limits'">Hourly
                    Limits</a></li>
                <li><a (click)="selectTab('duplicates')" [class.is-active]="selectedTab == 'duplicates'">Getting
                    Duplicates</a></li>
              </ul>
            </li>
            <li><a (click)="selectTab('rateupbanners')" [class.is-active]="selectedTab == 'rateupbanners'">Rate-up
                Banners</a></li>
            <li><a (click)="selectTab('drops')" [class.is-active]="selectedTab == 'drops'">Channel Drops</a></li>
            <li><a (click)="selectTab('quests')" [class.is-active]="selectedTab == 'quests'">Quests</a></li>
            <li><a (click)="selectTab('achievements')"
                [class.is-active]="selectedTab == 'achievements'">Achievements</a>
            </li>
            <li><a (click)="selectTab('leaderboard')" [class.is-active]="selectedTab == 'leaderboard'">Leaderboard</a>
            </li>
            <li><a (click)="selectTab('reward')" [class.is-active]="selectedTab == 'reward'">Items & Rewards</a>
            </li>

          </ul>
          <p class=" menu-label has-text-grey-light">
            Customization
          </p>
          <ul class="menu-list">
            <li><a (click)="selectTab('favorites')" [class.is-active]="selectedTab == 'favorites'">Favorites</a></li>
            <li><a (click)="selectTab('altart')" [class.is-active]="selectedTab == 'altart'">Alternate Art</a></li>
            <li><a (click)="selectTab('effects')" [class.is-active]="selectedTab == 'effects'">Card Effects &
                Borders</a></li>
            <li><a (click)="selectTab('tradeable')" [class.is-active]="selectedTab == 'tradeable'">Marking for Trade</a>
            </li>
          </ul>
          <p class=" menu-label has-text-grey-light">
            Stardust Exchange
          </p>
          <ul class="menu-list">
            <li><a (click)="selectTab('stardustexchange')" [class.is-active]="selectedTab == 'stardustexchange'">Shop &
                Shop Items</a></li>
          </ul>
          <p class="menu-label has-text-grey-light">
            Trading
          </p>
          <ul class="menu-list">
            <li><a (click)="selectTab('trading')" [class.is-active]="selectedTab == 'trading'">Trading</a></li>
            <li><a (click)="selectTab('marketplace')" [class.is-active]="selectedTab == 'marketplace'">
                Card Bazaar <span class="tag is-info">New!</span>
              </a></li>
            <li><a (click)="selectTab('cardvalue')" [class.is-active]="selectedTab == 'cardvalue'">Card Value
                Reference</a></li>
          </ul>
          <p class="menu-label has-text-grey-light">
            Combat & Parties
          </p>
          <ul class="menu-list">
            <li><a (click)="selectTab('parties')" [class.is-active]="selectedTab == 'parties'">Parties</a></li>
            <li><a (click)="selectTab('expeditions')" [class.is-active]="selectedTab == 'expeditions'">Expeditions</a>
            </li>
            <li><a (click)="selectTab('dungeons')" [class.is-active]="selectedTab == 'dungeons'">Dungeons</a></li>
          </ul>
          <p class="menu-label has-text-grey-light">
            Guilds
          </p>
          <ul class="menu-list">
            <li><a (click)="selectTab('guilds')" [class.is-active]="selectedTab == 'guilds'">Guild Overview</a></li>
            <li><a (click)="selectTab('createguild')" [class.is-active]="selectedTab == 'createguild'">Creating a
                Guild</a></li>
            <li><a (click)="selectTab('findguild')" [class.is-active]="selectedTab == 'findguild'">Find & Join a
                Guild</a></li>
            <li><a (click)="selectTab('guildshop')" [class.is-active]="selectedTab == 'guildshop'">Guild Shop</a></li>
          </ul>
          <p class="menu-label has-text-grey-light">
            Sacrificing
          </p>
          <ul class="menu-list">
            <li><a (click)="selectTab('sacrificing')" [class.is-active]="selectedTab == 'sacrificing'">Sacrificing</a>
            </li>
          </ul>
          <p class="menu-label has-text-grey-light">
            Support & Premium
          </p>
          <ul class="menu-list">
            <li><a (click)="selectTab('support')" [class.is-active]="selectedTab == 'support'">Get Support</a></li>
            <li><a (click)="selectTab('suggest')" [class.is-active]="selectedTab == 'suggest'">Suggest New Gacha & Alt
                Art</a></li>
            <li><a (click)="selectTab('premium')" [class.is-active]="selectedTab == 'premium'">Premium</a></li>
          </ul>
        </div>
      </aside>
    </div>
    <div *ngIf="tabContentTemplates" class="column is-10">
      <ng-container *ngTemplateOutlet="(template$ | async)"></ng-container>
    </div>
  </div>
</div>

<ng-template typeTemplate="quickstart">
  <div class="content">
    <h1 class="title is-size-1">Quickstart</h1>
    <p>Welcome to Upa! Upa is a card collecting game in Discord where you roll for cards, upgrade your collection,
      compete on the leaderboard, trade, sacrifice, and much more.</p>
    <h2 class="subtitle is-size-3">Inviting Upa</h2>
    <p>First, start out by <a [routerLink]="['/server']">inviting Upa to your server</a>. You need the
      <code>Manage Server</code>
      Discord permission to do so.
    </p>
    <h2 class="subtitle is-size-3">Rolling for Cards</h2>
    <p>Your first command should be <code>/roll</code>! Rolling lets you roll for a card, up to 10 times per hour. Don't
      worry about this limit - there are many ways to increase it and roll more.</p>
    <h2 class="subtitle is-size-3">Your Profile</h2>
    <p>Rolling automatically created a profile for you. You should use <code>/me</code> to see your profile and your own
      link to your page - click it, and check out your collection!</p>
    <p>You can also access it through the shortcut <a [routerLink]="['/me']">upa.moe/me</a>. You'll have to login
      through Discord first, though!</p>
    <h2 class="subtitle is-size-3">Next Steps</h2>
    <p>There's simply so much to Upa to discover! We recommend continuing through this guide, viewing the <a
        [routerLink]="['/commands']">commands page</a>, or using <code>/help</code> to see what you can do next.</p>
  </div>
</ng-template>

<ng-template typeTemplate="rolling">
  <div class="content">
    <h1 class="title is-size-1">Rolling for Gacha</h1>
    <p>Rolling for gacha is the primary way to obtain cards in Upa. You can roll for a card using the <code>/roll</code>
      command. This will give you a random card that only you can claim for the first minute. After that, there's a
      period where <em>anyone</em> can claim it!</p>
    <h2 class="subtitle is-size-3">Rates</h2>
    <p>
      5⭐ rates are 0.5% per roll - which averages out to about 1 in 200 rolls. Check out your <em>dry statistics</em>
      with the <code>/dry</code> command to see how you're doing. There are also achievements for going dry, so it's not
      all lost if you're having a bad luck streak!
    </p>
    <h2 class="subtitle is-size-3">Wishlist</h2>
    <p>
      If you have a wishlist token, you can use <code>/wishlist</code> to pick a media you want to wishlist. This will
      increase the chance that a 5⭐ roll will drop directly from this media! This is a super powerful tool to help
      you target exactly what you want. To get more tokens, you can buy them from other players or through the
      <a (click)="selectTab('stardustexchange')">Stardust Exchange</a>.
    </p>
    <div class="columns">
      <div class="column is-8">
        <h2 class="subtitle is-size-3">Rainbow Rare</h2>
        <p>Any gacha rolled has a very low (0.02%) chance of having a Rainbow holo effect on the card. These cards
          are very rare and are highly sought after - especially on a 5⭐ card!</p>
      </div>
      <div class="column is-4 has-text-centered">
        <app-gacha-v2 [gacha]="exampleRainbowRareGacha" [media]="exampleRainbowRareMedia"></app-gacha-v2>
        <p><em>Example of a Rainbow Rare card</em></p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template typeTemplate="hourly-limits">
  <div class="content">
    <h1 class="title is-size-1">Hourly Limits</h1>
    <h2 class="subtitle is-size-3">Roll Limits</h2>
    <p>By default, you can roll up to 10 times per hour. The limit resets every hour <em>on the hour</em> - basically,
      at 0 minutes on the hour.
      That means if you rolled at 2:59PM, you can roll again at 3:00PM!</p>
    <p>There are several ways to increase this limit, including -</p>
    <ul>
      <li>
        <strong>Upgrading your hourly limit</strong>. Using the <a (click)="selectTab('stardustexchange')">Stardust
          Exchange</a> (<code>/stardustexchange</code>) command, you can use <strong>Stardust</strong> to upgrade your
        account.
      </li>
      <li>
        <strong>Joining a guild</strong>. <a (Click)="selectTab('guild-benefits')">Guilds</a> can use their guild
        currency to upgrade hourly limits for all their
        members.
      </li>
      <li>
        <strong>Getting extra rolls...</strong>
        <ul>
          <li>by voting with <code>/vote</code> every 12 hours</li>
          <li>by purchasing them in the Stardust Exchange</li>
          <li>by getting them as loot in <code>/expedition</code></li>
        </ul>
      </li>
    </ul>
    <h2 class="subtitle is-size-3">Claim Limits</h2>
    <p>There are also limits on how many times you can claim gacha per hour. This starts at 1 per hour, but can also be
      upgraded by the above.
      You can also join the <a [routerLink]="['/server']">Upa Community server</a> to get +2 claims every hour!</p>
  </div>
</ng-template>

<ng-template typeTemplate="duplicates">
  <div class="content">
    <h1 class="title is-size-1">Duplicate Rolls</h1>
    <p>When you roll for a card, you may get a duplicate of a card you already own. You can't own multiple copies of the
      same card, but you can do two things with duplicate rolls -</p>
    <div class="columns">
      <div class="column is-6">
        <ul>
          <li>
            <strong>Starpower</strong> - cards can combine up to level 3 starpower. This is a cosmetic change - however,
            once
            you reach level 3, you get a special effect that can be placed on the card!
          </li>
          <li>
            <strong>Dust</strong> - you can consume the card while rolling to turn it into <a
              (click)="selectTab('stardustexchange')">Stardust</a>, a currency you can use at the shop.
            <div><img src="/assets/duplicate.png"></div>
          </li>
        </ul>
      </div>
      <div class="column is-6 has-text-centered">
        <app-gacha-v2 [gacha]="exampleAltArtGacha" [media]="exampleAltArtMedia" [starpower]="3"></app-gacha-v2>
        <p><em>Example of a starpower 3 card</em></p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template typeTemplate="rateupbanners">
  <div class="content">
    <h1 class="title is-size-1">Rate-up Banners</h1>
    <p>Rate-up banners are special events that increase the chance of getting specific cards. These banners are
      time-limited, so be sure to check often on what's available!</p>
    <p>You can view the current banners with <code>/banners</code> or by going to
      <a [routerLink]="['/view','banners']">the Banners & Events page</a>
    </p>
    <h2 class="subtitle is-size-3">How to roll</h2>
    <p>Banners have a dedicated roll command <code>/rollbanner</code>. Functionally, it is the same as
      <code>/roll</code>,
      but you choose the banner to increase your chances on.
    </p>
    <div class="notification is-warning is-light">
      Warning! Wishlists <strong>do not work</strong> on the <code>/rollbanner</code> command!
    </div>
  </div>
</ng-template>

<ng-template typeTemplate="drops">
  <div class="content">
    <h1 class="title is-size-1">Channel Drops</h1>
    <p>
      <code>Server Managers</code> can set a channel as a <strong>drop channel</strong>. Drop channels will receive free
      cards
      and other items about ~4 times an hour, 24/7
    </p>
    <h2 class="subtitle is-size-3">How to get drops</h2>
    <p>A <code>Server Manager</code> can set the channel with <code>/setdropchannel #channel</code>.</p>
    <h2 class="subtitle is-size-3">What you can get</h2>
    <p>
      Drops mostly drop gacha cards, and the more active server members are, the better the drops will be.
      Drops are on a 10 minute cooldown between claims. We have special mechanisms to prevent "drop server" abuse, so
      please don't do that :)
    </p>
  </div>
</ng-template>

<ng-template typeTemplate="quests">
  <div class="content">
    <h1 class="title is-size-1">Quests</h1>
    <div class="columns">
      <div class="column is-8">
        <p>Upa has two types of quests: <em>permanent</em> and <em>repeatable</em>.</p>
        <h2 class="subtitle is-size-3">How to view</h2>
        <p>Use the <code>/quests</code> command to view your quests.</p>
        <h2 class="subtitle is-size-3">Permanent Quests</h2>
        <p>Quests that provide a one-time reward - once you complete them, they're completed forever for your account.
        </p>
        <h2 class="subtitle is-size-3">Repeatable Quests</h2>
        <p>Quests that provide a reward every time you complete them. These are great for earning extra Stardust and
          other
          rewards.</p>
        <p>Upa currently has daily and weekly quests, though monthly could be coming soon 👀</p>
      </div>
      <div class="column is-4">
        <img src="/assets/quests.png" alt="Quests">
      </div>
    </div>
  </div>
</ng-template>

<ng-template typeTemplate="achievements">
  <div class="content">
    <h1 class="title is-size-1">Achievements</h1>
    <p>Completing certain tasks will grant you achievements which you can show off on your user profile</p>
    <h2 class="subtitle is-size-3">How to view</h2>
    <p>The best place to view your achievements is on your web profile linked through <code>/me</code> or <a
        [routerLink]="['/me']">found here.</a></p>
    <h2 class="subtitle is-size-3">How to earn</h2>
    <p>There are many available achievements, including -</p>
    <ul>
      <li>Roll count</li>
      <li>Claim count</li>
      <li>Dry streak</li>
      <li>25%, 50%, 75%, 100% a series</li>
      <li>and many more!</li>
    </ul>
  </div>
</ng-template>

<ng-template typeTemplate="leaderboard">
  <div class="content">
    <h1 class="title is-size-1">Leaderboard</h1>
    <p>Upa features two leaderboards - a monthly and an all-time. These are accessed at
      <a [routerLink]="['/leaderboard', 'global', 'monthly']">upa.moe/leaderboard</a> or through the
      <code>/leaderboard</code> command.
    </p>
    <p>
      Within each leaderboard, there are 3 sub-boards: Upa Score, Rolls, and Claims
    </p>
    <ul>
      <li><strong>Upa Score</strong> - a score based on your rolls and claims</li>
      <li><strong>Rolls</strong> - the total number of rolls you've done</li>
      <li><strong>Claims</strong> - the total number of claims you've done</li>
    </ul>
    <h2 class="subtitle is-size-3">Monthly Rewards</h2>
    <p>
      At the end of each month, the top 10 users on the monthly leaderboard will receive a special reward. This reward
      typically includes
      a <a (click)="selectTab('reward')">Selector Ticket</a>, but can vary month to month. Be sure to join the
      <a [routerLink]="['/server']">Upa Community server</a> to stay up to date on the rewards!
    </p>
  </div>
</ng-template>

<ng-template typeTemplate="reward">
  <div class="content">
    <h1 class="title is-size-1">Items & Rewards</h1>
    <p>
      There are many supplemental rewards and items that can help your collection journey
    </p>
    <ul>
      <li>
        <strong>Selector Ticket</strong> - a ticket that lets you select a gacha to receive instantly. Get one from...
        <ul>
          <li>Top monthly leaderboard finish</li>
          <li>Giveaways in the <a [routerLink]="['/server']">Upa Community server</a></li>
          <li>Special events, such as anniversary, Christmas and more</li>
        </ul>
      </li>
      <li>
        <strong>Wishlist Token</strong> - a token that lets you add a card to your wishlist, increasing the chances of
        getting it. Used with <code>/wishlist</code>. Get one from...
        <ul>
          <li>Purchasing through the <a (click)="selectTab('stardustexchange')">Stardust Exchange</a></li>
          <li>Giveaways in the <a [routerLink]="['/server']">Upa Community server</a></li>
          <li>Trading other players</li>
        </ul>
      </li>
      <li>
        <strong>Booster Packs</strong> - a pack that contains a certain set of cards. These are typically described in
        its description. For example, "A booster pack that contains two 5⭐ cards from Honkai: Star Rail. Get one from...
        <ul>
          <li>Top monthly leaderboard finish</li>
          <li>Special events, such as anniversary, Christmas and more</li>
          <li>Giveaways in the <a [routerLink]="['/server']">Upa Community server</a></li>
          <li>Trading other players</li>
        </ul>
      </li>
      <li>
        <strong>Card Effects</strong> - special effects that can be attached to cards. Get them from...
        <ul>
          <li>Top monthly leaderboard finish</li>
          <li>Starpower 3 a 5⭐ card</li>
          <li>Special events, such as anniversary, Christmas and more</li>
          <li>Giveaways in the <a [routerLink]="['/server']">Upa Community server</a></li>
          <li>Trading other players</li>
        </ul>
      </li>
    </ul>
  </div>
</ng-template>

<ng-template typeTemplate="favorites">
  <div class="content">
    <h1 class="title is-size-1">Favorites</h1>
    <p>
      You can set up to 16(!) cards as your favorites. These favorites are shown in various places throughout Upa, so we
      strongly suggest keeping it up to date!
    </p>
    <h2 class="subtitle is-size-3">How to set</h2>
    <h4 class="subtitle is-size-5">From the Bot</h4>
    <p>
      Using <code>/favorites set</code>, you can set each slot (1-16) to a card in your collection.
    </p>
    <h4 class="subtitle is-size-5">From the Website</h4>
    <p>
      From <a [routerLink]="['/me']">your profile,</a> click the <button
        class="button is-small is-light is-primary">Edit Favorites</button> button. Then, you can set each individual
      slot and search for your cards.
    </p>
    <h2 class="subtitle is-size-3">Where to see</h2>
    <p>
      Your favorites are used in several places:
    </p>
    <ul>
      <li>
        <strong>Web Profile</strong> - your favorites are featured on your profile
      </li>
      <li>
        <strong><code>/me</code></strong> - your #1 slot is featured in your <code>/me</code> details
      </li>
      <li>
        <strong>Leaderboard</strong> - if you're on the leaderboard, your favorites will cycle through to make a cool
        collage
      </li>
      <li>
        <strong>Guild profile</strong> - if you're in a guild, the guild profile will also show all your favorites!
      </li>
    </ul>
  </div>
</ng-template>

<ng-template typeTemplate="altart">
  <div class="content">
    <h1 class="title is-size-1">Alternate Art</h1>
    <p>Alternate art is a special feature in Upa that allows you to collect different art for the same card. You can
      view
      alternate art in the <a [routerLink]="['/view/altart/all']">Alternate Art browser</a>.</p>
    <h2 class="subtitle is-size-3">Getting tokens</h2>
    <p>Alt art is purchased using <em>alt art tokens</em>. You can earn these through</p>
    <ul>
      <li>Weekly vote quests</li>
      <li><a (click)="selectTab('expeditions')">Expedition</a> rewards</li>
      <li>Purchasing from other users</li>
    </ul>
    <p>It costs 1 token per star rarity of a gacha - so a 1⭐ requires 1 token, and a 5⭐ requires 5 tokens.</p>
    <hr>
    <div class="columns">
      <div class="column is-6">
        <h2 class="subtitle is-size-3">How to purchase art</h2>
        <p>You can purchase alt art through the bot or through the website.</p>
        <h4 class="subtitle is-size-4">Bot</h4>
        <ol>
          <li>Use <code>/altart</code>. The command allows you to filter for gacha you own, and will show all alt art
            you can buy</li>
          <li>Purchase and set alt art through the command!</li>

        </ol>
      </div>
      <div class="column is-6">
        <img style="width:85%" src="/assets/altart_basecommand.png" alt="Alt art command">
      </div>
    </div>
    <hr>
    <div class="columns">
      <div class="column is-6">
        <h4 class="subtitle is-size-4">Website</h4>
        <ol>
          <li>Go to the <a [routerLink]="['/view/altart/all']">Alternate Art browser</a></li>
          <li>Find the art you want to purchase alt art for</li>
          <li>Click the cart button in the bottom right of the card</li>
          <li>Then, you can set it through your web profile!</li>
        </ol>
      </div>
      <div class="column is-6">
        <img src="/assets/buyaltart.png" alt="Buy alt art from the website">
      </div>
    </div>
  </div>
</ng-template>

<ng-template typeTemplate="effects">
  <div class="content">
    <h1 class="title is-size-1">Card Effects & Borders</h1>
    <div class="columns">
      <div class="column is-4">
        <p>
          Upa has lots of effects and borders you can apply to your cards. These are cosmetic, but can really enhance
          the theme for your favorites.
        </p>
        <p>
          Check out what effects you have with <code>/effects show</code> and attach them to your cards with
          <code>/effects apply</code>.
        </p>
        <div class="notification is-warning is-light">
          Tip! Attaching an effect to your card is permanent! However, you can turn on or off the effect at any time.
        </div>
        <p>To enable or disable effects, <a [routerLink]="['/me']">go to your profile</a> and click the edit icon on the
          card you want to change. There's a helpful filter to find cards with special effects, too!
        </p>
      </div>
      <div class="column is-4 has-text-centered">
        <app-gacha-v2 [gacha]="exampleEffectCensored" [media]="exampleMediaCensored"></app-gacha-v2>
        <p><em>Example "Censored" effect</em></p>
        <app-gacha-v2 [gacha]="exampleRainbowRareGacha" [media]="exampleRainbowRareMedia"></app-gacha-v2>
        <p><em>Example Rainbow Rare effect</em></p>
      </div>
      <div class="column is-4 has-text-centered">
        <app-gacha-v2 [gacha]="exampleEffectAnniversaryBorder" [media]="exampleAltArtMedia"></app-gacha-v2>
        <p><em>Example "First Anniversary" border</em></p>
        <app-gacha-v2 [gacha]="exampleEffectFallingSakura" [media]="exampleEffectFallingSakuraMedia"></app-gacha-v2>
        <p><em>Example "Falling Sakura" effect</em></p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template typeTemplate="tradeable">
  <div class="content">
    <h1 class="title is-size-1">Marking for Trade</h1>
    <p>Cards can be marked as <em>open for trade</em> (OFT) or <em>not for trade</em> (NFT). By default, new cards you
      roll or claim from drops are marked as OFT.</p>
    <h2 class="subtitle is-size-3">What happens with locked cards?</h2>
    <p>
      Locked cards (NFT cards) don't actually <em>prevent</em> you from trading them. This is a cosmetic lock that
      appears on several places,
      such as your collection. This is mostly a reminder for users to not trade away cards they want to keep and to
      potentially prevent
      people from soliciting your stuff :)
    </p>
    <h2 class="subtitle is-size-3">How to mark</h2>
    <h2 class="subtitle is-size-5">From the bot</h2>
    <ul>
      <li>
        Use the <code>/mark gacha</code> command to search for cards you want to mark as OFT or NFT.
      </li>
      <li>Use the <code>/mark media</code> command to mark entire media as OFT or NFT.</li>
    </ul>
    <h2 class="subtitle is-size-5">From the website</h2>
    <p>From your <a [routerLink]="['/me']">profile</a>, you can click the edit icon on individual cards and mark them as
      OFT or NFT.</p>
  </div>
</ng-template>

<ng-template typeTemplate="stardustexchange">
  <div class="content">
    <h1 class="title is-size-1">Stardust Exchange</h1>
    <div class="columns">
      <div class="column is-5">
        <p>The Stardust Exchange the bot's shop where you can spend <em>Stardust</em> currency to upgrade your account,
          purchase items, and more.</p>
        <h2 class="subtitle is-size-3">How to view</h2>
        <p>Use the <code>/stardustexchange</code> command to view the shop.</p>
      </div>
      <div class="column is-7">
        <img src="/assets/stardustexchange.png" alt="Stardust Exchange">
      </div>
    </div>
    <h2 class="subtitle is-size-3">What you can buy</h2>
    <p>There are many items available in the shop, including -</p>
    <ul>
      <li><strong>Increase hourly rolls/claims</strong> - increase your hourly limits</li>
      <li><strong>Multiroll</strong> - allows you to roll multiple gacha in a single <code>/roll</code> command</li>
      <li><strong>Individual rolls/claims</strong> - purchase one-time-use rolls and claims</li>
      <li><strong>Rate Up rolls</strong> - double your 5⭐ roll chance</li>
      <li><strong>Wishlist token</strong> - allows you to add a media to your <em>wishlist</em>, which increases the
        chances of getting a 5⭐ from that media</li>
    </ul>
  </div>
</ng-template>

<ng-template typeTemplate="trading">
  <div class="content">
    <h1 class="title is-size-1">Trading</h1>
    <div class="columns">
      <div class="column is-6">
        <p>
          You can trade your gacha and backpack items - including wishlist tokens, alt art tokens, card effects, and
          more.
          Basically, all the stuff in your <code>/backpack</code>
        </p>
        <h2 class="subtitle is-size-3">How to trade</h2>
        <ol>
          <li>Use the <code>/trade &#64;user</code> command to start a trade with another player. You can add your
            cards,
            stardust, and items to the trade.
            <div class="notification is-primary is-light">
              Tip! If you lose the original message, just do <code>/trade &#64;user</code> again! It loads the same
              trade up
            </div>
          </li>
          <li>
            Add your gacha, items, and stardust to this trade screen.
          </li>
          <li>
            <strong>Accept</strong> the trade. Once both users have accepted, each one then needs to
            <strong>confirm</strong> to have the trade go through.
          </li>
        </ol>
      </div>
      <div class="column is-6 has-text-centered">
        <img src="/assets/trade_base.png" alt="Trade">
        <p><em>Starting a trade</em></p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template typeTemplate="marketplace">
  <div class="content">
    <h1 class="title is-size-1">Card Bazaar</h1>
    <div class="columns">
      <div class="column is-8">
        You can access the Upa Card Bazaar <a [routerLink]="['/view/marketplace']">here</a>. At the bazaar,
        you can buy and sell your cards to other players across all players of Upa.
      </div>
    </div>
    <div class="columns">
      <div class="column is-9">
        <h2 class="subtitle is-size-3">Buying</h2>
        <p>Buying cards is simple - just click the <button class="button is-small is-primary">Buy</button>
          button
          on the card you want to purchase. Make sure you're sure, because once you buy it, it's yours!</p>
        <p>You can also react to cards. Pretty cool, maybe</p>
      </div>
      <div class="column is-3 has-text-centered">
        <img src="/assets/marketplace_buy.png" alt="Marketplace purchase">
        <p><em>Buying a card</em></p>
      </div>
    </div>
    <div class="columns">
      <div class="column is-9">
        <h2 class="subtitle is-size-3">Selling</h2>
        <p>Selling your cards is also simple.</p>
        <ul>
          <li>Open your profile at <a [routerLink]="['/me']">this link</a></li>
          <li>Find a card you want to sell</li>
          <li>Open the edit card options with the <fa-icon [icon]="faEdit"></fa-icon> button in the bottom left of your
            card</li>
          <li>
            Click the
            <button class="button is-small is-warning is-light">
              <span class="icon">
                <i class="fab"><fa-icon [icon]="faMoneyBill"></fa-icon></i>
              </span>
              <span>Sell on Market</span>
            </button>
            button
          </li>
          <li>Input the details, and you're good to go!</li>
        </ul>
      </div>
      <div class="column is-3 has-text-centered">
        <img src="/assets/marketplace_sell.png" alt="Selling a card on the marketplace">
        <p><em>Selling a card</em></p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template typeTemplate="cardvalue">
  <div class="content">
    Under construction!
  </div>
</ng-template>

<ng-template typeTemplate="parties">
  <div class="content">
    <h1 class="title is-size-1">Parties</h1>
    <div class="columns">
      <div class="column is-6">
        <p>
          Upa lets you create a party of up to 4 of your cards to embark on expeditions and more future PvE content.
        </p>
        <h2 class="subtitle is-size-3">How to create</h2>
        <ol>
          <li>From the bot, run <code>/party edit</code>. You can input a party name and up to 4 cards to set in each
            slot.</li>
          <li>
            Once you set each slot, that's all you need to do~ You can then use <code>/party show</code> to check your
            party levels and stats.
          </li>
        </ol>
        <div class="notification is-info is-light">
          Tip! You can't edit your party while it's on an expedition!
        </div>
        <div class="notification is-warning is-light">
          Cards will keep their stats even if they're traded - so if you trade away a level 50, that card will retain
          its stats. Be careful what you trade around!
        </div>
        <h2 class="subtitle is-size-3">Stats</h2>
        <p>
          Each card in your party has several stats that contribute to your party's overall power.
        </p>
        <ul>
          <li><strong>Level</strong> - the overall level of your gacha</li>
          <li><strong>Exp</strong> - the experience your gacha has. Determines your level.</li>
          <li><strong>Health</strong> - determines survivability.</li>
          <li><strong>Mana</strong> - determines ability power</li>
          <li><strong>Attack</strong> - determines basic attack power</li>
          <li><strong>Defense</strong> - determines durability</li>
          <li><strong>Speed</strong> - determines priority</li>
          <li><strong>Luck</strong> - determines many things, such as rewards and stealth</li>
        </ul>
        <p>
          Leveling up your card will increase their stats. Higher star ratings see higher stat gains.
        </p>
        <h2 class="subtitle is-size-3">Nature</h2>
        <p>
          Each card will have a randomly assigned <em>nature</em>. Natures determine stat growth distributions as you
          level up.
        </p>
        <ul>
          <li><strong>Adaptable</strong> - balanced growth</li>
          <li><strong>Leader</strong> - increase to <strong>health</strong>, decrease to <strong>attack</strong></li>
          <li><strong>Fighter</strong> - increase to <strong>attack</strong>, decrease to <strong>luck</strong></li>
          <li><strong>Cunning</strong> - increase to <strong>luck</strong>, decrease to <strong>defense</strong></li>
          <li><strong>Subtle</strong> - increase to <strong>speed</strong>, decrease to <strong>health</strong></li>
          <li><strong>Lazy</strong> - increase to <strong>defense</strong>, decrease to <strong>speed</strong></li>
        </ul>
      </div>
      <div class="column is-6 has-text-centered">
        <img src="/assets/party_edit.png" alt="Party">
        <p><em>Editing your party</em></p>
        <img src="/assets/party_stats.png" alt="Party">
        <p><em>Viewing your party stats</em></p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template typeTemplate="expeditions">
  <div class="content">
    <h1 class="title is-size-1">Expeditions</h1>
    <p>
      Expeditions are a PvE feature in Upa that lets you send your party on missions to earn rewards in the background.
    </p>
    <div class="columns">
      <div class="column is-6">
        <h2 class="subtitle is-size-3">How to start</h2>
        <ol>
          <li>
            Use the <code>/expedition start</code> command to start an expedition.
          </li>
          <li>
            Choose your party to send on the expedition. If they're already on one, you can't send them until they're
            done.
          </li>
          <li>
            Choose an expedition.
          </li>
          <li>
            Each expedition takes a different amount of time to finish. You can check its status with
            <code>/expedition status</code>
          </li>
          <li>
            After it's finished, claim your results with <code>/expedition status</code>
          </li>
        </ol>
        <h2 class="subtitle is-size-3">Rewards</h2>
        <p>
          Rewards include stardust, alt art tokens, and more. The higher level expeditions give <em>more</em> and
          <em>better</em> rewards.
        </p>
        <h2 class="subtitle is-size-3">Party requirements</h2>
        <p>
          Each expedition has a recommended party level. This takes into account stats of cards around that level. Each
          one will give you a % completion chance before starting, so choose wisely!
        </p>
        <h2 class="subtitle is-size-3">Failing an expedition</h2>
        <p>
          Failing an expedition isn't the end of the world! Your party will still gain experience for as far in as
          they got to the expedition~
        </p>
      </div>
      <div class="column is-6 has-text-centered">
        <img src="/assets/expedition_start.png" alt="starting an expedition">
        <p><em>Starting an expedition</em></p>
        <img src="/assets/expedition_choose.png" alt="choosing an expedition">
        <p><em>Choosing which expedition to try</em></p>
        <img src="/assets/expedition_status.png" alt="viewing your expedition status">
        <p><em>Viewing your expedition status</em></p>
      </div>
    </div>

  </div>
</ng-template>

<ng-template typeTemplate="dungeons">
  <div class="content">
    Dungeons are coming soon™
  </div>
</ng-template>

<ng-template typeTemplate="guilds">
  <div class="content">
    <h1 class="title is-size-1">Guilds</h1>
    <p>
      Guilds are a social feature in Upa that lets you join a community of players to work together, earn rewards, and
      show off your shared interests
    </p>
    <h2 class="subtitle is-size-3">Benefits</h2>
    <p>There are lots of reasons to join a guild, including...</p>
    <ul>
      <li>Customization for yourself</li>
      <li>Your own guild profile to show off your favorites</li>
      <li>A guild-exclusive shop to increase limits, such as more rolls per hour</li>
    </ul>
    <h2 class="subtitle is-size-3">Guild profile</h2>
    <p>Every guild has their own profile you can access through <code>/guild view</code>. You can see a list of all our
      Upa guilds
      <a [routerLink]="['/view','guilds','all']">here, too</a>!
    </p>
    <h2 class="subtitle is-size-3">Join Policy</h2>
    <p>Guilds can set their join policy to one of three choices -</p>
    <ul>
      <li><strong>Open</strong> - anyone can join using <code>/guild join</code></li>
      <li><strong>Application</strong> - anyone can apply, and a guild owner or officer can review and accept (or deny)
        applicants</li>
      <li><strong>Invite only</strong> - only the guild owner or officers can invite new members</li>
    </ul>
    <h2 class="subtitle is-size-3">Levels & Exp Contribution</h2>
    <p>
      Everyone who's a member of a guild also contributes experience when doing normal Upa actions - rolling, claiming,
      expeditions, and more.
      This experience will contribute to a guild level, which gives many benefits -
    </p>
    <ul>
      <li>Increase the member cap</li>
      <li>Unlock new items in the guild shop</li>
      <li>Unlock new customizations for the guild</li>
    </ul>
    <p>You can view each person's contribution on the guild profile with <code>/guild view</code> or at the guild web
      profile.</p>
    <h2 class="subtitle is-size-3">Guild Chest</h2>
    <p>Every guild has a chest that contains stardust that's donated by members. Members can donate up to 6k stardust
      per day - this stardust is then used in the
      <a (click)="selectTab('guildshop')">guild shop</a> to purchase upgrades for the guild.
    </p>
  </div>
</ng-template>

<ng-template typeTemplate="createguild">
  <div class="content">
    <h1 class="title is-size-1">Creating a Guild</h1>
    <p>Creating a guild is simple, but requires an investment of 50,000 stardust. You should find other members that
      want to help contribute!</p>

    Use the <code>/guild create</code> command to start the creation process. Here, you specify a:
    <ul>
      <li>
        <strong>Name</strong> - the name of your guild
      </li>
      <li>
        <strong>Description</strong> - a description for your guild
      </li>
      <li>
        <strong>Member Join Policy</strong> - a choice of open, application, or invite only. See <a
          (click)="selectTab('guilds')">Guilds</a> for more information on what each policy means.
      </li>
    </ul>
    <p>Once you finish and pay the fee, you're now the owner of a brand new guild!</p>
  </div>
</ng-template>

<ng-template typeTemplate="findguild">
  <div class="content">
    <h1 class="title is-size-1">Finding a Guild</h1>
    <p>There are many ways to find a guild to join -</p>
    <ul>
      <li>Check the <a [routerLink]="['/view','guilds','all']">guild list</a> to see all the guilds available</li>
      <li>Ask around in the <a [routerLink]="['/server']">Upa Community server</a> to see if anyone's recruiting. We
        have a dedicated
        forum for people to find guilds as well as guilds to advertise for new users</li>
    </ul>
    <p>Once you find a guild to join, figure out its join policy. If it's invite only, be sure to message the owner or
      officers.</p>
    <p>If it's an application, you can use <code>/guild join join &#64;user</code> on anyone in the guild to send an
      application</p>
    <p>If it's an open guild, you can use <code>/guild join join &#64;user</code> on anyone in the guild to just join on
      the
      spot!</p>
    <h1 class="title is-size-1">Inviting to a Guild</h1>
    <p>Guilds can also invite people to join! To do so, use <code>/guild invite invite-user &#64;user</code>. This will
      send
      them an invitation. You can revoke invitations with <code>/guild invite revoke</code> at any time.</p>
    <p>Once a user receives an invite, they can check it with <code>/guild join accept-invite</code> and accept the
      invitation</p>
  </div>
</ng-template>

<ng-template typeTemplate="guildshop">
  <div class="content">
    <h1 class="title is-size-1">Guild Shop</h1>
    <div class="columns">
      <div class="column is-6">
        <p>
          Guilds have an exclusive shop where you can buy upgrades for your guild members. These upgrades are only
          purchaseable by the guild owner or officers.
        </p>
        <p>Boosts last 24 hours and are applied to all guild members during this time. The cost of the upgrades scales
          with the number of members - although, not completely linearly, so you're still better off with more members
          :)</p>
      </div>
      <div class="column is-6 has-text-centered">
        <img src="/assets/guild_shop.png" alt="Guild Shop">
        <em>Guild shop purchase interface</em>
      </div>
    </div>
  </div>
</ng-template>

<ng-template typeTemplate="sacrificing">
  <div class="content">
    <h1 class="title is-size-1">Sacrificing</h1>
    <p>
      Sacrificing allows you to consume 3 cards to gain a new one <em>that you do not already own</em>.
      This is a great way to get rid of cards you don't care for outside of trading them away.
    </p>
    <h2 class="subtitle is-size-3">What you get</h2>
    <p>
      Sacrificing will give you a new card of the same star rarity as the cards you sacrificed. If you sacrifice 3 5⭐,
      you'll get a new 5⭐. This new card will be determined by:
    </p>
    <ul>
      <li>The average mint of your input cards</li>
      <li>and will not be a card you already own</li>
    </ul>
    <hr>
    <div class="columns">
      <div class="column is-5">
        <h2 class="subtitle is-size-3">How to sacrifice</h2>
        <ol>
          <li>Use <code>/sacrifice gacha</code> to start the process</li>
          <li>Choose 3 cards <em>of the same rarity</em> to sacrifice</li>
          <div class="notification is-primary is-light">
            Tip! Searching for mint ranges can be done with <em>intervals</em>
            <p>
              <code>2-</code> means mint 2 and above
            </p>
            <p>
              <code>-6</code> means up to mint 6
            </p>
            <p>
              <code>2-6</code> means mint 2 to 6
            </p>
          </div>
          <li>Double check the mint range of your new card!</li>
          <li>Confirm the sacrifice</li>
        </ol>
      </div>
      <div class="column is-7 has-text-centered">
        <img src="/assets/sacrifice_base.png" alt="Sacrifice">
        <p><em>Starting a sacrifice</em></p>
        <img src="/assets/sacrifice_search.png" alt="Sacrifice search">
        <p><em>Choosing cards to sacrifice</em></p>
        <img src="/assets/sacrifice_finish.png" alt="Sacrifice finish">
        <p><em>Checking and finishing the sacrifice</em></p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template typeTemplate="support">
  <div class="content">
    <h1 class="title is-size-1">Support</h1>
    <p>
      If you need help with anything, you can always reach out to the Upa team in the <a [routerLink]="['/server']">Upa
        Community server</a>
    </p>
    <p>
      You can also send direct feedback with the <code>/feedback</code> command. This will send a message to the
      bot developers!
    </p>
  </div>
</ng-template>

<ng-template typeTemplate="suggest">
  <div class="content">
    <h1 class="title is-size-1">Suggesting New Gacha</h1>
    <p>
      We have dedicated channels in the <a [routerLink]="['/server']">Upa Community server</a> for suggesting new gacha
      and alt art. Most of our new releases are based on user suggestions, so we're always looking for new ideas!
    </p>
  </div>
</ng-template>

<ng-template typeTemplate="premium">
  <div class="content">
    <h1 class="title is-size-1">Premium</h1>
    <p>
      Upa wouldn't be here without our players, and we want to give back to those who support us. Premium is a
      Patreon subscription that gives you access to several benefits that hopefully make your life a little easier :)
    </p>
    <ul>
      <li>Create your own custom card that only you own!</li>
      <li>Setting to auto-dust duplicate gacha</li>
      <li>Setting to auto-starpower duplicate gacha</li>
      <li>Setting to auto-multiroll all your hourly rolls in one command</li>
      <li>Receive roll reminders customized to the exact hours of the day you want a reminder</li>
    </ul>
    <p>
      You can view more details with <code>/premium</code>, or if you're convinced, you can subscribe through
      <a [routerLink]="['/premium']">our Patreon!</a>
    </p>
  </div>
</ng-template>