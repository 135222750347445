<div class="container">
  <div class="columns">
    <img *ngIf="media && media.bannerImageUrl" class="bg-image" src="{{media.bannerImageUrl}}">
    <div *ngIf="media" class="column">
      <h1 class="is-size-1" *ngIf="mediaName">
        <ng-container *ngIf="auth.user$ | async as user">
          <a (click)="setFavoriteMedia(media.id!)" *ngIf="favoriteMedia === undefined || favoriteMedia.id !== media.id">
            <fa-icon class="star" [icon]="faHollowStar"></fa-icon>
          </a>
          <a (click)="removeFavoriteMedia()" *ngIf="favoriteMedia !== undefined && favoriteMedia.id === media.id">
            <fa-icon class="star filledStar" [icon]="faSolidStar"></fa-icon>
          </a>
        </ng-container> {{ mediaName }}
      </h1>
    </div>
  </div>
  <div class="columns is-centered is-multiline" infiniteScroll [infiniteScrollDistance]="3" (scrolled)="onScrollDown()">
    <ng-container *ngFor="let g of gachas; trackBy: trackBy">
      <div class="column has-text-centered">
        <app-gacha-v2 [gacha]="g.gacha" [media]="media" [outOf]="g.numCopies"></app-gacha-v2>
      </div>
    </ng-container>
  </div>
</div>