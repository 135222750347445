import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BatchExtractCharacterUrlsFromUrlResponseCharacterUrlData } from 'src/app/openapi/models';
import { AdminServiceService } from 'src/app/openapi/services';

@Component({
  selector: 'app-multi-upload-form',
  templateUrl: './multi-upload-form.component.html',
  styleUrls: ['./multi-upload-form.component.scss']
})
export class MultiUploadFormComponent implements OnInit {
  currentIdx: number = 0;
  characterUrlData?: BatchExtractCharacterUrlsFromUrlResponseCharacterUrlData[];

  urlToExtract?: string;
  removeDupes?: boolean;
  constructor(private adminService: AdminServiceService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  getNext() {
    if (!this.characterUrlData) {
      this.toastr.error("attempting to get next url but they don't exist!");
      return;
    }

    if (this.currentIdx + 1 < this.characterUrlData.length) {
      this.currentIdx++;
      // this.extract(this.urlsAndFavorites[this.currentIdx].url);
    } else {
      this.toastr.error("no more entries, thank you for your service!");
    }
  }

  getPrev() {
    if (!this.characterUrlData) {
      this.toastr.error("attempting to get prev url but they don't exist!");
      return;
    }

    if (this.currentIdx > 0) {
      this.currentIdx--;
      // this.extract(this.urlsAndFavorites[this.currentIdx].url);
    } else {
      this.toastr.error("no more entries before this one!");
    }
  }

  resetToZero() {
    if (this.currentIdx == 0) {
      return;
    }
    this.currentIdx = 0;
    if (this.characterUrlData) {
      // this.extract(this.urlsAndFavorites[this.currentIdx].url);
    }
  }

  setIdx(i: number) {
    if (!this.characterUrlData) {
      this.toastr.error("attempting to get prev url but they don't exist!");
      return;
    }
    if (i < this.characterUrlData.length && i >= 0) {
      this.currentIdx = i;
    }
  }

  onRemoveDupesChange(e: boolean) {
    this.removeDupes = e;
    this.extract(this.urlToExtract!);
    this.toastr.info("Working, this might take a bit don't press that button again!");
  }

  extract(url: string) {
    this.currentIdx = 0;
    this.adminService.adminServiceBatchExtractCharacterUrlsFromUrl({ body: { url: url, removeDuplicates: this.removeDupes } }).subscribe(
      u => {
        this.characterUrlData = u.characterData || [];
        this.toastr.success("Extracted " + u.characterData?.length + " URLs");
      },
      err => this.toastr.error(JSON.stringify(err))
    )
  }
}
