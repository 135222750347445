import { Component, OnInit } from '@angular/core';
import { AltArt, Gacha, Media } from 'src/app/openapi/models';
import { imageCropperData } from '../view-staging/view-staging.component';
import { AdminServiceService, GachaServiceService, MediaServiceService } from 'src/app/openapi/services';
import { ToastrService } from 'ngx-toastr';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { AdminServiceUpdateProdAltArt$Params } from 'src/app/openapi/fn/admin-service/admin-service-update-prod-alt-art';

interface altArtAndEditData {
  altArt: AltArt;
  originalName: string;
  originalDescription: string;
  uploadImageEdit: boolean;
  gacha: Gacha;
  isEditing: boolean;

  ngxCropperData: imageCropperData;
}

@Component({
  selector: 'app-view-admin-all-alt-art',
  templateUrl: './view-admin-all-alt-art.component.html',
  styleUrl: './view-admin-all-alt-art.component.scss'
})
export class ViewAdminAllAltArtComponent implements OnInit {
  medias?: Media[];
  selectedMediaID?: string;
  altArts?: altArtAndEditData[];

  constructor(
    private adminService: AdminServiceService,
    private gachaService: GachaServiceService,
    private mediaService: MediaServiceService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.gachaService.gachaServiceGetGachaWithAltArt({ showStaging: true }).subscribe(g => {
      // Grab the unique media IDs
      const mediaIDs = new Set<string>();
      (g.gacha || []).filter(g => g.mediaId).forEach(g => mediaIDs.add(g.mediaId!));
      this.mediaService.mediaServiceGetAllMedia().subscribe(m => {
        // Filter the media to only the ones we care about
        this.medias = m.media?.filter(m => mediaIDs.has(m.id!));
        if (this.medias && this.medias.length > 0) {
          this.selectedMediaID = this.medias[0].id!;
          this.onSelectMedia(this.medias[0].id!);
        }
      });
    });
  }

  onSelectMedia(e: string) {
    console.log(e);
    const spl = e.split(" ");
    var mediaID = spl.length > 1 ? spl[1] : spl[0];
    this.gachaService.gachaServiceGetGachaWithAltArt({ showStaging: true }).subscribe(g => {
      const altArts: altArtAndEditData[] = [];
      (g.gacha || []).filter(g => g.mediaId === mediaID).forEach(g => {
        g.altArts?.forEach(a => {
          altArts.push({
            altArt: a,
            originalName: a.name!,
            originalDescription: a.description!,
            isEditing: false,
            uploadImageEdit: false,
            gacha: g,
            ngxCropperData: {
              imgChangeEvt: '',
              croppedBase64String: '',
              imageBase64String: '',
              updateImage: false,
            }
          });
        });
      });
      this.altArts = altArts;
    });
  }

  /*********
   * Image editing for existing gacha
   * *******/

  onFileChange(event: any, gacha: altArtAndEditData): void {
    gacha.ngxCropperData.imgChangeEvt = event;
    console.log('changed');
    gacha.uploadImageEdit = true;
  }

  cropImg(e: ImageCroppedEvent, gacha: altArtAndEditData) {
    // this.cropImgPreview = e.base64;
    gacha.ngxCropperData.croppedBase64String = e.base64!;
  }

  imgLoad() {
    // display cropper tool
  }

  initCropper() {
    // init cropper
  }

  imgFailed() { }

  onPaste(e: ClipboardEvent, gacha: altArtAndEditData) {
    let file: File | undefined | null;
    for (let i = 0; i < e.clipboardData?.items.length!; i++) {
      const type = e.clipboardData?.items[i].type;
      if (type === "image/png" || type === "image/jpg") {
        file = e.clipboardData?.items[i].getAsFile();
        break;
      }
    }

    if (!file) {
      this.toastr.error("no image found in clipboard!");
      return;
    }

    var reader = new FileReader();
    reader.onload = (event: any) => {
      gacha.ngxCropperData.imageBase64String = (event.target.result); // event.target.results contains the base64 code to create the image.
    };
    reader.readAsDataURL(file); // Convert the blob from clipboard to base64
  }

  startEdit(a: altArtAndEditData) {
    a.isEditing = true;
    this.adminService.adminServiceGetAltArtImageData({ gachaId: a.gacha.id!, altArtId: a.altArt.artId! })
      .subscribe(blob => a.ngxCropperData.imageBase64String = `data:${blob.contentType};base64,${blob.data}`);
  }

  cancelEdit(a: altArtAndEditData) {
    a.isEditing = false;
  }

  saveEdit(a: altArtAndEditData) {
    if (!a.isEditing) {
      return;
    }
    a.isEditing = false;

    const r: AdminServiceUpdateProdAltArt$Params = {
      gachaId: a.gacha.id!,
      altArtId: a.altArt.artId!,
      body: {
        imageBlob: a.uploadImageEdit ? a.ngxCropperData.croppedBase64String.split(",")[1] : undefined,
      }
    }
    if (a.altArt.name !== a.originalName) {
      r.body.name = a.altArt.name;
    }
    if (a.altArt.description !== a.originalDescription) {
      r.body.description = a.altArt.description;
    }
    if (!r.body.imageBlob && !r.body.name && !r.body.description) {
      this.toastr.warning("No changes detected!");
      return;
    }

    this.adminService.adminServiceUpdateProdAltArt(r).subscribe(res => {
      switch (res.status) {
        case "SUCCESS":
          this.toastr.success("Successfully updated alt art!");
          break;
        case "GACHA_NOT_FOUND":
          this.toastr.error("alt art not found!");
          break;
        case "UNDEFINED":
          this.toastr.error("Undefined error!");
          break;
      }
    }, err => {
      this.toastr.error("Error updating alt art image! " + err.message);
    });
  }

}
