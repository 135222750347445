import { inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ResolveFn } from '@angular/router';

export const resolveLeaderboard: ResolveFn<boolean> = (route, state) => {
  const m = inject(Meta);
  m.removeTag('name="title"');
  m.removeTag('name="description"');
  m.removeTag('name="og:title"');
  m.removeTag('name="og:description"');
  m.addTag({ name: 'title', content: `Upa - Leaderboard` })
  m.addTag({ name: 'og:title', content: `Upa - Leaderboard` });
  m.addTag({ name: 'description', content: `Check out the Upa leaderboard - see where you land and rank among the top!` });
  m.addTag({ name: 'og:description', content: `Check out the Upa leaderboard - see where you land and rank among the top!` });
  return true;
};
