import { Component, ViewChild, ElementRef } from '@angular/core';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { CreateMediaRequest, Media, Origin } from 'src/app/openapi/models';
import { ORIGIN } from 'src/app/openapi/models/origin-array';
import { AdminServiceService } from 'src/app/openapi/services';

enum ImageType {
  COVER,
  BANNER,
}

interface Image {
  imageBase64String?: string;
  imageBlob?: string;
  imgChangeEvt: any;
  cropImgPreview: any;
}

@Component({
  selector: 'app-upload-media',
  templateUrl: './upload-media.component.html',
  styleUrls: ['./upload-media.component.scss']
})
export class UploadMediaComponent {
  imageTypeCover = ImageType.COVER;
  imageTypeBanner = ImageType.BANNER;
  media: Media = {
    id: "",
    name: "",
    coverImageUrl: 'https://cdn.upa.moe/256x362.png',
  }

  mediaOriginCategories: Origin[];

  @ViewChild(ImageCropperComponent)
  imageCropper!: ImageCropperComponent;

  @ViewChild("imageRenderer")
  imageRenderer!: ElementRef;

  @ViewChild("extractInput")
  extractInput!: ElementRef;

  coverImage: Image = {
    imgChangeEvt: "",
    cropImgPreview: "",
  }

  bannerImage: Image = {
    imgChangeEvt: "",
    cropImgPreview: "",
  }

  constructor(private adminService: AdminServiceService, private toastr: ToastrService) {
    this.mediaOriginCategories = [];
    for (const o of ORIGIN) {
      if (o === "UNDEFINED_ORIGIN") {
        continue;
      }
      this.mediaOriginCategories.push(o as Origin);
    }
  }


  onFileChange(event: any, imageType: ImageType): void {
    switch (imageType) {
      case ImageType.COVER:
        this.coverImage.imgChangeEvt = event;
        break;
      case ImageType.BANNER:
        this.bannerImage.imgChangeEvt = event;
        break;
    }
  }

  cropImg(e: ImageCroppedEvent, imageType: ImageType) {
    console.log(e.width);
    switch (imageType) {
      case ImageType.COVER:
        this.coverImage.cropImgPreview = e.base64;
        this.coverImage.imageBlob = e.base64!.split(",")[1];
        break;
      case ImageType.BANNER:
        this.bannerImage.cropImgPreview = e.base64;
        this.bannerImage.imageBlob = e.base64!.split(",")[1];
        break;
    }
  }

  imgLoad() {
    // display cropper tool
  }

  initCropper() {
    // init cropper
  }

  imgFailed() { }

  onPaste(e: ClipboardEvent, type: ImageType) {
    let file: File | undefined | null;
    for (let i = 0; i < e.clipboardData?.items.length!; i++) {
      const type = e.clipboardData?.items[i].type;
      if (type === "image/png" || type === "image/jpg") {
        file = e.clipboardData?.items[i].getAsFile();
        break;
      }
    }

    if (!file) {
      this.toastr.error("no image found in clipboard!");
      return;
    }

    var reader = new FileReader();
    switch (type) {
      case ImageType.COVER:
        reader.onload = (event: any) => {
          this.coverImage.imageBase64String = (event.target.result);
        };
        break;
      case ImageType.BANNER:
        reader.onload = (event: any) => {
          this.bannerImage.imageBase64String = (event.target.result);
          var image = new Image();
          image.onload = () => {
            if (image.width < 1080) {
              this.toastr.warning(`Banner width of ${image.width} is less than 1080 pixels!`, "WARNING",
                {
                  timeOut: 10000,
                })
            }
          }
          image.src = event.target.result;
        };
        break;
    }
    reader.readAsDataURL(file);
  }

  submitMedia() {
    const req: CreateMediaRequest = {
      name: this.media.name,
      origin: this.media.origin,
    }
    if (this.coverImage.imageBlob) {
      req.coverImage = {
        imageBlob: this.coverImage.imageBlob,
      }
    }
    if (this.bannerImage.imageBlob) {
      req.bannerImage = {
        imageBlob: this.bannerImage.imageBlob,
      }
    }
    console.log(req);

    this.adminService.adminServiceCreateMedia({ body: req }).subscribe(m => this.toastr.success(`created media with ID: ${m.id}`),
      err => this.toastr.error(JSON.stringify(err)));
  }

  stringifyOrigin(o: Origin): string {
    return o.toString();
  }
}
