/* tslint:disable */
/* eslint-disable */
import { GachaOrientation } from './gacha-orientation';

/**
 * Each possible value of `GachaOrientation`
 */
export const GACHA_ORIENTATION: GachaOrientation[] = [
  'UNDEFINED_ORIENTATION',
  'NORMAL',
  'WIDE',
  'SQUARE'
];