<ng-container *ngIf="gacha && media">
  <div class="view-card" [ngStyle]="buildCardDropShadow()">
    <div class="view-card-inset">
      <div class="view-card-inset-lock">
        {{buildLock()}}
      </div>
      <div class="view-card-inset-stars">
        {{buildStars()}}
      </div>
      <img class="view-card-image" src="{{gacha.imageUrl}}" loading="lazy" [ngStyle]="
      {
        'width': calculateWidth()
      }" />

      <!--  [ngStyle]="{
        'background-image': 'linear-gradient(to right, '+buildRarityColor()+'CC, '+buildRarityColor()+'80)',
        'color': buildRarityTextColor()
      }" -->
      <span class="view-card-gacha-stats is-size-6" *ngIf="copyNumber || (outOf !== undefined)">
        Minted: {{copyNumber ? copyNumber + " / " : ""}}{{outOf !== undefined ? outOf
        : "unknown"}}
      </span>

      <!--
        [ngStyle]="{
          'background-image': 'linear-gradient(to left, '+buildRarityColor()+'CC, '+buildRarityColor()+'80)',
          'color': buildRarityTextColor()
        }"
      -->
      <ng-container *ngIf="datePulled">
        <span class="view-card-gacha-date is-size-6">
          {{datePulled ? transformDate(datePulled) : "unknown"}}
        </span>
      </ng-container>
    </div>

    <div class="view-card-details">
      <a [routerLink]="['/view/gacha/'+gacha.id]" routerLinkActive="router-link-active">
        <p class="view-card-gacha-name is-size-4">{{gacha.name}}</p>
      </a>
      <a [routerLink]="['/view/media/'+media.id]" routerLinkActive="router-link-active"> <span
          class="view-card-gacha-name is-size-6">{{media.name}}</span>
      </a>

      <!-- slam some marketplace functionality here-->
      <ng-container *ngIf="marketplacePrice && marketplaceSeller">
        <p class="view-card-gacha-name is-size-5">
          {{ marketplacePrice | number:'1.0':'en-US' }} stardust
        </p>
        <p class="view-card-gacha-name is-size-5">
          <strong>Seller: </strong>
          <a class="view-card-gacha-name is-size-5" style="color: #CC8899"
            [routerLink]="['/view/user', marketplaceSeller.userUuid]">
            {{ marketplaceSeller.displayName }}</a>
        </p>
        <div *ngIf="marketplaceCardEnhancements" class="view-card-gacha-name is-size-5">
          <hr />
          <strong>Effects: </strong>
          {{ buildEnhancementNames(marketplaceCardEnhancements) }}
        </div>
        <div class="buttons is-centered">
          <div class="field is-grouped is-centered">
            <p *ngIf="marketplaceSeller.userUuid !== userUUID" class="control">
              <button (click)="showMarketplaceBuyConfirmationModal()" class="button is-primary">
                <span class="icon">
                  <i class="fab"><fa-icon [icon]="faCreditCard"></fa-icon></i>
                </span>
                <span>Buy</span>
              </button>
            </p>
            <p *ngIf="marketplaceSeller.userUuid === userUUID" class="control">
              <button (click)="cancelListing()" class="button is-danger">
                <span class="icon">
                  <i class="fab"><fa-icon [icon]="faXMark"></fa-icon></i>
                </span>
                <span>Cancel Listing</span>
              </button>
            </p>
            <ng-container *ngIf="marketplaceAdminMode">
              <ng-container *ngIf="auth.user$ | async as user">
                <p *ngIf="canViewDeleteListing(user)" class="control">
                  <button (click)="deleteListing()" class="button is-danger">
                    <span class="icon">
                      <i class="fab"><fa-icon [icon]="faXMark"></fa-icon></i>
                    </span>
                    <span>DELETE LISTING</span>
                  </button>
                </p>
              </ng-container>
            </ng-container>
          </div>
        </div>

        <!-- reactions show up here for hte listing -->
        <div *ngIf="marketplaceReactions">
          <ng-container *ngFor="let reaction of marketplaceReactions">
            <app-reaction (click)="reactToListing(reaction.type!)" [reaction]="reaction.type!"
              [count]="reaction.count!"></app-reaction>
          </ng-container>
        </div>
        <p class="subtitle is-size-4" *ngIf="marketplaceCardListedAt">
          <span class="view-card-gacha-listed-at is-size-6">
            Listed: {{(marketplaceCardListedAt | date: 'short')}}
          </span>
        </p>
      </ng-container>

      <p *ngIf="gacha.duoDetails" class="view-card-gacha-name is-size-5">
        {{renderDuoCardName()}}
      </p>
      <a *ngIf="gachaInstanceId">
        <fa-icon (click)="copyImageGen()" class="view-card-gacha-share-button" [icon]="faShareFromSquare"></fa-icon>
      </a>
      <ng-container *ngIf="auth.user$ | async as user">
        <a *ngIf="showEdit">
          <fa-icon (click)="isCurrentlyEditing = !isCurrentlyEditing" class="view-card-gacha-edit-button"
            [icon]="isCurrentlyEditing ? faCheck : faEdit"></fa-icon>
        </a>
      </ng-container>
    </div>
    <div *ngIf="isCurrentlyEditing" class="view-card-details has-text-centered">
      <div class="buttons has-text-centered are-small is-centered">
        <button *ngIf="tradeableStatus != 'TRADEABLE'" (click)="toggleTradeable('TRADEABLE')"
          class="button is-light is-success">
          <span class="icon">
            <i class="fab"><fa-icon [icon]="faLockOpen"></fa-icon></i>
          </span>
          <span>Mark tradeable</span>
        </button>
        <button *ngIf="tradeableStatus != 'KINDA_TRADEABLE'" (click)="toggleTradeable('KINDA_TRADEABLE')"
          class="button is-light is-danger">
          <span class="icon">
            <i class="fab"><fa-icon [icon]="faKey"></fa-icon></i>
          </span>
          <span>Mark kinda tradeable</span>
        </button>
        <button *ngIf="tradeableStatus != 'UNTRADEABLE'" (click)="toggleTradeable('UNTRADEABLE')"
          class="button is-light is-danger">
          <span class="icon">
            <i class="fab"><fa-icon [icon]="faLock"></fa-icon></i>
          </span>
          <span>Mark not tradeable</span>
        </button>
        <button class="button is-warning is-light" (click)="showMarketplaceListingModal()">
          <span class="icon">
            <i class="fab"><fa-icon [icon]="faMoneyBill"></fa-icon></i>
          </span>
          <span>Sell on Market</span>
        </button>
      </div>
      <!-- It's enforced that at least 1 enhancement exists, the NO_EFFECT -->
      <ng-container *ngIf="allowedGachaEnhancements && allowedGachaEnhancements.length > 1">
        <hr>
        <button (click)="showEditEnhancementModal()"
          *ngIf="allowedGachaEnhancements && allowedGachaEnhancements.length > 1" class="button is-primary">
          Effects
        </button>
      </ng-container>
    </div>
    <div *ngIf="showFavoritesEdit" class="view-card-remove-favorite-x">
      <fa-icon (click)="removeFavorite(favoritePriority!)" [icon]="removeFavoriteIcon"></fa-icon>
    </div>
    <div *ngIf="showFavoriteSet && !showFavoritesEdit" class="view-card-set-favorite">
      <fa-icon (click)="setFavorite()" [icon]="setFavoriteIcon"></fa-icon>
    </div>
  </div>
</ng-container>

<div *ngIf="allowedGachaEnhancements && allowedGachaEnhancements.length > 1 && gacha && media" class="modal"
  #editEnhancementModal>
  <div class="modal-background"></div>
  <div class="modal-content">
    <div class="box">
      <h1 class="is-size-4 is-subtitle">Change your card effect</h1>
      <ng-container *ngFor="let enhancement of allowedGachaEnhancements">
        <div class="view-card" [ngStyle]="
          {
            'box-shadow': '10px 10px 24px -4px ' + buildRarityColor(),
            'background-color': buildRarityColor()+'50',
            'border-color': buildRarityColor()+'AA'
          }">
          <div class="view-card-inset">
            <div class="view-card-inset-stars">
              {{buildStars()}}
            </div>
            <img class="view-card-image" src="{{cachedImageUrls[enhancement.gachaEnhancementType!]}}" loading="lazy" />
          </div>

          <div class="view-card-details">
            <p class="view-card-gacha-name is-size-4">{{buildEnhancementName(enhancement.gachaEnhancementType!)}}</p>
            <p><span class="view-card-gacha-name is-size-6">
                <button
                  (click)="enhancement.gachaEnhancementType! === 'NO_GACHA_ENHANCEMENT' ? unsetCardEffect() : setCardEffect(enhancement.gachaEnhancementType!)"
                  class="button is-light">Set
                  Effect</button>
              </span>
            </p>
          </div>
        </div>
      </ng-container>
      <button class="button is-danger" (click)="closeEditEnhancementModal()">Cancel</button>
      <button (click)="closeEditEnhancementModal()" class="modal-close is-large" aria-label="close"></button>
    </div>
  </div>
</div>

<div class="modal" *ngIf="gacha && media" #marketplaceListingModal>
  <div class="modal-background"></div>
  <div class="modal-content">
    <div class="box">
      <h1 class="is-size-4 is-subtitle">Sell card on market</h1>

      <div class="view-card" [ngStyle]="
          {
            'box-shadow': '10px 10px 24px -4px ' + buildRarityColor(),
            'background-color': buildRarityColor()+'50',
            'border-color': buildRarityColor()+'AA'
          }">
        <div class="view-card-inset">
          <div class="view-card-inset-stars">
            {{buildStars()}}
          </div>
          <img class="view-card-image" src="{{ gacha.imageUrl }}" loading="lazy" />
          <!--  [ngStyle]="{
            'background-image': 'linear-gradient(to right, '+buildRarityColor()+'CC, '+buildRarityColor()+'80)',
            'color': buildRarityTextColor()
          }" -->
          <span class="view-card-gacha-stats is-size-6" *ngIf="copyNumber || (outOf !== undefined)">
            Minted: {{copyNumber ? copyNumber + " / " : ""}}{{outOf !== undefined ? outOf
            : "unknown"}}
          </span>

          <!--
            [ngStyle]="{
              'background-image': 'linear-gradient(to left, '+buildRarityColor()+'CC, '+buildRarityColor()+'80)',
              'color': buildRarityTextColor()
            }"
          -->
          <ng-container *ngIf="datePulled">
            <span class="view-card-gacha-date is-size-6">
              {{datePulled ? transformDate(datePulled) : "unknown"}}
            </span>
          </ng-container>
        </div>

        <div class="view-card-details">
          <p class="view-card-gacha-name is-size-4">{{ gacha.name }}</p>
        </div>
      </div>

      <div class="field">
        <label class="label">Stardust</label>
        <div class="control">
          <input class="input" type="number" placeholder="Stardust" style="width: 100px" [(ngModel)]="marketplacePrice">
        </div>
      </div>

      <div class="buttons has-text-centered is-centered">
        <button class="button is-danger" (click)="closeMarketplaceListingModal()">Cancel</button>
        <button class="button is-primary" (click)="listOnMarket()">Sell</button>
      </div>
      <button (click)="closeMarketplaceListingModal()" class="modal-close is-large" aria-label="close"></button>
    </div>
  </div>
</div>

<div class="modal" *ngIf="gacha && media && marketplacePrice && marketplaceSeller" #marketplaceBuyConfirmationModal>
  <div class="modal-background"></div>
  <div class="modal-content">
    <div class="box">
      <h1 class="is-size-4 is-subtitle">Buy this card?</h1>

      <div class="view-card" [ngStyle]="
          {
            'box-shadow': '10px 10px 24px -4px ' + buildRarityColor(),
            'background-color': buildRarityColor()+'50',
            'border-color': buildRarityColor()+'AA'
          }">
        <div class="view-card-inset">
          <div class="view-card-inset-stars">
            {{buildStars()}}
          </div>
          <img class="view-card-image" src="{{ gacha.imageUrl }}" loading="lazy" />
          <!--  [ngStyle]="{
            'background-image': 'linear-gradient(to right, '+buildRarityColor()+'CC, '+buildRarityColor()+'80)',
            'color': buildRarityTextColor()
          }" -->
          <span class="view-card-gacha-stats is-size-6" *ngIf="copyNumber || (outOf !== undefined)">
            Minted: {{copyNumber ? copyNumber + " / " : ""}}{{outOf !== undefined ? outOf
            : "unknown"}}
          </span>

          <!--
            [ngStyle]="{
              'background-image': 'linear-gradient(to left, '+buildRarityColor()+'CC, '+buildRarityColor()+'80)',
              'color': buildRarityTextColor()
            }"
          -->
          <ng-container *ngIf="datePulled">
            <span class="view-card-gacha-date is-size-6">
              {{datePulled ? transformDate(datePulled) : "unknown"}}
            </span>
          </ng-container>
        </div>

        <div class="view-card-details">
          <p class="view-card-gacha-name is-size-4">{{ gacha.name }}</p>
          <p class="view-card-gacha-name is-size-5">
            <strong>Seller: </strong>
            <a class="view-card-gacha-name is-size-5" style="color: #CC8899"
              [routerLink]="['/view/user', marketplaceSeller.userUuid]">
              {{ marketplaceSeller.displayName }}</a>
          </p>
          <p class="view-card-gacha-name is-size-5">
            Stardust: {{ marketplacePrice | number:'1.0':'en-US' }}
          </p>
        </div>
      </div>

      <div class="buttons has-text-centered is-centered">
        <button class="button is-danger" (click)="closeMarketplaceBuyConfirmationModal()">Cancel</button>
        <button class="button is-primary" (click)="purchaseGacha()">Buy</button>
      </div>
      <button (click)="closeMarketplaceBuyConfirmationModal()" class="modal-close is-large" aria-label="close"></button>
    </div>
  </div>
</div>