/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { GetShadowbannedUsersResponse } from '../../models/get-shadowbanned-users-response';

export interface AbuseServiceGetShadowbannedUsers$Params {
}

export function abuseServiceGetShadowbannedUsers(http: HttpClient, rootUrl: string, params?: AbuseServiceGetShadowbannedUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<GetShadowbannedUsersResponse>> {
  const rb = new RequestBuilder(rootUrl, abuseServiceGetShadowbannedUsers.PATH, 'get');
  if (params) {
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<GetShadowbannedUsersResponse>;
    })
  );
}

abuseServiceGetShadowbannedUsers.PATH = '/api/v3/gateway/admin/shadowban';
