/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { GetGachaResponse } from '../../models/get-gacha-response';

export interface GachaServiceGetGacha2$Params {
  gachaIds: Array<string>;
}

export function gachaServiceGetGacha2(http: HttpClient, rootUrl: string, params: GachaServiceGetGacha2$Params, context?: HttpContext): Observable<StrictHttpResponse<GetGachaResponse>> {
  const rb = new RequestBuilder(rootUrl, gachaServiceGetGacha2.PATH, 'get');
  if (params) {
    rb.path('gachaIds', params.gachaIds, {"style":"simple","explode":false});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<GetGachaResponse>;
    })
  );
}

gachaServiceGetGacha2.PATH = '/api/v3/gateway/gacha/{gachaIds}';
