/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AdminServiceUpdateStagedGachaRequest } from '../../models/admin-service-update-staged-gacha-request';
import { UpdateStagedGachaResponse } from '../../models/update-staged-gacha-response';

export interface AdminServiceUpdateStagedGacha$Params {

/**
 * Gacha ID.
 * Required.
 */
  'gacha.id': string;
      body: AdminServiceUpdateStagedGachaRequest
}

export function adminServiceUpdateStagedGacha(http: HttpClient, rootUrl: string, params: AdminServiceUpdateStagedGacha$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateStagedGachaResponse>> {
  const rb = new RequestBuilder(rootUrl, adminServiceUpdateStagedGacha.PATH, 'put');
  if (params) {
    rb.path('gacha.id', params['gacha.id'], {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<UpdateStagedGachaResponse>;
    })
  );
}

adminServiceUpdateStagedGacha.PATH = '/api/v3/gateway/admin/stage/gacha/{gacha.id}';
