/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { GachaServiceMarkTradeableStatusRequest } from '../../models/gacha-service-mark-tradeable-status-request';
import { MarkTradeableStatusResponse } from '../../models/mark-tradeable-status-response';

export interface GachaServiceMarkTradeableStatus$Params {
  userUuid: string;
  gachaId: string;
      body: GachaServiceMarkTradeableStatusRequest
}

export function gachaServiceMarkTradeableStatus(http: HttpClient, rootUrl: string, params: GachaServiceMarkTradeableStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<MarkTradeableStatusResponse>> {
  const rb = new RequestBuilder(rootUrl, gachaServiceMarkTradeableStatus.PATH, 'put');
  if (params) {
    rb.path('userUuid', params.userUuid, {});
    rb.path('gachaId', params.gachaId, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<MarkTradeableStatusResponse>;
    })
  );
}

gachaServiceMarkTradeableStatus.PATH = '/api/v3/gateway/user/{userUuid}/gacha/{gachaId}/tradeable';
