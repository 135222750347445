<div class="container">
  <ng-container *ngIf="guild">
    <div class="columns">
      <div class="column is-1 is-mobile">
        <figure class="image is-96x96" *ngIf="guild.milestoneRewardOptions && guild.milestoneRewardOptions.iconImgUrl">
          <img src="{{guild.milestoneRewardOptions.iconImgUrl}}">
        </figure>
      </div>
      <div class="column is-10">
        <div *ngIf="guild.memberPolicy" class="tags are-medium has-addons">
          <span [class]="generatePolicyCssClass(guild.memberPolicy)" class="tag is-light">
            {{ generatePolicyText(guild.memberPolicy) }}
          </span>
        </div>
        <h1 class="is-size-1">
          <span class="tag is-info is-light is-large"
            *ngIf="guild.milestoneRewardOptions && guild.milestoneRewardOptions.tag">
            {{ guild.milestoneRewardOptions.tag }}
          </span>&nbsp;{{guild.name}}
        </h1>
        <h1 class="is-size-4">Level {{guild.level}} ({{guild.exp | number:'1.0':'en-US'}} exp)</h1>
        <h1 class="is-size-4">{{guild.members?.length}}/{{guild.memberCap}} members</h1>
        <hr>
        <p>{{guild.description}}</p>
      </div>
    </div>
    <div class="columns is-multiline">
      <div class="column is-3" *ngFor="let m of guild.members">
        <div class="card">
          <div class="card-image">
            <figure class="image">

              <ng-container
                *ngIf="memberFavorites[m.user!.userUuid!] && memberFavorites[m.user!.userUuid!].imageAndStates as imgs">
                <img class="relimage" src="{{ imgs[memberFavorites[m.user!.userUuid!].shownImageIdx].imageUrl }}">
                <ng-container *ngFor="let f of imgs">
                  <img class="absimage" [@crossfade]="f.state" src="{{ f.imageUrl }}" alt="Favorite gacha image">
                </ng-container>
              </ng-container>

              <img
                *ngIf="!memberFavorites[m.user!.userUuid!] || memberFavorites[m.user!.userUuid!].imageAndStates.length == 0"
                class="placeholder-favorite is-skeleton" src="/assets/empty-card-bg.png" alt="No favorite gacha set">
            </figure>
          </div>
          <div class="card-content">
            <div class="media">
              <div class="media-content">
                <p class="title is-4">
                  <a [routerLink]="['/view/user', m.user!.userUuid!]">
                    {{ m.user!.displayName }}
                  </a>
                </p>
                <p class="subtitle is-6">
                  <span class="tag" [class]="generateRoleCssClass(m.role!)">
                    {{ generateRoleText(m.role!) }}
                  </span>&nbsp; Exp: {{ m.expContribution! | number:'1.0':'en-US' }}
              </div>
            </div>

            <!-- <hr class="navbar-divider" /> -->

            <div class="content">
              <!-- <div class="columns">
                <div class="column is-4">
                  <p class="subtitle is-7 lower-padding">Upa Score</p>
                  <p class="title is-5">{{ l.value![2]!.value! / 10 | number: '1.0-0' }}</p>
                </div>
                <div class="column is-4">
                  <p class="subtitle is-7 lower-padding">Owned</p>
                  <p class="title is-5">{{ l.value![0]!.value! }}</p>
                </div>
                <div class="column is-4">
                  <p class="subtitle is-7 lower-padding">Rolls</p>
                  <p class="title is-5">{{ l.value![1]!.value! }}</p>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

  </ng-container>
</div>