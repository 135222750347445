/* tslint:disable */
/* eslint-disable */
import { GachaEnhancementType } from './gacha-enhancement-type';

/**
 * Each possible value of `GachaEnhancementType`
 */
export const GACHA_ENHANCEMENT_TYPE: GachaEnhancementType[] = [
  'NO_GACHA_ENHANCEMENT',
  'RAINBOW_HOLO',
  'SAKURA_FALLING_GIF',
  'MOSAIC_CENSOR',
  'FIRST_ANNIVERSARY_BORDER',
  'SP3_SPARKLE_FALLING_GIF',
  'COMPETITION_1_BORDER_1',
  'COMPETITION_1_BORDER_2',
  'COMPETITION_1_BORDER_3',
  'COMPETITION_1_BORDER_4',
  'COMPETITION_1_BORDER_5',
  'COMPETITION_1_EFFECT_1',
  'COMPETITION_1_EFFECT_2',
  'COMPETITION_1_EFFECT_3'
];