/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { GetUserTradeHistoryResponse } from '../models/get-user-trade-history-response';
import { tradeServiceGetUserTradeHistory } from '../fn/trade-service/trade-service-get-user-trade-history';
import { TradeServiceGetUserTradeHistory$Params } from '../fn/trade-service/trade-service-get-user-trade-history';

@Injectable({ providedIn: 'root' })
export class TradeServiceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `tradeServiceGetUserTradeHistory()` */
  static readonly TradeServiceGetUserTradeHistoryPath = '/api/v3/gateway/user/{userUuid}/trades';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tradeServiceGetUserTradeHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  tradeServiceGetUserTradeHistory$Response(params: TradeServiceGetUserTradeHistory$Params, context?: HttpContext): Observable<StrictHttpResponse<GetUserTradeHistoryResponse>> {
    return tradeServiceGetUserTradeHistory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tradeServiceGetUserTradeHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tradeServiceGetUserTradeHistory(params: TradeServiceGetUserTradeHistory$Params, context?: HttpContext): Observable<GetUserTradeHistoryResponse> {
    return this.tradeServiceGetUserTradeHistory$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetUserTradeHistoryResponse>): GetUserTradeHistoryResponse => r.body)
    );
  }

}
