<div class="container">
  <div class="field">
    <label class="label">Pick Media</label>
    <div class="control">
      <div class="select is-fullwidth">
        <select [(ngModel)]="media" (ngModelChange)="onSelectMedia($event)">
          <option value="">~~Select Media~~</option>
          <option *ngFor="let media of allMedias" [ngValue]="media">
            {{media.name}}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div class="field">
    <label class="label">Pick Rarity</label>
    <div class="control">
      <div class="select is-fullwidth">
        <select [(ngModel)]="rarity" (ngModelChange)="onSelectRarity($event)">
          <option *ngFor="let r of rarityOptions" [ngValue]="r">{{ r }}⭐</option>
        </select>
      </div>
      <div class="field control">
        <button (click)="onClickAddAll()" class="button is-primary">Add All</button>
      </div>
    </div>
  </div>

  <div class="field">
    <ng-container *ngIf="media">
      <label class="label">Pick Gacha {{ stars() }}</label>
      <div class="field is-narrow">
        <div class="control">
          <div class="select is-fullwidth">
            <select [(ngModel)]="gacha" (ngModelChange)="onSelectGacha($event)">
              <option value="">~~Select Gacha~~</option>
              <option *ngFor="let gacha of mediaGachas" [ngValue]="gacha">{{gacha.name}}</option>
            </select>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="field" *ngIf="gacha">
    <div class="control">
      <button (click)="addGacha()" class="button is-primary">Add Gacha</button>
    </div>
  </div>
</div>