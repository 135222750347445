import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { faSquareMinus, faSquarePlus } from '@fortawesome/free-regular-svg-icons';
import { faCartPlus } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { map, mergeMap, take, tap } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { AltArt, Gacha } from 'src/app/openapi/models';
import { AltArtServiceService } from 'src/app/openapi/services';

export type AltArtSetState = "SET" | "NOT_SET" | "NOT_OWNED";
@Component({
  selector: 'app-view-alt-art-card',
  templateUrl: './view-alt-art-card.component.html',
  styleUrls: ['./view-alt-art-card.component.scss']
})
export class ViewAltArtCardComponent {
  faCart = faCartPlus;
  faSetArt = faSquarePlus;
  faUnsetArt = faSquareMinus;

  // Required - the base gacha
  @Input({ required: true })
  gacha!: Gacha;

  // Required - the alt art
  @Input({ required: true })
  altArt!: AltArt;

  /* START BUYABLE PARAMS */
  // Optional 
  @Input()
  buyable?: boolean;

  // Optional. To display the amount of tokens a user may have while purchasing.
  @Input()
  altArtTokens?: number;

  // Optional. Signals that a user bought the alt art if we need to do a mass refresh or anything.
  @Output()
  boughtRefresh = new EventEmitter<void>();
  @ViewChild('buyAltArtModal') buyAltArtModal!: ElementRef;
  /* END BUYABLE PARAMS */

  /* START SETTABLE PARAMS */
  // Optional
  @Input()
  altArtSetState?: AltArtSetState;
  // Optional. Signals that a user set the alt art if we need to do a mass refresh or anything.
  @Output()
  setRefresh = new EventEmitter<void>();

  constructor(
    private auth: AuthService,
    private toastr: ToastrService,
    private altArtService: AltArtServiceService) {
  }

  calculateWidth(): string {
    if (!this.altArt) {
      return "256px";
    }
    switch (this.altArt.orientation) {
      case "NORMAL":
        return "256px";
      case "WIDE":
        // We add +40 to the wide orientation to account for the margin it eats
        // as if it had 2 cards side by side (which each have a margin of 20px so 20px*2)
        return "552px";
      default:
        return "256px";
    }
  }

  calculateCost(g: Gacha, a: AltArt): number {
    let cost = g.rarity!;
    if (a.orientation === "WIDE") {
      cost *= 2;
    }
    return cost;
  }

  buildRarityColor(): string {
    switch (this.gacha.rarity) {
      case 1:
        return "#c9c9c9";
      case 2:
        return "#7ab800";
      case 3:
        return "#4542e3";
      case 4:
        return "#9018db";
      case 5:
        return "#e3bb42";
      default:
        return "#c4c4c4";
    }
  }

  buildStars(): string {
    if (this.gacha.category === 'DUO') {
      return "⭐".repeat(6);
    }
    return "⭐".repeat(this.gacha.rarity!);
  }

  showBuyArtConfirmationModal() {
    this.buyAltArtModal.nativeElement.classList.add('is-active');
  }
  closeBuyArtConfirmationModal() {
    this.buyAltArtModal.nativeElement.classList.remove('is-active');
  }

  setAltArt(gachaId: string, altArtId: number) {
    this.auth.user$.pipe(
      take(1),
      tap(user => {
        if (!user) {
          throw new Error("Not logged in!");
        }
      }),
      mergeMap(user => this.altArtService.altArtServiceSetAltArt({
        userUuid: user!.user_uuid!,
        gachaId: gachaId,
        altArtId: altArtId!,
      }))
    ).subscribe(res => {
      switch (res.status) {
        case "ALT_ART_NOT_FOUND":
          this.toastr.error("Alt art not found");
          break;
        case "USER_DOES_NOT_OWN_ALT_ART":
          this.toastr.error("You don't own this alt art");
          break;
        case "USER_DOES_NOT_OWN_GACHA":
          this.toastr.error("You don't own this gacha");
          break;
        case "SUCCESS":
          this.toastr.success(`Set alt art for ${this.gacha.name} to ${this.altArt.name}`);
          break;
        default:
          this.toastr.error("Unknown error, please try again later.");
      }
      this.setRefresh.emit();
    })
  }

  unsetAltArt(gachaId: string, altArtId: number) {
    this.auth.user$.pipe(
      take(1),
      tap(user => {
        if (!user) {
          throw new Error("Not logged in!");
        }
      }),
      mergeMap(user => this.altArtService.altArtServiceUnsetAltArt({
        userUuid: user!.user_uuid!,
        gachaId: gachaId,
      }))
    ).subscribe(res => {
      switch (res.status) {
        case "ALT_ART_NOT_FOUND":
          this.toastr.error("Alt art not found");
          break;
        case "USER_DOES_NOT_OWN_GACHA":
          this.toastr.error("You don't own this gacha");
          break;
        case "SUCCESS":
          this.toastr.success(`Unset alt art for ${this.gacha.name}`);
          break;
        default:
          this.toastr.error("Unknown error, please try again later.");
      }
      this.setRefresh.emit();
    });
  }

  buyArt() {
    this.auth.user$.pipe(
      take(1),
      tap(user => {
        if (!user) {
          throw new Error("Not logged in!");
        }
      }),
      mergeMap(user => this.altArtService.altArtServiceBuyAltArt({
        altArtId: this.altArt.artId!,
        userUuid: user!.user_uuid!,
        gachaId: this.gacha.id!,
      })),
      tap(() => this.boughtRefresh.emit()),
    ).subscribe(res => {
      switch (res.status) {
        case "ALT_ART_NOT_FOUND":
          this.toastr.error("Alt art not found");
          break;
        case "USER_ALREADY_OWNS_ALT_ART":
          this.toastr.error("You already own this alt art");
          break;
        case "USER_DOES_NOT_HAVE_ENOUGH_TOKENS":
          this.toastr.error(`You don't have enough tokens. You need ${res.needMoreTokens} more`);
          break;
        case "USER_DOES_NOT_OWN_GACHA":
          this.toastr.error("You don't own this gacha");
          break;
        case "SUCCESS":
          this.toastr.success(
            `Tap here to set alt art for ${this.gacha.name} to ${this.altArt.name}`,
            "Successfully bought alt art",
            {
              timeOut: 10000,
              progressBar: true,
              enableHtml: true,
              closeButton: true,
              extendedTimeOut: 7500,
            }).onTap.pipe(
              take(1),
            ).subscribe(() => {
              this.setAltArt(this.gacha.id!, this.altArt.artId!);
            });
          break;
        default:
          this.toastr.error("Unknown error, please try again later.");
      }

      this.closeBuyArtConfirmationModal();
    })
  }
}
