import { Component, OnInit } from '@angular/core';
import { imageCropperData } from '../view-staging/view-staging.component';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/auth/auth.service';
import { take } from 'rxjs';
import { Edition, Gacha, GachaCategory, Media, UpdateGachaImageResponseStatus } from 'src/app/openapi/models';
import { AdminServiceService, GachaServiceService, MediaServiceService } from 'src/app/openapi/services';
import { AdminServiceStageGachaWithEdition$Params } from 'src/app/openapi/fn/admin-service/admin-service-stage-gacha-with-edition';
import { AdminServiceUpdateGachaImage$Params } from 'src/app/openapi/fn/admin-service/admin-service-update-gacha-image';
import { CalculateAspectRatio, CalculateCropperMinWidth, CalculateCropperResizeToWidth } from '../upload-form/upload-form.component';

interface gachaAndEditData {
  gacha: Gacha;
  isEditing: boolean;

  ngxCropperData: imageCropperData;
}

@Component({
  selector: 'app-view-gacha',
  templateUrl: './view-gacha.component.html',
  styleUrls: ['./view-gacha.component.scss']
})
export class
  ViewGachaComponent implements OnInit {
  calcMinWidth = CalculateCropperMinWidth;
  calcResizeToWidth = CalculateCropperResizeToWidth;
  calcAspectRatio = CalculateAspectRatio;

  selectedMediaID?: string;

  medias?: Media[];
  gachas?: gachaAndEditData[];
  editions?: Edition[];
  selectedEdition?: string;

  creatingEditionFor?: Gacha;
  newEdition?: Gacha;
  newEditionCopies: number = 0;

  renderImages = false;

  constructor(private adminService: AdminServiceService,
    private mediaService: MediaServiceService,
    private gachaService: GachaServiceService,
    private toastr: ToastrService,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.mediaService.mediaServiceGetAllMedia().subscribe(m => {
      this.medias = m.media || [];
      if (this.medias && this.medias.length > 0) {
        this.onSelectMedia(this.medias[0].id!);
        this.selectedMediaID = this.medias[0].id!;
      }
    });
    this.adminService.adminServiceGetAllEditions().subscribe(e => {
      this.editions = e.editions || [];
      if (this.editions.length > 0) {
        this.selectedEdition = this.editions[0].editionId!;
      }
    });
  }

  // TODO: Recursively paginate this to fill out from paginated API
  onSelectMedia(e: string) {
    const spl = e.split(" ");
    var mediaID = e;
    if (spl.length > 1) {
      mediaID = spl[1];
    }

    this.gachaService.gachaServiceGetAllGachaForMedia({ mediaId: mediaID }).subscribe(gachas => {
      this.gachas = (gachas.gachas || []).map(g => ({
        gacha: g,
        isEditing: false,
        ngxCropperData: {
          imgChangeEvt: '',
          croppedBase64String: '',
          imageBase64String: '',
          updateImage: false,
        }
      }))
    });
  }

  createEdition(g: Gacha) {
    this.creatingEditionFor = g;
    this.newEdition = {
      name: g.name,
      imageUrl: g.imageUrl,
      category: g.category,
      rarity: g.rarity,
      mediaId: g.mediaId,
    }
  }

  createEditionSubmit() {
    console.log('submitting');
    this.newEdition!.name = this.newEdition!.name!.trim();
    const req: AdminServiceStageGachaWithEdition$Params = {
      body: {
        editionId: this.selectedEdition!,
        baseGachaId: this.creatingEditionFor!.id,
        creationLimit: this.newEditionCopies,
        stageGachaRequest: {
          name: this.newEdition!.name,
          category: this.newEdition!.category,
          imageBlob: this.edition_image_blob!,
          mediaId: this.newEdition!.mediaId,
          rarity: this.newEdition!.rarity,
        }
      }
    }

    if (this.newEdition!.rarity! < 1 || this.newEdition!.rarity! > 5) {
      this.toastr.error("Rarity must be between 1 and 5");
      return;
    }

    if (this.newEdition!.category == "UNDEFINED_CATEGORY") {
      this.toastr.error("Please select gacha category");
      return;
    }

    this.authService.user$.pipe(take(1)).subscribe(u => {
      if (!u) {
        this.toastr.error("Not logged in!");
        return;
      }
      this.adminService.adminServiceStageGachaWithEdition(req).subscribe(g => {
        this.toastr.success(JSON.stringify(g));
      }, err => this.toastr.error(JSON.stringify(err)));
    });
  }

  stringifyCategory(g: GachaCategory): string {
    return g.toString();
  }

  /*********
   * Image editing for existing gacha
   * *******/

  onFileChange(event: any, gacha: gachaAndEditData): void {
    gacha.ngxCropperData.imgChangeEvt = event;
  }

  cropImg(e: ImageCroppedEvent, gacha: gachaAndEditData) {
    // this.cropImgPreview = e.base64;
    gacha.ngxCropperData.croppedBase64String = e.base64!;
  }

  imgLoad() {
    // display cropper tool
  }

  initCropper() {
    // init cropper
  }

  imgFailed() { }

  onPaste(e: ClipboardEvent, gacha: gachaAndEditData) {
    let file: File | undefined | null;
    for (let i = 0; i < e.clipboardData?.items.length!; i++) {
      const type = e.clipboardData?.items[i].type;
      if (type === "image/png" || type === "image/jpg") {
        file = e.clipboardData?.items[i].getAsFile();
        break;
      }
    }

    if (!file) {
      this.toastr.error("no image found in clipboard!");
      return;
    }

    var reader = new FileReader();
    reader.onload = (event: any) => {
      gacha.ngxCropperData.imageBase64String = (event.target.result); // event.target.results contains the base64 code to create the image.
    };
    reader.readAsDataURL(file); // Convert the blob from clipboard to base64
  }

  startEdit(g: gachaAndEditData) {
    g.isEditing = true;
    this.adminService.adminServiceGetGachaImageData({ gachaId: g.gacha.id!, environment: "PRODUCTION" })
      .subscribe(blob => g.ngxCropperData.imageBase64String = `data:${blob.contentType};base64,${blob.data}`);
  }

  cancelEdit(g: gachaAndEditData) {
    g.isEditing = false;
  }

  saveEdit(g: gachaAndEditData) {
    if (!g.isEditing) {
      return;
    }
    g.isEditing = false;

    const r: AdminServiceUpdateGachaImage$Params = {
      environment: "PRODUCTION",
      gachaId: g.gacha.id!,
      body: {
        imageBlob: g.ngxCropperData.croppedBase64String.split(",")[1],
      }
    }
    this.adminService.adminServiceUpdateGachaImage(r).subscribe(res => {
      switch (res.status) {
        case "SUCCESS":
          this.toastr.success("Successfully updated gacha image!");
          break;
        case "GACHA_NOT_FOUND":
          this.toastr.error("Gacha not found!");
          break;
        case "UNDEFINED":
          this.toastr.error("Undefined error!");
          break;
      }
    }, err => {
      this.toastr.error("Error updating gacha image! " + err.message);
    });
  }


  /*************
   * Image editing for new edition
   *************/
  edition_image_blob?: string;
  editionImgChangeEvt: any = '';
  editionCropImgPreview: any = '';
  editionImageBase64String: string = "";

  editionOnFileChange(event: any): void {
    this.editionImgChangeEvt = event;
  }
  editionCropImg(e: ImageCroppedEvent) {
    this.editionCropImgPreview = e.base64;
    this.edition_image_blob = e.base64!.split(",")[1];
    // this.gacha.image_blob = e.base64!.split(",")[1];
  }

  editionImgLoad() {
    // display cropper tool
  }

  editionInitCropper() {
    // init cropper
  }

  editionImgFailed() { }

  editionOnPaste(e: ClipboardEvent) {
    let file: File | undefined | null;
    for (let i = 0; i < e.clipboardData?.items.length!; i++) {
      const type = e.clipboardData?.items[i].type;
      if (type === "image/png" || type === "image/jpg") {
        file = e.clipboardData?.items[i].getAsFile();
        break;
      }
    }

    if (!file) {
      this.toastr.error("no image found in clipboard!");
      return;
    }

    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.editionImageBase64String = (event.target.result); //event.target.results contains the base64 code to create the image.
    };
    reader.readAsDataURL(file);//Convert the blob from clipboard to base64
  }
}
