/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ViewUserSummaryResponse } from '../../models/view-user-summary-response';

export interface AbuseServiceViewUserSummary$Params {

/**
 * string user_id = 1;
 * Do something very bad here and accept either a regular user ID or a user UUID since
 * admins may not have access to regular user ID.
 */
  targetId: string;
}

export function abuseServiceViewUserSummary(http: HttpClient, rootUrl: string, params: AbuseServiceViewUserSummary$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewUserSummaryResponse>> {
  const rb = new RequestBuilder(rootUrl, abuseServiceViewUserSummary.PATH, 'get');
  if (params) {
    rb.path('targetId', params.targetId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ViewUserSummaryResponse>;
    })
  );
}

abuseServiceViewUserSummary.PATH = '/api/v3/gateway/admin/abuse/{targetId}/summary';
