import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, take } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { AdminServiceStageEquipment$Params } from 'src/app/openapi/fn/admin-service/admin-service-stage-equipment';
import { Equipment, EquipmentCategory, Media } from 'src/app/openapi/models';
import { EQUIPMENT_CATEGORY } from 'src/app/openapi/models/equipment-category-array';
import { AdminServiceService, MediaServiceService } from 'src/app/openapi/services';

@Component({
  selector: 'app-upload-equipment',
  templateUrl: './upload-equipment.component.html',
  styleUrls: ['./upload-equipment.component.scss']
})
export class UploadEquipmentComponent implements AfterViewInit {

  // Upload form object
  equipment: Equipment = {
    equipmentId: "",
    name: "",
    rarity: 1,
    mediaId: "",
    description: "",
    attributes: {
      stats: {
        health: 0,
        mana: 0,
        attack: 0,
        defense: 0,
        luck: 0,
        speed: 0,
      },
      invariateStats: {
        level: 1,
        experience: 0,
      },
      multipliers: {
        health: 1.0,
        mana: 1.0,
        attack: 1.0,
        defense: 1.0,
        luck: 1.0,
        speed: 1.0,
      }
    },
  }

  @Output()
  successfulSubmit = new EventEmitter();
  medias: Media[] = [];
  categoryOptions: EquipmentCategory[];
  rarityOptions = [1, 2, 3, 4, 5];

  constructor(private adminService: AdminServiceService,
    private mediaService: MediaServiceService,
    private toastr: ToastrService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef) {
    this.categoryOptions = [];
    for (const g of EQUIPMENT_CATEGORY) {
      if (g === "UNDEFINED_EQUIPMENT_CATEGORY")
        continue;
      this.categoryOptions.push(g);
    }
  }

  ngAfterViewInit(): void {
    const mediaList$ = this.mediaService.mediaServiceGetAllMedia().pipe(take(1));

    forkJoin(mediaList$).subscribe(
      m => {
        this.medias = m[0].media || [];
        this.cdr.detectChanges();
      },
      err => this.toastr.error(JSON.stringify(err))
    );
  }

  submitEquipment() {
    this.equipment.name = this.equipment.name!.trim();
    const req: AdminServiceStageEquipment$Params = {
      body: {
        name: this.equipment.name,
        imageBlob: this.image_blob!,
        category: this.equipment.category,
        mediaId: this.equipment.mediaId,
        rarity: this.equipment.rarity,
        description: this.equipment.description,
        attributes: this.equipment.attributes,
      }
    };

    if (this.equipment.rarity! < 1 || this.equipment.rarity! > 5) {
      this.toastr.error("Rarity must be between 1 and 5");
      return;
    }

    if (this.equipment.category == "UNDEFINED_EQUIPMENT_CATEGORY") {
      this.toastr.error("Please select gacha category");
      return;
    }

    this.authService.user$.pipe(take(1)).subscribe(u => {
      if (!u) {
        this.toastr.error("Not logged in!");
        return;
      }
      this.adminService.adminServiceStageEquipment(req).subscribe(g => {
        this.toastr.success(JSON.stringify(g));
        this.successfulSubmit.emit();
      }, err => this.toastr.error(JSON.stringify(err)));
    });
  }

  image_blob?: string;

  @ViewChild('input')
  inputElement!: ElementRef;

  @ViewChild(ImageCropperComponent)
  imageCropper!: ImageCropperComponent;

  @ViewChild("imageRenderer")
  imageRenderer!: ElementRef;

  @ViewChild("extractInput")
  extractInput!: ElementRef;

  imgChangeEvt: any = '';
  cropImgPreview: any = '';
  imageBase64String: string = "";

  onFileChange(event: any): void {
    this.imgChangeEvt = event;
  }
  cropImg(e: ImageCroppedEvent) {
    this.cropImgPreview = e.base64;
    this.image_blob = e.base64!.split(",")[1];
    // this.gacha.image_blob = e.base64!.split(",")[1];
  }

  imgLoad() {
    // display cropper tool
  }

  initCropper() {
    // init cropper
  }

  imgFailed() { }

  onPaste(e: ClipboardEvent) {
    let file: File | undefined | null;
    for (let i = 0; i < e.clipboardData?.items.length!; i++) {
      const type = e.clipboardData?.items[i].type;
      if (type === "image/png" || type === "image/jpg") {
        file = e.clipboardData?.items[i].getAsFile();
        break;
      }
    }

    if (!file) {
      return;
    }

    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageBase64String = (event.target.result); //event.target.results contains the base64 code to create the image.
    };
    reader.readAsDataURL(file);//Convert the blob from clipboard to base64
  }

  stringifyCategory(c: EquipmentCategory): string {
    return c;
  }
}
