<div class="container">
  <div class="columns is-mobile" *ngIf="commands">
    <div class="column is-3">
      <aside class="menu">
        <ng-container *ngFor="let key of sortCommandKeys()">
          <p class="menu-label">
            {{ key }}
          </p>
          <ul class="menu-list">
            <li *ngFor="let subcommand of commands.get(key)">
              <a [routerLink]="['/commands', subcommand.name]" routerLinkActive="is-active">/{{ subcommand.name }}</a>
            </li>
          </ul>
        </ng-container>
      </aside>
    </div>
    <div class="column is-9">
      <ng-container *ngIf="selectedCommand">
        <div class="card">
          <div class="card-content">
            <div class="media">
              <div class="media-content">
                <p class="title is-4">
                  <span style="display:contents;">
                    <code>/{{ selectedCommand.name }}</code> &nbsp;
                  </span>
                  <span style=" height: 50%; display:contents;" class="tags has-addons are-normal">
                    <span class="tag is-light">Permission</span>
                    <span class="tag is-info">{{ selectedCommand.permission }}</span>
                  </span>
                </p>
                <p class="subtitle is-6">&nbsp;&nbsp;{{ selectedCommand.helpText }}</p>
              </div>
            </div>

            <p class="menu-label">&nbsp;Usage</p>
            <p>
              <span [innerHTML]="selectedCommand.usage"></span>
            </p>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>