<div class="container">
  <div class="notification is-danger is-light">
    This page is <strong>under construction!</strong> However, you can still view the guilds below and find one you'd
    like to join!<br />
    Hint: Many of these are available in the community server at <a href="https://upa.moe/server">this link :)</a>
  </div>
  <div class="columns is-multiline">
    <div class="column is-4" *ngFor="let guild of guilds">
      <app-view-guild-card [guild]="guild"></app-view-guild-card>
    </div>
  </div>
  <div *ngIf="!guilds || guilds.length == 0">
    <div class="columns is-multiline">
      <ng-container *ngFor="let e of [].constructor(6)">
        <div class="column is-4">
          <div class="skeleton-block skeleton-block-tall"></div>
        </div>
      </ng-container>
    </div>
  </div>
</div>