import { Component, OnInit, isDevMode } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, bufferTime, debounce, debounceTime, delay, from, mergeMap, of, switchMap, tap } from 'rxjs';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { Gacha, LiveGachaFeedResponse, LiveGachaFeedResponseGachaAndMedia, Media } from 'src/app/openapi/models';
import { trigger, style, transition, animate, state } from '@angular/animations';
import { environment } from 'src/environments/environment';

function getWsUrl(): string {
  // return isDevMode() ? "ws://localhost:8080/api/v3/gateway/ws/gacha/live" : "wss://upa.moe/api/v3/gateway/ws/gacha/live";
  return environment.wsRoot + "/api/v3/gateway/ws/gacha/live";
  return "wss://upa.moe/api/v3/gateway/ws/gacha/live";
}

interface GachaMediaAndAnim {
  gacha: Gacha;
  media: Media;
  state: string;
}

const MAX_SIZE = 48;

@Component({
  selector: 'app-live-rolls',
  templateUrl: './live-rolls.component.html',
  styleUrl: './live-rolls.component.scss',
  animations: [
    trigger('slideIn', [
      state('in', style({ opacity: 1, transform: 'translateY(0)' })),
      transition('void => *', [
        style({ opacity: 0, transform: 'translateY(20px)' }),
        animate('0.3s ease-in-out')
      ])
    ]),
  ]
})
export class LiveRollsComponent implements OnInit {

  constructor(private toastr: ToastrService) { }
  private socket$: WebSocketSubject<LiveGachaFeedResponse> = webSocket<LiveGachaFeedResponse>({
    url: getWsUrl(),
    openObserver: {
      next: () => this.toastr.success('Connected to live rolls feed~'),
    }
  });
  private useDebounce = new BehaviorSubject<boolean>(true);

  showRarity: { [key: number]: boolean } = {
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
  };

  private socketDelayOrDebounce$ = this.socket$.pipe(
    switchMap(value => {
      return this.useDebounce.pipe(
        switchMap(shouldDebounce => {
          if (shouldDebounce) {
            return of(value).pipe(debounceTime(500));
          } else {
            return of(value).pipe(delay(500));
          }
        })
      );
    })
  );

  gachaQueue: GachaMediaAndAnim[] = [];
  ngOnInit() {
    this.socket$.pipe(
      bufferTime(1500),
      mergeMap(buffer => from(buffer)),
      tap((msg) => {
        const g = (msg as any)['result'].gacha as LiveGachaFeedResponseGachaAndMedia;
        if (!this.showRarity[g.gacha!.rarity!]) {
          return;
        }
        const gma = {
          gacha: g.gacha,
          media: g.media,
          state: 'in',
        } as GachaMediaAndAnim;
        this.gachaQueue.push(gma);
        if (this.gachaQueue.length > MAX_SIZE) {
          this.gachaQueue.shift();
        }

      })
    ).subscribe();
  }

  firehose(event: any) {
    this.useDebounce.next(!event.target.checked);
  }
}
