import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { map, mergeMap, Observable, lastValueFrom, of, combineLatest, merge, switchMap, forkJoin } from 'rxjs';
import { animate, group, query, style, transition, trigger, state } from '@angular/animations';
import { KeyValue, ViewportScroller, isPlatformBrowser } from '@angular/common';
import { ImageState, BannerAndImages, toDateTime } from './view-banner/view-banner.component';
import { Banner, BannerRateUpGachas } from 'src/app/openapi/models';
import { GachaServiceService } from 'src/app/openapi/services';


@Component({
  selector: 'app-view-banners',
  templateUrl: './view-banners.component.html',
  styleUrls: ['./view-banners.component.scss'],
  animations: [
    trigger('crossfade', [
      state('in', style({ 'opacity': '1' })),
      state('out', style({ 'opacity': '0' })),
      transition('* <=> *', [
        animate(750)
      ])
    ])
  ],
})
export class ViewBannersComponent implements OnInit {

  // Slap Number into the app scope to cast the rate_up key from string->number
  Number = Number;
  banners: BannerAndImages[] = [];

  // Cache the ID -> Name for display in the rate up section
  gachaIDToNameMapping: { [key: string]: { gachaID: string, gachaName: string } } = {};

  constructor(private gachaApi: GachaServiceService,
    private viewportScroller: ViewportScroller,
    @Inject(PLATFORM_ID) private platformID: Object) { }

  toAnchor(anchor: string) {
    this.viewportScroller.scrollToAnchor(anchor);
    let pos = this.viewportScroller.getScrollPosition();
    this.viewportScroller.scrollToPosition([pos[0], pos[1] - 100]);
  }

  isPresent(b: Banner): boolean {
    const start = new Date(b.startTime!);
    const end = new Date(b.endTime!);
    const now = new Date();
    return start <= now && now <= end;
  }

  isPast(b: Banner): boolean {
    const now = new Date();
    const end = new Date(b.endTime!);
    return end < now;
  }

  isFuture(b: Banner): boolean {
    const start = new Date(b.startTime!);
    const now = new Date();
    return start > now;
  }

  ngOnInit(): void {
    // TODO: convert this to the new getGachaForIDs
    if (isPlatformBrowser(this.platformID)) {
      this.gachaApi.gachaServiceGetBanners(
        {
          onlyGetCurrent: true,
        }
      ).pipe(
        mergeMap(b => b.banners || []),
        map(banner => {
          let gachaIDs: string[] = [];
          for (const k in banner.rateUpGachas!) {
            gachaIDs = gachaIDs.concat(banner.rateUpGachas![k].gachaIds || [])
          }
          return combineLatest([of(banner), of(gachaIDs)]);
        }),
        mergeMap(x =>
          x.pipe(
            mergeMap(([banner, gachaIDs]) => combineLatest([of(banner), this.gachaApi.gachaServiceGetGacha({
              body: {
                gachaIds: gachaIDs,
              }
            }
            )])),
            map(([banner, res]) => {
              (res.gacha || []).forEach(g => this.gachaIDToNameMapping[g.id!] = { gachaID: g.id!, gachaName: g.name! });
              return {
                banner: banner,
                shownImageIdx: 0,
                imageStates: (res.gacha || []).map(g => {
                  return { imageURL: g.imageUrl!, state: "out" };
                })
              };
            }),
          )
        ),
      ).subscribe(b => {
        this.banners.push(b);
        b.imageStates[b.shownImageIdx].state = "in";
        setInterval(() => {
          b.imageStates[b.shownImageIdx].state = "out";
          b.shownImageIdx = (b.shownImageIdx + 1) % b.imageStates.length;
          b.imageStates[b.shownImageIdx].state = "in";
        }, this.randomNumberInRange(3000, 4000));
      });
    }
  }

  randomNumberInRange(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  originalOrder = (a: KeyValue<number, BannerRateUpGachas>, b: KeyValue<number, BannerRateUpGachas>): number => {
    return a.key > b.key ? 1 : (a.key < b.key ? -1 : 0);
  }
}
