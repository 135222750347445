import { inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ResolveFn } from '@angular/router';

export const resolveHelp: ResolveFn<boolean> = (route, state) => {
  const m = inject(Meta);
  m.removeTag('name="title"');
  m.removeTag('name="description"');
  m.removeTag('name="og:title"');
  m.removeTag('name="og:description"');
  m.addTag({ name: 'title', content: `Upa - Guide` })
  m.addTag({ name: 'og:title', content: `Upa - Guide` });
  m.addTag({ name: 'description', content: `Quickly learn how to play Upa and learn all the features we have to offer` });
  m.addTag({ name: 'og:description', content: `Quickly learn how to play Upa and learn all the features we have to offer` });
  return true;
};
