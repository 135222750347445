<div class="card">
  <div class="card-content">
    <div class="media">
      <div *ngIf="guild.milestoneRewardOptions?.iconImgUrl" class="media-left">
        <figure class="image is-48x48">
          <img class="relimage" src="{{ guild.milestoneRewardOptions!.iconImgUrl }}" alt="Guild image icon">
        </figure>
      </div>
      <div class="media-content">
        <p class="title is-4">
          <a [routerLink]="['/view/guild', guild.id]">
            {{ guild.name }}
          </a>
        </p>
        <p class="subtitle is-6">
          level {{ guild.level }} ({{ guild.exp | number:'1.0':'en-US' }} exp)<br />
          {{ guild.members!.length }}/{{ guild.memberCap }} members
        </p>
      </div>
    </div>
    <div class="content">
      {{ guild.description }}
    </div>
  </div>
</div>