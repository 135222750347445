<div class="container">
  <h1 class="title is-1">Live Rolls</h1>
  <div class="field is-grouped">
    <label class="checkbox">
      <input [(ngModel)]="showRarity[1]" type="checkbox" />
      1⭐
    </label>
    <label class="checkbox">
      <input [(ngModel)]="showRarity[2]" type="checkbox" />
      2⭐
    </label>
    <label class="checkbox">
      <input [(ngModel)]="showRarity[3]" type="checkbox" />
      3⭐
    </label>
    <label class="checkbox">
      <input [(ngModel)]="showRarity[4]" type="checkbox" />
      4⭐
    </label>
    <label class="checkbox">
      <input [(ngModel)]="showRarity[5]" type="checkbox" />
      5⭐
    </label>
  </div>
  <!-- <h2 class="subtitle is-4">
    <label class="checkbox">
      <input (change)="firehose($event)" type="checkbox" />
      Show me ALL the rolls!!!
    </label>
  </h2> -->

  <div class="columns">
    <div class="column has-text-centered">
      <ng-container *ngFor="let gacha of gachaQueue.slice().reverse()">
        <app-gacha-v2 [@slideIn]="gacha.state" [gacha]="gacha.gacha!" [media]="gacha.media!"></app-gacha-v2>
      </ng-container>
    </div>
  </div>
</div>