<div class="container is-fluid">
  <div class="columns">
    <div class="column is-half">
      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field">
            <label class="label">Banner ID</label>
            <div class="control">
              <input class="input" type="text" placeholder="Unique, no space ID" [(ngModel)]="banner.id">
            </div>
          </div>
          <div class="field">
            <label class="label">Banner Name</label>
            <div class="control">
              <input class="input" type="text" placeholder="Banner name" [(ngModel)]="banner.name">
            </div>
          </div>
        </div>
      </div>

      <div class="field">
        <label class="label">Description</label>
        <div class="control">
          <textarea class="textarea" placeholder="Description" [(ngModel)]="banner.description"></textarea>
        </div>
      </div>

      <div class="field">
        <label class="label">Banner Image (We dont' set this right now)</label>
        <div class="control">
          <input class="input" type="text" placeholder="Banner image URL" disabled>
        </div>
      </div>

      <img *ngIf="banner.imageUrl" src="{{ banner.imageUrl }}" alt="">

      <div class="field">
        <label class="label">Thumbnail Image</label>
        <div class="control">
          <input class="input" type="text" placeholder="Thumbnail image URL" [(ngModel)]="banner.thumbnailUrl">
        </div>
      </div>

      <img *ngIf="banner.thumbnailUrl" width="100px" src="{{ banner.thumbnailUrl }}" alt="">

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Start Date</label>
        </div>
        <div class="field">
          <input [(ngModel)]="startDate" (ngModelChange)="changeStartDate()" type="date" id="start" name="banner-start"
            value="{{today()}}">
        </div>

        <div class="field-label is-normal">
          <label class="label">End Date</label>
        </div>
        <div class="field-body">
          <p class="control">
            <input [(ngModel)]="endDate" (ngModelChange)="changeEndDate()" type="date" id="end" name="banner-end"
              value="{{tomorrow()}}">
          </p>
        </div>
      </div>

      <div class="field">
        <label class="checkbox">
          <input type="checkbox" [(ngModel)]="banner.visible">
          Immediately visible?
        </label>
      </div>
      <div class="columns">
        <div class="column is-half">
          <h1 class="title is-4">Add Gacha To Banner</h1>
          <app-gacha-v2 *ngIf="mediaPreview && gachaPreview" [gacha]="gachaPreview"
            [media]="mediaPreview"></app-gacha-v2>
        </div>
        <div class="column is-half">
          <app-gacha-picker (addGachaEvent)="addGacha($event!)" (selectMediaEvent)="selectedMedia($event!)"
            (selectGachaEvent)="selectedGacha($event!)"></app-gacha-picker>
        </div>
      </div>
      <div class="field is-grouped">
        <div class="control">
          <button (click)="emitBanner()" class="button is-link">{{ buttonLabel }}</button>
        </div>
      </div>
    </div>
    <div class="column is-half">
      <div>
        <h1 class="title is-4">Rateup Data</h1>
        <ng-container *ngFor="let k of banner.rateUpGachas | keyvalue;">
          <div>
            <div class="field is-horizontal">
              <div class="field-label">
                {{ k.key }}⭐:
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="field has-addons">
                    <p class="control">
                      <input [(ngModel)]="k.value.rateUpPercentage" class="input" placeholder="{{k.key}}⭐ drop percent">
                    </p>
                    <p class="control">
                      <a disabled class="button is-info is-disabled">
                        %
                      </a>
                    </p>
                  </div>
                  <p class="help">Percent value that a drop will give banner instead</p>
                </div>
              </div>
            </div>

            <div class="columns is-multiline">
              <ng-container *ngFor="let gachaID of k.value.gachaIds">
                <div class="column is-half" *ngIf="(gachaID | getGachaPipe | async) as gachaAndMedia">
                  <app-gacha-v2 [gacha]="gachaAndMedia.g" [media]="gachaAndMedia.m"></app-gacha-v2>
                  <button (click)="setThumbnailURL(gachaAndMedia.g.imageUrl!)" class="button is-info">Set
                    Thumbnail</button>
                  <button (click)="removeGacha(gachaAndMedia!.g!)" class="button is-danger">Remove
                    {{gachaAndMedia.g.name}}</button>
                </div>
              </ng-container>
            </div>
          </div>
          <hr />
        </ng-container>
      </div>
      <hr />
    </div>
  </div>
</div>