import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, take } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { AdminServiceCreateBanner$Params } from 'src/app/openapi/fn/admin-service/admin-service-create-banner';
import { Banner, CreateBannerResponseStatus } from 'src/app/openapi/models';
import { AdminServiceService } from 'src/app/openapi/services';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent {

  constructor(private adminService: AdminServiceService,
    private toastr: ToastrService,
    private authService: AuthService,
    private router: Router) { }

  submitBanner(banner: Banner) {
    const req: AdminServiceCreateBanner$Params = {
      body: {
        banner: banner,
        visible: false,
      }
    }
    this.authService.user$.pipe(take(1)).subscribe(u => {
      if (!u) {
        this.toastr.error("Not logged in!");
        return;
      }
      this.adminService.adminServiceCreateBanner(req).subscribe(b => {
        switch (b.status) {
          case "BANNER_ID_ALREADY_EXISTS":
            this.toastr.error(`Banner ID ${b.banner?.id} already exists!`);
            return;
          case "SUCCESS":
            break;
          default:
            this.toastr.error(`Unknown error: ${b.status}`);
            return;
        }
        this.toastr.success(`Created banner ${b.banner?.name}!`);
        this.router.navigate(['/admin/banners'])
      }, err => this.toastr.error(`Failed to create banner: ${err.message}`));
    });
  }
}
