/* tslint:disable */
/* eslint-disable */
import { EquipmentCategory } from './equipment-category';

/**
 * Each possible value of `EquipmentCategory`
 */
export const EQUIPMENT_CATEGORY: EquipmentCategory[] = [
  'UNDEFINED_EQUIPMENT_CATEGORY',
  'WEAPON',
  'SHIELD',
  'ARMOR',
  'ACCESSORY'
];