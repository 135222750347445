/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { auctionServiceBidAuction } from '../fn/auction-service/auction-service-bid-auction';
import { AuctionServiceBidAuction$Params } from '../fn/auction-service/auction-service-bid-auction';
import { auctionServiceCreateAuction } from '../fn/auction-service/auction-service-create-auction';
import { AuctionServiceCreateAuction$Params } from '../fn/auction-service/auction-service-create-auction';
import { auctionServiceGetAuction } from '../fn/auction-service/auction-service-get-auction';
import { AuctionServiceGetAuction$Params } from '../fn/auction-service/auction-service-get-auction';
import { auctionServiceListAuctions } from '../fn/auction-service/auction-service-list-auctions';
import { AuctionServiceListAuctions$Params } from '../fn/auction-service/auction-service-list-auctions';
import { BidAuctionResponse } from '../models/bid-auction-response';
import { CreateAuctionResponse } from '../models/create-auction-response';
import { GetAuctionResponse } from '../models/get-auction-response';
import { ListAuctionsResponse } from '../models/list-auctions-response';

@Injectable({ providedIn: 'root' })
export class AuctionServiceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `auctionServiceListAuctions()` */
  static readonly AuctionServiceListAuctionsPath = '/api/v3/gateway/auctions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `auctionServiceListAuctions()` instead.
   *
   * This method doesn't expect any request body.
   */
  auctionServiceListAuctions$Response(params?: AuctionServiceListAuctions$Params, context?: HttpContext): Observable<StrictHttpResponse<ListAuctionsResponse>> {
    return auctionServiceListAuctions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `auctionServiceListAuctions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  auctionServiceListAuctions(params?: AuctionServiceListAuctions$Params, context?: HttpContext): Observable<ListAuctionsResponse> {
    return this.auctionServiceListAuctions$Response(params, context).pipe(
      map((r: StrictHttpResponse<ListAuctionsResponse>): ListAuctionsResponse => r.body)
    );
  }

  /** Path part for operation `auctionServiceCreateAuction()` */
  static readonly AuctionServiceCreateAuctionPath = '/api/v3/gateway/auctions/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `auctionServiceCreateAuction()` instead.
   *
   * This method doesn't expect any request body.
   */
  auctionServiceCreateAuction$Response(params?: AuctionServiceCreateAuction$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateAuctionResponse>> {
    return auctionServiceCreateAuction(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `auctionServiceCreateAuction$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  auctionServiceCreateAuction(params?: AuctionServiceCreateAuction$Params, context?: HttpContext): Observable<CreateAuctionResponse> {
    return this.auctionServiceCreateAuction$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateAuctionResponse>): CreateAuctionResponse => r.body)
    );
  }

  /** Path part for operation `auctionServiceGetAuction()` */
  static readonly AuctionServiceGetAuctionPath = '/api/v3/gateway/auctions/{auctionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `auctionServiceGetAuction()` instead.
   *
   * This method doesn't expect any request body.
   */
  auctionServiceGetAuction$Response(params: AuctionServiceGetAuction$Params, context?: HttpContext): Observable<StrictHttpResponse<GetAuctionResponse>> {
    return auctionServiceGetAuction(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `auctionServiceGetAuction$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  auctionServiceGetAuction(params: AuctionServiceGetAuction$Params, context?: HttpContext): Observable<GetAuctionResponse> {
    return this.auctionServiceGetAuction$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetAuctionResponse>): GetAuctionResponse => r.body)
    );
  }

  /** Path part for operation `auctionServiceBidAuction()` */
  static readonly AuctionServiceBidAuctionPath = '/api/v3/gateway/auctions/{auctionId}/bid';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `auctionServiceBidAuction()` instead.
   *
   * This method doesn't expect any request body.
   */
  auctionServiceBidAuction$Response(params: AuctionServiceBidAuction$Params, context?: HttpContext): Observable<StrictHttpResponse<BidAuctionResponse>> {
    return auctionServiceBidAuction(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `auctionServiceBidAuction$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  auctionServiceBidAuction(params: AuctionServiceBidAuction$Params, context?: HttpContext): Observable<BidAuctionResponse> {
    return this.auctionServiceBidAuction$Response(params, context).pipe(
      map((r: StrictHttpResponse<BidAuctionResponse>): BidAuctionResponse => r.body)
    );
  }

}
