/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { altArtServiceBuyAltArt } from '../fn/alt-art-service/alt-art-service-buy-alt-art';
import { AltArtServiceBuyAltArt$Params } from '../fn/alt-art-service/alt-art-service-buy-alt-art';
import { altArtServiceGetAltArtCost } from '../fn/alt-art-service/alt-art-service-get-alt-art-cost';
import { AltArtServiceGetAltArtCost$Params } from '../fn/alt-art-service/alt-art-service-get-alt-art-cost';
import { altArtServiceGetGachaOwnedByUserWithAltArt } from '../fn/alt-art-service/alt-art-service-get-gacha-owned-by-user-with-alt-art';
import { AltArtServiceGetGachaOwnedByUserWithAltArt$Params } from '../fn/alt-art-service/alt-art-service-get-gacha-owned-by-user-with-alt-art';
import { altArtServiceSetAltArt } from '../fn/alt-art-service/alt-art-service-set-alt-art';
import { AltArtServiceSetAltArt$Params } from '../fn/alt-art-service/alt-art-service-set-alt-art';
import { altArtServiceUnsetAltArt } from '../fn/alt-art-service/alt-art-service-unset-alt-art';
import { AltArtServiceUnsetAltArt$Params } from '../fn/alt-art-service/alt-art-service-unset-alt-art';
import { BuyAltArtResponse } from '../models/buy-alt-art-response';
import { GetAltArtCostResponse } from '../models/get-alt-art-cost-response';
import { GetGachaOwnedByUserWithAltArtResponse } from '../models/get-gacha-owned-by-user-with-alt-art-response';
import { SetAltArtResponse } from '../models/set-alt-art-response';
import { UnsetAltArtResponse } from '../models/unset-alt-art-response';

@Injectable({ providedIn: 'root' })
export class AltArtServiceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `altArtServiceGetAltArtCost()` */
  static readonly AltArtServiceGetAltArtCostPath = '/api/v3/gateway/gacha/{gachaId}/altart/{altArtId}/cost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `altArtServiceGetAltArtCost()` instead.
   *
   * This method doesn't expect any request body.
   */
  altArtServiceGetAltArtCost$Response(params: AltArtServiceGetAltArtCost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetAltArtCostResponse>> {
    return altArtServiceGetAltArtCost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `altArtServiceGetAltArtCost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  altArtServiceGetAltArtCost(params: AltArtServiceGetAltArtCost$Params, context?: HttpContext): Observable<GetAltArtCostResponse> {
    return this.altArtServiceGetAltArtCost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetAltArtCostResponse>): GetAltArtCostResponse => r.body)
    );
  }

  /** Path part for operation `altArtServiceGetGachaOwnedByUserWithAltArt()` */
  static readonly AltArtServiceGetGachaOwnedByUserWithAltArtPath = '/api/v3/gateway/user/{userUuid}/gacha/altart';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `altArtServiceGetGachaOwnedByUserWithAltArt()` instead.
   *
   * This method doesn't expect any request body.
   */
  altArtServiceGetGachaOwnedByUserWithAltArt$Response(params: AltArtServiceGetGachaOwnedByUserWithAltArt$Params, context?: HttpContext): Observable<StrictHttpResponse<GetGachaOwnedByUserWithAltArtResponse>> {
    return altArtServiceGetGachaOwnedByUserWithAltArt(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `altArtServiceGetGachaOwnedByUserWithAltArt$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  altArtServiceGetGachaOwnedByUserWithAltArt(params: AltArtServiceGetGachaOwnedByUserWithAltArt$Params, context?: HttpContext): Observable<GetGachaOwnedByUserWithAltArtResponse> {
    return this.altArtServiceGetGachaOwnedByUserWithAltArt$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetGachaOwnedByUserWithAltArtResponse>): GetGachaOwnedByUserWithAltArtResponse => r.body)
    );
  }

  /** Path part for operation `altArtServiceUnsetAltArt()` */
  static readonly AltArtServiceUnsetAltArtPath = '/api/v3/gateway/user/{userUuid}/gacha/{gachaId}/altart';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `altArtServiceUnsetAltArt()` instead.
   *
   * This method doesn't expect any request body.
   */
  altArtServiceUnsetAltArt$Response(params: AltArtServiceUnsetAltArt$Params, context?: HttpContext): Observable<StrictHttpResponse<UnsetAltArtResponse>> {
    return altArtServiceUnsetAltArt(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `altArtServiceUnsetAltArt$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  altArtServiceUnsetAltArt(params: AltArtServiceUnsetAltArt$Params, context?: HttpContext): Observable<UnsetAltArtResponse> {
    return this.altArtServiceUnsetAltArt$Response(params, context).pipe(
      map((r: StrictHttpResponse<UnsetAltArtResponse>): UnsetAltArtResponse => r.body)
    );
  }

  /** Path part for operation `altArtServiceSetAltArt()` */
  static readonly AltArtServiceSetAltArtPath = '/api/v3/gateway/user/{userUuid}/gacha/{gachaId}/altart/{altArtId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `altArtServiceSetAltArt()` instead.
   *
   * This method doesn't expect any request body.
   */
  altArtServiceSetAltArt$Response(params: AltArtServiceSetAltArt$Params, context?: HttpContext): Observable<StrictHttpResponse<SetAltArtResponse>> {
    return altArtServiceSetAltArt(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `altArtServiceSetAltArt$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  altArtServiceSetAltArt(params: AltArtServiceSetAltArt$Params, context?: HttpContext): Observable<SetAltArtResponse> {
    return this.altArtServiceSetAltArt$Response(params, context).pipe(
      map((r: StrictHttpResponse<SetAltArtResponse>): SetAltArtResponse => r.body)
    );
  }

  /** Path part for operation `altArtServiceBuyAltArt()` */
  static readonly AltArtServiceBuyAltArtPath = '/api/v3/gateway/user/{userUuid}/gacha/{gachaId}/altart/{altArtId}/buy';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `altArtServiceBuyAltArt()` instead.
   *
   * This method doesn't expect any request body.
   */
  altArtServiceBuyAltArt$Response(params: AltArtServiceBuyAltArt$Params, context?: HttpContext): Observable<StrictHttpResponse<BuyAltArtResponse>> {
    return altArtServiceBuyAltArt(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `altArtServiceBuyAltArt$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  altArtServiceBuyAltArt(params: AltArtServiceBuyAltArt$Params, context?: HttpContext): Observable<BuyAltArtResponse> {
    return this.altArtServiceBuyAltArt$Response(params, context).pipe(
      map((r: StrictHttpResponse<BuyAltArtResponse>): BuyAltArtResponse => r.body)
    );
  }

}
