import { Pipe, PipeTransform } from "@angular/core";
import { combineLatest, map, mergeMap, Observable, of } from "rxjs";
import { Gacha, Media } from "src/app/openapi/models";
import { GachaServiceService, MediaServiceService } from "src/app/openapi/services";

@Pipe({
    name: 'getGachaPipe'
})
export class GetGachaPipe implements PipeTransform {

    constructor(private mediaService: MediaServiceService,
        private gachaService: GachaServiceService) { }
    transform(id: string): Observable<{ g: Gacha; m: Media }> {
        return this.gachaService.gachaServiceGetGacha2({ gachaIds: [id] }).pipe(
            mergeMap(res => {
                if (!res.gacha || res.gacha.length === 0) {
                    throw new Error("Gacha not found");
                }
                const media = this.mediaService.mediaServiceGetMedia({ mediaId: res.gacha[0].mediaId! });
                return combineLatest([of(res.gacha[0]), media]);
            }),
            map(([g, m]) => {
                if (!g.mediaId || !m.media) {
                    throw new Error("Gacha media not found");
                }
                const media = m.media;
                return { g, m: media };
            }),
        );
    }
}