/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { GetUserFavoriteMediaResponse } from '../../models/get-user-favorite-media-response';

export interface UserServiceGetUserFavoriteMedia$Params {
  userUuid: string;
  discordId?: string;
}

export function userServiceGetUserFavoriteMedia(http: HttpClient, rootUrl: string, params: UserServiceGetUserFavoriteMedia$Params, context?: HttpContext): Observable<StrictHttpResponse<GetUserFavoriteMediaResponse>> {
  const rb = new RequestBuilder(rootUrl, userServiceGetUserFavoriteMedia.PATH, 'get');
  if (params) {
    rb.path('userUuid', params.userUuid, {});
    rb.query('discordId', params.discordId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<GetUserFavoriteMediaResponse>;
    })
  );
}

userServiceGetUserFavoriteMedia.PATH = '/api/v3/gateway/user/{userUuid}/favorite/media';
