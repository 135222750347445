<footer class="footer">
    <div class="columns is-centered">
      <div class="column is-half">
        <p>
          <a [routerLink]="['/policies']">Privacy Policy</a>
        </p>
        <p>
          <a [routerLink]="['/policies']">Terms of Service</a>
        </p>
      </div>
  </div>
</footer>