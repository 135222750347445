import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { AuthService, User } from './auth.service';
import { UserAccessRights } from '../openapi/models';
import { USER_ACCESS_RIGHTS } from '../openapi/models/user-access-rights-array';

@Injectable(
  { providedIn: 'root' }
)
export class AuthGuard {
  constructor(public auth: AuthService, public router: Router) { }

  canActivateAnd(...wantRights: UserAccessRights[]): Observable<boolean> {
    return this.auth.user$.pipe(
      map(u => {
        if (!u) {
          this.router.navigate(['']);
          return false;
        }

        if (wantRights.length === 0) {
          return true;
        }

        if (isSuperUser(u)) {
          return true;
        }

        for (const right of wantRights) {
          if (!u.rights.includes(getProtoFieldNumberForUserAccessRights(right))) {
            this.router.navigate(['']);
            return false;
          }
        }
        return true;
      }),
    )
  }

  canActivateOr(...wantRights: UserAccessRights[]): Observable<boolean> {
    return this.auth.user$.pipe(
      map(u => {
        if (!u) {
          this.router.navigate(['']);
          return false;
        }

        if (wantRights.length === 0) {
          return true;
        }

        if (isSuperUser(u)) {
          return true;
        }

        for (const right of wantRights) {
          if (u.rights.includes(getProtoFieldNumberForUserAccessRights(right))) {
            return true;
          }
        }
        this.router.navigate(['']);
        return false;
      }),
    )
  }
}

function isSuperUser(u: User): boolean {
  return u.rights.includes(getProtoFieldNumberForUserAccessRights("SUPERUSER"));
}

export function getProtoFieldNumberForUserAccessRights(right: UserAccessRights): number {
  return USER_ACCESS_RIGHTS.indexOf(right);
}