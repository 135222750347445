/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ApiHttpBody } from '../models/api-http-body';
import { AttachGachaEnhancementToGachaFromBackpackResponse } from '../models/attach-gacha-enhancement-to-gacha-from-backpack-response';
import { gachaServiceAttachGachaEnhancementToGachaFromBackpack } from '../fn/gacha-service/gacha-service-attach-gacha-enhancement-to-gacha-from-backpack';
import { GachaServiceAttachGachaEnhancementToGachaFromBackpack$Params } from '../fn/gacha-service/gacha-service-attach-gacha-enhancement-to-gacha-from-backpack';
import { gachaServiceGenerateGachaInstanceImage } from '../fn/gacha-service/gacha-service-generate-gacha-instance-image';
import { GachaServiceGenerateGachaInstanceImage$Params } from '../fn/gacha-service/gacha-service-generate-gacha-instance-image';
import { gachaServiceGetAllGachaForMedia } from '../fn/gacha-service/gacha-service-get-all-gacha-for-media';
import { GachaServiceGetAllGachaForMedia$Params } from '../fn/gacha-service/gacha-service-get-all-gacha-for-media';
import { gachaServiceGetBanners } from '../fn/gacha-service/gacha-service-get-banners';
import { GachaServiceGetBanners$Params } from '../fn/gacha-service/gacha-service-get-banners';
import { gachaServiceGetGacha } from '../fn/gacha-service/gacha-service-get-gacha';
import { GachaServiceGetGacha$Params } from '../fn/gacha-service/gacha-service-get-gacha';
import { gachaServiceGetGacha2 } from '../fn/gacha-service/gacha-service-get-gacha-2';
import { GachaServiceGetGacha2$Params } from '../fn/gacha-service/gacha-service-get-gacha-2';
import { gachaServiceGetGachaEnhancementExamples } from '../fn/gacha-service/gacha-service-get-gacha-enhancement-examples';
import { GachaServiceGetGachaEnhancementExamples$Params } from '../fn/gacha-service/gacha-service-get-gacha-enhancement-examples';
import { gachaServiceGetGachaImageUrl } from '../fn/gacha-service/gacha-service-get-gacha-image-url';
import { GachaServiceGetGachaImageUrl$Params } from '../fn/gacha-service/gacha-service-get-gacha-image-url';
import { gachaServiceGetGachaInstance } from '../fn/gacha-service/gacha-service-get-gacha-instance';
import { GachaServiceGetGachaInstance$Params } from '../fn/gacha-service/gacha-service-get-gacha-instance';
import { gachaServiceGetGachaMinted } from '../fn/gacha-service/gacha-service-get-gacha-minted';
import { GachaServiceGetGachaMinted$Params } from '../fn/gacha-service/gacha-service-get-gacha-minted';
import { gachaServiceGetGachaMinted2 } from '../fn/gacha-service/gacha-service-get-gacha-minted-2';
import { GachaServiceGetGachaMinted2$Params } from '../fn/gacha-service/gacha-service-get-gacha-minted-2';
import { gachaServiceGetGachaOwners } from '../fn/gacha-service/gacha-service-get-gacha-owners';
import { GachaServiceGetGachaOwners$Params } from '../fn/gacha-service/gacha-service-get-gacha-owners';
import { gachaServiceGetGachasCountForFilter } from '../fn/gacha-service/gacha-service-get-gachas-count-for-filter';
import { GachaServiceGetGachasCountForFilter$Params } from '../fn/gacha-service/gacha-service-get-gachas-count-for-filter';
import { gachaServiceGetGachasPaginated } from '../fn/gacha-service/gacha-service-get-gachas-paginated';
import { GachaServiceGetGachasPaginated$Params } from '../fn/gacha-service/gacha-service-get-gachas-paginated';
import { gachaServiceGetGachaWithAltArt } from '../fn/gacha-service/gacha-service-get-gacha-with-alt-art';
import { GachaServiceGetGachaWithAltArt$Params } from '../fn/gacha-service/gacha-service-get-gacha-with-alt-art';
import { gachaServiceGetNumberOfGacha } from '../fn/gacha-service/gacha-service-get-number-of-gacha';
import { GachaServiceGetNumberOfGacha$Params } from '../fn/gacha-service/gacha-service-get-number-of-gacha';
import { gachaServiceGetNumberOfGacha2 } from '../fn/gacha-service/gacha-service-get-number-of-gacha-2';
import { GachaServiceGetNumberOfGacha2$Params } from '../fn/gacha-service/gacha-service-get-number-of-gacha-2';
import { gachaServiceGetRandomGacha } from '../fn/gacha-service/gacha-service-get-random-gacha';
import { GachaServiceGetRandomGacha$Params } from '../fn/gacha-service/gacha-service-get-random-gacha';
import { gachaServiceGetStatistics } from '../fn/gacha-service/gacha-service-get-statistics';
import { GachaServiceGetStatistics$Params } from '../fn/gacha-service/gacha-service-get-statistics';
import { gachaServiceLiveGachaFeed } from '../fn/gacha-service/gacha-service-live-gacha-feed';
import { GachaServiceLiveGachaFeed$Params } from '../fn/gacha-service/gacha-service-live-gacha-feed';
import { gachaServiceLiveGachaFeed2 } from '../fn/gacha-service/gacha-service-live-gacha-feed-2';
import { GachaServiceLiveGachaFeed2$Params } from '../fn/gacha-service/gacha-service-live-gacha-feed-2';
import { gachaServiceMarkTradeableStatus } from '../fn/gacha-service/gacha-service-mark-tradeable-status';
import { GachaServiceMarkTradeableStatus$Params } from '../fn/gacha-service/gacha-service-mark-tradeable-status';
import { gachaServiceMarkTradeableStatus2 } from '../fn/gacha-service/gacha-service-mark-tradeable-status-2';
import { GachaServiceMarkTradeableStatus2$Params } from '../fn/gacha-service/gacha-service-mark-tradeable-status-2';
import { gachaServiceSearchUserGacha } from '../fn/gacha-service/gacha-service-search-user-gacha';
import { GachaServiceSearchUserGacha$Params } from '../fn/gacha-service/gacha-service-search-user-gacha';
import { GetAllGachaForMediaResponse } from '../models/get-all-gacha-for-media-response';
import { GetBannersResponse } from '../models/get-banners-response';
import { GetGachaEnhancementExamplesResponse } from '../models/get-gacha-enhancement-examples-response';
import { GetGachaImageUrlResponse } from '../models/get-gacha-image-url-response';
import { GetGachaInstanceResponse } from '../models/get-gacha-instance-response';
import { GetGachaMintedResponse } from '../models/get-gacha-minted-response';
import { GetGachaOwnersResponse } from '../models/get-gacha-owners-response';
import { GetGachaResponse } from '../models/get-gacha-response';
import { GetGachasCountForFilterResponse } from '../models/get-gachas-count-for-filter-response';
import { GetGachasPaginatedResponse } from '../models/get-gachas-paginated-response';
import { GetGachaWithAltArtResponse } from '../models/get-gacha-with-alt-art-response';
import { GetNumberOfGachaResponse } from '../models/get-number-of-gacha-response';
import { GetRandomGachaResponse } from '../models/get-random-gacha-response';
import { GetStatisticsResponse } from '../models/get-statistics-response';
import { MarkTradeableStatusResponse } from '../models/mark-tradeable-status-response';
import { SearchUserGachaResponse } from '../models/search-user-gacha-response';
import { StreamResultOfLiveGachaFeedResponse } from '../models/stream-result-of-live-gacha-feed-response';

@Injectable({ providedIn: 'root' })
export class GachaServiceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `gachaServiceGetBanners()` */
  static readonly GachaServiceGetBannersPath = '/api/v3/gateway/banners/gacha';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gachaServiceGetBanners()` instead.
   *
   * This method doesn't expect any request body.
   */
  gachaServiceGetBanners$Response(params?: GachaServiceGetBanners$Params, context?: HttpContext): Observable<StrictHttpResponse<GetBannersResponse>> {
    return gachaServiceGetBanners(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gachaServiceGetBanners$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  gachaServiceGetBanners(params?: GachaServiceGetBanners$Params, context?: HttpContext): Observable<GetBannersResponse> {
    return this.gachaServiceGetBanners$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetBannersResponse>): GetBannersResponse => r.body)
    );
  }

  /** Path part for operation `gachaServiceGetGacha()` */
  static readonly GachaServiceGetGachaPath = '/api/v3/gateway/gacha';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gachaServiceGetGacha()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gachaServiceGetGacha$Response(params: GachaServiceGetGacha$Params, context?: HttpContext): Observable<StrictHttpResponse<GetGachaResponse>> {
    return gachaServiceGetGacha(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gachaServiceGetGacha$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gachaServiceGetGacha(params: GachaServiceGetGacha$Params, context?: HttpContext): Observable<GetGachaResponse> {
    return this.gachaServiceGetGacha$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetGachaResponse>): GetGachaResponse => r.body)
    );
  }

  /** Path part for operation `gachaServiceGetGachaWithAltArt()` */
  static readonly GachaServiceGetGachaWithAltArtPath = '/api/v3/gateway/gacha/altart/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gachaServiceGetGachaWithAltArt()` instead.
   *
   * This method doesn't expect any request body.
   */
  gachaServiceGetGachaWithAltArt$Response(params?: GachaServiceGetGachaWithAltArt$Params, context?: HttpContext): Observable<StrictHttpResponse<GetGachaWithAltArtResponse>> {
    return gachaServiceGetGachaWithAltArt(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gachaServiceGetGachaWithAltArt$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  gachaServiceGetGachaWithAltArt(params?: GachaServiceGetGachaWithAltArt$Params, context?: HttpContext): Observable<GetGachaWithAltArtResponse> {
    return this.gachaServiceGetGachaWithAltArt$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetGachaWithAltArtResponse>): GetGachaWithAltArtResponse => r.body)
    );
  }

  /** Path part for operation `gachaServiceGetNumberOfGacha()` */
  static readonly GachaServiceGetNumberOfGachaPath = '/api/v3/gateway/gacha/copies';

  /**
   * Returns a mapping of each gacha in the request to the number that it exists for all users.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gachaServiceGetNumberOfGacha()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gachaServiceGetNumberOfGacha$Response(params: GachaServiceGetNumberOfGacha$Params, context?: HttpContext): Observable<StrictHttpResponse<GetNumberOfGachaResponse>> {
    return gachaServiceGetNumberOfGacha(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns a mapping of each gacha in the request to the number that it exists for all users.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gachaServiceGetNumberOfGacha$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gachaServiceGetNumberOfGacha(params: GachaServiceGetNumberOfGacha$Params, context?: HttpContext): Observable<GetNumberOfGachaResponse> {
    return this.gachaServiceGetNumberOfGacha$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetNumberOfGachaResponse>): GetNumberOfGachaResponse => r.body)
    );
  }

  /** Path part for operation `gachaServiceGetNumberOfGacha2()` */
  static readonly GachaServiceGetNumberOfGacha2Path = '/api/v3/gateway/gacha/copies/{gachaId}';

  /**
   * Returns a mapping of each gacha in the request to the number that it exists for all users.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gachaServiceGetNumberOfGacha2()` instead.
   *
   * This method doesn't expect any request body.
   */
  gachaServiceGetNumberOfGacha2$Response(params: GachaServiceGetNumberOfGacha2$Params, context?: HttpContext): Observable<StrictHttpResponse<GetNumberOfGachaResponse>> {
    return gachaServiceGetNumberOfGacha2(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns a mapping of each gacha in the request to the number that it exists for all users.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gachaServiceGetNumberOfGacha2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  gachaServiceGetNumberOfGacha2(params: GachaServiceGetNumberOfGacha2$Params, context?: HttpContext): Observable<GetNumberOfGachaResponse> {
    return this.gachaServiceGetNumberOfGacha2$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetNumberOfGachaResponse>): GetNumberOfGachaResponse => r.body)
    );
  }

  /** Path part for operation `gachaServiceGetGachaEnhancementExamples()` */
  static readonly GachaServiceGetGachaEnhancementExamplesPath = '/api/v3/gateway/gacha/enhancements';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gachaServiceGetGachaEnhancementExamples()` instead.
   *
   * This method doesn't expect any request body.
   */
  gachaServiceGetGachaEnhancementExamples$Response(params?: GachaServiceGetGachaEnhancementExamples$Params, context?: HttpContext): Observable<StrictHttpResponse<GetGachaEnhancementExamplesResponse>> {
    return gachaServiceGetGachaEnhancementExamples(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gachaServiceGetGachaEnhancementExamples$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  gachaServiceGetGachaEnhancementExamples(params?: GachaServiceGetGachaEnhancementExamples$Params, context?: HttpContext): Observable<GetGachaEnhancementExamplesResponse> {
    return this.gachaServiceGetGachaEnhancementExamples$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetGachaEnhancementExamplesResponse>): GetGachaEnhancementExamplesResponse => r.body)
    );
  }

  /** Path part for operation `gachaServiceGetGachaInstance()` */
  static readonly GachaServiceGetGachaInstancePath = '/api/v3/gateway/gacha/instance/{gachaInstanceId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gachaServiceGetGachaInstance()` instead.
   *
   * This method doesn't expect any request body.
   */
  gachaServiceGetGachaInstance$Response(params: GachaServiceGetGachaInstance$Params, context?: HttpContext): Observable<StrictHttpResponse<GetGachaInstanceResponse>> {
    return gachaServiceGetGachaInstance(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gachaServiceGetGachaInstance$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  gachaServiceGetGachaInstance(params: GachaServiceGetGachaInstance$Params, context?: HttpContext): Observable<GetGachaInstanceResponse> {
    return this.gachaServiceGetGachaInstance$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetGachaInstanceResponse>): GetGachaInstanceResponse => r.body)
    );
  }

  /** Path part for operation `gachaServiceGenerateGachaInstanceImage()` */
  static readonly GachaServiceGenerateGachaInstanceImagePath = '/api/v3/gateway/gacha/instance/{gachaInstanceId}/image';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gachaServiceGenerateGachaInstanceImage()` instead.
   *
   * This method doesn't expect any request body.
   */
  gachaServiceGenerateGachaInstanceImage$Response(params: GachaServiceGenerateGachaInstanceImage$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiHttpBody>> {
    return gachaServiceGenerateGachaInstanceImage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gachaServiceGenerateGachaInstanceImage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  gachaServiceGenerateGachaInstanceImage(params: GachaServiceGenerateGachaInstanceImage$Params, context?: HttpContext): Observable<ApiHttpBody> {
    return this.gachaServiceGenerateGachaInstanceImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiHttpBody>): ApiHttpBody => r.body)
    );
  }

  /** Path part for operation `gachaServiceGetGachaMinted()` */
  static readonly GachaServiceGetGachaMintedPath = '/api/v3/gateway/gacha/minted';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gachaServiceGetGachaMinted()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gachaServiceGetGachaMinted$Response(params: GachaServiceGetGachaMinted$Params, context?: HttpContext): Observable<StrictHttpResponse<GetGachaMintedResponse>> {
    return gachaServiceGetGachaMinted(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gachaServiceGetGachaMinted$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gachaServiceGetGachaMinted(params: GachaServiceGetGachaMinted$Params, context?: HttpContext): Observable<GetGachaMintedResponse> {
    return this.gachaServiceGetGachaMinted$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetGachaMintedResponse>): GetGachaMintedResponse => r.body)
    );
  }

  /** Path part for operation `gachaServiceGetGachaMinted2()` */
  static readonly GachaServiceGetGachaMinted2Path = '/api/v3/gateway/gacha/minted/{gachaIds}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gachaServiceGetGachaMinted2()` instead.
   *
   * This method doesn't expect any request body.
   */
  gachaServiceGetGachaMinted2$Response(params: GachaServiceGetGachaMinted2$Params, context?: HttpContext): Observable<StrictHttpResponse<GetGachaMintedResponse>> {
    return gachaServiceGetGachaMinted2(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gachaServiceGetGachaMinted2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  gachaServiceGetGachaMinted2(params: GachaServiceGetGachaMinted2$Params, context?: HttpContext): Observable<GetGachaMintedResponse> {
    return this.gachaServiceGetGachaMinted2$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetGachaMintedResponse>): GetGachaMintedResponse => r.body)
    );
  }

  /** Path part for operation `gachaServiceGetRandomGacha()` */
  static readonly GachaServiceGetRandomGachaPath = '/api/v3/gateway/gacha/random';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gachaServiceGetRandomGacha()` instead.
   *
   * This method doesn't expect any request body.
   */
  gachaServiceGetRandomGacha$Response(params?: GachaServiceGetRandomGacha$Params, context?: HttpContext): Observable<StrictHttpResponse<GetRandomGachaResponse>> {
    return gachaServiceGetRandomGacha(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gachaServiceGetRandomGacha$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  gachaServiceGetRandomGacha(params?: GachaServiceGetRandomGacha$Params, context?: HttpContext): Observable<GetRandomGachaResponse> {
    return this.gachaServiceGetRandomGacha$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetRandomGachaResponse>): GetRandomGachaResponse => r.body)
    );
  }

  /** Path part for operation `gachaServiceGetGacha2()` */
  static readonly GachaServiceGetGacha2Path = '/api/v3/gateway/gacha/{gachaIds}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gachaServiceGetGacha2()` instead.
   *
   * This method doesn't expect any request body.
   */
  gachaServiceGetGacha2$Response(params: GachaServiceGetGacha2$Params, context?: HttpContext): Observable<StrictHttpResponse<GetGachaResponse>> {
    return gachaServiceGetGacha2(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gachaServiceGetGacha2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  gachaServiceGetGacha2(params: GachaServiceGetGacha2$Params, context?: HttpContext): Observable<GetGachaResponse> {
    return this.gachaServiceGetGacha2$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetGachaResponse>): GetGachaResponse => r.body)
    );
  }

  /** Path part for operation `gachaServiceGetGachaImageUrl()` */
  static readonly GachaServiceGetGachaImageUrlPath = '/api/v3/gateway/gacha/{gachaId}/image';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gachaServiceGetGachaImageUrl()` instead.
   *
   * This method doesn't expect any request body.
   */
  gachaServiceGetGachaImageUrl$Response(params: GachaServiceGetGachaImageUrl$Params, context?: HttpContext): Observable<StrictHttpResponse<GetGachaImageUrlResponse>> {
    return gachaServiceGetGachaImageUrl(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gachaServiceGetGachaImageUrl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  gachaServiceGetGachaImageUrl(params: GachaServiceGetGachaImageUrl$Params, context?: HttpContext): Observable<GetGachaImageUrlResponse> {
    return this.gachaServiceGetGachaImageUrl$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetGachaImageUrlResponse>): GetGachaImageUrlResponse => r.body)
    );
  }

  /** Path part for operation `gachaServiceGetGachaOwners()` */
  static readonly GachaServiceGetGachaOwnersPath = '/api/v3/gateway/gacha/{gachaId}/owners';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gachaServiceGetGachaOwners()` instead.
   *
   * This method doesn't expect any request body.
   */
  gachaServiceGetGachaOwners$Response(params: GachaServiceGetGachaOwners$Params, context?: HttpContext): Observable<StrictHttpResponse<GetGachaOwnersResponse>> {
    return gachaServiceGetGachaOwners(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gachaServiceGetGachaOwners$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  gachaServiceGetGachaOwners(params: GachaServiceGetGachaOwners$Params, context?: HttpContext): Observable<GetGachaOwnersResponse> {
    return this.gachaServiceGetGachaOwners$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetGachaOwnersResponse>): GetGachaOwnersResponse => r.body)
    );
  }

  /** Path part for operation `gachaServiceGetGachasPaginated()` */
  static readonly GachaServiceGetGachasPaginatedPath = '/api/v3/gateway/media/{mediaId}/gacha';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gachaServiceGetGachasPaginated()` instead.
   *
   * This method doesn't expect any request body.
   */
  gachaServiceGetGachasPaginated$Response(params: GachaServiceGetGachasPaginated$Params, context?: HttpContext): Observable<StrictHttpResponse<GetGachasPaginatedResponse>> {
    return gachaServiceGetGachasPaginated(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gachaServiceGetGachasPaginated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  gachaServiceGetGachasPaginated(params: GachaServiceGetGachasPaginated$Params, context?: HttpContext): Observable<GetGachasPaginatedResponse> {
    return this.gachaServiceGetGachasPaginated$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetGachasPaginatedResponse>): GetGachasPaginatedResponse => r.body)
    );
  }

  /** Path part for operation `gachaServiceGetAllGachaForMedia()` */
  static readonly GachaServiceGetAllGachaForMediaPath = '/api/v3/gateway/media/{mediaId}/gacha/all';

  /**
   * TODO: This should be used sparingly.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gachaServiceGetAllGachaForMedia()` instead.
   *
   * This method doesn't expect any request body.
   */
  gachaServiceGetAllGachaForMedia$Response(params: GachaServiceGetAllGachaForMedia$Params, context?: HttpContext): Observable<StrictHttpResponse<GetAllGachaForMediaResponse>> {
    return gachaServiceGetAllGachaForMedia(this.http, this.rootUrl, params, context);
  }

  /**
   * TODO: This should be used sparingly.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gachaServiceGetAllGachaForMedia$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  gachaServiceGetAllGachaForMedia(params: GachaServiceGetAllGachaForMedia$Params, context?: HttpContext): Observable<GetAllGachaForMediaResponse> {
    return this.gachaServiceGetAllGachaForMedia$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetAllGachaForMediaResponse>): GetAllGachaForMediaResponse => r.body)
    );
  }

  /** Path part for operation `gachaServiceGetGachasCountForFilter()` */
  static readonly GachaServiceGetGachasCountForFilterPath = '/api/v3/gateway/media/{mediaId}/gacha/count';

  /**
   * Basically, paired with the above RPC we can get an estimate of the total without having to 
   * paginate through all of them.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gachaServiceGetGachasCountForFilter()` instead.
   *
   * This method doesn't expect any request body.
   */
  gachaServiceGetGachasCountForFilter$Response(params: GachaServiceGetGachasCountForFilter$Params, context?: HttpContext): Observable<StrictHttpResponse<GetGachasCountForFilterResponse>> {
    return gachaServiceGetGachasCountForFilter(this.http, this.rootUrl, params, context);
  }

  /**
   * Basically, paired with the above RPC we can get an estimate of the total without having to 
   * paginate through all of them.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gachaServiceGetGachasCountForFilter$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  gachaServiceGetGachasCountForFilter(params: GachaServiceGetGachasCountForFilter$Params, context?: HttpContext): Observable<GetGachasCountForFilterResponse> {
    return this.gachaServiceGetGachasCountForFilter$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetGachasCountForFilterResponse>): GetGachasCountForFilterResponse => r.body)
    );
  }

  /** Path part for operation `gachaServiceGetStatistics()` */
  static readonly GachaServiceGetStatisticsPath = '/api/v3/gateway/statistics';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gachaServiceGetStatistics()` instead.
   *
   * This method doesn't expect any request body.
   */
  gachaServiceGetStatistics$Response(params?: GachaServiceGetStatistics$Params, context?: HttpContext): Observable<StrictHttpResponse<GetStatisticsResponse>> {
    return gachaServiceGetStatistics(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gachaServiceGetStatistics$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  gachaServiceGetStatistics(params?: GachaServiceGetStatistics$Params, context?: HttpContext): Observable<GetStatisticsResponse> {
    return this.gachaServiceGetStatistics$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetStatisticsResponse>): GetStatisticsResponse => r.body)
    );
  }

  /** Path part for operation `gachaServiceSearchUserGacha()` */
  static readonly GachaServiceSearchUserGachaPath = '/api/v3/gateway/user/{userUuid}/gacha/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gachaServiceSearchUserGacha()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gachaServiceSearchUserGacha$Response(params: GachaServiceSearchUserGacha$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchUserGachaResponse>> {
    return gachaServiceSearchUserGacha(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gachaServiceSearchUserGacha$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gachaServiceSearchUserGacha(params: GachaServiceSearchUserGacha$Params, context?: HttpContext): Observable<SearchUserGachaResponse> {
    return this.gachaServiceSearchUserGacha$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchUserGachaResponse>): SearchUserGachaResponse => r.body)
    );
  }

  /** Path part for operation `gachaServiceMarkTradeableStatus()` */
  static readonly GachaServiceMarkTradeableStatusPath = '/api/v3/gateway/user/{userUuid}/gacha/{gachaId}/tradeable';

  /**
   * TODO: Move the optional attribute database traits to a better rpc (one that manages all of them)
   * Mark a user's gacha as not tradable.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gachaServiceMarkTradeableStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gachaServiceMarkTradeableStatus$Response(params: GachaServiceMarkTradeableStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<MarkTradeableStatusResponse>> {
    return gachaServiceMarkTradeableStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * TODO: Move the optional attribute database traits to a better rpc (one that manages all of them)
   * Mark a user's gacha as not tradable.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gachaServiceMarkTradeableStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gachaServiceMarkTradeableStatus(params: GachaServiceMarkTradeableStatus$Params, context?: HttpContext): Observable<MarkTradeableStatusResponse> {
    return this.gachaServiceMarkTradeableStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<MarkTradeableStatusResponse>): MarkTradeableStatusResponse => r.body)
    );
  }

  /** Path part for operation `gachaServiceAttachGachaEnhancementToGachaFromBackpack()` */
  static readonly GachaServiceAttachGachaEnhancementToGachaFromBackpackPath = '/api/v3/gateway/user/{userUuid}/gacha/{gachaInstanceId}/enhancement';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gachaServiceAttachGachaEnhancementToGachaFromBackpack()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gachaServiceAttachGachaEnhancementToGachaFromBackpack$Response(params: GachaServiceAttachGachaEnhancementToGachaFromBackpack$Params, context?: HttpContext): Observable<StrictHttpResponse<AttachGachaEnhancementToGachaFromBackpackResponse>> {
    return gachaServiceAttachGachaEnhancementToGachaFromBackpack(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gachaServiceAttachGachaEnhancementToGachaFromBackpack$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gachaServiceAttachGachaEnhancementToGachaFromBackpack(params: GachaServiceAttachGachaEnhancementToGachaFromBackpack$Params, context?: HttpContext): Observable<AttachGachaEnhancementToGachaFromBackpackResponse> {
    return this.gachaServiceAttachGachaEnhancementToGachaFromBackpack$Response(params, context).pipe(
      map((r: StrictHttpResponse<AttachGachaEnhancementToGachaFromBackpackResponse>): AttachGachaEnhancementToGachaFromBackpackResponse => r.body)
    );
  }

  /** Path part for operation `gachaServiceMarkTradeableStatus2()` */
  static readonly GachaServiceMarkTradeableStatus2Path = '/api/v3/gateway/user/{userUuid}/media/{mediaId}/tradeable';

  /**
   * TODO: Move the optional attribute database traits to a better rpc (one that manages all of them)
   * Mark a user's gacha as not tradable.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gachaServiceMarkTradeableStatus2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gachaServiceMarkTradeableStatus2$Response(params: GachaServiceMarkTradeableStatus2$Params, context?: HttpContext): Observable<StrictHttpResponse<MarkTradeableStatusResponse>> {
    return gachaServiceMarkTradeableStatus2(this.http, this.rootUrl, params, context);
  }

  /**
   * TODO: Move the optional attribute database traits to a better rpc (one that manages all of them)
   * Mark a user's gacha as not tradable.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gachaServiceMarkTradeableStatus2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gachaServiceMarkTradeableStatus2(params: GachaServiceMarkTradeableStatus2$Params, context?: HttpContext): Observable<MarkTradeableStatusResponse> {
    return this.gachaServiceMarkTradeableStatus2$Response(params, context).pipe(
      map((r: StrictHttpResponse<MarkTradeableStatusResponse>): MarkTradeableStatusResponse => r.body)
    );
  }

  /** Path part for operation `gachaServiceLiveGachaFeed()` */
  static readonly GachaServiceLiveGachaFeedPath = '/api/v3/gateway/ws/gacha/live';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gachaServiceLiveGachaFeed()` instead.
   *
   * This method doesn't expect any request body.
   */
  gachaServiceLiveGachaFeed$Response(params?: GachaServiceLiveGachaFeed$Params, context?: HttpContext): Observable<StrictHttpResponse<StreamResultOfLiveGachaFeedResponse>> {
    return gachaServiceLiveGachaFeed(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gachaServiceLiveGachaFeed$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  gachaServiceLiveGachaFeed(params?: GachaServiceLiveGachaFeed$Params, context?: HttpContext): Observable<StreamResultOfLiveGachaFeedResponse> {
    return this.gachaServiceLiveGachaFeed$Response(params, context).pipe(
      map((r: StrictHttpResponse<StreamResultOfLiveGachaFeedResponse>): StreamResultOfLiveGachaFeedResponse => r.body)
    );
  }

  /** Path part for operation `gachaServiceLiveGachaFeed2()` */
  static readonly GachaServiceLiveGachaFeed2Path = '/api/v3/gateway/ws/gacha/live';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gachaServiceLiveGachaFeed2()` instead.
   *
   * This method doesn't expect any request body.
   */
  gachaServiceLiveGachaFeed2$Response(params?: GachaServiceLiveGachaFeed2$Params, context?: HttpContext): Observable<StrictHttpResponse<StreamResultOfLiveGachaFeedResponse>> {
    return gachaServiceLiveGachaFeed2(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gachaServiceLiveGachaFeed2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  gachaServiceLiveGachaFeed2(params?: GachaServiceLiveGachaFeed2$Params, context?: HttpContext): Observable<StreamResultOfLiveGachaFeedResponse> {
    return this.gachaServiceLiveGachaFeed2$Response(params, context).pipe(
      map((r: StrictHttpResponse<StreamResultOfLiveGachaFeedResponse>): StreamResultOfLiveGachaFeedResponse => r.body)
    );
  }

}
