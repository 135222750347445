<div class="container">
  <div class="tabs is-medium is-boxed">
    <ul>
      <li [class.is-active]="tabName == 'quickstart'">
        <a (click)="selectTab('quickstart')">Quickstart</a>
      </li>
      <li [class.is-active]="tabName == 'rolling'">
        <a (click)="selectTab('rolling')">Rolling</a>
      </li>
      <li [class.is-active]="tabName == 'duplicates'">
        <a (click)="selectTab('duplicates')">Duplicates</a>
      </li>
      <li [class.is-active]="tabName == 'sacrificing'">
        <a (click)="selectTab('sacrificing')">Sacrificing</a>
      </li>
      <li [class.is-active]="tabName == 'stardustExchange'">
        <a (click)="selectTab('stardustExchange')">Stardust Exchange</a>
      </li>
      <li [class.is-active]="tabName == 'quests'">
        <a (click)="selectTab('quests')">Quests</a>
      </li>
      <li [class.is-active]="tabName == 'drops'">
        <a (click)="selectTab('drops')">Drops</a>
      </li>
      <li [class.is-active]="tabName == 'altart'">
        <a (click)="selectTab('altart')">Alternate Art</a>
      </li>
      <li [class.is-active]="tabName == 'trading'">
        <a (click)="selectTab('trading')">Trading</a>
      </li>
    </ul>
  </div>

  <div *ngIf="selectedTab" class="content">
    <ng-container *ngTemplateOutlet="selectedTab"></ng-container>
  </div>

  <ng-template #quickstart>
    <div class="content">
      <section class="block">
        <h1>1) Invite the bot</h1>
        <p>
          Use the button below to invite the bot to your server. You must have the
          <code>Manage Server</code> permission to do so.
        </p>
        <section>
          <a target="_blank" [routerLink]="['/invite']" style="text-decoration: none;">
            <button class="button is-primary">
              Invite Upa
            </button>
          </a>
        </section>
      </section>

      <section class="block">
        <h1>2) Use the <code>/roll</code> command!</h1>
        <p>
          You're ready to go! If you need more help, check out the other tabs. Be sure to use
          the <code>/help</code> command to see all the commands available.
        </p>
      </section>
    </div>
  </ng-template>

  <ng-template #rolling>
    <div class="content">
      <h1>Rolling</h1>
      <div class="tile is-ancestor">
        <div class="tile is-6 is-vertical is-parent">
          <div class="tile is-child box">
            <h2>
              How do rolling and claiming work?
            </h2>
            <p>
              Rolling has several limits. Simply put, you have a limited number of <em>rolls</em> per hour.
              You also have a limited number of <em>claims</em> per hour. You can check your stats with
              <code>/me</code>.
            </p>
          </div>
          <div class="tile is-child box">
            <h2>
              Can someone else steal my roll (or can I steal others)?
            </h2>
            <p>
              Yes! But after a grace period of 1 minute. That means if you don't see what you want,
              feel free to keep rolling (but be wary of snipers!)
            </p>
          </div>
          <div class="tile is-child box">
            <h2>
              What's a banner?
            </h2>
            <p>
              A banner, also called a <em>rate-up event</em>, is a special roll where the chance of rolling specific
              gacha characters is enhanced. You can view all of our currently running banners
              <a [routerLink]="['/view/banners']">here</a>, and roll on them using <code>/rollbanner</code>.
            </p>
          </div>
        </div>
        <div class="tile is-6 is-vertical is-parent">
          <div class="tile is-child box">
            <h2>
              When do my rolls and claims reset?
            </h2>
            <p>
              They reset every hour on the hour. If you roll at 10:00AM, they don't reset until 11:00AM.
              Conversely, if you roll at 10:59AM, they reset at 11:00AM.
            </p>
          </div>
          <div class="tile is-child box">
            <h2>
              How do I get more rolls?
            </h2>
            <p>
              There are several ways! The easiest is to <a [routerLink]="['/server']">join our community server</a> for
              a buff!<br />
              You can accrue Stardust via quests, and spend them in the <em>Stardust Exchange</em>.<br />
              You can also help the bot by voting and get free stardust, rolls, or claims - check out the
              <code>/vote</code> command!<br />
              Your bot-usage will soon be rewarded with more rolls and claims, so keep playing with Upa!
            </p>
          </div>
        </div>

      </div>
    </div>
  </ng-template>

  <ng-template #duplicates>
    <div class="tile is-ancestor">
      <div class="tile is-5 is-vertical is-parent">
        <div class="tile is-child box">
          <h2>Rolling a Duplicate</h2>
          <p>
            Rolling a duplicate gives you a choice to either <em>level up</em> your gacha or to <em>stardust</em> it.
          </p>
          <div class="notification is-danger is-light">
            Note that you can only level up or dust a gacha that <em>you rolled</em>.
          </div>
        </div>

        <div class="tile is-child box">
          <h2>
            Level up
          </h2>
          <p>
            Gacha have 3 levels, represented by 3 different star icons: ⭐, ✨, 🌟. Each duplicate you get
            allows you to level-up your gacha to the next tier, until it's maxed at level 3. Leveling up
            is a cosmetic addition, but there may be benefits (and cosmetics) coming in the future!
          </p>
        </div>

        <div class="tile is-child box">
          <h2>Stardust</h2>
          <p>
            You can turn your duplicate rolls into stardust! The amount you get depends on the rarity. Stardust
            is then used in the bot shop, accessed through <code>/stardustexchange</code>
          </p>
        </div>
      </div>
      <div class="tile is-7 is-vertical is-parent">
        <div class="tile is-child box">
          <img src="/assets/duplicate.png">
        </div>

        <div class="tile is-child box">
          <img src="/assets/levelup.png">
        </div>

        <div class="tile is-child box">
          <img src="/assets/dustgacha.png">
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #stardustExchange>
    <h1>The shop - Stardust Exchange</h1>
    <div class="tile is-ancestor">
      <div class="tile is-5 is-vertical is-parent">
        <div class="tile is-child box">
          <h2>
            Getting dust
          </h2>
          <p>
            The main way of getting stardust, our currency, is through rolling duplicates and completing quests.
          </p>

          <h2>
            Spending dust
          </h2>
          <p>
            You can open the shop with <code>/stardustexchange</code>. We currently have self-buffs - powerups that
            only affect yourself. We're working on buffs per-server, though!
          </p>
        </div>
      </div>
      <div class="tile is-7 is-vertical is-parent">
        <div class="tile is-child box">
          <img src="/assets/stardustexchange.png">
        </div>

      </div>
    </div>
  </ng-template>

  <ng-template #quests>
    <div class="tile is-ancestor">
      <div class="tile is-7 is-vertical is-parent">
        <div class="tile is-child box">
          <h2>Permanent Quests</h2>
          <p>
            Upa features several <em>permanent quests</em>. These are quests that you can complete once per account.
            However, they have several tiers where you get rewards - for example, rolling 1, 5, 10, 25 and onwards.
          </p>
        </div>

        <div class="tile is-child box">
          <h2>
            Repeatable Quests
          </h2>
          <p>
            Several of our quests are also repeatable on a <em>daily, weekly, or monthly</em> basis. These quests
            will reset progress so you can consistently gain stardust!
          </p>
        </div>
      </div>
      <div class="tile is-5 is-vertical is-parent">
        <div class="tile is-child box">
          <img src="/assets/quests.png">
        </div>
        <div class="tile is-child box">
          <img src="/assets/questcomplete.png">
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #drops>
    <div class="tile is-ancestor">
      <div class="tile is-7 is-vertical is-parent">
        <div class="tile is-child box">
          <h2>Setting up Drops</h2>
          <p>
            Drops allows any user with the <code>Manage Channels</code> permission to set up a channel in which we
            randomly drop gacha for users to claim. Use <code>/setdropchannel #channel</code> to do so.
          </p>
          <p>Drops occur roughly every 15 minutes, so you can expect a drop
            4 times an hour. You'll see a boost in rarity depending on how active your server is!
          </p>
        </div>

        <div class="tile is-child box">
          <h2>
            Claiming Drops
          </h2>
          <p>
            Drops <strong>do not reduce your claim count!</strong> However, you can only claim one drop per 10 minutes.
            This helps prevent abuse across servers.
          </p>
          <div class="notification is-danger is-light">
            You cannot dust or level up from drops.
          </div>

        </div>
      </div>
      <div class="tile is-5 is-vertical is-parent">
        <div class="tile is-child box">
          <img src="/assets/drop.png">
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #trading>
    <div class="tile is-ancestor">
      <div class="tile is-7 is-vertical is-parent">
        <div class="tile is-child box">
          <h2>Trading</h2>
          <p>
            You can trade your gacha to others with <code>/trade &#64;user</code>. Trading is a great way to foster a
            community and finish your collection!
          </p>
          <div class="notification is-danger is-light">
            You have to offer at least the same rarity of gacha in a trade to help prevent abuse. However, we're
            looking into removing all trade restrictions :)
          </div>
        </div>

        <div class="tile is-child box">
          <h2>What about my enhancements and minted order?</h2>
          <p>
            Gacha preserve all attributes when traded - that means their enhancement (like a Rainbow Rare), star-level,
            and minted order will be preserved between trades.
          </p>
        </div>

        <div class="tile is-child box">
          <h2>
            How It Works
          </h2>
          <div>
            Trading has several phases.
            <ul>
              <li>
                <strong>Offering</strong> - You and your trade partner offer gacha and stardust to each other.
              </li>
              <li>
                <strong>Accepting</strong> - You and your trade partner accept and lock-in your offer. You cannot modify
                your offer after this point, but you can still decline it.
              </li>
              <li>
                <strong>Confirm</strong> - You and your trade partner confirm the trade. Once both parties confirm, the
                trade is complete!
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="tile is-5 is-vertical is-parent">
        <div class="tile is-child box">
          <img src="/assets/trade_base.png">
        </div>
        <div class="tile is-child box">
          <img src="/assets/trade_search.png">
        </div>
        <div class="tile is-child box">
          <img src="/assets/trade_accepted.png">
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #altart>
    <div class="tile is-ancestor">
      <div class="tile is-6 is-vertical is-parent">
        <div class="tile is-child box">
          <h2>Alternate Art</h2>
          <p>
            Some gacha have alternate art that you can buy and swap in for your gacha to change its appearance.
            You can see the list you can buy with <code>/altart</code> as well as on
            the website at <a [routerLink]="['/view/altart']">Browse Alternate Art.</a>
          </p>
          <div class="notification is-info is-light">
            The <code>/altart</code> command only shows art available for <em>gacha that you own.</em> The best way
            to see all the great art is through the website!
          </div>
        </div>

        <div class="tile is-child box">
          <h2>How do I get alt art tokens?</h2>
          <p>
            Starpowering (consuming duplicate) 5⭐ gacha gives you 5 alt art tokens. It costs 1 token per rarity you want
            to purchase. For example, if you want to buy a 5⭐ alt art, it costs 5 tokens. If you want to buy a 3⭐ alt
            art,
            it costs 3 tokens.
          </p>
        </div>
      </div>
      <div class="tile is-6 is-vertical is-parent">
        <div class="tile is-child box">
          <img src="/assets/altart_basecommand.png">
        </div>
        <div class="tile is-child box has-text-centered">
          <app-gacha-v2 [gacha]="exampleAltArtGacha" [media]="exampleAltArtMedia"></app-gacha-v2>
          <app-gacha-v2 [gacha]="exampleAltArtGachaAltArt" [media]="exampleAltArtMedia"></app-gacha-v2>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #sacrificing>
    <div class="tile is-ancestor">
      <div class="tile is-6 is-vertical is-parent">
        <div class="tile is-child box">
          <h2>Sacrificing Gacha</h2>
          <p>
            You can sacrifice 3 gacha at a time to get a random gacha <strong>that you don't already own.</strong>
            <br />
            Each gacha needs to be <em>the same rarity</em>. The gacha you get back will have a mint value <em>near the
              average</em>
            of what you put in - the bot will make it clear the range you can get back!
          </p>
        </div>

        <div class="tile is-child box">
          <h2>Tutorial</h2>
          <ul>
            <li>Use <code>/sacrifice gacha</code> to start your sacrificial circle</li>
            <li>Click <code>Add Gacha</code> to search your collection for gacha to sacrifice.</li>
            <li>
              Once you have chosen 3 gacha, the bot tells you the range of mints you can receive back.
              Click <code>Sacrifice</code> to see what you get!
            </li>
          </ul>
        </div>
      </div>
      <div class="tile is-6 is-vertical is-parent">
        <div class="tile is-child box">
          <img src="/assets/sacrifice_base.png">
        </div>
        <div class="tile is-child box has-text-centered">
          <img src="/assets/sacrifice_full.png">
        </div>
      </div>
    </div>
  </ng-template>
</div>