import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, map, mergeMap, of } from 'rxjs';

/* created from the go struct:
Name       string `json:"name"`
	Permission string `json:"permission"`
	HelpText   string `json:"helpText"`
	Usage      string `json:"usage"`
  */
export interface command {
  name: string;
  permission: string;
  helpText: string;
  usage: string;
}

@Component({
  selector: 'app-view-commands',
  templateUrl: './view-commands.component.html',
  styleUrls: ['./view-commands.component.scss']
})
export class ViewCommandsComponent implements OnInit {

  commands: Map<string, command[]> = new Map();
  selectedCommand?: command;

  constructor(private httpClient: HttpClient, private route: ActivatedRoute) {
   this.route.data.subscribe(_ => {});
  }

  findCommand(command: string): command | undefined {
    for (const commands of this.commands.values()) {
      for (const c of commands) {
        if (c.name === command) {
          return c;
        }
      }
    }
    return undefined;
  }

  ngOnInit(): void {
    this.httpClient.get("/assets/commands.json").subscribe((data: any) => {
      // data is a map of category (string) -> command[]
      for (const [category, commands] of Object.entries(data)) {
        this.commands.set(category, commands as command[]);
      }
      this.route.params.pipe(map(p => p['command'])).subscribe(command => {
        if (command) {
          this.selectedCommand = this.findCommand(command);
        } else {
          this.selectedCommand = this.findCommand("altart");
        }
      });
    });
  }

  sortCommandKeys(): string[] {
    // Custom sorting based on some general rules
    // Order:
    /*
    gacha
    trading
    general
    guild
    combat
    fun
    admin
    special events
    */
   // Then, just slap everything else at the end
    const order = ["gacha", "trading", "general", "guild", "combat", "fun", "admin", "special events"];
    const keys = Array.from(this.commands.keys());
    keys.sort((a, b) => {
      a = a.toLowerCase();
      b = b.toLowerCase();
      const aIndex = order.indexOf(a);
      const bIndex = order.indexOf(b);
      if (aIndex === -1 && bIndex === -1) {
        return a.localeCompare(b);
      } else if (aIndex === -1) {
        return 1;
      } else if (bIndex === -1) {
        return -1;
      } else {
        return aIndex - bIndex;
      }
    });
    return keys;
  }
}
