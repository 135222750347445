/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { GetGachaImageUrlResponse } from '../../models/get-gacha-image-url-response';

export interface GachaServiceGetGachaImageUrl$Params {
  gachaId: string;
  altArtId?: number;

/**
 * - RAINBOW_HOLO: Holo pattern
 *  - SAKURA_FALLING_GIF: Falling sakura gif
 *  - MOSAIC_CENSOR: Mosaic censorship png
 *  - FIRST_ANNIVERSARY_BORDER: First anniversary (2024) CNY border
 *  - SP3_SPARKLE_FALLING_GIF: Starpower 3 falling sparkle gif
 *  - COMPETITION_1_BORDER_1: Competition 1 winner border #1
 *  - COMPETITION_1_BORDER_2: Competition 1 winner border #2
 *  - COMPETITION_1_BORDER_3: Competition 1 winner border #3
 *  - COMPETITION_1_BORDER_4: Competition 1 winner border #4
 *  - COMPETITION_1_BORDER_5: Competition 1 winner border #5
 *  - COMPETITION_1_EFFECT_1: Competition 1 winner effect #1
 *  - COMPETITION_1_EFFECT_2: Competition 1 winner effect #2
 *  - COMPETITION_1_EFFECT_3: Competition 1 winner effect #3
 */
  enhancement?: 'NO_GACHA_ENHANCEMENT' | 'RAINBOW_HOLO' | 'SAKURA_FALLING_GIF' | 'MOSAIC_CENSOR' | 'FIRST_ANNIVERSARY_BORDER' | 'SP3_SPARKLE_FALLING_GIF' | 'COMPETITION_1_BORDER_1' | 'COMPETITION_1_BORDER_2' | 'COMPETITION_1_BORDER_3' | 'COMPETITION_1_BORDER_4' | 'COMPETITION_1_BORDER_5' | 'COMPETITION_1_EFFECT_1' | 'COMPETITION_1_EFFECT_2' | 'COMPETITION_1_EFFECT_3';
}

export function gachaServiceGetGachaImageUrl(http: HttpClient, rootUrl: string, params: GachaServiceGetGachaImageUrl$Params, context?: HttpContext): Observable<StrictHttpResponse<GetGachaImageUrlResponse>> {
  const rb = new RequestBuilder(rootUrl, gachaServiceGetGachaImageUrl.PATH, 'post');
  if (params) {
    rb.path('gachaId', params.gachaId, {});
    rb.query('altArtId', params.altArtId, {});
    rb.query('enhancement', params.enhancement, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<GetGachaImageUrlResponse>;
    })
  );
}

gachaServiceGetGachaImageUrl.PATH = '/api/v3/gateway/gacha/{gachaId}/image';
