import { Component, OnInit, Output, Input, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { Gacha, Media } from 'src/app/openapi/models';
import { GachaServiceService, MediaServiceService } from 'src/app/openapi/services';

@Component({
  selector: 'app-gacha-picker',
  templateUrl: './gacha-picker.component.html',
  styleUrls: ['./gacha-picker.component.scss']
})
export class GachaPickerComponent implements OnInit, OnChanges {
  allMedias: Media[] = [];
  media?: Media;
  gacha?: Gacha;
  mediaGachas: Gacha[] = [];

  rarityOptions: number[] = [1, 2, 3, 4, 5];
  rarity: number = 1;

  @Output()
  addGachaEvent = new EventEmitter<Gacha>();

  @Output()
  selectMediaEvent = new EventEmitter<Media>();

  @Output()
  selectGachaEvent = new EventEmitter<Gacha>();

  constructor(private mediaService: MediaServiceService,
    private gachaService: GachaServiceService) { }

  ngOnInit(): void {
    this.mediaService.mediaServiceGetAllMedia().subscribe(m => this.allMedias = m.media || []);
  }

  ngOnChanges(c: SimpleChanges): void {
    if (c['rarity'] && this.media) {
      this.onSelectMedia(this.media);
    }
  }

  onSelectRarity(e: number) {
    if (!this.media) {
      return;
    }
    this.onSelectMedia(this.media);
  }

  onClickAddAll() {
    if (!this.media) {
      return;
    }
    this.mediaGachas
      .filter(g => g.rarity == this.rarity)
      .forEach(g => this.addGachaEvent.emit(g));
  }

  onSelectMedia(e: Media) {
    this.selectMediaEvent.emit(e);
    this.gachaService.gachaServiceGetAllGachaForMedia({ mediaId: e.id! }).subscribe(g => this.mediaGachas =
      (g.gachas || []).filter(g => g.rarity == this.rarity)
        .sort(this.sortGacha));
  }

  onSelectGacha(e: Gacha) {
    this.selectGachaEvent.emit(e);
  }

  addGacha() {
    this.addGachaEvent.emit(this.gacha);
  }

  stars() {
    return "⭐".repeat(this.rarity);
  }

  sortGacha(a: Gacha, b: Gacha) {
    if (a.rarity! < b.rarity!) {
      return 1;
    }
    return a.name!.localeCompare(b.name!);
  }
}
