/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CreateMarketplaceItemListingResponse } from '../models/create-marketplace-item-listing-response';
import { DeleteMarketplaceItemListingResponse } from '../models/delete-marketplace-item-listing-response';
import { FilterUserGachaAgainstResponse } from '../models/filter-user-gacha-against-response';
import { GetMarketplaceListingResponse } from '../models/get-marketplace-listing-response';
import { GetMarketplaceRepresentedMediaResponse } from '../models/get-marketplace-represented-media-response';
import { GetMarketplaceResponse } from '../models/get-marketplace-response';
import { marketplaceServiceCreateMarketplaceItemListing } from '../fn/marketplace-service/marketplace-service-create-marketplace-item-listing';
import { MarketplaceServiceCreateMarketplaceItemListing$Params } from '../fn/marketplace-service/marketplace-service-create-marketplace-item-listing';
import { marketplaceServiceDeleteMarketplaceItemListing } from '../fn/marketplace-service/marketplace-service-delete-marketplace-item-listing';
import { MarketplaceServiceDeleteMarketplaceItemListing$Params } from '../fn/marketplace-service/marketplace-service-delete-marketplace-item-listing';
import { marketplaceServiceFilterUserGachaAgainst } from '../fn/marketplace-service/marketplace-service-filter-user-gacha-against';
import { MarketplaceServiceFilterUserGachaAgainst$Params } from '../fn/marketplace-service/marketplace-service-filter-user-gacha-against';
import { marketplaceServiceGetMarketplace } from '../fn/marketplace-service/marketplace-service-get-marketplace';
import { MarketplaceServiceGetMarketplace$Params } from '../fn/marketplace-service/marketplace-service-get-marketplace';
import { marketplaceServiceGetMarketplaceListing } from '../fn/marketplace-service/marketplace-service-get-marketplace-listing';
import { MarketplaceServiceGetMarketplaceListing$Params } from '../fn/marketplace-service/marketplace-service-get-marketplace-listing';
import { marketplaceServiceGetMarketplaceRepresentedMedia } from '../fn/marketplace-service/marketplace-service-get-marketplace-represented-media';
import { MarketplaceServiceGetMarketplaceRepresentedMedia$Params } from '../fn/marketplace-service/marketplace-service-get-marketplace-represented-media';
import { marketplaceServicePurchaseMarketplaceListing } from '../fn/marketplace-service/marketplace-service-purchase-marketplace-listing';
import { MarketplaceServicePurchaseMarketplaceListing$Params } from '../fn/marketplace-service/marketplace-service-purchase-marketplace-listing';
import { marketplaceServiceReactToListing } from '../fn/marketplace-service/marketplace-service-react-to-listing';
import { MarketplaceServiceReactToListing$Params } from '../fn/marketplace-service/marketplace-service-react-to-listing';
import { PurchaseMarketplaceListingResponse } from '../models/purchase-marketplace-listing-response';
import { ReactToListingResponse } from '../models/react-to-listing-response';

@Injectable({ providedIn: 'root' })
export class MarketplaceServiceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `marketplaceServiceGetMarketplace()` */
  static readonly MarketplaceServiceGetMarketplacePath = '/api/v3/gateway/marketplace';

  /**
   * What is a booster pack if not a roll gacha response :).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `marketplaceServiceGetMarketplace()` instead.
   *
   * This method doesn't expect any request body.
   */
  marketplaceServiceGetMarketplace$Response(params?: MarketplaceServiceGetMarketplace$Params, context?: HttpContext): Observable<StrictHttpResponse<GetMarketplaceResponse>> {
    return marketplaceServiceGetMarketplace(this.http, this.rootUrl, params, context);
  }

  /**
   * What is a booster pack if not a roll gacha response :).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `marketplaceServiceGetMarketplace$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  marketplaceServiceGetMarketplace(params?: MarketplaceServiceGetMarketplace$Params, context?: HttpContext): Observable<GetMarketplaceResponse> {
    return this.marketplaceServiceGetMarketplace$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetMarketplaceResponse>): GetMarketplaceResponse => r.body)
    );
  }

  /** Path part for operation `marketplaceServiceGetMarketplaceRepresentedMedia()` */
  static readonly MarketplaceServiceGetMarketplaceRepresentedMediaPath = '/api/v3/gateway/marketplace/active_media';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `marketplaceServiceGetMarketplaceRepresentedMedia()` instead.
   *
   * This method doesn't expect any request body.
   */
  marketplaceServiceGetMarketplaceRepresentedMedia$Response(params?: MarketplaceServiceGetMarketplaceRepresentedMedia$Params, context?: HttpContext): Observable<StrictHttpResponse<GetMarketplaceRepresentedMediaResponse>> {
    return marketplaceServiceGetMarketplaceRepresentedMedia(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `marketplaceServiceGetMarketplaceRepresentedMedia$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  marketplaceServiceGetMarketplaceRepresentedMedia(params?: MarketplaceServiceGetMarketplaceRepresentedMedia$Params, context?: HttpContext): Observable<GetMarketplaceRepresentedMediaResponse> {
    return this.marketplaceServiceGetMarketplaceRepresentedMedia$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetMarketplaceRepresentedMediaResponse>): GetMarketplaceRepresentedMediaResponse => r.body)
    );
  }

  /** Path part for operation `marketplaceServiceFilterUserGachaAgainst()` */
  static readonly MarketplaceServiceFilterUserGachaAgainstPath = '/api/v3/gateway/marketplace/filter_gacha';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `marketplaceServiceFilterUserGachaAgainst()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  marketplaceServiceFilterUserGachaAgainst$Response(params: MarketplaceServiceFilterUserGachaAgainst$Params, context?: HttpContext): Observable<StrictHttpResponse<FilterUserGachaAgainstResponse>> {
    return marketplaceServiceFilterUserGachaAgainst(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `marketplaceServiceFilterUserGachaAgainst$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  marketplaceServiceFilterUserGachaAgainst(params: MarketplaceServiceFilterUserGachaAgainst$Params, context?: HttpContext): Observable<FilterUserGachaAgainstResponse> {
    return this.marketplaceServiceFilterUserGachaAgainst$Response(params, context).pipe(
      map((r: StrictHttpResponse<FilterUserGachaAgainstResponse>): FilterUserGachaAgainstResponse => r.body)
    );
  }

  /** Path part for operation `marketplaceServiceCreateMarketplaceItemListing()` */
  static readonly MarketplaceServiceCreateMarketplaceItemListingPath = '/api/v3/gateway/marketplace/listing';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `marketplaceServiceCreateMarketplaceItemListing()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  marketplaceServiceCreateMarketplaceItemListing$Response(params: MarketplaceServiceCreateMarketplaceItemListing$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateMarketplaceItemListingResponse>> {
    return marketplaceServiceCreateMarketplaceItemListing(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `marketplaceServiceCreateMarketplaceItemListing$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  marketplaceServiceCreateMarketplaceItemListing(params: MarketplaceServiceCreateMarketplaceItemListing$Params, context?: HttpContext): Observable<CreateMarketplaceItemListingResponse> {
    return this.marketplaceServiceCreateMarketplaceItemListing$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateMarketplaceItemListingResponse>): CreateMarketplaceItemListingResponse => r.body)
    );
  }

  /** Path part for operation `marketplaceServiceGetMarketplaceListing()` */
  static readonly MarketplaceServiceGetMarketplaceListingPath = '/api/v3/gateway/marketplace/listing/{marketplaceListingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `marketplaceServiceGetMarketplaceListing()` instead.
   *
   * This method doesn't expect any request body.
   */
  marketplaceServiceGetMarketplaceListing$Response(params: MarketplaceServiceGetMarketplaceListing$Params, context?: HttpContext): Observable<StrictHttpResponse<GetMarketplaceListingResponse>> {
    return marketplaceServiceGetMarketplaceListing(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `marketplaceServiceGetMarketplaceListing$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  marketplaceServiceGetMarketplaceListing(params: MarketplaceServiceGetMarketplaceListing$Params, context?: HttpContext): Observable<GetMarketplaceListingResponse> {
    return this.marketplaceServiceGetMarketplaceListing$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetMarketplaceListingResponse>): GetMarketplaceListingResponse => r.body)
    );
  }

  /** Path part for operation `marketplaceServiceDeleteMarketplaceItemListing()` */
  static readonly MarketplaceServiceDeleteMarketplaceItemListingPath = '/api/v3/gateway/marketplace/listing/{marketplaceListingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `marketplaceServiceDeleteMarketplaceItemListing()` instead.
   *
   * This method doesn't expect any request body.
   */
  marketplaceServiceDeleteMarketplaceItemListing$Response(params: MarketplaceServiceDeleteMarketplaceItemListing$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteMarketplaceItemListingResponse>> {
    return marketplaceServiceDeleteMarketplaceItemListing(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `marketplaceServiceDeleteMarketplaceItemListing$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  marketplaceServiceDeleteMarketplaceItemListing(params: MarketplaceServiceDeleteMarketplaceItemListing$Params, context?: HttpContext): Observable<DeleteMarketplaceItemListingResponse> {
    return this.marketplaceServiceDeleteMarketplaceItemListing$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteMarketplaceItemListingResponse>): DeleteMarketplaceItemListingResponse => r.body)
    );
  }

  /** Path part for operation `marketplaceServicePurchaseMarketplaceListing()` */
  static readonly MarketplaceServicePurchaseMarketplaceListingPath = '/api/v3/gateway/marketplace/listing/{marketplaceListingId}/purchase';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `marketplaceServicePurchaseMarketplaceListing()` instead.
   *
   * This method doesn't expect any request body.
   */
  marketplaceServicePurchaseMarketplaceListing$Response(params: MarketplaceServicePurchaseMarketplaceListing$Params, context?: HttpContext): Observable<StrictHttpResponse<PurchaseMarketplaceListingResponse>> {
    return marketplaceServicePurchaseMarketplaceListing(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `marketplaceServicePurchaseMarketplaceListing$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  marketplaceServicePurchaseMarketplaceListing(params: MarketplaceServicePurchaseMarketplaceListing$Params, context?: HttpContext): Observable<PurchaseMarketplaceListingResponse> {
    return this.marketplaceServicePurchaseMarketplaceListing$Response(params, context).pipe(
      map((r: StrictHttpResponse<PurchaseMarketplaceListingResponse>): PurchaseMarketplaceListingResponse => r.body)
    );
  }

  /** Path part for operation `marketplaceServiceReactToListing()` */
  static readonly MarketplaceServiceReactToListingPath = '/api/v3/gateway/marketplace/listing/{marketplaceListingId}/react';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `marketplaceServiceReactToListing()` instead.
   *
   * This method doesn't expect any request body.
   */
  marketplaceServiceReactToListing$Response(params: MarketplaceServiceReactToListing$Params, context?: HttpContext): Observable<StrictHttpResponse<ReactToListingResponse>> {
    return marketplaceServiceReactToListing(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `marketplaceServiceReactToListing$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  marketplaceServiceReactToListing(params: MarketplaceServiceReactToListing$Params, context?: HttpContext): Observable<ReactToListingResponse> {
    return this.marketplaceServiceReactToListing$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReactToListingResponse>): ReactToListingResponse => r.body)
    );
  }

}
