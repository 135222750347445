/* tslint:disable */
/* eslint-disable */
import { UserAccessRights } from './user-access-rights';

/**
 * Each possible value of `UserAccessRights`
 */
export const USER_ACCESS_RIGHTS: UserAccessRights[] = [
  'NONE',
  'SUPERUSER',
  'MEDIA_CREATOR',
  'GACHA_EDITOR',
  'GACHA_APPROVER',
  'BANNER_EDITOR',
  'EQUIPMENT_EDITOR',
  'EQUIPMENT_APPROVER',
  'GACHA_IMAGE_EDITOR',
  'ALT_ART_EDITOR',
  'ABUSE_VIEWER',
  'BAN_POWER',
  'GIVEAWAY_MANAGER',
  'MARKETPLACE_MANAGER'
];