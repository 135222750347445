import { Component, OnInit } from '@angular/core';
import { EnhancementMetadataType, GetGachaEnhancementExamplesResponseEnhancement } from 'src/app/openapi/models';
import { GachaServiceService } from 'src/app/openapi/services';

@Component({
  selector: 'app-view-all-enhancements',
  templateUrl: './view-all-enhancements.component.html',
  styleUrl: './view-all-enhancements.component.scss'
})
export class ViewAllEnhancementsComponent implements OnInit {
  constructor(private gachaService: GachaServiceService) { }

  effects?: GetGachaEnhancementExamplesResponseEnhancement[];
  borders?: GetGachaEnhancementExamplesResponseEnhancement[];

  selectedTab: EnhancementMetadataType = "BORDER";

  ngOnInit(): void {
    this.gachaService.gachaServiceGetGachaEnhancementExamples().subscribe(e => {
      e.enhancements?.forEach(enh => {
        switch (enh.enhancementType) {
          case "BORDER":
            if (!this.borders) {
              this.borders = [];
            }
            this.borders.push(enh);
            break;
          case "EFFECT":
            if (!this.effects) {
              this.effects = [];
            }
            this.effects.push(enh);
            break;
          default:
            console.error(`Unknown enhancement type ${enh.enhancementType}`);
            break;
        }
      })
    });
  }

  select(enhancementType: EnhancementMetadataType) {
    this.selectedTab = enhancementType;
  }
}
