<section>
  <h2 class="title">Create a new Equipment</h2>
  <div class="columns">
    <div class="column is-4">
      <div class="field">
        <label class="label">Equipment Name</label>
        <div class="control">
          <input [(ngModel)]="equipment.name" class="input" type="text" placeholder="Text input">
        </div>
      </div>

      <div class="field">
        <label class="label">Equipment Description</label>
        <div class="control">
          <textarea [(ngModel)]="equipment.description" class="textarea" type="text"
            placeholder="A one or two sentence description, or flavor text.">
            </textarea>
        </div>
      </div>

      <div class="field">
        <label class="label">Select Media Franchise</label>
        <div class="control">
          <div class="select">
            <select [(ngModel)]="equipment.mediaId">
              <option *ngFor="let media of medias" [ngValue]="media.id">{{media.name}}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="field">
        <label class="label">Select Category</label>
        <div class="control">
          <div *ngFor="let category of categoryOptions">
            <label class="radio">
              <input type="radio" name="radio" id="radio-{{category}}" [(ngModel)]="equipment.category"
                [value]="category" />
              {{stringifyCategory(category)}}
            </label>
          </div>
        </div>
      </div>

      <div class="field">
        <label class="label">Select Rarity</label>
        <div class="control">
          <div *ngFor="let rarity of rarityOptions">
            <label class="radio">
              <input type="radio" name="radiorarity" id="radio-{{rarity}}" [(ngModel)]="equipment.rarity"
                [value]="rarity" />
              {{rarity}}⭐
            </label>
          </div>
        </div>
      </div>

      <div class="field">
        <label class="label">Attributes</label>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Health</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <input [(ngModel)]="equipment.attributes!.stats!.health" class="input" type="number"
                  placeholder="Health">
              </div>
            </div>
          </div>
          <div class="field-label is-normal">
            <label class="label">Mult</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <input [(ngModel)]="equipment.attributes!.multipliers!.health" class="input" type="number"
                  placeholder="Health Multiplier">
              </div>
            </div>
          </div>
          <div class="field-label is-normal">
            <label class="label">%</label>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Mana</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <input [(ngModel)]="equipment.attributes!.stats!.mana" class="input" type="number" placeholder="Mana">
              </div>
            </div>
          </div>
          <div class="field-label is-normal">
            <label class="label">Mult</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <input [(ngModel)]="equipment.attributes!.multipliers!.mana" class="input" type="number"
                  placeholder="Mana Multiplier">
              </div>
            </div>
          </div>
          <div class="field-label is-normal">
            <label class="label">%</label>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Attack</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <input [(ngModel)]="equipment.attributes!.stats!.attack" class="input" type="number"
                  placeholder="attack">
              </div>
            </div>
          </div>
          <div class="field-label is-normal">
            <label class="label">Mult</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <input [(ngModel)]="equipment.attributes!.multipliers!.attack" class="input" type="number"
                  placeholder="attack Multiplier">
              </div>
            </div>
          </div>
          <div class="field-label is-normal">
            <label class="label">%</label>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Defense</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <input [(ngModel)]="equipment.attributes!.stats!.defense" class="input" type="number"
                  placeholder="defense">
              </div>
            </div>
          </div>
          <div class="field-label is-normal">
            <label class="label">Mult</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <input [(ngModel)]="equipment.attributes!.multipliers!.defense" class="input" type="number"
                  placeholder="defense Multiplier">
              </div>
            </div>
          </div>
          <div class="field-label is-normal">
            <label class="label">%</label>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Luck</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <input [(ngModel)]="equipment.attributes!.stats!.luck" class="input" type="number" placeholder="Luck">
              </div>
            </div>
          </div>
          <div class="field-label is-normal">
            <label class="label">Mult</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <input [(ngModel)]="equipment.attributes!.multipliers!.luck" class="input" type="number"
                  placeholder="luck Multiplier">
              </div>
            </div>
          </div>
          <div class="field-label is-normal">
            <label class="label">%</label>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Speed</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <input [(ngModel)]="equipment.attributes!.stats!.speed" class="input" type="number" placeholder="Speed">
              </div>
            </div>
          </div>
          <div class="field-label is-normal">
            <label class="label">Mult</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <input [(ngModel)]="equipment.attributes!.multipliers!.speed" class="input" type="number"
                  placeholder="Speed Multiplier">
              </div>
            </div>
          </div>
          <div class="field-label is-normal">
            <label class="label">%</label>
          </div>
        </div>

      </div>

      <div class="field is-grouped">
        <div class="control">
          <button (click)="submitEquipment()" class="button is-link">Submit to Staging</button>
        </div>
      </div>
    </div>
    <div class="column is-4">
      <textarea #input placeholder="Paste image here" (paste)="onPaste($event)"
        (change)="onFileChange($event)"></textarea>
      <image-cropper output="base64" [imageChangedEvent]="imgChangeEvt" [maintainAspectRatio]="true"
        [imageBase64]="imageBase64String" [aspectRatio]="1" [cropperMinWidth]="384" format="png"
        (imageCropped)="cropImg($event)" (imageLoaded)="imgLoad()" (cropperReady)="initCropper()"
        (loadImageFailed)="imgFailed()" [resizeToWidth]="576" [onlyScaleDown]="true">
      </image-cropper>
    </div>

    <div class="column is-4">
      <h6>Image Preview</h6>
      <p class="subtitle">Grid background is to show transparency, it won't appear in final image</p>
      <img class="grid-background" [src]="cropImgPreview" />
    </div>
  </div>
</section>