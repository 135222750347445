/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ReactToListingResponse } from '../../models/react-to-listing-response';

export interface MarketplaceServiceReactToListing$Params {
  marketplaceListingId: string;
  discordId?: string;
  userUuid?: string;

/**
 * - LIKE_MARKETPLACE_REACTION: The user liked the listing.
 *  - DISLIKE_MARKETPLACE_REACTION: The user disliked the listing.
 *  - LAUGH_MARKETPLACE_REACTION: Laugh reaction.
 *  - HEART_MARKETPLACE_REACTION: Heart reaction.
 *  - FIRE_MARKETPLACE_REACTION: Fire reaction.
 */
  reaction?: 'UNDEFINED_MARKETPLACE_REACTION_TYPE' | 'LIKE_MARKETPLACE_REACTION' | 'DISLIKE_MARKETPLACE_REACTION' | 'LAUGH_MARKETPLACE_REACTION' | 'HEART_MARKETPLACE_REACTION' | 'FIRE_MARKETPLACE_REACTION';
}

export function marketplaceServiceReactToListing(http: HttpClient, rootUrl: string, params: MarketplaceServiceReactToListing$Params, context?: HttpContext): Observable<StrictHttpResponse<ReactToListingResponse>> {
  const rb = new RequestBuilder(rootUrl, marketplaceServiceReactToListing.PATH, 'post');
  if (params) {
    rb.path('marketplaceListingId', params.marketplaceListingId, {});
    rb.query('discordId', params.discordId, {});
    rb.query('userUuid', params.userUuid, {});
    rb.query('reaction', params.reaction, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ReactToListingResponse>;
    })
  );
}

marketplaceServiceReactToListing.PATH = '/api/v3/gateway/marketplace/listing/{marketplaceListingId}/react';
