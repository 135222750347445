import { Component, OnInit } from '@angular/core';
import { Equipment, EquipmentCategory } from 'src/app/openapi/models';
import { EQUIPMENT_CATEGORY } from 'src/app/openapi/models/equipment-category-array';
import { AdminServiceService } from 'src/app/openapi/services';

@Component({
  selector: 'app-view-staging-equipment',
  templateUrl: './view-staging-equipment.component.html',
  styleUrls: ['./view-staging-equipment.component.scss']
})
export class ViewStagingEquipmentComponent implements OnInit {

  constructor(private adminService: AdminServiceService) {
    for (const g of EQUIPMENT_CATEGORY) {
      if (g === "UNDEFINED_EQUIPMENT_CATEGORY") {
        continue;
      }
      this.equipmentCategories.push(g);
    }
  }

  equipments: Equipment[] = [];
  equipmentCategories: EquipmentCategory[] = [];

  ngOnInit(): void {
    this.adminService.adminServiceGetAllStagedEquipment().subscribe((res) => {
      this.equipments = res.equipment || [];
    });
  }

  stringify(obj: any): string {
    return JSON.stringify(obj);
  }

  stringifyCategory(category: EquipmentCategory): string {
    return category.toString();
  }
}
