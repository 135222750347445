/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { BidAuctionResponse } from '../../models/bid-auction-response';

export interface AuctionServiceBidAuction$Params {

/**
 * The auction that is being bid on.
 */
  auctionId: string;

/**
 * The user that is bidding on the auction.
 */
  userId?: string;

/**
 * The stardust bid
 */
  stardustBid?: number;
}

export function auctionServiceBidAuction(http: HttpClient, rootUrl: string, params: AuctionServiceBidAuction$Params, context?: HttpContext): Observable<StrictHttpResponse<BidAuctionResponse>> {
  const rb = new RequestBuilder(rootUrl, auctionServiceBidAuction.PATH, 'post');
  if (params) {
    rb.path('auctionId', params.auctionId, {});
    rb.query('userId', params.userId, {});
    rb.query('stardustBid', params.stardustBid, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<BidAuctionResponse>;
    })
  );
}

auctionServiceBidAuction.PATH = '/api/v3/gateway/auctions/{auctionId}/bid';
