<section>
  <h2 class="title">Create a new Media</h2>
  <div class="columns">
    <div class="column is-4">
      <div class="field">
        <label class="label">Media Name (Prefer english unless super well known)</label>
        <div class="control">
          <input [(ngModel)]="media.name" class="input" type="text" placeholder="Text input">
        </div>
      </div>

      <div class="field">
        <label class="label">Select Category</label>
        <div class="control">
          <div class="select">
            <select [(ngModel)]="media.origin">
              <option *ngFor="let o of mediaOriginCategories" [ngValue]="o">{{stringifyOrigin(o)}}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="field is-grouped">
        <div class="control">
          <button (click)="submitMedia()" class="button is-link">Submit Media</button>
        </div>
      </div>

    </div>

    <div class="column is-4">
      <h3 class="title">(Required) Upload Media Cover Image</h3>
      <textarea #input placeholder="Paste image here" (paste)="onPaste($event, imageTypeCover)"
        (change)="onFileChange($event, imageTypeCover)"></textarea>
      <image-cropper output="base64" [imageChangedEvent]="coverImage.imgChangeEvt" [maintainAspectRatio]="true"
        [imageBase64]="coverImage.imageBase64String" [aspectRatio]="1 / 1.41421356237" [cropperMinWidth]="384"
        format="png" (imageCropped)="cropImg($event, imageTypeCover)" (imageLoaded)="imgLoad()"
        (cropperReady)="initCropper()" (loadImageFailed)="imgFailed()" [resizeToWidth]="576" [onlyScaleDown]="true">
      </image-cropper>
      <br />
    </div>
    <div class="column is-4">
      <h6>Cover Image Preview</h6>
      <p class="subtitle">Grid background is to show transparency, it won't appear in final image</p>
      <img class="grid-background" [src]="coverImage.cropImgPreview" />
    </div>

  </div>
  <div class="columns">
    <div class="column is-full">
      <h3 class="title">(OPTIONAL) Upload Banner Image</h3>
      <div class="notification is-danger is-light">
        Banner image should be pretty high res, at least 1080 pixels across.
        If the preview looks like shit then don't use the image (or upscale it in <a
          href="https://waifu2x.udp.jp/">waifu2x)</a>
      </div>
      <textarea #input placeholder="Paste image here" (paste)="onPaste($event, imageTypeBanner)"
        (change)="onFileChange($event, imageTypeBanner)"></textarea>
      <image-cropper output="base64" [imageChangedEvent]="bannerImage.imgChangeEvt" [maintainAspectRatio]="true"
        [imageBase64]="bannerImage.imageBase64String" [aspectRatio]="16 / 6.5" [cropperMinWidth]="1080" format="png"
        (imageCropped)="cropImg($event, imageTypeBanner)" (imageLoaded)="imgLoad()" (cropperReady)="initCropper()"
        (loadImageFailed)="imgFailed()" [resizeToWidth]="1440" [onlyScaleDown]="true">
      </image-cropper>
      <br />
      <h6>Banner Image Preview</h6>
      <p class="subtitle">Grid background is to show transparency, it won't appear in final image</p>
      <img class="grid-background" [src]="bannerImage.cropImgPreview" />
    </div>
  </div>
</section>