import { User as UserV2 } from "../openapi/models/user";

export function generateUserActivityV2(u: UserV2): string {
  const lastActivity = new Date(u.lastActive!);
  const now = new Date();
  const diff = now.getTime() - lastActivity.getTime();
  const diffSeconds = diff / 1000;
  const diffMinutes = diffSeconds / 60;
  const diffHours = diffMinutes / 60;
  const diffDays = diffHours / 24;
  const diffWeeks = diffDays / 7;
  const diffMonths = diffWeeks / 4;
  const diffYears = diffMonths / 12;
  if (diffYears >= 1) {
    return `>${Math.floor(diffYears)}yr`;
  }
  if (diffMonths >= 1) {
    return `${Math.floor(diffMonths)}mo`;
  }
  if (diffWeeks >= 1) {
    return `${Math.floor(diffWeeks)}w`;
  }
  if (diffDays >= 1) {
    return `${Math.floor(diffDays)}d`;
  }
  return `<1d`;
}

export function generateUserActivityCssClassV2(u: UserV2): string {
  // Using the thresholds:
  // >#y is-danger
  // >#m is-warning
  // >#w is-info
  // >#d is-primary
  // <1d is-success
  const lastActivity = new Date(u.lastActive!);
  const now = new Date();
  const diff = now.getTime() - lastActivity.getTime();
  const diffSeconds = diff / 1000;
  const diffMinutes = diffSeconds / 60;
  const diffHours = diffMinutes / 60;
  const diffDays = diffHours / 24;
  const diffWeeks = diffDays / 7;
  const diffMonths = diffWeeks / 4;
  const diffYears = diffMonths / 12;
  if (diffYears >= 1) {
    return 'is-danger';
  }
  if (diffMonths >= 1) {
    return 'is-warning';
  }
  if (diffWeeks >= 1) {
    return 'is-info';
  }
  if (diffDays >= 1) {
    return 'is-primary';
  }
  return 'is-success';
}
