<div class="container">
  <div class="columns">

    <div class="column is-2">
      <aside class="menu">
        <p class="menu-label">
          Franchises
          <a href="#view-gachas-franchises-collapsible" class="no-decoration-a white" data-action="collapse">
            <span class="icon">
              <fa-icon *ngIf="collapsible && !collapsible.collapsed()" [icon]="faMinimize"></fa-icon>
              <fa-icon *ngIf="collapsible && collapsible.collapsed()" [icon]="faMaximize"></fa-icon>
            </span>
          </a>
        </p>
        <ul class="menu-list is-collapsible" id="view-gachas-franchises-collapsible">
          <input [(ngModel)]="searchFilter" class="input" type="text" placeholder="Search">
          <ng-container *ngFor="let m of filterMedias()">
            <li>
              <a class="transparent" (click)="selectMedia(m)">
                {{ m.name }}
              </a>
            </li>
          </ng-container>
        </ul>
      </aside>
    </div>

    <div class="column is-1"></div>
    <div id="anchor" class="column is-9 has-text-centered" #anchor>
      <div *ngIf="selectedMedia">

        <img *ngIf="selectedMedia.bannerImageUrl" class="bg-image" src="{{selectedMedia.bannerImageUrl}}">

        <div infiniteScroll [infiniteScrollDistance]="2" (scrolled)="onScrollDown()" (scrolledUp)="onScrollUp()">
          <h1 class="is-size-1">
            <ng-container *ngIf="auth.user$ | async as user">
              <a (click)="setFavoriteMedia(selectedMedia.id!)"
                *ngIf="favoriteMedia === undefined || favoriteMedia.id !== selectedMedia.id">
                <fa-icon class="star" [icon]="faHollowStar"></fa-icon>
              </a>
              <a (click)="removeFavoriteMedia()"
                *ngIf="favoriteMedia !== undefined && favoriteMedia.id === selectedMedia.id">
                <fa-icon class="star filledStar" [icon]="faSolidStar"></fa-icon>
              </a>
            </ng-container>

            {{selectedMedia.name}}
          </h1>
          <div class="field has-text-centered is-horizontal has-addons has-addons-centered">
            <div *ngIf="auth.user$ | async as user">
              <div class="control">
                <label class="checkbox">
                  <input type="checkbox" (click)="onSelectShowUnownedFilter($event, user.id)">
                  Show Only Unowned
                </label>
              </div>
              <div *ngIf="shownCount > 0">Total: {{ shownCount }}</div>
            </div>
            &nbsp;
            <div *ngIf="mediaDuoCardMapping[selectedMedia.id!]" class="control">
              <label class="checkbox">
                <input [(ngModel)]="showDuos" type="checkbox" (click)="onSelectShowDuoFilter($event)">
                Show Duo Cards
              </label>
            </div>
          </div>
          <ng-container *ngFor="let g of gachas">
            <app-gacha-v2 [gacha]="g.gacha" [outOf]="g.minted" [media]="selectedMedia"></app-gacha-v2>
          </ng-container>
          <ng-container *ngIf="gachas.length === 0">
            <h1 class="is-size-4">Gacha cards coming soon!</h1>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>