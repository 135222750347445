/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { GetNotificationsResponse } from '../models/get-notifications-response';
import { MarkNotificationAsReadResponse } from '../models/mark-notification-as-read-response';
import { MarkNotificationsAsReadBulkResponse } from '../models/mark-notifications-as-read-bulk-response';
import { notificationServiceGetNotifications } from '../fn/notification-service/notification-service-get-notifications';
import { NotificationServiceGetNotifications$Params } from '../fn/notification-service/notification-service-get-notifications';
import { notificationServiceMarkNotificationAsRead } from '../fn/notification-service/notification-service-mark-notification-as-read';
import { NotificationServiceMarkNotificationAsRead$Params } from '../fn/notification-service/notification-service-mark-notification-as-read';
import { notificationServiceMarkNotificationsAsReadBulk } from '../fn/notification-service/notification-service-mark-notifications-as-read-bulk';
import { NotificationServiceMarkNotificationsAsReadBulk$Params } from '../fn/notification-service/notification-service-mark-notifications-as-read-bulk';

@Injectable({ providedIn: 'root' })
export class NotificationServiceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `notificationServiceMarkNotificationAsRead()` */
  static readonly NotificationServiceMarkNotificationAsReadPath = '/api/v3/gateway/user/{userUuid}/notification/{notificationId}/read';

  /**
   * Gets a marketplace listing by ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationServiceMarkNotificationAsRead()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationServiceMarkNotificationAsRead$Response(params: NotificationServiceMarkNotificationAsRead$Params, context?: HttpContext): Observable<StrictHttpResponse<MarkNotificationAsReadResponse>> {
    return notificationServiceMarkNotificationAsRead(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets a marketplace listing by ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationServiceMarkNotificationAsRead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationServiceMarkNotificationAsRead(params: NotificationServiceMarkNotificationAsRead$Params, context?: HttpContext): Observable<MarkNotificationAsReadResponse> {
    return this.notificationServiceMarkNotificationAsRead$Response(params, context).pipe(
      map((r: StrictHttpResponse<MarkNotificationAsReadResponse>): MarkNotificationAsReadResponse => r.body)
    );
  }

  /** Path part for operation `notificationServiceGetNotifications()` */
  static readonly NotificationServiceGetNotificationsPath = '/api/v3/gateway/user/{userUuid}/notifications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationServiceGetNotifications()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationServiceGetNotifications$Response(params: NotificationServiceGetNotifications$Params, context?: HttpContext): Observable<StrictHttpResponse<GetNotificationsResponse>> {
    return notificationServiceGetNotifications(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationServiceGetNotifications$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationServiceGetNotifications(params: NotificationServiceGetNotifications$Params, context?: HttpContext): Observable<GetNotificationsResponse> {
    return this.notificationServiceGetNotifications$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetNotificationsResponse>): GetNotificationsResponse => r.body)
    );
  }

  /** Path part for operation `notificationServiceMarkNotificationsAsReadBulk()` */
  static readonly NotificationServiceMarkNotificationsAsReadBulkPath = '/api/v3/gateway/user/{userUuid}/notifications/read';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationServiceMarkNotificationsAsReadBulk()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationServiceMarkNotificationsAsReadBulk$Response(params: NotificationServiceMarkNotificationsAsReadBulk$Params, context?: HttpContext): Observable<StrictHttpResponse<MarkNotificationsAsReadBulkResponse>> {
    return notificationServiceMarkNotificationsAsReadBulk(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationServiceMarkNotificationsAsReadBulk$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationServiceMarkNotificationsAsReadBulk(params: NotificationServiceMarkNotificationsAsReadBulk$Params, context?: HttpContext): Observable<MarkNotificationsAsReadBulkResponse> {
    return this.notificationServiceMarkNotificationsAsReadBulk$Response(params, context).pipe(
      map((r: StrictHttpResponse<MarkNotificationsAsReadBulkResponse>): MarkNotificationsAsReadBulkResponse => r.body)
    );
  }

}
