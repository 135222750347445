<section>
  <div class="notification is-danger is-light">
    <strong>Please Read</strong>
    <br />
    This page is for editing cover and banner images for media. Banners are <em>optional</em>, but covers are
    <em>required</em> eventually for all media franchises.
    <br />
    <strong>Cover images</strong> are used for booster pack art - think of it like the same image size as
    a gacha, but represents the franchise. Cover images don't have to be "promo"/"marketing" art (such as theater movie
    posters),
    but they should give a good balance for the franchise and can represent the main parts of it.
    <br />
    <a href="https://cdn.upa.moe/media/d85a5923-8497-42b9-845d-124a5c1ddb43/cover.png" target="_blank">
      Example of a good cover image for Kill la Kill
    </a>
    <br />
    <strong>Banner images</strong> are very wide banners that are used for top of media pages (background) and
    for user profiles when they favorite a media. They should be high res (there's an "fyi" when you try to upload)
    and is looser for requirements. For example, promo art that just has a cast of characters, a screenshot of a cool
    and well known action scene, etc. Something that you would be proud to favorite and show-off".
    <br />
    <a href="" target="_blank">
      Example of a good banner image for Kill la Kill
    </a>
  </div>
  <p *ngIf="medias" class="has-text-weight-semibold">Total: {{medias.length}}</p>
  <div class="field is-horizontal">
    <label class="checkbox">
      <input [(ngModel)]="filterForNeedCover" (ngModelChange)="applyFilter()" type="checkbox">
      Filter for need cover
    </label>
    <label class="checkbox">
      <input [(ngModel)]="filterForNeedBanner" (ngModelChange)="applyFilter()" type="checkbox">
      Filter for need banner
    </label>
  </div>
  <div class="columns">
    <div class="column is-4">
      <table class="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Need cover?</th>
            <th>Need banner?</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let media of medias">
            <tr>
              <th class="has-text-weight-light">{{ media.name }}</th>
              <th class="has-text-weight-normal">
                <ng-container *ngIf="media.coverImageUrl !== 'https://cdn.upa.moe/256x362.png'; else needImage">
                  <a href="{{ media.coverImageUrl }}" target="_blank">No</a>
                </ng-container>

              </th>
              <th class="has-text-weight-normal">
                <ng-container *ngIf="media.bannerImageUrl !== undefined; else needImage">
                  <a href="{{ media.bannerImageUrl }}" target="_blank">No</a>
                </ng-container>
              </th>

              <th>
                <ng-container *ngIf="selectedMedia !== media; else isEditing">
                  <button class="button is-primary is-light" (click)="editMedia(media)">Edit</button>
                </ng-container>
                <ng-template #isEditing>
                  <div class="buttons">
                    <button class="button is-success is-light" (click)="saveMedia()">Save</button>
                    <button class="button is-warning is-light" (click)="cancelEdit()">Cancel</button>
                  </div>
                </ng-template>
              </th>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>


    <div *ngIf="selectedMedia" class="column is-8">
      <h2 class="title">Edit {{ selectedMedia.name }}</h2>
      <div class="buttons">
        <button class="button is-success is-light" (click)="saveMedia()">Save</button>
        <button class="button is-warning is-light" (click)="cancelEdit()">Cancel</button>
      </div>
      <label class="checkbox">
        <input [(ngModel)]="updateCoverImage" type="checkbox">
        Update the cover image?
      </label>
      <div class="columns">
        <div class="column is-half">
          <h3 class="title">(Required) Upload Media Cover Image</h3>
          <textarea #input placeholder="Paste image here" (paste)="onPaste($event, imageTypeCover)"
            (change)="onFileChange($event, imageTypeCover)"></textarea>
          <image-cropper output="base64" [imageChangedEvent]="coverImage.imgChangeEvt" [maintainAspectRatio]="true"
            [imageBase64]="coverImage.imageBase64String" [aspectRatio]="1 / 1.41421356237" [cropperMinWidth]="384"
            format="png" (imageCropped)="cropImg($event, imageTypeCover)" (imageLoaded)="imgLoad()"
            (cropperReady)="initCropper()" (loadImageFailed)="imgFailed()" [resizeToWidth]="576" [onlyScaleDown]="true">
          </image-cropper>
          <br />
        </div>
        <div class="column is-half">
          <h6>Cover Image Preview</h6>
          <p class="subtitle">Grid background is to show transparency, it won't appear in final image</p>
          <img class="grid-background" [src]="coverImage.cropImgPreview" />
        </div>
      </div>
      <hr>
      <div class="columns">
        <div class="column is-full">
          <h3 class="title">(OPTIONAL) Upload Banner Image</h3>
          <label class="checkbox">
            <input [(ngModel)]="updateBannerImage" type="checkbox">
            Update the banner image?
          </label>
          <div class="notification is-danger is-light">
            Banner image should be pretty high res, at least 1080 pixels across.
            <br />
            <strong>Please ensure the image is high res before putting it here, the preview can be deceptive on small
              screens!</strong>
            <br />
            If the preview looks like shit then don't use the image (or upscale it in <a
              href="https://waifu2x.udp.jp/">waifu2x)</a>
          </div>
          <textarea #input placeholder="Paste image here" (paste)="onPaste($event, imageTypeBanner)"
            (change)="onFileChange($event, imageTypeBanner)"></textarea>
          <image-cropper output="base64" [imageChangedEvent]="bannerImage.imgChangeEvt" [maintainAspectRatio]="true"
            [imageBase64]="bannerImage.imageBase64String" [aspectRatio]="16 / 6.5" [cropperMinWidth]="1080" format="png"
            (imageCropped)="cropImg($event, imageTypeBanner)" (imageLoaded)="imgLoad()" (cropperReady)="initCropper()"
            (loadImageFailed)="imgFailed()" [resizeToWidth]="1440" [onlyScaleDown]="true">
          </image-cropper>
          <br />
          <h6>Banner Image Preview</h6>
          <p class="subtitle">Grid background is to show transparency, it won't appear in final image</p>
          <img class="grid-background" [src]="bannerImage.cropImgPreview" />
        </div>
      </div>
    </div>

    <ng-template #needImage>
      <strong>Yes</strong>
    </ng-template>