import { isPlatformServer, PlatformLocation } from "@angular/common";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Observable } from "rxjs";
import { SSR_BYPASS_TOKEN } from "../app.module";

@Injectable()
export class CsrfInterceptor implements HttpInterceptor {
    constructor(@Inject(PLATFORM_ID) private platformId: Object,
        @Inject(SSR_BYPASS_TOKEN) private bypassSSRToken: string) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (isPlatformServer(this.platformId)) {
            if (this.bypassSSRToken && this.bypassSSRToken.length > 0) {
                request = request.clone({
                    setHeaders: {
                        ['X-CSRF-TOKEN']: this.bypassSSRToken
                    }
                });
                console.log(`added bypass token to request path: ${request.urlWithParams}`);
                console.log(`with method: ${request.method}`);
            } else {
                console.log(`no bypass token to add to request path: ${request.url}`);
            }
            console.log(`request headers: ${JSON.stringify(request.headers)}`);
            return next.handle(request);
        }

        // Add XSRF token by reading the XSRF-TOKEN cookie and setting it as a header
        const xsrfToken = this.getCookie('XSRF-TOKEN');
        if (!xsrfToken) {
            return next.handle(request);
        }
        request = request.clone({
            setHeaders: {
                ['X-XSRF-TOKEN']: xsrfToken
            }
        });
        return next.handle(request);
    }

    getCookie(name: string): string | null {
        if (document === undefined || document === null) {
            return null;
        }
        const cookiesString = document.cookie;
        const cookies = cookiesString.split('; ');

        for (const cookie of cookies) {
            const [cookieName, cookieValue] = cookie.split('=');

            if (cookieName === name) {
                return decodeURIComponent(cookieValue);
            }
        }

        return null;
    }
}