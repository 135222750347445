import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { retry, catchError, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { isDevMode } from '@angular/core';
import { of } from 'rxjs';
import { distinctUntilChanged } from 'rxjs';
import { map } from 'rxjs';
import { share } from 'rxjs';
import { ReplaySubject } from 'rxjs';
import { timer } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { SupporterStatus, UserAccessRights } from '../openapi/models';
import { includesAny } from '../view/navbar/navbar.component';
import { environment } from 'src/environments/environment';

// There are more fields, but these are the ones we care about
export interface User {
  id: string;
  username: string;
  discriminator: string;
  // Can be formatted into
  // https://cdn.discordapp.com/avatars/{id}/{avatar}.png
  // if it starts with a_, then it's a gif
  avatar?: string;
  user_uuid?: string;

  // Obviously this is server authoritative, but we guard on this to stop at least the UI showing
  // The server returns the enum tag number, not the string representation.
  rights: number[];
  supporter_status: SupporterStatus;
}

function getApiUrl(): string {
  return environment.apiRoot + "/api/v2/auth";
}

// 1 Hour
const KEY_TIMEOUT = 60 * 60;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private getUserEndpoint = `${getApiUrl()}/user`;

  constructor(private httpClient: HttpClient, @Inject(PLATFORM_ID) private platformId: Object) { }

  handleError(error: HttpErrorResponse) {
    if (isDevMode()) {
      console.log(error);
    }
    return throwError(error.message);
  }

  readonly user$ = this.user();
  readonly avatar$ = this.buildAvatarURL();

  private user(): Observable<User | undefined> {
    if (!isPlatformBrowser(this.platformId)) {
      return of(undefined);
    }
    return this.httpClient.get<User | undefined>(this.getUserEndpoint, { withCredentials: true })
      .pipe(
        distinctUntilChanged(),
        share({
          connector: () => new ReplaySubject(1),
          resetOnComplete: () => timer(KEY_TIMEOUT * 1000),
        }),
        catchError(err => {
          if (err.status === 401) {
            return of(undefined);
          }
          return this.handleError(err);
        })
      );
  }

  public login() {
    window.location.href = `${getApiUrl()}/login`;
  }

  public logout(dest?: { returnTo: string }) {
    window.location.href = `${getApiUrl()}/logout?${dest ? `redirectTo=${dest.returnTo}` : ""}`;
  }

  public buildAvatarURL() {
    return this.user$.pipe(
      map(user => {
        if (!user) {
          return "";
        }
        if (!user.avatar) {
          return "";
        }
        if (user.avatar.startsWith("a_")) {
          return `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.gif`;
        }
        return `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png`;
      }));
  }
}

export function canViewDeleteListing(u: User): boolean {
  return includesAny(u, "MARKETPLACE_MANAGER");
}
