import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { map } from 'rxjs';
import { command } from './view-commands.component';
import { Meta } from '@angular/platform-browser';

export const resolveAllCommands: ResolveFn<boolean> = (route, state) => {
  const h = inject(HttpClient);
  const m = inject(Meta);
  return h.get("/assets/commands.json").pipe(
    map((data: any) => {
      const d = data as Map<string, command[]>;
      const count = d.size;
      m.removeTag('name="title"');
      m.removeTag('name="description"');
      m.removeTag('name="og:title"');
      m.removeTag('name="og:description"');
      m.addTag({ name: 'title', content: `Upa - All Commands` })
      m.addTag({ name: 'og:title', content: `Upa - All Commands` });
      m.addTag({ name: 'description', content: `Learn about Upa's ${count} available commands. Trust me, it's easy!` });
      m.addTag({ name: 'og:description', content: `Learn about Upa's ${count} commands. Trust me, it's easy!` });
    }), map(_ => true)
  );
};

export const resolveSpecificCommand: ResolveFn<boolean> = (route, state) => {
  const h = inject(HttpClient);
  const m = inject(Meta);
  return h.get("/assets/commands.json").pipe(
    map((data: any) => {
      const commands = new Map<string, command[]>();
      for (const [category, gotCmd] of Object.entries(data)) {
        commands.set(category, gotCmd as command[]);
      }
      const cmdName = route.params['command'];
      const found = findCommand(cmdName, commands);
      if (!found) {
        return;
      }
      m.removeTag('name="title"');
      m.removeTag('name="description"');
      m.removeTag('name="og:title"');
      m.removeTag('name="og:description"');
      m.addTag({ name: 'title', content: `Upa - /${route.params['command']} command guide` })
      m.addTag({ name: 'og:title', content: `Upa - /${route.params['command']} command guide` });
      m.addTag({ name: 'description', content: `/${found.name} - ${found.helpText}` });
      m.addTag({ name: 'og:description', content: `/${found.name} - ${found.helpText}` });
    }), map(_ => true)
  );
};

function findCommand(command: string, inCommands: Map<string, command[]>): command | undefined {
  for (const commands of inCommands.values()) {
    for (const c of commands) {
      if (c.name === command) {
        return c;
      }
    }
  }
  return undefined;
}