<div class="container is-fluid">
  <div>
    <h1 class="title">Bazaar</h1>
    <p class="subtitle" *ngIf="stardustBalance">Stardust Balance: {{ stardustBalance | number:'1.0':'en-US' }}</p>
  </div>
  <div class="columns">
    <!-- the left column to show filters, forms, etc etc-->
    <div class="column is-2">

      <div class="field">
        <label class="label">Medias</label>
        <div class="control">
          <div class="select">
            <select [(ngModel)]="selectedMediaOrAll" (change)="loadMarketplaceForMedia()">
              <option value="allMedias">All</option>
              <option *ngFor="let media of availableMedias" [ngValue]="media.id">{{ media.name }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="field">
        <label class="label">Sort By</label>
        <div class="control">
          <div class="select">
            <select [(ngModel)]="selectedSort" (change)="applyFrontendSort()">
              <option value="DEFAULT">Default</option>
              <option value="PRICE_ASC">Price</option>
              <option value="CREATED_AT_DESC">Creation Time</option>
            </select>
          </div>
        </div>
      </div>
      <div class="field">
        <label class="label">Rarities</label>
        <div class="control">
          <label class="checkbox" *ngFor="let rarity of gachaRarities">
            <input (change)="toggleRarityFilter(rarity)" type="checkbox">
            {{ rarity }}⭐
          </label>
        </div>
      </div>
      <div class="field">
        <button class="button is-link" (click)="loadMarketplaceForMyself()">Show My Listings</button>
      </div>
      <div class="field">
        <button class="button is-warning is-light" (click)="reloadMarketplace(true)">Reset Filters</button>
      </div>

      <div class="field">
        <!-- checkbox-->
        <label class="checkbox">
          <input type="checkbox" id="unownedCards" (click)="toggleFilter('NOT_OWNED_BY_ME')">
          Show only unowned cards
        </label>
      </div>

      <ng-container *ngIf="authService.user$ | async as user">
        <div class="field" *ngIf="canViewDeleteListing(user)">
          <!-- checkbox-->
          <label class="checkbox">
            <input type="checkbox" id="adminMode" [(ngModel)]="marketplaceAdminMode">
            Admin Mode (enable force delete listings)
          </label>
        </div>
      </ng-container>

      <!-- <div class="field is-grouped">
        <div class="control">
          <button class="button is-link">Search</button>
        </div>
      </div> -->
    </div>

    <div class="column is-10 has-text-centered">
      <ng-container *ngFor="let m of shownMarketplaceListings">
        <app-gacha-v2 [gacha]="m.item!.gachaInstance!.gacha!" [media]="m.item!.gachaInstance!.media!"
          [marketplacePrice]="m.stardustPrice!" [marketplaceSeller]="m.user!" [marketplaceListingId]="m.listingId!"
          [marketplaceCardEnhancements]="m.item!.gachaInstance!.gachaEnhancements"
          [marketplaceAdminMode]="marketplaceAdminMode" [marketplaceCardListedAtStr]="m.createdAt!"
          [copyNumber]="mintedMap[m.item!.gachaInstance!.gachaInstanceId!].mint!"
          [outOf]="mintedMap[m.item!.gachaInstance!.gachaInstanceId!].outOf!" [userUUID]="userUUID"
          [starpower]="m.item!.gachaInstance!.starpower!"
          (forceGachaReload)="reloadMarketplaceIndividualCard(m.listingId!)"
          [marketplaceReactions]="m.reactions!"></app-gacha-v2>
      </ng-container>

      <div *ngIf="shownMarketplaceListings && shownMarketplaceListings.length === 0">
        <h1 class="title">No listings found. Check your filters and try again!</h1>
      </div>

      <div *ngIf="!shownMarketplaceListings">
        <div class="skeleton-block"></div>
      </div>
    </div>
  </div>
</div>