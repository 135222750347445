import { HttpContext, HttpContextToken } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { map } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { AltArt, Gacha, GetGachaOwnedByUserWithAltArtResponseAltArtOwnershipNestedDetails, UserHasGachas } from 'src/app/openapi/models';
import { AltArtServiceService } from 'src/app/openapi/services';

@Component({
  selector: 'app-view-user-owned-alt-art',
  templateUrl: './view-user-owned-alt-art.component.html',
  styleUrl: './view-user-owned-alt-art.component.scss'
})
export class ViewUserOwnedAltArtComponent implements OnInit {

  @Input({ required: true })
  userUUID?: string;

  gachas?: Map<String, Gacha>;
  altArt?: {
    [key: string]: GetGachaOwnedByUserWithAltArtResponseAltArtOwnershipNestedDetails;
  };

  // Doesn't get set if the user isn't logged in
  gachaIsSet?: {
    // Map gacha ID -> alt art ID -> boolean
    [key: string]: {
      [key: number]: boolean;
    };
  };

  constructor(private altArtService: AltArtServiceService, private auth: AuthService) {
  }

  ngOnInit(): void {
    this.loadAltArts();
  }

  swapper: boolean = false;

  loadAltArts(): void {
    if (!this.userUUID) {
      return;
    }
    this.auth.user$.pipe(
      map(user => user?.user_uuid),
      map(loggedInUserUUID => loggedInUserUUID === this.userUUID),
      map(isSelf => {
        this.altArtService.altArtServiceGetGachaOwnedByUserWithAltArt({
          userUuid: this.userUUID!,
          onlyIncludeGachaWithOwnedAltArt: true,
          c: (new Date()).getSeconds()
        }).subscribe(altArt => {
          this.gachas = altArt.userHasGachas?.gachas?.reduce((map, gacha) => {
            map.set(gacha.gacha!.id!, gacha.gacha!);
            return map;
          }, new Map<String, Gacha>());
          this.altArt = altArt.altArtOwnershipDetails;
          if (isSelf) {
            this.gachaIsSet = Object.entries(altArt.altArtOwnershipDetails || {}).reduce((map, [gachaId, a]) => {
              map[gachaId] = (a.altArtOwnershipDetails || []).reduce((map: { [key: number]: boolean }, altArt) => {
                const isSet = altArt.isCurrentlySetAltArt === true;
                map[altArt.altArtId!] = !isSet;
                return map;
              }, {} as { [key: number]: boolean });
              return map;
            }, {} as { [key: string]: { [key: number]: boolean } });
          }
        });
      })
    ).subscribe();
  }

  findAltArtForGachaId(gachaId: string, altArtId: number): AltArt | undefined {
    if (!this.altArt || !this.gachas) {
      return undefined;
    }
    const gacha = this.gachas.get(gachaId);
    if (!gacha) {
      return undefined;
    }

    const ret = gacha.altArts?.find(altArt => {
      return altArt.artId === altArtId;
    });
    return ret;
  }
}
