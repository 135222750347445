<div class="container">

  <h1 class="title">Website Settings</h1>

  <div class="columns">
    <div class="column">
      <h2 class="subtitle">Hide Achievements</h2>
      <div class="field">
        <label class="checkbox">
          <input (change)="checkboxChange($event, .25)" [checked]="getAchievementCookie(.25)" type="checkbox">
          Hide 25% achievements
        </label>
      </div>
      <div class="field">
        <label class="checkbox">
          <input (change)="checkboxChange($event, .5)" [checked]="getAchievementCookie(.5)" type="checkbox">
          Hide 50% achievements
        </label>
      </div>
      <div class="field">
        <label class="checkbox">
          <input (change)="checkboxChange($event, .75)" [checked]="getAchievementCookie(.75)" type="checkbox">
          Hide 75% achievements
        </label>
      </div>
      <div class="field">
        <label class="checkbox">
          <input (change)="checkboxChange($event, 1)" [checked]="getAchievementCookie(1)" type="checkbox">
          Hide 100% achievements
        </label>
      </div>
    </div>
  </div>
  <ng-container *ngIf="userSettings">
    <hr>
    <h1 class="title">Upa Bot Settings</h1>
    <div class="column">
      <div class="field">
        <label class="label">
          Automatically mark card tradeable status as you claim
        </label>
        <div class="control">
          <div class="select">
            <select>
              <option *ngFor="let option of tradeableStatus">
                {{ stringifyTradeableStatus(option) }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="field">
        <label class="checkbox">
          <input type="checkbox" [(ngModel)]="userSettings.sendVoteReminder">
          Send vote reminder
        </label>
      </div>
      <ng-container *ngIf="isSupporter">
        <div class="field">
          <label class="checkbox">
            <input disabled type="checkbox" [(ngModel)]="userSettings.autoStarpower">
            <s>Automatic starpower duplicate gacha less than 5⭐</s>
            Removed for per-rarity auto starpower below
          </label>
        </div>
        <div class="field">
          <label class="checkbox">
            <input disabled type="checkbox" [(ngModel)]="userSettings.autoDust">
            <s>Automatic dust duplicate gacha less than 5⭐</s>
            Removed for per-rarity auto dust below
          </label>
        </div>
        <div class="field">
          <label class="checkbox">
            <input type="checkbox" [(ngModel)]="userSettings.autoRollHourly">
            When you multiroll, automatically use all your hourly rolls
          </label>
        </div>
        <hr>
        <h1 class="title">Roll Reminder</h1>
        <h2 class="subtitle">Upa will send you a PM ~35 minutes after the hour to remind you to roll if you have any
          left!</h2>
        <p>Timezones are local to your browser: {{ timezone }}</p>
        <table class="table is-bordered is-hoverable is-narrow">
          <tbody>
            <tr>
              <th>12 AM</th>
              <th>1 AM</th>
              <th>2 AM</th>
              <th>3 AM</th>
              <th>4 AM</th>
              <th>5 AM</th>
              <th>6 AM</th>
              <th>7 AM</th>
              <th>8 AM</th>
              <th>9 AM</th>
              <th>10 AM</th>
              <th>11 AM</th>
            </tr>
            <tr>
              <td> <input type="checkbox" [(ngModel)]="localizedRollReminder![0]"> </td>
              <td> <input type="checkbox" [(ngModel)]="localizedRollReminder![1]"> </td>
              <td> <input type="checkbox" [(ngModel)]="localizedRollReminder![2]"> </td>
              <td> <input type="checkbox" [(ngModel)]="localizedRollReminder![3]"> </td>
              <td> <input type="checkbox" [(ngModel)]="localizedRollReminder![4]"> </td>
              <td> <input type="checkbox" [(ngModel)]="localizedRollReminder![5]"> </td>
              <td> <input type="checkbox" [(ngModel)]="localizedRollReminder![6]"> </td>
              <td> <input type="checkbox" [(ngModel)]="localizedRollReminder![7]"> </td>
              <td> <input type="checkbox" [(ngModel)]="localizedRollReminder![8]"> </td>
              <td> <input type="checkbox" [(ngModel)]="localizedRollReminder![9]"> </td>
              <td> <input type="checkbox" [(ngModel)]="localizedRollReminder![10]"> </td>
              <td> <input type="checkbox" [(ngModel)]="localizedRollReminder![11]"> </td>

            </tr>
            <tr>
              <th>12 PM</th>
              <th>1 PM</th>
              <th>2 PM</th>
              <th>3 PM</th>
              <th>4 PM</th>
              <th>5 PM</th>
              <th>6 PM</th>
              <th>7 PM</th>
              <th>8 PM</th>
              <th>9 PM</th>
              <th>10 PM</th>
              <th>11 PM</th>
            </tr>
            <tr>
              <td> <input type="checkbox" [(ngModel)]="localizedRollReminder![12]"> </td>
              <td> <input type="checkbox" [(ngModel)]="localizedRollReminder![13]"> </td>
              <td> <input type="checkbox" [(ngModel)]="localizedRollReminder![14]"> </td>
              <td> <input type="checkbox" [(ngModel)]="localizedRollReminder![15]"> </td>
              <td> <input type="checkbox" [(ngModel)]="localizedRollReminder![16]"> </td>
              <td> <input type="checkbox" [(ngModel)]="localizedRollReminder![17]"> </td>
              <td> <input type="checkbox" [(ngModel)]="localizedRollReminder![18]"> </td>
              <td> <input type="checkbox" [(ngModel)]="localizedRollReminder![19]"> </td>
              <td> <input type="checkbox" [(ngModel)]="localizedRollReminder![20]"> </td>
              <td> <input type="checkbox" [(ngModel)]="localizedRollReminder![21]"> </td>
              <td> <input type="checkbox" [(ngModel)]="localizedRollReminder![22]"> </td>
              <td> <input type="checkbox" [(ngModel)]="localizedRollReminder![23]"> </td>
            </tr>
          </tbody>
        </table>

        <hr>

        <h1 class="title">Auto-claim Rarity</h1>
        <h2 class="subtitle">Choose rarity where you will <em>auto-claim</em> non-duplicates</h2>
        <div class="field">
          <div class="select is-multiple"
            *ngIf="userSettings.autoClaimStars && userSettings.autoClaimStars.autoClaimStars">
            <mat-form-field>
              <mat-label>Rarity</mat-label>
              <mat-select [(ngModel)]="selectedAutoClaimRarities" (ngModelChange)="onSelectAutoClaimRarity($event)"
                [panelWidth]="null" multiple>
                @for (rarity of rarities; track rarity) {
                <mat-option [value]="rarity">{{rarity}} ⭐</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <hr>

        <h1 class="title">Auto-starpower Rarity</h1>
        <h2 class="subtitle">Choose rarity where you will <em>auto-starpower</em> duplicates</h2>
        <div class="field">
          <div class="select is-multiple"
            *ngIf="userSettings.autoStarpowerByRarity && userSettings.autoStarpowerByRarity.autoStarpower">
            <mat-form-field>
              <mat-label>Rarity</mat-label>
              <mat-select [(ngModel)]="selectedAutoStarpowerRarities"
                (ngModelChange)="onSelectAutoStarpowerRarity($event)" [panelWidth]="null" multiple>
                @for (rarity of rarities; track rarity) {
                <mat-option [value]="rarity">{{rarity}} ⭐</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <hr>

        <h1 class="title">Auto-dust Rarity</h1>
        <h2 class="subtitle">Choose rarity where you will <em>auto-dust</em> duplicates</h2>
        <div class="field">
          <div class="select is-multiple"
            *ngIf="userSettings.autoDustByRarity && userSettings.autoDustByRarity.autoDust">
            <mat-form-field>
              <mat-label>Rarity</mat-label>
              <mat-select [(ngModel)]="selectedAutoDustRarities" (ngModelChange)="onSelectAutoDustRarity($event)"
                [panelWidth]="null" multiple>
                @for (rarity of rarities; track rarity) {
                <mat-option [value]="rarity">{{rarity}} ⭐</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <hr>

        <h1 class="title">Auto-claim Medias</h1>
        <h2 class="subtitle">Choose media where you will <em>auto-claim</em> non-duplicates</h2>
        <div class="field">
          <div class="select is-multiple"
            *ngIf="userSettings.autoClaimMedia && userSettings.autoClaimMedia.autoClaimMedia">
            <mat-form-field>
              <mat-label>Media</mat-label>
              <mat-select [(ngModel)]="selectedAutoClaimMedia" (ngModelChange)="onSelectAutoClaimMedia($event)"
                [panelWidth]="null" multiple>
                @for (media of medias; track media) {
                <mat-option [value]="media.id">{{media.name}}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="field is-grouped">
          <p class="control">
            <button class="button is-info" (click)="selectAllAutoClaimMedia()">Select All</button>
          </p>
          <p class="control">
            <button class="button is-warning" (click)="deselectAllAutoClaimMedia()">Unselect All</button>
          </p>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <hr>
  <button class="button is-primary" (click)="saveSettings()">Save</button>
</div>