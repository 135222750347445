import { AfterViewInit, Component, OnInit } from '@angular/core';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { filter, switchMap, take } from 'rxjs';
import { getProtoFieldNumberForUserAccessRights } from 'src/app/auth/auth-guard';
import { AuthService, User } from 'src/app/auth/auth.service';
import { Notification, UserAccessRights } from 'src/app/openapi/models';
import { NotificationServiceService } from 'src/app/openapi/services';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  faCog = faCog;
  faBell = faBell;
  constructor(public auth: AuthService,
    private notificationService: NotificationServiceService
  ) { }

  unreadNotifications: Notification[] = [];
  readNotifications: Notification[] = [];
  notificationsToggled = false;
  toggleNotifications() {
    this.notificationsToggled = !this.notificationsToggled;
    if (this.notificationsToggled && this.unreadNotifications.length > 0) {
      // Presumably the user has read all unread, so mark them and move them to read.
      this.auth.user$.pipe(
        take(1),
        filter(u => !!u && !!u.user_uuid),
        switchMap(u => this.notificationService.notificationServiceMarkNotificationsAsReadBulk(
          {
            "userUuid": u!.user_uuid!,
            "notificationIds": this.unreadNotifications.filter(n => !!n.id).map(n => n.id!)
          }
        ))
      ).subscribe(() => {
        this.readNotifications = this.readNotifications.concat(this.unreadNotifications).sort((a, b) => {
          return new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime();
        });
        this.unreadNotifications = [];
      });
    }
  }

  ngOnInit(): void {
    this.auth.user$.pipe(
      take(1),
      filter(u => !!u && !!u.user_uuid),
      switchMap(u => this.notificationService.notificationServiceGetNotifications({ "userUuid": u!.user_uuid!, "includeAcknowledged": true }))
    ).subscribe(n => {
      n.notifications?.forEach(n2 => {
        if (n2.acknowledgedAt) {
          this.readNotifications.push(n2);
        } else {
          this.unreadNotifications.push(n2);
        }
      });
    })
  }

  hamburgerToggled = false;
  toggleHamburger() {
    this.hamburgerToggled = !this.hamburgerToggled;
  }

  canViewAdminTab(u: User): boolean {
    return includesAny(u, "GACHA_APPROVER", "GACHA_EDITOR", "BANNER_EDITOR", "MEDIA_CREATOR", "ABUSE_VIEWER", "BAN_POWER", "ALT_ART_EDITOR");
  }

  canViewUpload(u: User): boolean {
    return includesAny(u, "GACHA_EDITOR", "BANNER_EDITOR", "MEDIA_CREATOR");
  }

  canViewStaging(u: User): boolean {
    return includesAny(u, "GACHA_APPROVER", "GACHA_EDITOR");
  }

  canViewBanners(u: User): boolean {
    return includesAny(u, "BANNER_EDITOR");
  }

  canViewGachaList(u: User): boolean {
    return includesAny(u, "GACHA_APPROVER", "GACHA_EDITOR", "BANNER_EDITOR", "MEDIA_CREATOR");
  }

  canViewStagingEquipmentUpload(u: User): boolean {
    return includesAny(u, "EQUIPMENT_EDITOR");
  }

  canViewStagingEquipment(u: User): boolean {
    return includesAny(u, "EQUIPMENT_EDITOR", "EQUIPMENT_APPROVER");
  }

  canViewAltArt(u: User): boolean {
    return includesAny(u, "GACHA_EDITOR", "ALT_ART_EDITOR");
  }

  canViewAbuse(u: User): boolean {
    return includesAny(u, "ABUSE_VIEWER", "BAN_POWER");
  }

  canViewGiveaway(u: User): boolean {
    return includesAny(u, "GIVEAWAY_MANAGER");
  }

}

export function includesAny(u: User, ...rights: UserAccessRights[]): boolean {
  if (!u || !u.rights) {
    return false;
  }
  if (u.rights.includes(getProtoFieldNumberForUserAccessRights("SUPERUSER"))) {
    return true;
  }

  for (const right of rights) {
    if (u.rights.includes(getProtoFieldNumberForUserAccessRights(right))) {
      return true;
    }
  }
  return false;
}