import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, map, take } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { AltArt, ExtractCharacterFromUrlResponse, Gacha, GachaCategory, GachaOrientation, Media, Origin, StageGachaRequest } from 'src/app/openapi/models';
import { GACHA_CATEGORY } from 'src/app/openapi/models/gacha-category-array';
import { GACHA_ORIENTATION } from 'src/app/openapi/models/gacha-orientation-array';
import { ORIGIN } from 'src/app/openapi/models/origin-array';
import { AdminServiceService, GachaServiceService, MediaServiceService } from 'src/app/openapi/services';

// temp cache to map character URL to its character to better support
// jumping around in the multi-upload UI
const cache = new Map<string, ExtractCharacterFromUrlResponse>();

export interface Character {
  name: string;
  media: string;
  origin: string;
  image_base_64: string;
  media_id: string;
}

export function ResizeImageTo(base64Image: string, width: number, aspectRatio: number): string {
  const img = new Image();
  img.src = base64Image;
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    console.error("could not get 2d context");
    return base64Image;
  }
  ctx.imageSmoothingEnabled = true;
  ctx.imageSmoothingQuality = "high";
  canvas.width = width;
  // Calculate the height of the image as just relative to the aspect ratio and width
  const height = width / aspectRatio;
  canvas.height = height;
  ctx.drawImage(img, 0, 0, width, height);
  return canvas.toDataURL();
}


export function CalculateCropperMinWidth(g: Gacha): number {
  return CalculateCropperMinWidthOrientation(g.orientation!);
}

export function CalculateCropperResizeToWidth(g: Gacha): number {
  return CalculateCropperResizeToWidthOrientation(g.orientation!);
}

export function CalculateAspectRatio(g: Gacha): number {
  return CalculateAspectRatioOrientation(g.orientation!);
}

export function CalculateCropperMinWidthOrientation(o: GachaOrientation): number {
  switch (o) {
    case "NORMAL":
      return 384;
    case "WIDE":
      return 683;
    case "SQUARE":
      return 384;
    default:
      return 384;
  }
}

export function CalculateCropperResizeToWidthOrientation(o: GachaOrientation): number {
  switch (o) {
    case "NORMAL":
      return 576;
    case "WIDE":
      return 968;
    case "SQUARE":
      return 576;
    default:
      return 576;
  }
}

export function CalculateAspectRatioOrientation(o: GachaOrientation): number {
  switch (o) {
    case "NORMAL":
      return 1 / 1.41421356237;
    case "WIDE":
      return 1.6 / 1;
    case "SQUARE":
      return 1;
    default:
      return 1 / 1.41421356237;
  }
}


@Component({
  selector: 'app-upload-form',
  templateUrl: './upload-form.component.html',
  styleUrls: ['./upload-form.component.scss']
})
export class UploadFormComponent implements OnInit, AfterViewInit, OnChanges {
  calcMinWidth = CalculateCropperMinWidth;
  calcResizeToWidth = CalculateCropperResizeToWidth;
  calcAspectRatio = CalculateAspectRatio;

  // The variables in the forms
  gacha: Gacha = {}

  media: Media = {
    coverImageUrl: 'https://cdn.upa.moe/256x362.png',
  }

  mediaOriginCategories: Origin[];

  @Input()
  extractUrl?: string;

  @Input()
  autoSelectMedia: boolean = true;
  deselectAfterSubmit = true;
  // End vars in forms

  categoryOptions: GachaCategory[];
  rarityOptions = [1, 2, 3, 4, 5];
  orientationOptions = GACHA_ORIENTATION.filter(o => o !== "UNDEFINED_ORIENTATION");
  parentGachas: Gacha[] = [];
  gachaForSelectedMedia: Gacha[] = [];

  @Output()
  successfulSubmit = new EventEmitter();

  medias?: Media[];
  image_blob?: string;

  @ViewChild('input')
  inputElement!: ElementRef;

  @ViewChild(ImageCropperComponent)
  imageCropper!: ImageCropperComponent;

  @ViewChild("imageRenderer")
  imageRenderer!: ElementRef;

  @ViewChild("extractInput")
  extractInput!: ElementRef;

  imgChangeEvt: any = '';
  cropImgPreview: any = '';
  imageBase64String: string = "";

  isRollableGacha: boolean = true;
  isTradeableGacha: boolean = true;

  onSelectParentGacha(g: Gacha[]) {
    this.parentGachas = g;
  }

  onSelectMedia(mediaID: string) {
    this.gachaForSelectedMedia = [];
    this.gachaService.gachaServiceGetAllGachaForMedia({ mediaId: mediaID }).subscribe(g => {
      this.gachaForSelectedMedia = g.gachas || [];
    });
  }

  onFileChange(event: any): void {
    this.imgChangeEvt = event;
  }
  cropImg(e: ImageCroppedEvent) {
    this.cropImgPreview = e.base64;
    this.image_blob = e.base64!.split(",")[1];
    // this.gacha.image_blob = e.base64!.split(",")[1];
  }

  imgLoad() {
    // display cropper tool
  }

  initCropper() {
    // init cropper
  }

  imgFailed() { }

  onPaste(e: ClipboardEvent) {
    let file: File | undefined | null;
    for (let i = 0; i < e.clipboardData?.items.length!; i++) {
      const type = e.clipboardData?.items[i].type;
      if (type === "image/png" || type === "image/jpg") {
        file = e.clipboardData?.items[i].getAsFile();
        break;
      }
    }

    if (!file) {
      this.toastr.error("no image found in clipboard!");
      return;
    }

    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageBase64String = (event.target.result); //event.target.results contains the base64 code to create the image.
    };
    reader.readAsDataURL(file);//Convert the blob from clipboard to base64
  }

  constructor(private adminService: AdminServiceService,
    private mediaService: MediaServiceService,
    private toastr: ToastrService,
    private authService: AuthService,
    private gachaService: GachaServiceService,
    private cdr: ChangeDetectorRef) {
    this.mediaOriginCategories = [];
    for (const o of ORIGIN) {
      if (o === "UNDEFINED_ORIGIN") {
        continue;
      }
      this.mediaOriginCategories.push(o);
    }

    this.categoryOptions = [];
    for (const g of GACHA_CATEGORY) {
      if (g === "UNDEFINED_CATEGORY") {
        continue;
      }
      this.categoryOptions.push(g);
    }
  }

  submitGacha() {
    if (!this.gacha || !this.gacha.name || !this.gacha.category ||
      !this.gacha.rarity || !this.image_blob) {
      this.toastr.error("Please fill out all fields");
      return;
    }
    this.gacha.name = this.gacha.name.trim();
    const req: StageGachaRequest = {
      name: this.gacha.name,
      imageBlob: this.image_blob,
      category: this.gacha.category,
      mediaId: this.gacha.mediaId,
      rarity: this.gacha.rarity,
      orientation: this.gacha.orientation,
      nonRollableGacha: !this.isRollableGacha,
      tradeableGacha: this.isTradeableGacha,
      parentGachaIds: this.parentGachas.map(g => g.id!),
    };

    if (this.gacha.description && this.gacha.description.length > 0) {
      req.description = this.gacha.description;
    }

    if (this.gacha.rarity < 1 || this.gacha.rarity > 5) {
      this.toastr.error("Rarity must be between 1 and 5");
      return;
    }

    if (this.gacha.category == "UNDEFINED_CATEGORY") {
      this.toastr.error("Please select gacha category");
      return;
    }

    if (this.gacha.orientation == "UNDEFINED_ORIENTATION") {
      this.toastr.error("Please select gacha orientation");
      return;
    }

    if (this.gacha.category === "DUO" && this.parentGachas.length < 2) {
      this.toastr.error("Please select 2 or more parent gachas for duo gacha");
      return;
    }

    this.authService.user$.pipe(take(1)).subscribe(u => {
      if (!u) {
        this.toastr.error("Not logged in!");
        return;
      }
      req.creatorDiscordId = u.id;
      this.adminService.adminServiceStageGacha({ body: req }).subscribe(g => {
        this.toastr.success(JSON.stringify(g));
        this.extractInput.nativeElement.select();
        // Always clear parent gachas after a successful submit
        this.parentGachas = [];
        this.successfulSubmit.emit();
        if (this.deselectAfterSubmit) {
          this.gacha.category = "UNDEFINED_CATEGORY";
          this.gacha.rarity = 0;
          this.gacha.name = "";
        }
      }, err => this.toastr.error(JSON.stringify(err)));
    });

  }

  extract(e: string) {
    if (cache.has(e)) {
      this.toastr.success("frontend cache hit, thank you for not refreshing your page a lot");
      let c = cache.get(e)!;
      this.imageBase64String = "data:image/png;base64," + c.character?.imageBlob;
      this.gacha.name = c.character?.name?.trim();
      if (this.autoSelectMedia)
        this.gacha.mediaId = c.character?.mediaId;
      cache.set(e, c);
      if (c.matches && c.matches.length > 0) {
        this.toastr.error(`Potential matches in database already: ${c.matches}`)
      }
      return;
    }

    this.adminService.adminServiceExtractCharacterFromUrl({ body: { url: e } }).subscribe(c => {
      this.toastr.success("success");
      this.imageBase64String = "data:image/png;base64," + c.character?.imageBlob;
      this.gacha.name = c.character?.name?.trim();
      if (this.autoSelectMedia)
        this.gacha.mediaId = c.character?.mediaId;
      cache.set(e, c);
      if (c.matches && c.matches.length > 0) {
        this.toastr.error(`Potential matches in database already: ${c.matches}`)
      }
    }, err => this.toastr.error(JSON.stringify(err)));
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    const mediaList$ = this.mediaService.mediaServiceGetAllMedia().pipe(take(1));

    forkJoin(mediaList$).subscribe(
      m => {
        this.medias = m[0].media || [];
        // this.mediaOriginCategories = m[1];
        console.log(`completed with val: ${this.extractUrl}`)
        if (this.extractUrl) {
          this.extract(this.extractUrl);
        }
        this.cdr.detectChanges();
      },
      err => this.toastr.error(JSON.stringify(err))
    );
  }

  stringifyOrigin(o: Origin): string {
    return o;
  }

  stringifyCategory(c: GachaCategory): string {
    return c as string;
  }

  ngOnChanges(_: SimpleChanges) {
    if (this.extractUrl) {
      console.log(`detected change to: ${this.extractUrl}`);
      this.extract(this.extractUrl);
    }
  }
}
