<div class="container">
  <section>
    <nav #statistics class="level is-skeleton">
      <div class="level-item has-text-centered">
        <div>
          <p class="heading has-text-white-ter">Total Gacha</p>
          <p class="title has-text-white">{{ stats ? (stats.totalGacha | number) : '~' }}</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading has-text-white-ter">Claimed Gacha</p>
          <p class="title has-text-white">{{ stats ? (stats.totalClaimedGacha | number) : '~' }}</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading has-text-white-ter">Rolls</p>
          <p class="title has-text-white">{{ stats ? (stats.totalRolls | number) : '~' }}</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading has-text-white-ter">Drops</p>
          <p class="title has-text-white">{{ stats ? (stats.totalDrops | number) : '~' }}</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading has-text-white-ter">Players</p>
          <p class="title has-text-white">{{ stats ? (stats.totalUsers | number) : '~' }}</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading has-text-white-ter">Servers</p>
          <p class="title has-text-white">{{ stats ? (stats.totalServers | number) : '~' }}</p>
        </div>
      </div>
    </nav>
  </section>
  <hr>
  <section>
    <div class="grid">
      <div class="cell">
        <div class="cell">
          <article class="box">
            <p class="title">What is Upa?</p>
            <p class="subtitle">Upa is a Discord bot lets you pull your favorite characters from popular anime,
              manga, games, and more. Collect, show off, and get your name on the leaderboards!</p>
          </article>
          <article class="box">
            <p class="title">How do I start?</p>
            <p class="subtitle">
              Simply invite the bot to your server and use <code>/roll</code>! After you start, check out the <a
                [routerLink]="['/guide']">guide</a> to learn more.
            </p>
            <div class="content">
              <section>
                <a [routerLink]="['/invite']" style="text-decoration: none;">
                  <button class="button is-success">
                    Invite Upa
                  </button>
                </a>
              </section>
            </div>
          </article>
          <article class="box">
            <p class="title">I don't see the character I want :(</p>
            <p class="subtitle">
              Join our Discord server and help suggest more characters!
            </p>
            <div class="content">
              <section>
                <a [routerLink]="['/server']" style="text-decoration: none;">
                  <button class="button is-primary">
                    Join our Discord
                  </button>
                </a>
              </section>
            </div>
          </article>
        </div>
      </div>
      <div class="cell">
        <article class="box">
          <div class="content">
            <p class="title">Random character you can pull 👀</p>
            <div class="content has-text-centered">
              <app-gacha-v2 *ngIf="gacha" [gacha]="gacha" [media]="media"></app-gacha-v2>
            </div>
          </div>
        </article>
      </div>
    </div>
    <div class="cell">
      <div class="cell">
        <article class="box">
          <p class="title">Why this bot?</p>
          <div class="columns">
            <div class="column is-half">
              <div class="content">
                <ul>
                  <li>A rarity system from 1 through 5 stars, including rate-up events</li>
                  <li>Personalize your profile with your favorite cards, franchises, and achievements</li>
                  <li>Global leaderboards & marketplace - show off your achievements and find your favorites</li>
                  <li>Compete with others on higher minted cards. Seek the 1st edition!</li>
                </ul>
              </div>
            </div>
            <div class="column is-half">
              <div class="content">
                <ul>
                  <li>Decorate your cards with beautiful borders and effects</li>
                  <li>Rewards for pulling more and being in an active server</li>
                  <li>Trade characters, wishlist, and receive selector tickets</li>
                  <li>A community-driven effort to add more characters and suggest more features</li>
                </ul>
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
  </section>
</div>