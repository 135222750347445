/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ApiHttpBody } from '../../models/api-http-body';

export interface GachaServiceGenerateGachaInstanceImage$Params {
  gachaInstanceId: string;
}

export function gachaServiceGenerateGachaInstanceImage(http: HttpClient, rootUrl: string, params: GachaServiceGenerateGachaInstanceImage$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiHttpBody>> {
  const rb = new RequestBuilder(rootUrl, gachaServiceGenerateGachaInstanceImage.PATH, 'get');
  if (params) {
    rb.path('gachaInstanceId', params.gachaInstanceId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ApiHttpBody>;
    })
  );
}

gachaServiceGenerateGachaInstanceImage.PATH = '/api/v3/gateway/gacha/instance/{gachaInstanceId}/image';
