import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subject, combineLatest, debounceTime, distinctUntilChanged, filter, map, merge, mergeMap, of, startWith, switchMap, tap } from 'rxjs';
import { GachaServiceSearchUserGacha$Params } from 'src/app/openapi/fn/gacha-service/gacha-service-search-user-gacha';
import { Media, UserHasGacha, UserHasGachas } from 'src/app/openapi/models';
import { GachaServiceService, MediaServiceService } from 'src/app/openapi/services';

@Component({
  selector: 'app-empty-edit-favorite-slot',
  templateUrl: './empty-edit-favorite-slot.component.html',
  styleUrl: './empty-edit-favorite-slot.component.scss'
})
export class EmptyEditFavoriteSlotComponent implements OnInit {

  @ViewChild('cardSearchModal')
  cardSearchModal!: ElementRef;

  @Output()
  setFavoriteSlot: EventEmitter<{ gachaID: string, priority: number }> =
    new EventEmitter<{ gachaID: string, priority: number }>();

  @Input({ required: true })
  userUUID!: string;

  @Input({ required: true })
  priority!: number;

  medias: Media[] = [];

  gachaResults$!: Observable<UserHasGacha[]>;
  private searchGachaText$ = new Subject<string>();
  private raritySelected$ = new Subject<number>();
  private mediaSelect$ = new Subject<string>();

  constructor(private gachaService: GachaServiceService,
    private mediaService: MediaServiceService) { }

  ngOnInit(): void {
    const searchPipe = this.searchGachaText$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      startWith(''),
    );

    const rarityPipe = this.raritySelected$.pipe(
      filter(rarity => rarity > 0 && rarity <= 5),
      startWith(0),
    );

    const mediaPipe = this.mediaSelect$.pipe(
      filter(mediaID => mediaID.length > 0),
      startWith(''),
    );

    this.gachaResults$ = combineLatest([searchPipe, rarityPipe, mediaPipe]).pipe(
      switchMap(([text, rarity, mediaID]) => {
        if (text.length === 0 && rarity === 0 && mediaID.length === 0) {
          return of([]);
        }
        const req: GachaServiceSearchUserGacha$Params = {
          userUuid: this.userUUID,
          body: {
            searchSlug: text,
          }
        };
        if (rarity > 0 && rarity <= 5) {
          req.body.rarity = rarity;
        }
        if (mediaID.length > 0) {
          req.body.mediaId = mediaID;
        }
        return this.gachaService.gachaServiceSearchUserGacha(req).pipe(
          filter(res => res.gacha !== undefined),
          map(res => res.gacha!.gachas?.sort((a, b) => {
            if (a.gacha?.rarity === b.gacha?.rarity) {
              return a.gacha?.name?.localeCompare(b.gacha?.name!) || 0;
            }
            return (b.gacha?.rarity || 0) - (a.gacha?.rarity || 0);
          }) || [])
        )
      })
    )
  }

  selectRarity(rarity: number) {
    if (rarity < 0 || rarity > 5) {
      throw new Error('Invalid rarity');
    }
    this.raritySelected$.next(rarity);
  }

  selectMedia(mediaID: string) {
    this.mediaSelect$.next(mediaID);
  }

  setFavorite(gachaID: string) {
    this.setFavoriteSlot.emit({ gachaID: gachaID, priority: this.priority });
    this.closeSearchModal();
  }

  searchUserGacha(text: string) {
    this.searchGachaText$.next(text);
  }

  getValue(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  showSearchModal() {
    if (this.medias.length === 0) {
      this.mediaService.mediaServiceGetAllMedia().subscribe(res => {
        this.medias = res.media || [];
      });
    }
    this.cardSearchModal.nativeElement.classList.add('is-active');
  }

  closeSearchModal() {
    this.cardSearchModal.nativeElement.classList.remove('is-active');
  }
}
