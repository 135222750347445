import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap, map, tap } from 'rxjs/operators';
import { mergeMap, of, combineLatest } from 'rxjs';
import { generateUserActivityCssClassV2, generateUserActivityV2 } from 'src/app/util/date_transform';
import AsyncLock from 'async-lock';
import { Media, Gacha, GetGachaOwnersResponseOwnerDetails, TradeableStatus } from 'src/app/openapi/models';
import { GachaServiceService, MediaServiceService } from 'src/app/openapi/services';

@Component({
  selector: 'app-view-gacha',
  templateUrl: './view-gacha.component.html',
  styleUrls: ['./view-gacha.component.scss']
})
export class ViewGachaComponent implements OnInit {

  scrollDownLock = new AsyncLock();
  nextToken?: string;
  gacha?: Gacha;
  numberOfCopies?: number;
  media?: Media;
  gachaOwners: GetGachaOwnersResponseOwnerDetails[] = [];
  constructor(private api: GachaServiceService, private mediaService: MediaServiceService, private route: ActivatedRoute) {
    this.route.data.subscribe(_ => { });
  }

  ngOnInit(): void {
    this.route.params.pipe(
      switchMap(p => this.api.gachaServiceGetGacha2({ gachaIds: p['gachaID'] })),
      mergeMap(res => {
        if (!res.gacha) {
          throw new Error('Gacha not found');
        }
        if (res.gacha.length === 0) {
          throw new Error('Gacha not found');
        }

        const numberOfGacha = this.api.gachaServiceGetNumberOfGacha({
          body: {
            gachaId: res.gacha ? res.gacha.map(g => g.id!) : [],
          }
        });
        return combineLatest([of(res.gacha[0]), numberOfGacha]);
      }),
      mergeMap(([res, num]) => {
        return combineLatest([of(res), of(num.gachaIdToCount ? num.gachaIdToCount[res.id!] : 0)]);
      }),
      map(([res, num]) => {
        this.gacha = res;
        this.numberOfCopies = num;
        this.api.gachaServiceGetGachaOwners({
          gachaId: res.id!,
          "paginationOptions.pageSize": 50,
        }).subscribe(owners => {
          this.gachaOwners = owners.owners || [];
          this.nextToken = owners.paginationDetails?.nextPage;
        });

        return res;
      }),
      switchMap(g => this.mediaService.mediaServiceGetMedia({ mediaId: g.mediaId! })),
      tap(m => this.media = m.media)
    ).subscribe();
  }

  buildTradeableStatusLock(s: TradeableStatus): string {
    switch (s) {
      case "KINDA_TRADEABLE":
        return "🔒";
      case "UNTRADEABLE":
        return "🔐";
      case "TRADEABLE":
      default:
        return "";
    }
  }

  onScrollDown() {
    this.scrollDownLock.acquire('scroll-down', (done) => {
      if (!this.gacha || !this.nextToken) {
        done();
        return;
      }
      this.api.gachaServiceGetGachaOwners({
        gachaId: this.gacha.id!,
        "paginationOptions.pageSize": 50,
        "paginationOptions.page": this.nextToken,
      }).subscribe(res => {
        this.gachaOwners = this.gachaOwners.concat(res.owners || []);
        this.nextToken = res.paginationDetails?.nextPage;
        done();
      });
    });
  }

  onScrollUp() { }

  generateUserActivity = generateUserActivityV2;
  generateUserActivityCssClass = generateUserActivityCssClassV2;

  // Render the gacha creation date as a Month, Day, Year string
  // Month as the name of the month though
  addedAt(g: Gacha): string {
    const d = new Date(g.createdAt!);
    return `${d.toLocaleString('default', { month: 'long' })} ${d.getDate()}, ${d.getFullYear()}`;
  }
}
