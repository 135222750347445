import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { interval, map } from 'rxjs';
import { gsap } from 'gsap';

const animationDuration = 10 * 1000; // 10 seconds, keep in sync with the :enter transition
@Component({
  selector: 'app-live-rolls-bottom-pin',
  templateUrl: './live-rolls-bottom-pin.component.html',
  styleUrl: './live-rolls-bottom-pin.component.scss',
})
export class LiveRollsBottomPinComponent implements OnInit {
  @ViewChild('tickerContainer') tickerContainer!: ElementRef;

  constructor() { }

  counter = 0;
  emittable$ = interval(500).pipe(map(() => "test " + this.counter++));
  liveGachaFeed: string[] = [];

  ngOnInit(): void {
    this.emittable$.subscribe((value) => {
      this.liveGachaFeed.push(value);
      this.animateNewItem();
    });
  }

  animateNewItem() {
    const lastItem = this.tickerContainer.nativeElement.lastElementChild;
    console.log('lastItem:', lastItem);
    console.log('Calculated itemWidth:', this.calculateItemWidth());

    if (lastItem) {
      gsap.set(lastItem, { x: '0px' });
      gsap.to(lastItem, {
        duration: 10, // Adjust as needed
        x: `-=600px`,  // Use calculated width
        // modifiers: {
        //   x: (x: string) => `+=${Number(gsap.getProperty(this.tickerContainer.nativeElement, "width")) - this.tickerContainer.nativeElement.children.length * this.calculateItemWidth()}`
        // },
        ease: 'none',
        onStart: () => console.log(`+=${Number(gsap.getProperty(this.tickerContainer.nativeElement, "width")) - this.tickerContainer.nativeElement.children.length * this.calculateItemWidth()}`),
        onComplete: () => this.removeItem()
        // No repeat as it's a single element animation
      });
    }
  }
  calculateItemWidth(): number {
    const firstItem = this.tickerContainer.nativeElement.querySelector('.live-gacha-item');
    if (firstItem) {
      return firstItem.offsetWidth;
    }
    throw new Error("No first item found");
  }

  removeItem() {
    const removed = this.liveGachaFeed.shift();
    console.log("Removed: ", removed);
  }
}
