/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { achievementServiceBatchGenerateAchievementHtml } from '../fn/achievement-service/achievement-service-batch-generate-achievement-html';
import { AchievementServiceBatchGenerateAchievementHtml$Params } from '../fn/achievement-service/achievement-service-batch-generate-achievement-html';
import { achievementServiceGenerateAchievementHtml } from '../fn/achievement-service/achievement-service-generate-achievement-html';
import { AchievementServiceGenerateAchievementHtml$Params } from '../fn/achievement-service/achievement-service-generate-achievement-html';
import { achievementServiceGenerateAchievementImage } from '../fn/achievement-service/achievement-service-generate-achievement-image';
import { AchievementServiceGenerateAchievementImage$Params } from '../fn/achievement-service/achievement-service-generate-achievement-image';
import { ApiHttpBody } from '../models/api-http-body';
import { BatchGenerateAchievementHtmlResponse } from '../models/batch-generate-achievement-html-response';

@Injectable({ providedIn: 'root' })
export class AchievementServiceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `achievementServiceGenerateAchievementHtml()` */
  static readonly AchievementServiceGenerateAchievementHtmlPath = '/api/v3/gateway/htmlgen/achievement';

  /**
   * Used by the bot to check for reminders that need to be sent to users.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `achievementServiceGenerateAchievementHtml()` instead.
   *
   * This method doesn't expect any request body.
   */
  achievementServiceGenerateAchievementHtml$Response(params: AchievementServiceGenerateAchievementHtml$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiHttpBody>> {
    return achievementServiceGenerateAchievementHtml(this.http, this.rootUrl, params, context);
  }

  /**
   * Used by the bot to check for reminders that need to be sent to users.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `achievementServiceGenerateAchievementHtml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  achievementServiceGenerateAchievementHtml(params: AchievementServiceGenerateAchievementHtml$Params, context?: HttpContext): Observable<ApiHttpBody> {
    return this.achievementServiceGenerateAchievementHtml$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiHttpBody>): ApiHttpBody => r.body)
    );
  }

  /** Path part for operation `achievementServiceBatchGenerateAchievementHtml()` */
  static readonly AchievementServiceBatchGenerateAchievementHtmlPath = '/api/v3/gateway/htmlgen/achievement/batch';

  /**
   * Acknowledge that a reminder has been sent to a user, marking it as sent.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `achievementServiceBatchGenerateAchievementHtml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementServiceBatchGenerateAchievementHtml$Response(params: AchievementServiceBatchGenerateAchievementHtml$Params, context?: HttpContext): Observable<StrictHttpResponse<BatchGenerateAchievementHtmlResponse>> {
    return achievementServiceBatchGenerateAchievementHtml(this.http, this.rootUrl, params, context);
  }

  /**
   * Acknowledge that a reminder has been sent to a user, marking it as sent.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `achievementServiceBatchGenerateAchievementHtml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementServiceBatchGenerateAchievementHtml(params: AchievementServiceBatchGenerateAchievementHtml$Params, context?: HttpContext): Observable<BatchGenerateAchievementHtmlResponse> {
    return this.achievementServiceBatchGenerateAchievementHtml$Response(params, context).pipe(
      map((r: StrictHttpResponse<BatchGenerateAchievementHtmlResponse>): BatchGenerateAchievementHtmlResponse => r.body)
    );
  }

  /** Path part for operation `achievementServiceGenerateAchievementImage()` */
  static readonly AchievementServiceGenerateAchievementImagePath = '/api/v3/gateway/imagegen/achievement';

  /**
   * Used by the bot to schedule a reminder for a user to vote.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `achievementServiceGenerateAchievementImage()` instead.
   *
   * This method doesn't expect any request body.
   */
  achievementServiceGenerateAchievementImage$Response(params: AchievementServiceGenerateAchievementImage$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiHttpBody>> {
    return achievementServiceGenerateAchievementImage(this.http, this.rootUrl, params, context);
  }

  /**
   * Used by the bot to schedule a reminder for a user to vote.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `achievementServiceGenerateAchievementImage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  achievementServiceGenerateAchievementImage(params: AchievementServiceGenerateAchievementImage$Params, context?: HttpContext): Observable<ApiHttpBody> {
    return this.achievementServiceGenerateAchievementImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiHttpBody>): ApiHttpBody => r.body)
    );
  }

}
