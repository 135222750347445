import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AchievementServiceService } from 'src/app/openapi/services';
import { Achievement } from 'src/app/openapi/models';

@Pipe({
  name: 'achievementrenderer'
})
export class AchievementrendererPipe {

  constructor(private achievementService: AchievementServiceService,
    private sanitizer: DomSanitizer) { }

  // transform(achievement: Achievement, ...args: unknown[]): Observable<SafeHtml> {
  //   return this.achievementService
  //   .achievementServiceGenerateAchievementHtml({
  //     label: achievement.label,
  //   }).pipe(map(res => this.sanitizer.bypassSecurityTrustHtml(res)));
  // }

}
