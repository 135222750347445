import { inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ResolveFn } from '@angular/router';

export const resolveAllGuilds: ResolveFn<boolean> = (route, state) => {
  const m = inject(Meta);
  m.addTag({ name: 'title', content: `Upa Guilds` })
  m.addTag({ name: 'og:title', content: `Upa Guilds` });
  m.addTag({ name: 'description', content: `Check out all the guilds in Upa and find one to join today!` });
  m.addTag({ name: 'og:description', content: `Check out all the guilds in Upa and find one to join today!` });
  m.addTag({
    name: 'og:image', content: 'https://cdn.upa.moe/JirachiDice256.png'
  });
  return true;
};
