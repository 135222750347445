<div class="container">
  <div class="tile is-ancestor">
    <div class="tile is-parent">
      <div class="columns">
        <div class="column is-2">
          <aside class="menu">
            <p class="menu-label">
              Current Banners
            </p>
            <ul class="menu-list">
              <ng-container *ngFor="let b of banners">
                <ng-container *ngIf="isPresent(b.banner!)">
                  <li>
                    <a (click)="toAnchor(b.banner.id!)">
                      {{ b.banner!.name }}
                    </a>
                  </li>
                </ng-container>
              </ng-container>
            </ul>
            <p class="menu-label">
              Future Banners
            </p>
            <ul class="menu-list">
              <ng-container *ngFor="let b of banners">
                <ng-container *ngIf="isFuture(b.banner!)">
                  <li>
                    <a (click)="toAnchor(b.banner.id!)">
                      {{ b.banner!.name }}
                    </a>
                  </li>
                </ng-container>
              </ng-container>
            </ul>
          </aside>
        </div>

        <div class="tile is-vertical is-12">
          <div class="tile">
            <div class="tile is-parent is-vertical">
              <h1 class="title is-1">Current Banners</h1>
              <div class="columns is-multiline is-fullwidth">
                <ng-container *ngFor="let b of banners;">
                  <div *ngIf="isPresent(b.banner)" class="column is-3" id="{{b.banner.id}}">
                    <app-view-banner [gachaIDToNameMapping]="gachaIDToNameMapping" [banner]="b"></app-view-banner>
                  </div>
                </ng-container>
              </div>
              <div class="tile is-child">
                <h1 class="title is-1">Future Banners</h1>
                <div class="columns is-multiline is-fullwidth">
                  <ng-container *ngFor="let b of banners;">
                    <div *ngIf="isFuture(b.banner)" class="column is-3" id="{{b.banner.id}}">
                      <app-view-banner [gachaIDToNameMapping]="gachaIDToNameMapping" [banner]="b"></app-view-banner>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>