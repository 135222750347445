import { Component, OnInit } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { AdminServiceUpdateMediaImage$Params } from 'src/app/openapi/fn/admin-service/admin-service-update-media-image';
import { Media } from 'src/app/openapi/models';
import { AdminServiceService, MediaServiceService } from 'src/app/openapi/services';

enum ImageType {
  COVER,
  BANNER,
}

interface Image {
  imageBase64String?: string;
  imageBlob?: string;
  imgChangeEvt: any;
  cropImgPreview: any;
}

@Component({
  selector: 'app-view-edit-media',
  templateUrl: './view-edit-media.component.html',
  styleUrls: ['./view-edit-media.component.scss']
})
export class ViewEditMediaComponent implements OnInit {
  imageTypeCover = ImageType.COVER;
  imageTypeBanner = ImageType.BANNER;
  coverImage: Image = {
    imgChangeEvt: "",
    cropImgPreview: "",
  }
  bannerImage: Image = {
    imgChangeEvt: "",
    cropImgPreview: "",
  }


  medias: Media[] = [];
  applyFilter() {
    this.mediaService.mediaServiceGetAllMedia().subscribe((medias) => {
      this.medias = (medias.media || []).filter(m => {
        if (this.filterForNeedCover) {
          return m.coverImageUrl === 'https://cdn.upa.moe/256x362.png';
        }
        if (this.filterForNeedBanner) {
          return m.bannerImageUrl === undefined;
        }
        return true;
      });
    });
  }
  selectedMedia?: Media;
  updateCoverImage = false;
  updateBannerImage = false;
  filterForNeedCover = false;
  filterForNeedBanner = false;

  constructor(private adminService: AdminServiceService, private mediaService: MediaServiceService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.mediaService.mediaServiceGetAllMedia().subscribe((medias) => {
      this.medias = medias.media || [];
    });
  }

  editMedia(media: Media) {
    this.selectedMedia = media;
    this.adminService.adminServiceGetMediaImageData({ mediaId: media.id! }).subscribe((imageData) => {
      this.coverImage.cropImgPreview = imageData.coverImage ?
        `data:${imageData.coverImage?.contentType};base64,${imageData.coverImage?.data}` : undefined;
      this.bannerImage.cropImgPreview = imageData.bannerImage ?
        `data:${imageData.bannerImage?.contentType};base64,${imageData.bannerImage?.data}` : undefined;
    })
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'auto'
    });
  }

  cancelEdit() {
    this.coverImage = {
      imgChangeEvt: "",
      cropImgPreview: "",
    }
    this.bannerImage = {
      imgChangeEvt: "",
      cropImgPreview: "",
    }

    this.selectedMedia = undefined;
    this.updateCoverImage = false;
    this.updateBannerImage = false;
  }

  saveMedia() {
    if (!this.selectedMedia) {
      return;
    }

    const reset = () => {
      this.mediaService.mediaServiceGetAllMedia().subscribe((medias) => {
        this.medias = medias.media || [];
        this.updateCoverImage = false;
        this.updateBannerImage = false;
        this.selectedMedia = undefined;
      });
    }

    const utf8Encode = new TextEncoder();
    const observables = [];

    if (this.updateCoverImage) {
      const req: AdminServiceUpdateMediaImage$Params = {
        mediaId: this.selectedMedia.id!,
        body: {
          coverImageBlob: this.coverImage.imageBlob!,
        }
      };
      observables.push(this.adminService.adminServiceUpdateMediaImage(req));
    }

    if (this.updateBannerImage) {
      const req: AdminServiceUpdateMediaImage$Params = {
        mediaId: this.selectedMedia.id!,
        body: {
          bannerImageBlob: this.bannerImage.imageBlob!,
        }
      };
      observables.push(this.adminService.adminServiceUpdateMediaImage(req));
    }

    if (observables.length > 0) {
      forkJoin(observables).subscribe((results) => {
        results.forEach((media) => {
          console.log(media);
          this.toastr.success(`Image updated!`);
        });
        reset();
      }, (err) => {
        this.toastr.error(`Error updating image: ${err.toString()}`);
      });
    }
  }

  onFileChange(event: any, imageType: ImageType): void {
    switch (imageType) {
      case ImageType.COVER:
        this.coverImage.imgChangeEvt = event;
        break;
      case ImageType.BANNER:
        this.bannerImage.imgChangeEvt = event;
        break;
    }
  }

  cropImg(e: ImageCroppedEvent, imageType: ImageType) {
    switch (imageType) {
      case ImageType.COVER:
        this.coverImage.cropImgPreview = e.base64;
        this.coverImage.imageBlob = e.base64!.split(",")[1];
        break;
      case ImageType.BANNER:
        this.bannerImage.cropImgPreview = e.base64;
        this.bannerImage.imageBlob = e.base64!.split(",")[1];
        if (e.width < 1080) {
          this.toastr.warning(`Banner width of ${e.width} is less than 1080 pixels!`, "WARNING",
            {
              timeOut: 10000,
            })
        }
        break;
    }
  }

  imgLoad() {
    // display cropper tool
  }

  initCropper() {
    // init cropper
  }

  imgFailed() { }

  onPaste(e: ClipboardEvent, type: ImageType) {
    let file: File | undefined | null;
    for (let i = 0; i < e.clipboardData?.items.length!; i++) {
      const type = e.clipboardData?.items[i].type;
      if (type === "image/png" || type === "image/jpg") {
        file = e.clipboardData?.items[i].getAsFile();
        break;
      }
    }

    if (!file) {
      this.toastr.error("no image found in clipboard!");
      return;
    }

    var reader = new FileReader();
    switch (type) {
      case ImageType.COVER:
        reader.onload = (event: any) => {
          this.coverImage.imageBase64String = (event.target.result);
          this.updateCoverImage = true;
        };
        break;
      case ImageType.BANNER:
        reader.onload = (event: any) => {
          this.bannerImage.imageBase64String = (event.target.result);
          this.updateBannerImage = true;
          var image = new Image();
          image.onload = () => {
            if (image.width < 1080) {
              this.toastr.warning(`Banner width of ${image.width} is less than 1080 pixels!`, "WARNING",
                {
                  timeOut: 10000,
                })
            }
          }
          image.src = event.target.result;
        };

        break;
    }
    reader.readAsDataURL(file);
  }
}
