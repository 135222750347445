import { Component, Injector, OnInit } from '@angular/core';
import { imageCropperData } from '../view-staging/view-staging.component';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { catchError, combineLatest, map, mergeMap, of, switchMap, take } from 'rxjs';
import { Gacha, GachaOrientation, Media, ReserveAltArtIdResponseStatus, StageAltArtResponseStatus } from 'src/app/openapi/models';
import { AdminServiceService, GachaServiceService, MediaServiceService } from 'src/app/openapi/services';
import { AdminServiceStageAltArt$Params } from 'src/app/openapi/fn/admin-service/admin-service-stage-alt-art';
import { GACHA_ORIENTATION } from 'src/app/openapi/models/gacha-orientation-array';
import { CalculateAspectRatioOrientation, CalculateCropperMinWidthOrientation, CalculateCropperResizeToWidthOrientation, ResizeImageTo } from '../upload-form/upload-form.component';

interface gachaMediaAndSelection {
  gacha: Gacha;
  media: Media;
  isEditing: boolean;

  altArtName: string;
  altArtDescription: string;
  altArtOrientation: GachaOrientation;

  ngxCropperData: imageCropperData;
}

@Component({
  selector: 'app-stage-alt-art',
  templateUrl: './stage-alt-art.component.html',
  styleUrls: ['./stage-alt-art.component.scss']
})
export class StageAltArtComponent implements OnInit {
  calcMinWidth = CalculateCropperMinWidthOrientation;
  calcResizeToWidth = CalculateCropperResizeToWidthOrientation;
  calcAspectRatio = CalculateAspectRatioOrientation;


  ngOnInit(): void {

  }

  medias: Media[] = [];
  gachas?: gachaMediaAndSelection[];
  selectedMediaID?: string;

  constructor(private adminService: AdminServiceService,
    private mediaService: MediaServiceService,
    private gachaService: GachaServiceService,
    private injector: Injector,
    private toastr: ToastrService) {
    this.mediaService.mediaServiceGetAllMedia().subscribe((medias) => {
      this.medias = medias.media || [];
    });
  }
  orientationOptions = GACHA_ORIENTATION.filter(o => o !== "UNDEFINED_ORIENTATION");

  onSelectMedia(e: string) {
    const spl = e.split(" ");
    var mediaID = e;
    if (spl.length > 1) {
      mediaID = spl[1];
    }
    this.gachaService.gachaServiceGetAllGachaForMedia({ mediaId: mediaID }).subscribe(gachas => {
      this.gachas = (gachas.gachas || []).map(g => (
        {
          gacha: g,
          media: this.medias.find(m => m.id == g.mediaId)!,
          isEditing: false,
          altArtName: '',
          altArtDescription: '',
          altArtOrientation: "NORMAL",
          ngxCropperData: {
            imgChangeEvt: '',
            croppedBase64String: '',
            imageBase64String: '',
            updateImage: false,
          },
        }));
      this.selectedMediaID = mediaID;
    });
  }

  startCreateAltArt(gacha: gachaMediaAndSelection) {
    gacha.isEditing = true;
  }

  saveAltArt(gacha: gachaMediaAndSelection) {
    if (!gacha.isEditing) {
      return;
    }
    if (!gacha.gacha.id) {
      this.toastr.error("Gacha ID is missing!");
      return;
    }
    if (gacha.altArtOrientation === "UNDEFINED_ORIENTATION") {
      this.toastr.error("Please select an orientation");
      return;
    }
    // First reserve Id, then stage it
    this.adminService.adminServiceReserveAltArtId({ gachaId: gacha.gacha.id }).pipe(
      switchMap(res => {
        switch (res.status) {
          case "SUCCESS":
            return of(res.altArtId!);
          case "GACHA_NOT_FOUND":
            this.toastr.error("Gacha not found");
            throw new Error("Gacha not found");
          default:
            this.toastr.error(`Unknown error ${res.status}`);
            throw new Error(`Unknown error ${res.status}`);
        }
      }),
      mergeMap((altArtID) => {
        const resized = ResizeImageTo(gacha.ngxCropperData.croppedBase64String, this.calcResizeToWidth(gacha.altArtOrientation),
          this.calcAspectRatio(gacha.altArtOrientation));
        const req: AdminServiceStageAltArt$Params = {
          body: {
            gachaId: gacha.gacha.id,
            imageBlob: resized.split(",")[1],
            name: gacha.altArtName,
            description: gacha.altArtDescription.length > 0 ? gacha.altArtDescription : undefined,
            altArtId: altArtID,
            orientation: gacha.altArtOrientation,
          }
        };

        return this.adminService.adminServiceStageAltArt(req);
      }),
      map(res => {
        switch (res.status) {
          case "SUCCESS":
            this.toastr.success("Successfully created alt art");
            break;
          case "GACHA_NOT_FOUND":
            this.toastr.error("Gacha not found");
            break;
          default:
            this.toastr.error(`Unknown error ${res.status}`);
            console.log(res);
            break;
        }
        gacha.isEditing = false;
        gacha.altArtDescription = '';
        gacha.altArtName = '';
        gacha.ngxCropperData.croppedBase64String = '';
        gacha.ngxCropperData.imageBase64String = '';
        gacha.ngxCropperData.imgChangeEvt = '';
        gacha.ngxCropperData.updateImage = false;
      }),
      catchError(async (err) => {
        this.toastr.error(JSON.stringify(err));
        console.log(err);
      })
    ).subscribe();
  }

  cancelAltArt(gacha: gachaMediaAndSelection) {
    if (!gacha.isEditing) {
      return;
    }
    gacha.isEditing = false;
    gacha.altArtDescription = '';
    gacha.altArtName = '';
    gacha.ngxCropperData.croppedBase64String = '';
    gacha.ngxCropperData.imageBase64String = '';
    gacha.ngxCropperData.imgChangeEvt = '';
    gacha.ngxCropperData.updateImage = false;
  }

  /**********
   * Image Editing
   **********/
  onFileChange(event: any, gacha: gachaMediaAndSelection): void {
    gacha.ngxCropperData.imgChangeEvt = event;
  }

  cropImg(e: ImageCroppedEvent, gacha: gachaMediaAndSelection) {
    gacha.ngxCropperData.croppedBase64String = e.base64!;
  }

  imgLoad() {
    // display cropper tool
  }

  initCropper() {
    // init cropper
  }

  imgFailed() { }

  onPaste(e: ClipboardEvent, gacha: gachaMediaAndSelection) {
    let file: File | undefined | null;
    for (let i = 0; i < e.clipboardData?.items.length!; i++) {
      const type = e.clipboardData?.items[i].type;
      if (type === "image/png" || type === "image/jpg") {
        file = e.clipboardData?.items[i].getAsFile();
        break;
      }
    }

    if (!file) {
      this.toastr.error("No image found in clipboard");
      return;
    }

    var reader = new FileReader();
    reader.onload = (event: any) => {
      gacha.ngxCropperData.imageBase64String = (event.target.result); // event.target.results contains the base64 code to create the image.
    };
    reader.readAsDataURL(file); // Convert the blob from clipboard to base64
  }
}
