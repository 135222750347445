import { animate, state, style, transition, trigger } from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, TransferState } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap, tap } from 'rxjs';
import { FavoriteGacha, UpaGuild, UpaGuildMemberPolicy, UpaGuildRole, UserHasGachas } from 'src/app/openapi/models';
import { UpaGuildServiceService, UserServiceService } from 'src/app/openapi/services';

interface shownImageIdxAndImageAndState {
  shownImageIdx: number;
  prevInterval?: any;
  imageAndStates: imageAndState[];
}

interface imageAndState {
  imageUrl: string;
  state: string;
}

@Component({
  selector: 'app-view-guild',
  templateUrl: './view-guild.component.html',
  styleUrl: './view-guild.component.scss',
  animations: [
    trigger('crossfade', [
      state('in', style({ 'opacity': '1' })),
      state('out', style({ 'opacity': '0' })),
      transition('* <=> *', [
        animate(750)
      ])
    ])
  ],
})
export class ViewGuildComponent implements OnInit {
  guild?: UpaGuild;
  memberFavorites: { [key: string]: shownImageIdxAndImageAndState } = {};

  constructor(@Inject(PLATFORM_ID) platformId: Object,
    private upaGuildService: UpaGuildServiceService,
    private userService: UserServiceService,
    private route: ActivatedRoute) {
    if (isPlatformBrowser(platformId)) {
      route.data.subscribe(_ => { });
    }
  }

  ngOnInit(): void {
    this.upaGuildService.upaGuildServiceGetUpaGuild({ guildId: this.route.snapshot.paramMap.get('guildID') ?? '' })
      .pipe(
        tap(guild => {
          if (guild.upaGuild?.milestoneRewardOptions?.iconImgUrl?.includes('discordapp')) {
            guild.upaGuild.milestoneRewardOptions.iconImgUrl = undefined;
          }
          this.guild = guild.upaGuild;
          // Sort the members by role (OWNER > OFFICER > MEMBER > RECRUIT)
          // and within each sort by their expContribution
          if (this.guild && this.guild.members) {
            this.guild.members = this.guild.members.sort((a, b) => {
              if (a.role === b.role) {
                if (!a.expContribution) {
                  a.expContribution = "0";
                }
                if (!b.expContribution) {
                  b.expContribution = "0";
                }
                // expContribution is a string that must be covnerted to BigInt
                const aExp = BigInt(a.expContribution!);
                const bExp = BigInt(b.expContribution!);
                return Number(bExp - aExp);
              }
              return this.roleToNumber(a.role!) - this.roleToNumber(b.role!);
            });
          }
        }),
        switchMap(guild => this.userService.userServiceBulkGetUserFavoriteGachas({
          body: {
            identifiers: guild.upaGuild?.members?.map(member => ({ userUuid: member.user?.userUuid })) ?? []
          }
        })),
        map(favorites => favorites.userFavoriteGachas || []),
        tap(favorites => {
          for (const f of favorites) {
            if (!f) {
              continue;
            }
            // this.memberFavorites[f.userUuid!] = f.favoriteGachas!;
            this.memberFavorites[f.userUuid!] = { shownImageIdx: 0, imageAndStates: [] };
            this.memberFavorites[f.userUuid!].imageAndStates = f.favoriteGachas!
              .filter(f => f.gacha !== undefined)
              .map(f =>
              ({
                imageUrl: f.gacha!.gacha!.imageUrl!,
                state: 'out'
              })
              );
            if (this.memberFavorites[f.userUuid!].imageAndStates.length > 0) {
              if (this.memberFavorites[f.userUuid!].imageAndStates.length > 0) {
                this.memberFavorites[f.userUuid!].imageAndStates[0].state = "in";
                const t = setInterval(() => {
                  this.memberFavorites[f.userUuid!].imageAndStates[this.memberFavorites[f.userUuid!].shownImageIdx].state = "out";
                  this.memberFavorites[f.userUuid!].shownImageIdx =
                    (this.memberFavorites[f.userUuid!].shownImageIdx + 1) % this.memberFavorites[f.userUuid!].imageAndStates.length;
                  this.memberFavorites[f.userUuid!].imageAndStates[this.memberFavorites[f.userUuid!].shownImageIdx].state = "in";
                }, this.randomNumberInRange(3000, 7500));
                this.memberFavorites[f.userUuid!].prevInterval = t;
              }
            }
          }
        })
      ).subscribe();
  }

  roleToNumber(role: UpaGuildRole): number {
    switch (role) {
      case "OWNER":
        return 0;
      case "OFFICER":
        return 10;
      case "MEMBER":
        return 20;
      case "RECRUIT":
        return 30;
      default:
        return 100;
    }
  }

  generatePolicyCssClass(policy: UpaGuildMemberPolicy): string {
    switch (policy) {
      case "OPEN":
        return "is-success";
      case "APPLICATION":
        return "is-warning";
      case "INVITE_ONLY":
        return "is-link";
      default:
        return "is-danger";
    }
  }

  generateRoleCssClass(role: UpaGuildRole): string {
    switch (role) {
      case "OWNER":
        return "is-primary";
      case "OFFICER":
        return "is-warning";
      case "MEMBER":
        return "is-info";
      case "RECRUIT":
        return "is-light";
      default:
        return "is-danger";
    }
  }

  generateRoleText(role: UpaGuildRole): string {
    switch (role) {
      case "OWNER":
        return "Owner";
      case "OFFICER":
        return "Officer";
      case "MEMBER":
        return "Member";
      case "RECRUIT":
        return "Recruit";
      default:
        return "Unknown";
    }
  }

  generatePolicyText(policy: UpaGuildMemberPolicy): string {
    switch (policy) {
      case "OPEN":
        return "Open to All";
      case "APPLICATION":
        return "Application Only";
      case "INVITE_ONLY":
        return "Invite Only";
      default:
        return "Unknown";
    }
  }

  randomNumberInRange(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
