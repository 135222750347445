import { Component, Input, OnInit } from '@angular/core';
import { animate, group, query, style, transition, trigger, state } from '@angular/animations';
import { Banner } from 'src/app/openapi/models';

export interface BannerAndImages {
  banner: Banner;
  imageStates: ImageState[];
  shownImageIdx: number;
}

export interface ImageState {
  state: string;
  imageURL: string;
}

export function toDateTime(seconds: number, nanos?: number): Date {
  if (!nanos) {
    nanos = 0;
  }
  var t = new Date(Date.UTC(1970, 0, 1));
  t.setUTCSeconds(seconds + (nanos / 1000000000));
  return t;
}

@Component({
  selector: 'app-view-banner',
  templateUrl: './view-banner.component.html',
  styleUrls: ['./view-banner.component.scss'],
  animations: [
    trigger('crossfade', [
      state('in', style({ 'opacity': '1' })),
      state('out', style({ 'opacity': '0' })),
      transition('* <=> *', [
        animate(750)
      ])
    ])
  ],
})
export class ViewBannerComponent implements OnInit {

  Number = Number;

  @Input()
  gachaIDToNameMapping: { [key: string]: { gachaID: string, gachaName: string} } = {};

  @Input()
  banner?: BannerAndImages;

  ngOnInit() {
  }

  tmplToDate(s: string): Date {
    return new Date(s);
  }

  isPresent(b: Banner): boolean {
    const start = new Date(b.startTime!)
    const end = new Date(b.endTime!)
    const now = new Date();
    return start <= now && now <= end;
  }

  isPast(b: Banner): boolean {
    const now = new Date();
    const end = new Date(b.endTime!);
    return end < now;
  }

  isFuture(b: Banner): boolean {
    const start = new Date(b.startTime!);
    const now = new Date();
    return start > now;
  }

  getGachasForRarity(rarity: number, bannerID: string): { gachaID: string, gachaName: string}[] {
    if (!this.banner) {
      return [];
    }
    const gachas = this.banner.banner.rateUpGachas![rarity];
    if (!gachas) {
      return [];
    }
    const gachaIDs = gachas.gachaIds || [];
    if (gachaIDs.length == 0) {
      return [];
    }
    return gachaIDs
      .filter(id => this.gachaIDToNameMapping[id])
      .map(id => this.gachaIDToNameMapping[id]);
  }

  fmtGachaNames(rarity: number, bannerID: string): string {
    return this.getGachasForRarity(rarity, bannerID)
      .sort((a, b) => a.gachaName.localeCompare(b.gachaName))
      .map(g => `<a href="[/view/gacha/${g.gachaID}]">${g.gachaName}</a>`)
      .join(", ");
  }

  hasGachaForRarity(rarity: number, bannerID: string): boolean {
    rarity = rarity;
    if (!this.banner) {
      return false;
    }

    if (!this.banner.banner.rateUpGachas![rarity]) {
      return false;
    }
    return this.banner.banner.rateUpGachas![rarity].gachaIds!.length > 0;
  }
}
