/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { MarkNotificationsAsReadBulkResponse } from '../../models/mark-notifications-as-read-bulk-response';

export interface NotificationServiceMarkNotificationsAsReadBulk$Params {
  userUuid: string;
  discordId?: string;
  notificationIds?: Array<string>;
}

export function notificationServiceMarkNotificationsAsReadBulk(http: HttpClient, rootUrl: string, params: NotificationServiceMarkNotificationsAsReadBulk$Params, context?: HttpContext): Observable<StrictHttpResponse<MarkNotificationsAsReadBulkResponse>> {
  const rb = new RequestBuilder(rootUrl, notificationServiceMarkNotificationsAsReadBulk.PATH, 'post');
  if (params) {
    rb.path('userUuid', params.userUuid, {});
    rb.query('discordId', params.discordId, {});
    rb.query('notificationIds', params.notificationIds, {"style":"form","explode":true});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<MarkNotificationsAsReadBulkResponse>;
    })
  );
}

notificationServiceMarkNotificationsAsReadBulk.PATH = '/api/v3/gateway/user/{userUuid}/notifications/read';
