/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminServiceBatchExtractCharacterUrlsFromUrl } from '../fn/admin-service/admin-service-batch-extract-character-urls-from-url';
import { AdminServiceBatchExtractCharacterUrlsFromUrl$Params } from '../fn/admin-service/admin-service-batch-extract-character-urls-from-url';
import { adminServiceCreateBanner } from '../fn/admin-service/admin-service-create-banner';
import { AdminServiceCreateBanner$Params } from '../fn/admin-service/admin-service-create-banner';
import { adminServiceCreateMedia } from '../fn/admin-service/admin-service-create-media';
import { AdminServiceCreateMedia$Params } from '../fn/admin-service/admin-service-create-media';
import { adminServiceDeleteBanner } from '../fn/admin-service/admin-service-delete-banner';
import { AdminServiceDeleteBanner$Params } from '../fn/admin-service/admin-service-delete-banner';
import { adminServiceDeleteStagedAltArt } from '../fn/admin-service/admin-service-delete-staged-alt-art';
import { AdminServiceDeleteStagedAltArt$Params } from '../fn/admin-service/admin-service-delete-staged-alt-art';
import { adminServiceDeleteStagedGacha } from '../fn/admin-service/admin-service-delete-staged-gacha';
import { AdminServiceDeleteStagedGacha$Params } from '../fn/admin-service/admin-service-delete-staged-gacha';
import { adminServiceEnableAltArt } from '../fn/admin-service/admin-service-enable-alt-art';
import { AdminServiceEnableAltArt$Params } from '../fn/admin-service/admin-service-enable-alt-art';
import { adminServiceEnableGacha } from '../fn/admin-service/admin-service-enable-gacha';
import { AdminServiceEnableGacha$Params } from '../fn/admin-service/admin-service-enable-gacha';
import { adminServiceExtractCharacterFromUrl } from '../fn/admin-service/admin-service-extract-character-from-url';
import { AdminServiceExtractCharacterFromUrl$Params } from '../fn/admin-service/admin-service-extract-character-from-url';
import { adminServiceForceDeleteMarketplaceListing } from '../fn/admin-service/admin-service-force-delete-marketplace-listing';
import { AdminServiceForceDeleteMarketplaceListing$Params } from '../fn/admin-service/admin-service-force-delete-marketplace-listing';
import { adminServiceGetAllEditions } from '../fn/admin-service/admin-service-get-all-editions';
import { AdminServiceGetAllEditions$Params } from '../fn/admin-service/admin-service-get-all-editions';
import { adminServiceGetAllStagedEquipment } from '../fn/admin-service/admin-service-get-all-staged-equipment';
import { AdminServiceGetAllStagedEquipment$Params } from '../fn/admin-service/admin-service-get-all-staged-equipment';
import { adminServiceGetAltArtImageData } from '../fn/admin-service/admin-service-get-alt-art-image-data';
import { AdminServiceGetAltArtImageData$Params } from '../fn/admin-service/admin-service-get-alt-art-image-data';
import { adminServiceGetGachaImageData } from '../fn/admin-service/admin-service-get-gacha-image-data';
import { AdminServiceGetGachaImageData$Params } from '../fn/admin-service/admin-service-get-gacha-image-data';
import { adminServiceGetMediaImageData } from '../fn/admin-service/admin-service-get-media-image-data';
import { AdminServiceGetMediaImageData$Params } from '../fn/admin-service/admin-service-get-media-image-data';
import { adminServiceGetStagedAltArt } from '../fn/admin-service/admin-service-get-staged-alt-art';
import { AdminServiceGetStagedAltArt$Params } from '../fn/admin-service/admin-service-get-staged-alt-art';
import { adminServiceGetStagedGacha } from '../fn/admin-service/admin-service-get-staged-gacha';
import { AdminServiceGetStagedGacha$Params } from '../fn/admin-service/admin-service-get-staged-gacha';
import { adminServiceGetUserCredentials } from '../fn/admin-service/admin-service-get-user-credentials';
import { AdminServiceGetUserCredentials$Params } from '../fn/admin-service/admin-service-get-user-credentials';
import { adminServiceLgtmStagedGacha } from '../fn/admin-service/admin-service-lgtm-staged-gacha';
import { AdminServiceLgtmStagedGacha$Params } from '../fn/admin-service/admin-service-lgtm-staged-gacha';
import { adminServiceReserveAltArtId } from '../fn/admin-service/admin-service-reserve-alt-art-id';
import { AdminServiceReserveAltArtId$Params } from '../fn/admin-service/admin-service-reserve-alt-art-id';
import { adminServiceStageAltArt } from '../fn/admin-service/admin-service-stage-alt-art';
import { AdminServiceStageAltArt$Params } from '../fn/admin-service/admin-service-stage-alt-art';
import { adminServiceStageEquipment } from '../fn/admin-service/admin-service-stage-equipment';
import { AdminServiceStageEquipment$Params } from '../fn/admin-service/admin-service-stage-equipment';
import { adminServiceStageGacha } from '../fn/admin-service/admin-service-stage-gacha';
import { AdminServiceStageGacha$Params } from '../fn/admin-service/admin-service-stage-gacha';
import { adminServiceStageGachaWithEdition } from '../fn/admin-service/admin-service-stage-gacha-with-edition';
import { AdminServiceStageGachaWithEdition$Params } from '../fn/admin-service/admin-service-stage-gacha-with-edition';
import { adminServiceUpdateBanner } from '../fn/admin-service/admin-service-update-banner';
import { AdminServiceUpdateBanner$Params } from '../fn/admin-service/admin-service-update-banner';
import { adminServiceUpdateBannerVisibility } from '../fn/admin-service/admin-service-update-banner-visibility';
import { AdminServiceUpdateBannerVisibility$Params } from '../fn/admin-service/admin-service-update-banner-visibility';
import { adminServiceUpdateGachaImage } from '../fn/admin-service/admin-service-update-gacha-image';
import { AdminServiceUpdateGachaImage$Params } from '../fn/admin-service/admin-service-update-gacha-image';
import { adminServiceUpdateMediaImage } from '../fn/admin-service/admin-service-update-media-image';
import { AdminServiceUpdateMediaImage$Params } from '../fn/admin-service/admin-service-update-media-image';
import { adminServiceUpdateProdAltArt } from '../fn/admin-service/admin-service-update-prod-alt-art';
import { AdminServiceUpdateProdAltArt$Params } from '../fn/admin-service/admin-service-update-prod-alt-art';
import { adminServiceUpdateStagedAltArt } from '../fn/admin-service/admin-service-update-staged-alt-art';
import { AdminServiceUpdateStagedAltArt$Params } from '../fn/admin-service/admin-service-update-staged-alt-art';
import { adminServiceUpdateStagedGacha } from '../fn/admin-service/admin-service-update-staged-gacha';
import { AdminServiceUpdateStagedGacha$Params } from '../fn/admin-service/admin-service-update-staged-gacha';
import { ApiHttpBody } from '../models/api-http-body';
import { BatchExtractCharacterUrlsFromUrlResponse } from '../models/batch-extract-character-urls-from-url-response';
import { CreateBannerResponse } from '../models/create-banner-response';
import { CreateMediaResponse } from '../models/create-media-response';
import { DeleteBannerResponse } from '../models/delete-banner-response';
import { DeleteStagedAltArtResponse } from '../models/delete-staged-alt-art-response';
import { DeleteStagedGachaResponse } from '../models/delete-staged-gacha-response';
import { EnableAltArtResponse } from '../models/enable-alt-art-response';
import { EnableGachaResponse } from '../models/enable-gacha-response';
import { ExtractCharacterFromUrlResponse } from '../models/extract-character-from-url-response';
import { ForceDeleteMarketplaceListingResponse } from '../models/force-delete-marketplace-listing-response';
import { GetAllEditionsResponse } from '../models/get-all-editions-response';
import { GetAllStagedEquipmentResponse } from '../models/get-all-staged-equipment-response';
import { GetMediaImageDataResponse } from '../models/get-media-image-data-response';
import { GetStagedAltArtResponse } from '../models/get-staged-alt-art-response';
import { GetStagedGachaResponse } from '../models/get-staged-gacha-response';
import { GetUserCredentialsResponse } from '../models/get-user-credentials-response';
import { LgtmStagedGachaResponse } from '../models/lgtm-staged-gacha-response';
import { ReserveAltArtIdResponse } from '../models/reserve-alt-art-id-response';
import { StageAltArtResponse } from '../models/stage-alt-art-response';
import { StageEquipmentResponse } from '../models/stage-equipment-response';
import { StageGachaResponse } from '../models/stage-gacha-response';
import { StageGachaWithEditionResponse } from '../models/stage-gacha-with-edition-response';
import { UpdateBannerResponse } from '../models/update-banner-response';
import { UpdateBannerVisibilityResponse } from '../models/update-banner-visibility-response';
import { UpdateGachaImageResponse } from '../models/update-gacha-image-response';
import { UpdateMediaImageResponse } from '../models/update-media-image-response';
import { UpdateProdAltArtResponse } from '../models/update-prod-alt-art-response';
import { UpdateStagedAltArtResponse } from '../models/update-staged-alt-art-response';
import { UpdateStagedGachaResponse } from '../models/update-staged-gacha-response';

@Injectable({ providedIn: 'root' })
export class AdminServiceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminServiceGetAltArtImageData()` */
  static readonly AdminServiceGetAltArtImageDataPath = '/api/v3/gateway/admin/PRODUCTION/gacha/{gachaId}/alt_art/{altArtId}/image/raw';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminServiceGetAltArtImageData()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminServiceGetAltArtImageData$Response(params: AdminServiceGetAltArtImageData$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiHttpBody>> {
    return adminServiceGetAltArtImageData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminServiceGetAltArtImageData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminServiceGetAltArtImageData(params: AdminServiceGetAltArtImageData$Params, context?: HttpContext): Observable<ApiHttpBody> {
    return this.adminServiceGetAltArtImageData$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiHttpBody>): ApiHttpBody => r.body)
    );
  }

  /** Path part for operation `adminServiceEnableAltArt()` */
  static readonly AdminServiceEnableAltArtPath = '/api/v3/gateway/admin/alt_art/enable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminServiceEnableAltArt()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminServiceEnableAltArt$Response(params: AdminServiceEnableAltArt$Params, context?: HttpContext): Observable<StrictHttpResponse<EnableAltArtResponse>> {
    return adminServiceEnableAltArt(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminServiceEnableAltArt$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminServiceEnableAltArt(params: AdminServiceEnableAltArt$Params, context?: HttpContext): Observable<EnableAltArtResponse> {
    return this.adminServiceEnableAltArt$Response(params, context).pipe(
      map((r: StrictHttpResponse<EnableAltArtResponse>): EnableAltArtResponse => r.body)
    );
  }

  /** Path part for operation `adminServiceUpdateProdAltArt()` */
  static readonly AdminServiceUpdateProdAltArtPath = '/api/v3/gateway/admin/alt_art/{gachaId}/{altArtId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminServiceUpdateProdAltArt()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminServiceUpdateProdAltArt$Response(params: AdminServiceUpdateProdAltArt$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateProdAltArtResponse>> {
    return adminServiceUpdateProdAltArt(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminServiceUpdateProdAltArt$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminServiceUpdateProdAltArt(params: AdminServiceUpdateProdAltArt$Params, context?: HttpContext): Observable<UpdateProdAltArtResponse> {
    return this.adminServiceUpdateProdAltArt$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateProdAltArtResponse>): UpdateProdAltArtResponse => r.body)
    );
  }

  /** Path part for operation `adminServiceCreateBanner()` */
  static readonly AdminServiceCreateBannerPath = '/api/v3/gateway/admin/banner';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminServiceCreateBanner()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminServiceCreateBanner$Response(params: AdminServiceCreateBanner$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateBannerResponse>> {
    return adminServiceCreateBanner(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminServiceCreateBanner$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminServiceCreateBanner(params: AdminServiceCreateBanner$Params, context?: HttpContext): Observable<CreateBannerResponse> {
    return this.adminServiceCreateBanner$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateBannerResponse>): CreateBannerResponse => r.body)
    );
  }

  /** Path part for operation `adminServiceUpdateBanner()` */
  static readonly AdminServiceUpdateBannerPath = '/api/v3/gateway/admin/banner/{banner.id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminServiceUpdateBanner()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminServiceUpdateBanner$Response(params: AdminServiceUpdateBanner$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateBannerResponse>> {
    return adminServiceUpdateBanner(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminServiceUpdateBanner$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminServiceUpdateBanner(params: AdminServiceUpdateBanner$Params, context?: HttpContext): Observable<UpdateBannerResponse> {
    return this.adminServiceUpdateBanner$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateBannerResponse>): UpdateBannerResponse => r.body)
    );
  }

  /** Path part for operation `adminServiceDeleteBanner()` */
  static readonly AdminServiceDeleteBannerPath = '/api/v3/gateway/admin/banner/{bannerId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminServiceDeleteBanner()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminServiceDeleteBanner$Response(params: AdminServiceDeleteBanner$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteBannerResponse>> {
    return adminServiceDeleteBanner(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminServiceDeleteBanner$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminServiceDeleteBanner(params: AdminServiceDeleteBanner$Params, context?: HttpContext): Observable<DeleteBannerResponse> {
    return this.adminServiceDeleteBanner$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteBannerResponse>): DeleteBannerResponse => r.body)
    );
  }

  /** Path part for operation `adminServiceUpdateBannerVisibility()` */
  static readonly AdminServiceUpdateBannerVisibilityPath = '/api/v3/gateway/admin/banner/{bannerId}/visibility/{visible}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminServiceUpdateBannerVisibility()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminServiceUpdateBannerVisibility$Response(params: AdminServiceUpdateBannerVisibility$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateBannerVisibilityResponse>> {
    return adminServiceUpdateBannerVisibility(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminServiceUpdateBannerVisibility$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminServiceUpdateBannerVisibility(params: AdminServiceUpdateBannerVisibility$Params, context?: HttpContext): Observable<UpdateBannerVisibilityResponse> {
    return this.adminServiceUpdateBannerVisibility$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateBannerVisibilityResponse>): UpdateBannerVisibilityResponse => r.body)
    );
  }

  /** Path part for operation `adminServiceGetAllEditions()` */
  static readonly AdminServiceGetAllEditionsPath = '/api/v3/gateway/admin/edition';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminServiceGetAllEditions()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminServiceGetAllEditions$Response(params?: AdminServiceGetAllEditions$Params, context?: HttpContext): Observable<StrictHttpResponse<GetAllEditionsResponse>> {
    return adminServiceGetAllEditions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminServiceGetAllEditions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminServiceGetAllEditions(params?: AdminServiceGetAllEditions$Params, context?: HttpContext): Observable<GetAllEditionsResponse> {
    return this.adminServiceGetAllEditions$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetAllEditionsResponse>): GetAllEditionsResponse => r.body)
    );
  }

  /** Path part for operation `adminServiceExtractCharacterFromUrl()` */
  static readonly AdminServiceExtractCharacterFromUrlPath = '/api/v3/gateway/admin/extract/character';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminServiceExtractCharacterFromUrl()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminServiceExtractCharacterFromUrl$Response(params: AdminServiceExtractCharacterFromUrl$Params, context?: HttpContext): Observable<StrictHttpResponse<ExtractCharacterFromUrlResponse>> {
    return adminServiceExtractCharacterFromUrl(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminServiceExtractCharacterFromUrl$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminServiceExtractCharacterFromUrl(params: AdminServiceExtractCharacterFromUrl$Params, context?: HttpContext): Observable<ExtractCharacterFromUrlResponse> {
    return this.adminServiceExtractCharacterFromUrl$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExtractCharacterFromUrlResponse>): ExtractCharacterFromUrlResponse => r.body)
    );
  }

  /** Path part for operation `adminServiceBatchExtractCharacterUrlsFromUrl()` */
  static readonly AdminServiceBatchExtractCharacterUrlsFromUrlPath = '/api/v3/gateway/admin/extract/character/batch';

  /**
   * Given an aggregator url, for example, a link to a page of characters on MAL,
   * extract all character URLs that can then be fed into ExtractCharacterFromUrl to individually
   * extract the character data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminServiceBatchExtractCharacterUrlsFromUrl()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminServiceBatchExtractCharacterUrlsFromUrl$Response(params: AdminServiceBatchExtractCharacterUrlsFromUrl$Params, context?: HttpContext): Observable<StrictHttpResponse<BatchExtractCharacterUrlsFromUrlResponse>> {
    return adminServiceBatchExtractCharacterUrlsFromUrl(this.http, this.rootUrl, params, context);
  }

  /**
   * Given an aggregator url, for example, a link to a page of characters on MAL,
   * extract all character URLs that can then be fed into ExtractCharacterFromUrl to individually
   * extract the character data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminServiceBatchExtractCharacterUrlsFromUrl$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminServiceBatchExtractCharacterUrlsFromUrl(params: AdminServiceBatchExtractCharacterUrlsFromUrl$Params, context?: HttpContext): Observable<BatchExtractCharacterUrlsFromUrlResponse> {
    return this.adminServiceBatchExtractCharacterUrlsFromUrl$Response(params, context).pipe(
      map((r: StrictHttpResponse<BatchExtractCharacterUrlsFromUrlResponse>): BatchExtractCharacterUrlsFromUrlResponse => r.body)
    );
  }

  /** Path part for operation `adminServiceEnableGacha()` */
  static readonly AdminServiceEnableGachaPath = '/api/v3/gateway/admin/gacha/enable';

  /**
   * Really should be called "BatchEnableGacha".
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminServiceEnableGacha()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminServiceEnableGacha$Response(params: AdminServiceEnableGacha$Params, context?: HttpContext): Observable<StrictHttpResponse<EnableGachaResponse>> {
    return adminServiceEnableGacha(this.http, this.rootUrl, params, context);
  }

  /**
   * Really should be called "BatchEnableGacha".
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminServiceEnableGacha$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminServiceEnableGacha(params: AdminServiceEnableGacha$Params, context?: HttpContext): Observable<EnableGachaResponse> {
    return this.adminServiceEnableGacha$Response(params, context).pipe(
      map((r: StrictHttpResponse<EnableGachaResponse>): EnableGachaResponse => r.body)
    );
  }

  /** Path part for operation `adminServiceForceDeleteMarketplaceListing()` */
  static readonly AdminServiceForceDeleteMarketplaceListingPath = '/api/v3/gateway/admin/marketplace/listing/{listingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminServiceForceDeleteMarketplaceListing()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminServiceForceDeleteMarketplaceListing$Response(params: AdminServiceForceDeleteMarketplaceListing$Params, context?: HttpContext): Observable<StrictHttpResponse<ForceDeleteMarketplaceListingResponse>> {
    return adminServiceForceDeleteMarketplaceListing(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminServiceForceDeleteMarketplaceListing$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminServiceForceDeleteMarketplaceListing(params: AdminServiceForceDeleteMarketplaceListing$Params, context?: HttpContext): Observable<ForceDeleteMarketplaceListingResponse> {
    return this.adminServiceForceDeleteMarketplaceListing$Response(params, context).pipe(
      map((r: StrictHttpResponse<ForceDeleteMarketplaceListingResponse>): ForceDeleteMarketplaceListingResponse => r.body)
    );
  }

  /** Path part for operation `adminServiceCreateMedia()` */
  static readonly AdminServiceCreateMediaPath = '/api/v3/gateway/admin/media';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminServiceCreateMedia()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminServiceCreateMedia$Response(params: AdminServiceCreateMedia$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateMediaResponse>> {
    return adminServiceCreateMedia(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminServiceCreateMedia$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminServiceCreateMedia(params: AdminServiceCreateMedia$Params, context?: HttpContext): Observable<CreateMediaResponse> {
    return this.adminServiceCreateMedia$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateMediaResponse>): CreateMediaResponse => r.body)
    );
  }

  /** Path part for operation `adminServiceUpdateMediaImage()` */
  static readonly AdminServiceUpdateMediaImagePath = '/api/v3/gateway/admin/media/{mediaId}/image';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminServiceUpdateMediaImage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminServiceUpdateMediaImage$Response(params: AdminServiceUpdateMediaImage$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateMediaImageResponse>> {
    return adminServiceUpdateMediaImage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminServiceUpdateMediaImage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminServiceUpdateMediaImage(params: AdminServiceUpdateMediaImage$Params, context?: HttpContext): Observable<UpdateMediaImageResponse> {
    return this.adminServiceUpdateMediaImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateMediaImageResponse>): UpdateMediaImageResponse => r.body)
    );
  }

  /** Path part for operation `adminServiceGetMediaImageData()` */
  static readonly AdminServiceGetMediaImageDataPath = '/api/v3/gateway/admin/media/{mediaId}/image/raw';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminServiceGetMediaImageData()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminServiceGetMediaImageData$Response(params: AdminServiceGetMediaImageData$Params, context?: HttpContext): Observable<StrictHttpResponse<GetMediaImageDataResponse>> {
    return adminServiceGetMediaImageData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminServiceGetMediaImageData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminServiceGetMediaImageData(params: AdminServiceGetMediaImageData$Params, context?: HttpContext): Observable<GetMediaImageDataResponse> {
    return this.adminServiceGetMediaImageData$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetMediaImageDataResponse>): GetMediaImageDataResponse => r.body)
    );
  }

  /** Path part for operation `adminServiceGetStagedAltArt()` */
  static readonly AdminServiceGetStagedAltArtPath = '/api/v3/gateway/admin/stage/alt_art';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminServiceGetStagedAltArt()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminServiceGetStagedAltArt$Response(params?: AdminServiceGetStagedAltArt$Params, context?: HttpContext): Observable<StrictHttpResponse<GetStagedAltArtResponse>> {
    return adminServiceGetStagedAltArt(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminServiceGetStagedAltArt$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminServiceGetStagedAltArt(params?: AdminServiceGetStagedAltArt$Params, context?: HttpContext): Observable<GetStagedAltArtResponse> {
    return this.adminServiceGetStagedAltArt$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetStagedAltArtResponse>): GetStagedAltArtResponse => r.body)
    );
  }

  /** Path part for operation `adminServiceStageAltArt()` */
  static readonly AdminServiceStageAltArtPath = '/api/v3/gateway/admin/stage/alt_art';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminServiceStageAltArt()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminServiceStageAltArt$Response(params: AdminServiceStageAltArt$Params, context?: HttpContext): Observable<StrictHttpResponse<StageAltArtResponse>> {
    return adminServiceStageAltArt(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminServiceStageAltArt$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminServiceStageAltArt(params: AdminServiceStageAltArt$Params, context?: HttpContext): Observable<StageAltArtResponse> {
    return this.adminServiceStageAltArt$Response(params, context).pipe(
      map((r: StrictHttpResponse<StageAltArtResponse>): StageAltArtResponse => r.body)
    );
  }

  /** Path part for operation `adminServiceReserveAltArtId()` */
  static readonly AdminServiceReserveAltArtIdPath = '/api/v3/gateway/admin/stage/alt_art/reserve/gacha/{gachaId}';

  /**
   * Chicken and egg for staging alt art. Since we use serial numbers to ID alt arts (globally),
   * we need to reserve a serial number from the database before we can upload the image through webserver.
   * This will create an empty alt art entry in the database with the serial number, and return it to the client.
   * Then the client must stage alt art with the given serial #.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminServiceReserveAltArtId()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminServiceReserveAltArtId$Response(params: AdminServiceReserveAltArtId$Params, context?: HttpContext): Observable<StrictHttpResponse<ReserveAltArtIdResponse>> {
    return adminServiceReserveAltArtId(this.http, this.rootUrl, params, context);
  }

  /**
   * Chicken and egg for staging alt art. Since we use serial numbers to ID alt arts (globally),
   * we need to reserve a serial number from the database before we can upload the image through webserver.
   * This will create an empty alt art entry in the database with the serial number, and return it to the client.
   * Then the client must stage alt art with the given serial #.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminServiceReserveAltArtId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminServiceReserveAltArtId(params: AdminServiceReserveAltArtId$Params, context?: HttpContext): Observable<ReserveAltArtIdResponse> {
    return this.adminServiceReserveAltArtId$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReserveAltArtIdResponse>): ReserveAltArtIdResponse => r.body)
    );
  }

  /** Path part for operation `adminServiceUpdateStagedAltArt()` */
  static readonly AdminServiceUpdateStagedAltArtPath = '/api/v3/gateway/admin/stage/alt_art/{gachaId}/{altArtId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminServiceUpdateStagedAltArt()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminServiceUpdateStagedAltArt$Response(params: AdminServiceUpdateStagedAltArt$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateStagedAltArtResponse>> {
    return adminServiceUpdateStagedAltArt(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminServiceUpdateStagedAltArt$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminServiceUpdateStagedAltArt(params: AdminServiceUpdateStagedAltArt$Params, context?: HttpContext): Observable<UpdateStagedAltArtResponse> {
    return this.adminServiceUpdateStagedAltArt$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateStagedAltArtResponse>): UpdateStagedAltArtResponse => r.body)
    );
  }

  /** Path part for operation `adminServiceDeleteStagedAltArt()` */
  static readonly AdminServiceDeleteStagedAltArtPath = '/api/v3/gateway/admin/stage/alt_art/{gachaId}/{altArtId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminServiceDeleteStagedAltArt()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminServiceDeleteStagedAltArt$Response(params: AdminServiceDeleteStagedAltArt$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteStagedAltArtResponse>> {
    return adminServiceDeleteStagedAltArt(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminServiceDeleteStagedAltArt$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminServiceDeleteStagedAltArt(params: AdminServiceDeleteStagedAltArt$Params, context?: HttpContext): Observable<DeleteStagedAltArtResponse> {
    return this.adminServiceDeleteStagedAltArt$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteStagedAltArtResponse>): DeleteStagedAltArtResponse => r.body)
    );
  }

  /** Path part for operation `adminServiceGetAllStagedEquipment()` */
  static readonly AdminServiceGetAllStagedEquipmentPath = '/api/v3/gateway/admin/stage/equipment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminServiceGetAllStagedEquipment()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminServiceGetAllStagedEquipment$Response(params?: AdminServiceGetAllStagedEquipment$Params, context?: HttpContext): Observable<StrictHttpResponse<GetAllStagedEquipmentResponse>> {
    return adminServiceGetAllStagedEquipment(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminServiceGetAllStagedEquipment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminServiceGetAllStagedEquipment(params?: AdminServiceGetAllStagedEquipment$Params, context?: HttpContext): Observable<GetAllStagedEquipmentResponse> {
    return this.adminServiceGetAllStagedEquipment$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetAllStagedEquipmentResponse>): GetAllStagedEquipmentResponse => r.body)
    );
  }

  /** Path part for operation `adminServiceStageEquipment()` */
  static readonly AdminServiceStageEquipmentPath = '/api/v3/gateway/admin/stage/equipment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminServiceStageEquipment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminServiceStageEquipment$Response(params: AdminServiceStageEquipment$Params, context?: HttpContext): Observable<StrictHttpResponse<StageEquipmentResponse>> {
    return adminServiceStageEquipment(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminServiceStageEquipment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminServiceStageEquipment(params: AdminServiceStageEquipment$Params, context?: HttpContext): Observable<StageEquipmentResponse> {
    return this.adminServiceStageEquipment$Response(params, context).pipe(
      map((r: StrictHttpResponse<StageEquipmentResponse>): StageEquipmentResponse => r.body)
    );
  }

  /** Path part for operation `adminServiceGetStagedGacha()` */
  static readonly AdminServiceGetStagedGachaPath = '/api/v3/gateway/admin/stage/gacha';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminServiceGetStagedGacha()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminServiceGetStagedGacha$Response(params?: AdminServiceGetStagedGacha$Params, context?: HttpContext): Observable<StrictHttpResponse<GetStagedGachaResponse>> {
    return adminServiceGetStagedGacha(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminServiceGetStagedGacha$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminServiceGetStagedGacha(params?: AdminServiceGetStagedGacha$Params, context?: HttpContext): Observable<GetStagedGachaResponse> {
    return this.adminServiceGetStagedGacha$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetStagedGachaResponse>): GetStagedGachaResponse => r.body)
    );
  }

  /** Path part for operation `adminServiceStageGacha()` */
  static readonly AdminServiceStageGachaPath = '/api/v3/gateway/admin/stage/gacha';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminServiceStageGacha()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminServiceStageGacha$Response(params: AdminServiceStageGacha$Params, context?: HttpContext): Observable<StrictHttpResponse<StageGachaResponse>> {
    return adminServiceStageGacha(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminServiceStageGacha$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminServiceStageGacha(params: AdminServiceStageGacha$Params, context?: HttpContext): Observable<StageGachaResponse> {
    return this.adminServiceStageGacha$Response(params, context).pipe(
      map((r: StrictHttpResponse<StageGachaResponse>): StageGachaResponse => r.body)
    );
  }

  /** Path part for operation `adminServiceDeleteStagedGacha()` */
  static readonly AdminServiceDeleteStagedGachaPath = '/api/v3/gateway/admin/stage/gacha/delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminServiceDeleteStagedGacha()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminServiceDeleteStagedGacha$Response(params: AdminServiceDeleteStagedGacha$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteStagedGachaResponse>> {
    return adminServiceDeleteStagedGacha(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminServiceDeleteStagedGacha$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminServiceDeleteStagedGacha(params: AdminServiceDeleteStagedGacha$Params, context?: HttpContext): Observable<DeleteStagedGachaResponse> {
    return this.adminServiceDeleteStagedGacha$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteStagedGachaResponse>): DeleteStagedGachaResponse => r.body)
    );
  }

  /** Path part for operation `adminServiceStageGachaWithEdition()` */
  static readonly AdminServiceStageGachaWithEditionPath = '/api/v3/gateway/admin/stage/gacha/edition';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminServiceStageGachaWithEdition()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminServiceStageGachaWithEdition$Response(params: AdminServiceStageGachaWithEdition$Params, context?: HttpContext): Observable<StrictHttpResponse<StageGachaWithEditionResponse>> {
    return adminServiceStageGachaWithEdition(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminServiceStageGachaWithEdition$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminServiceStageGachaWithEdition(params: AdminServiceStageGachaWithEdition$Params, context?: HttpContext): Observable<StageGachaWithEditionResponse> {
    return this.adminServiceStageGachaWithEdition$Response(params, context).pipe(
      map((r: StrictHttpResponse<StageGachaWithEditionResponse>): StageGachaWithEditionResponse => r.body)
    );
  }

  /** Path part for operation `adminServiceLgtmStagedGacha()` */
  static readonly AdminServiceLgtmStagedGachaPath = '/api/v3/gateway/admin/stage/gacha/lgtm';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminServiceLgtmStagedGacha()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminServiceLgtmStagedGacha$Response(params: AdminServiceLgtmStagedGacha$Params, context?: HttpContext): Observable<StrictHttpResponse<LgtmStagedGachaResponse>> {
    return adminServiceLgtmStagedGacha(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminServiceLgtmStagedGacha$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminServiceLgtmStagedGacha(params: AdminServiceLgtmStagedGacha$Params, context?: HttpContext): Observable<LgtmStagedGachaResponse> {
    return this.adminServiceLgtmStagedGacha$Response(params, context).pipe(
      map((r: StrictHttpResponse<LgtmStagedGachaResponse>): LgtmStagedGachaResponse => r.body)
    );
  }

  /** Path part for operation `adminServiceUpdateStagedGacha()` */
  static readonly AdminServiceUpdateStagedGachaPath = '/api/v3/gateway/admin/stage/gacha/{gacha.id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminServiceUpdateStagedGacha()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminServiceUpdateStagedGacha$Response(params: AdminServiceUpdateStagedGacha$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateStagedGachaResponse>> {
    return adminServiceUpdateStagedGacha(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminServiceUpdateStagedGacha$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminServiceUpdateStagedGacha(params: AdminServiceUpdateStagedGacha$Params, context?: HttpContext): Observable<UpdateStagedGachaResponse> {
    return this.adminServiceUpdateStagedGacha$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateStagedGachaResponse>): UpdateStagedGachaResponse => r.body)
    );
  }

  /** Path part for operation `adminServiceUpdateGachaImage()` */
  static readonly AdminServiceUpdateGachaImagePath = '/api/v3/gateway/admin/{environment}/gacha/{gachaId}/image';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminServiceUpdateGachaImage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminServiceUpdateGachaImage$Response(params: AdminServiceUpdateGachaImage$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateGachaImageResponse>> {
    return adminServiceUpdateGachaImage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminServiceUpdateGachaImage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminServiceUpdateGachaImage(params: AdminServiceUpdateGachaImage$Params, context?: HttpContext): Observable<UpdateGachaImageResponse> {
    return this.adminServiceUpdateGachaImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateGachaImageResponse>): UpdateGachaImageResponse => r.body)
    );
  }

  /** Path part for operation `adminServiceGetGachaImageData()` */
  static readonly AdminServiceGetGachaImageDataPath = '/api/v3/gateway/admin/{environment}/gacha/{gachaId}/image/raw';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminServiceGetGachaImageData()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminServiceGetGachaImageData$Response(params: AdminServiceGetGachaImageData$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiHttpBody>> {
    return adminServiceGetGachaImageData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminServiceGetGachaImageData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminServiceGetGachaImageData(params: AdminServiceGetGachaImageData$Params, context?: HttpContext): Observable<ApiHttpBody> {
    return this.adminServiceGetGachaImageData$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiHttpBody>): ApiHttpBody => r.body)
    );
  }

  /** Path part for operation `adminServiceGetUserCredentials()` */
  static readonly AdminServiceGetUserCredentialsPath = '/api/v3/gateway/user/{discordId}/credentials';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminServiceGetUserCredentials()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminServiceGetUserCredentials$Response(params: AdminServiceGetUserCredentials$Params, context?: HttpContext): Observable<StrictHttpResponse<GetUserCredentialsResponse>> {
    return adminServiceGetUserCredentials(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminServiceGetUserCredentials$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminServiceGetUserCredentials(params: AdminServiceGetUserCredentials$Params, context?: HttpContext): Observable<GetUserCredentialsResponse> {
    return this.adminServiceGetUserCredentials$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetUserCredentialsResponse>): GetUserCredentialsResponse => r.body)
    );
  }

}
