/* tslint:disable */
/* eslint-disable */
import { TradeableStatus } from './tradeable-status';

/**
 * Each possible value of `TradeableStatus`
 */
export const TRADEABLE_STATUS: TradeableStatus[] = [
  'TRADEABLE',
  'UNTRADEABLE',
  'KINDA_TRADEABLE'
];