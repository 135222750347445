/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { communityServerServiceGetGiveawayBudgetForCurrentTimePeriod } from '../fn/community-server-service/community-server-service-get-giveaway-budget-for-current-time-period';
import { CommunityServerServiceGetGiveawayBudgetForCurrentTimePeriod$Params } from '../fn/community-server-service/community-server-service-get-giveaway-budget-for-current-time-period';
import { communityServerServiceGiveawayItem } from '../fn/community-server-service/community-server-service-giveaway-item';
import { CommunityServerServiceGiveawayItem$Params } from '../fn/community-server-service/community-server-service-giveaway-item';
import { GetGiveawayBudgetForCurrentTimePeriodResponse } from '../models/get-giveaway-budget-for-current-time-period-response';
import { GiveawayItemResponse } from '../models/giveaway-item-response';

@Injectable({ providedIn: 'root' })
export class CommunityServerServiceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `communityServerServiceGiveawayItem()` */
  static readonly CommunityServerServiceGiveawayItemPath = '/api/v3/gateway/communityserver/giveaway';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `communityServerServiceGiveawayItem()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  communityServerServiceGiveawayItem$Response(params: CommunityServerServiceGiveawayItem$Params, context?: HttpContext): Observable<StrictHttpResponse<GiveawayItemResponse>> {
    return communityServerServiceGiveawayItem(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `communityServerServiceGiveawayItem$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  communityServerServiceGiveawayItem(params: CommunityServerServiceGiveawayItem$Params, context?: HttpContext): Observable<GiveawayItemResponse> {
    return this.communityServerServiceGiveawayItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<GiveawayItemResponse>): GiveawayItemResponse => r.body)
    );
  }

  /** Path part for operation `communityServerServiceGetGiveawayBudgetForCurrentTimePeriod()` */
  static readonly CommunityServerServiceGetGiveawayBudgetForCurrentTimePeriodPath = '/api/v3/gateway/communityserver/giveaway/budget';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `communityServerServiceGetGiveawayBudgetForCurrentTimePeriod()` instead.
   *
   * This method doesn't expect any request body.
   */
  communityServerServiceGetGiveawayBudgetForCurrentTimePeriod$Response(params?: CommunityServerServiceGetGiveawayBudgetForCurrentTimePeriod$Params, context?: HttpContext): Observable<StrictHttpResponse<GetGiveawayBudgetForCurrentTimePeriodResponse>> {
    return communityServerServiceGetGiveawayBudgetForCurrentTimePeriod(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `communityServerServiceGetGiveawayBudgetForCurrentTimePeriod$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  communityServerServiceGetGiveawayBudgetForCurrentTimePeriod(params?: CommunityServerServiceGetGiveawayBudgetForCurrentTimePeriod$Params, context?: HttpContext): Observable<GetGiveawayBudgetForCurrentTimePeriodResponse> {
    return this.communityServerServiceGetGiveawayBudgetForCurrentTimePeriod$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetGiveawayBudgetForCurrentTimePeriodResponse>): GetGiveawayBudgetForCurrentTimePeriodResponse => r.body)
    );
  }

}
