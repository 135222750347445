/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AdminServiceService } from './services/admin-service.service';
import { AbuseServiceService } from './services/abuse-service.service';
import { AuctionServiceService } from './services/auction-service.service';
import { GachaServiceService } from './services/gacha-service.service';
import { UserServiceService } from './services/user-service.service';
import { CommunityServerServiceService } from './services/community-server-service.service';
import { AltArtServiceService } from './services/alt-art-service.service';
import { AchievementServiceService } from './services/achievement-service.service';
import { LeaderboardServiceService } from './services/leaderboard-service.service';
import { MarketplaceServiceService } from './services/marketplace-service.service';
import { MediaServiceService } from './services/media-service.service';
import { UpaGuildServiceService } from './services/upa-guild-service.service';
import { BackpackServiceService } from './services/backpack-service.service';
import { NotificationServiceService } from './services/notification-service.service';
import { TradeServiceService } from './services/trade-service.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AdminServiceService,
    AbuseServiceService,
    AuctionServiceService,
    GachaServiceService,
    UserServiceService,
    CommunityServerServiceService,
    AltArtServiceService,
    AchievementServiceService,
    LeaderboardServiceService,
    MarketplaceServiceService,
    MediaServiceService,
    UpaGuildServiceService,
    BackpackServiceService,
    NotificationServiceService,
    TradeServiceService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
