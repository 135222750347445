<!-- <ng-container *ngIf="auth.user$ | async as user"> -->
<div class="container is-fluid">
  <section>
    <div class="columns">
      <div class="column is-two-fifths">
        <div class="field">
          <label class="label">Input MAL Character Page URL (remember to make new media if you haven't)</label>
          <div class="control">
            <input [(ngModel)]="urlToExtract" (ngModelChange)="extract($event)" class="input" type="text"
              placeholder="mal url">
          </div>
        </div>
      </div>
      <div class="column is-three-fifths">
        <div class="field">
          <label class="label">valid game wikis:</label>
          <ul>

            <li
              (click)="urlToExtract = 'https://azurlane.koumakan.jp/wiki/List_of_Ships_by_Type'; extract('https://azurlane.koumakan.jp/wiki/List_of_Ships_by_Type')">
              Azur Lane: https://azurlane.koumakan.jp/wiki/List_of_Ships_by_Type</li>
            <li (click)="urlToExtract = 'https://epic7x.com/characters/'; extract('https://epic7x.com/characters/')">
              Epic 7: https://epic7x.com/characters/</li>
            <li
              (click)="urlToExtract = 'https://gamepress.gg/arknights/tools/interactive-operator-list'; extract('https://gamepress.gg/arknights/tools/interactive-operator-list')">
              Arknights: https://gamepress.gg/arknights/tools/interactive-operator-list</li>
            <li (click)="urlToExtract = 'https://gbf.wiki/All_Characters'; extract('https://gbf.wiki/All_Characters')">
              Granblue Fantasy: https://gbf.wiki/All_Characters</li>
            <li (click)="urlToExtract = 'https://paimon.moe/characters/'; extract('https://paimon.moe/characters/')">
              Genshin: https://paimon.moe/characters/</li>
            <li
              (click)="urlToExtract = 'https://bulbapedia.bulbagarden.net/wiki/List_of_Pok%C3%A9mon_by_National_Pok%C3%A9dex_number'; extract('https://bulbapedia.bulbagarden.net/wiki/List_of_Pok%C3%A9mon_by_National_Pok%C3%A9dex_number')">
              Pokemon: https://bulbapedia.bulbagarden.net/wiki/List_of_Pok%C3%A9mon_by_National_Pok%C3%A9dex_number</li>
            <li
              (click)="urlToExtract = 'https://astralexpress.net/characters'; extract('https://astralexpress.net/characters')">
              Star Rail: https://astralexpress.net/characters</li>
            <li
              (click)="urlToExtract = 'https://feheroes.fandom.com/wiki/List_of_Heroes'; extract('https://feheroes.fandom.com/wiki/List_of_Heroes')">
              Fire Emblem Heroes: https://feheroes.fandom.com/wiki/List_of_Heroes</li>
            <li
              (click)="urlToExtract = 'https://gametora.com/umamusume/characters'; extract('https://gametora.com/umamusume/characters')">
              Umamusume: https://gametora.com/umamusume/characters</li>
            <li
              (click)="urlToExtract = 'https://www.prydwen.gg/zenless/characters/'; extract('https://www.prydwen.gg/zenless/characters/')">
              ZZZ: https://www.prydwen.gg/zenless/characters/</li>

          </ul>
        </div>
      </div>
    </div>
  </section>
  <section *ngIf="characterUrlData">
    <hr>
    <div>
      <button (click)="getPrev()" class="button is-info">&laquo; prev</button>&nbsp;
      <button (click)="resetToZero()" class="button is-link">back to start</button>&nbsp;
      <button (click)="getNext()" class="button is-info">next &raquo;</button>
    </div>
    <p class="title">Favorites: {{ characterUrlData[currentIdx].favorites }}</p>
    <div class="columns">
      <div class="column is-four-fifths">
        <app-upload-form [autoSelectMedia]="false" (successfulSubmit)=" getNext()"
          extractUrl="{{characterUrlData[currentIdx].url}}">
        </app-upload-form>
      </div>
      <div class="column is-one-fifth">
        <p>click a name to jump to it</p>
        <input type="checkbox" [(ngModel)]="removeDupes" (ngModelChange)="onRemoveDupesChange($event)"
          id=" removeDupes">
        <label for="removeDupes">Remove dupes (doesn't work if media doesn't exist!)</label>
        <table class="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Favorites</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of characterUrlData; let i = index">
              <tr>
                <td [ngClass]="currentIdx === i ? 'has-text-weight-bold' : 'has-text-weight-light'">
                  <a [attr.data-idx]="i" (click)="setIdx(i)">{{ item.name }}</a>
                </td>
                <td
                  [ngClass]="currentIdx === i ? 'has-text-weight-bold is-italic is-underlined' : 'has-text-weight-light'">
                  {{ item.favorites }}
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</div>
<!-- </ng-container> -->