<div class="navbar-end">
  
    <div class="navbar-item">
      <ng-container *ngIf="auth.user$ | async as user; else loggedOut">
      <div class="navbar-item has-dropdown is-hoverable">
        <a class="navbar-link">
          <figure class="image is-20x20 m-auto">
            <img width="32" height="32" style="height:100%" src="{{auth.avatar$ | async}}" class="is-32x32 is-rounded">
          </figure>&nbsp;<strong>{{ user.username }}</strong>&nbsp;#{{ user.discriminator }}
        </a>
        <div class="navbar-dropdown">
          <a *ngIf="user.user_uuid" [routerLink]="['/view/user/', user.user_uuid]" routerLinkActive="router-link-active" class="navbar-item">
            View Collection
          </a>
          <hr class="navbar-divider" />
          <a (click)="auth.logout({ returnTo: document.location.origin })" class="navbar-item">
            Log out
          </a>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #loggedOut>
  <button class="button is-primary" (click)="auth.login()">Log in</button>
</ng-template>