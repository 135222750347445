import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { ToastrService } from 'ngx-toastr';
import { filter, mergeMap, tap } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { Media, UserSettings, TradeableStatus } from 'src/app/openapi/models';
import { TRADEABLE_STATUS } from 'src/app/openapi/models/tradeable-status-array';
import { MediaServiceService, UserServiceService } from 'src/app/openapi/services';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  tradeableStatus = TRADEABLE_STATUS;
  userSettings?: UserSettings;
  isSupporter?: boolean;
  timezone: string = `UTC ${(-(new Date().getTimezoneOffset() / 60)).toString()}`;

  localizedRollReminder?: { [key: string]: boolean } = {};

  medias?: Media[];
  selectedAutoClaimMedia?: string[];
  rarities = [1, 2, 3, 4, 5];
  selectedAutoClaimRarities: number[] = [];
  selectedAutoDustRarities: number[] = [];
  selectedAutoStarpowerRarities: number[] = [];

  constructor(
    private cookieService: CookieService,
    private userService: UserServiceService,
    private mediaService: MediaServiceService,
    public auth: AuthService,
    private toastr: ToastrService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit(): void {
    this.auth.user$.pipe(
      filter(u => !!u),
      tap(u => this.isSupporter = u?.supporter_status! !== "SUPPORTER_STATUS_NONE"),
      mergeMap(u => this.userService.userServiceGetUserSettings({ userUuid: u!.user_uuid! }))
    ).subscribe(settings => {
      this.userSettings = settings.userSettings;
      if (this.userSettings) {
        if (!this.userSettings.autoClaimMedia) {
          this.userSettings.autoClaimMedia = { autoClaimMedia: {} };
        }
        if (!this.userSettings.autoClaimMedia.autoClaimMedia) {
          this.userSettings.autoClaimMedia.autoClaimMedia = {};
        }
        this.selectedAutoClaimMedia = Object.keys(this.userSettings.autoClaimMedia.autoClaimMedia);
        if (!this.userSettings.autoClaimStars) {
          this.userSettings.autoClaimStars = { autoClaimStars: {} };
        }
        if (!this.userSettings.autoClaimStars.autoClaimStars) {
          this.userSettings.autoClaimStars.autoClaimStars = {};
        }
        this.selectedAutoClaimRarities = Object.keys(this.userSettings.autoClaimStars.autoClaimStars).map(Number);
        if (!this.userSettings.autoDustByRarity) {
          this.userSettings.autoDustByRarity = { autoDust: {} };
        }
        if (!this.userSettings.autoDustByRarity.autoDust) {
          this.userSettings.autoDustByRarity.autoDust = {};
        }
        this.selectedAutoDustRarities = Object.keys(this.userSettings.autoDustByRarity.autoDust).map(Number);
        if (!this.userSettings.autoStarpowerByRarity) {
          this.userSettings.autoStarpowerByRarity = { autoStarpower: {} };
        }
        if (!this.userSettings.autoStarpowerByRarity.autoStarpower) {
          this.userSettings.autoStarpowerByRarity.autoStarpower = {};
        }
        this.selectedAutoStarpowerRarities = Object.keys(this.userSettings.autoStarpowerByRarity.autoStarpower).map(Number);
      }
      // Convert the rollReminder keys which represent hours of 0-23 UTC into the local timezone 0-23
      // It's of type [key: string]: boolean;
      if (this.userSettings?.rollReminder?.twentyFourHourToRemind) {
        this.localizedRollReminder = {};
        for (let key in this.userSettings.rollReminder.twentyFourHourToRemind) {
          // Account for getTimezoneOffset being positive for negative UTC offsets
          let localHour = (parseInt(key) - (new Date().getTimezoneOffset() / 60) + 24) % 24;
          this.localizedRollReminder[localHour.toString()] = this.userSettings.rollReminder.twentyFourHourToRemind[key];
        }
      }
      this.userSettings?.autoClaimMedia?.autoClaimMedia
    });
    this.mediaService.mediaServiceGetAllMedia().subscribe(medias => {
      this.medias = medias.media;
    });
  }

  stringifyTradeableStatus(status: TradeableStatus): string {
    switch (status) {
      case "TRADEABLE":
        return "Tradeable";
      case "KINDA_TRADEABLE":
        return "Kinda Tradeable";
      case "UNTRADEABLE":
        return "Untradeable";
      default:
        return "Unknown";
    }
  }

  didSetReminder(): boolean {
    return this.localizedRollReminder ? Object.values(this.localizedRollReminder).some(v => v) : false;
  }

  onSelectAutoClaimMedia(mediaIDs: string[]) {
    if (!this.userSettings?.autoClaimMedia || !this.userSettings.autoClaimMedia.autoClaimMedia) {
      this.toastr.error("No settings to save");
      return;
    }
    let autoClaimMedia = this.userSettings.autoClaimMedia.autoClaimMedia;
    for (let mediaID in autoClaimMedia) {
      if (!mediaIDs.includes(mediaID)) {
        delete autoClaimMedia[mediaID];
      }
    }
    for (let mediaID of mediaIDs) {
      if (!autoClaimMedia[mediaID]) {
        autoClaimMedia[mediaID] = true;
      }
    }
  }
  selectAllAutoClaimMedia() {
    if (!this.medias) {
      this.toastr.error("No media to select");
      return;
    }
    this.onSelectAutoClaimMedia(this.medias.map(m => m.id!));
    this.selectedAutoClaimMedia = this.medias.map(m => m.id!);
  }
  deselectAllAutoClaimMedia() {
    this.onSelectAutoClaimMedia([]);
    this.selectedAutoClaimMedia = [];
  }

  onSelectAutoClaimRarity(rarities: number[]) {
    if (!this.userSettings?.autoClaimStars || !this.userSettings.autoClaimStars.autoClaimStars) {
      this.toastr.error("No settings to save");
      return;
    }
    let autoClaimStars = this.userSettings.autoClaimStars.autoClaimStars;
    for (let rarity of this.rarities) {
      if (!rarities.includes(rarity)) {
        delete autoClaimStars[rarity];
      }
    }
    for (let rarity of rarities) {
      if (!autoClaimStars[rarity]) {
        autoClaimStars[rarity] = true;
      }
    }
  }

  onSelectAutoDustRarity(rarities: number[]) {
    if (!this.userSettings?.autoDustByRarity || !this.userSettings.autoDustByRarity.autoDust) {
      this.toastr.error("No settings to save");
      return;
    }
    let autoDustByRarity = this.userSettings.autoDustByRarity.autoDust;
    for (let rarity of this.rarities) {
      if (!rarities.includes(rarity)) {
        delete autoDustByRarity[rarity];
      }
    }
    for (let rarity of rarities) {
      if (!autoDustByRarity[rarity]) {
        autoDustByRarity[rarity] = true;
      }
    }
  }

  onSelectAutoStarpowerRarity(rarities: number[]) {
    if (!this.userSettings?.autoStarpowerByRarity || !this.userSettings.autoStarpowerByRarity.autoStarpower) {
      this.toastr.error("No settings to save");
      return;
    }
    let autoStarpowerByRarity = this.userSettings.autoStarpowerByRarity.autoStarpower;
    for (let rarity of this.rarities) {
      if (!rarities.includes(rarity)) {
        delete autoStarpowerByRarity[rarity];
      }
    }
    for (let rarity of rarities) {
      if (!autoStarpowerByRarity[rarity]) {
        autoStarpowerByRarity[rarity] = true;
      }
    }
  }

  saveSettings() {
    if (!this.userSettings) {
      this.toastr.error("No settings to save");
      return;
    }
    // Convert the rollReminder keys back to UTC
    if (this.didSetReminder()) {
      this.userSettings.rollReminder = { twentyFourHourToRemind: {} };
      for (let key in this.localizedRollReminder) {
        let utcHour = (parseInt(key) + (new Date().getTimezoneOffset() / 60)) % 24;
        this.userSettings.rollReminder!.twentyFourHourToRemind![utcHour.toString()] = this.localizedRollReminder[key];
      }
    }
    this.auth.user$.pipe(
      filter(u => !!u),
    ).subscribe(u => {
      this.userService.userServiceUpdateUserSettings({
        userUuid: u!.user_uuid!, body: {
          userSettings: this.userSettings
        }
      }).subscribe(() => {
        this.toastr.success("Settings saved");
      });
    });
  }

  setAchievementCookie(threshold: number, value: boolean) {
    let prevAchievementCookie = this.getCookie('hideAchievements');
    let achievementCookie = prevAchievementCookie ? JSON.parse(prevAchievementCookie) : {};
    achievementCookie[threshold] = value;
    this.putCookie('hideAchievements', JSON.stringify(achievementCookie));
  }

  getAchievementCookie(threshold: number): boolean {
    if (isPlatformBrowser(this.platformId)) {
      let achievementCookie = this.getCookie('hideAchievements');
      if (!achievementCookie) {
        return false;
      }
      let achievementCookieObj = JSON.parse(achievementCookie);
      return achievementCookieObj[threshold] || false;
    }
    return true;
  }

  putCookie(key: string, value: string) {
    this.cookieService.put(key, value);
  }

  getCookie(key: string): string | undefined {
    return this.cookieService.get(key);
  }

  checkboxChange(event: any, threshold: number) {
    this.setAchievementCookie(threshold, event.target.checked);
  }
}
