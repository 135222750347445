import { Component, OnInit, AfterViewInit, Inject, PLATFORM_ID, afterRender, NgZone, ViewChild, ElementRef, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, forkJoin, mergeMap, Observable, of, Subject } from 'rxjs';
import { switchMap, map, tap, takeUntil, take } from 'rxjs/operators';
import AsyncLock from 'async-lock';
import { generateUserActivityCssClassV2, generateUserActivityV2 } from 'src/app/util/date_transform';
import { faChevronDown, faChevronUp, faFilter } from '@fortawesome/free-solid-svg-icons';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { AuthService, User as LoggedInUser } from 'src/app/auth/auth.service';
import { CookieService } from 'ngx-cookie';
import { DomSanitizer, Meta, SafeHtml } from '@angular/platform-browser';
import { ViewUserGachaResolveUserData } from './resolve.resolver';
import { ViewportScroller, isPlatformBrowser } from '@angular/common';
import { Achievement, BatchGenerateAchievementHtmlRequestShield, FavoriteGacha, GetUserFavoriteGachasResponseStatus, GetUserGachaMintedOutOfResponseCopyOutOf, Media, TradeableStatus, User, UserHasGachasGachaAndMediaAndStarpower } from 'src/app/openapi/models';
import { UserServiceGetUserGachasPaginated$Params } from 'src/app/openapi/fn/user-service/user-service-get-user-gachas-paginated';
import { AchievementServiceService, GachaServiceService, MediaServiceService, UserServiceService } from 'src/app/openapi/services';
import { GACHA_ENHANCEMENT_TYPE } from 'src/app/openapi/models/gacha-enhancement-type-array';
import { ToastrService } from 'ngx-toastr';
import { TRADEABLE_STATUS } from 'src/app/openapi/models/tradeable-status-array';

const MAX_FAVORITES = 16;

interface achievementAndHTML {
  achievement: Achievement;
  html: SafeHtml;
}

interface favoriteGachaOrEmptySlot {
  favoriteGacha?: FavoriteGacha;
  emptySlot?: number;
}
@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.scss'],
  animations: [
    trigger('collapseAnimation', [
      transition('* <=> *', [
        query(
          ':enter',
          [
            style({ opacity: 0, transform: 'translateY(-5px)' }),
            stagger(
              '10ms',
              animate(
                '500ms ease-out',
                style({ opacity: 1, transform: 'translateY(0px)' })
              )
            )
          ],
          {
            optional: true
          }
        ),
        query(':leave',
          animate('400ms', style({ opacity: 0, transform: 'translateY(5px)' })),
          {
            optional: true
          })
      ])
    ]),
  ],
})
export class ViewUserComponent implements OnInit {
  currentTab = "gacha";

  isBrowser = false;

  toggleIcon = faChevronDown;
  filterIcon = faFilter;

  displayName?: string;
  nextToken?: string;
  prevToken?: string;
  scrollDownLock = new AsyncLock();

  gachas?: UserHasGachasGachaAndMediaAndStarpower[];
  favorites?: favoriteGachaOrEmptySlot[];
  // Map an achievement to its prerendered HTML
  shownAchievements?: achievementAndHTML[];
  favoriteMedia?: Media;
  medias?: Media[];
  user?: User;
  loggedInUser?: LoggedInUser;
  datePulled?: {
    [key: string]: Date;
  };
  mintedOutOf?: { [key: string]: GetUserGachaMintedOutOfResponseCopyOutOf };
  markedForTrade?: { [key: string]: TradeableStatus };

  // Editing favorites stuff
  isEditingFavorites = false;

  // Utilize these params to set filtering options as well as all other options when calling the API
  params: UserServiceGetUserGachasPaginated$Params = { userUuid: "" };

  // Useful for filters
  allMedia?: Media[];

  @ViewChild('loading') loadingBar!: ElementRef;
  constructor(
    private gachaService: GachaServiceService,
    private mediaService: MediaServiceService,
    private userService: UserServiceService,
    private achievementService: AchievementServiceService,
    private sanitier: DomSanitizer,
    private route: ActivatedRoute,
    public auth: AuthService,
    private cookieService: CookieService,
    private router: Router,
    private viewportScroller: ViewportScroller,
    private toastr: ToastrService,
    @Inject(PLATFORM_ID) private platformId: Object) {
    this.route.data.subscribe(d => { });
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.auth.user$.pipe(take(1)).subscribe(u => this.loggedInUser = u);
  }

  batchConvertAchievements(achievement: Achievement[]): Observable<SafeHtml[]> {
    let shields: Array<BatchGenerateAchievementHtmlRequestShield> = achievement.map(a => ({
      label: a.name,
      message: a.description,
      color: a.imageUrl ? (new URLSearchParams(a.imageUrl).get("color") ?? "informational") : "informational",
    }));
    return this.achievementService.achievementServiceBatchGenerateAchievementHtml({ body: { shields: shields } }).pipe(
      map(res => (res.html || []).map(r2 => {
        return this.sanitier.bypassSecurityTrustHtml(r2);
      }))
    );
  }

  generateUserActivity = generateUserActivityV2;
  generateUserActivityCssClass = generateUserActivityCssClassV2;
  toggleCollapse() {
    document.querySelectorAll(".collapsible").forEach(ele =>
      ele.classList.toggle("open"));
    this.toggleIcon = this.toggleIcon === faChevronDown ? faChevronUp : faChevronDown;
  }

  toggleFilterCollapse() {
    document.querySelector(".collapsible-filter")?.classList.toggle("open");
  }

  ngOnInit(): void {
    const stopSignal$ = new Subject();
    this.route.params.pipe(
      tap(p => this.params = { userUuid: p['userUUID'], "paginationOptions.pageSize": 24 }),
      mergeMap(p => this.userService.userServiceGetUserGachasPaginated(this.params).pipe(
        takeUntil(stopSignal$),
        map(res => {
          this.gachas = res.userHasGachas?.gachas;
          this.displayName = res.userHasGachas?.user?.displayName || undefined;
          this.user = res.userHasGachas?.user;
          this.nextToken = res.paginationDetails?.nextPage;
          this.prevToken = res.paginationDetails?.previousPage;
          this.markedForTrade = res.userHasGachas?.gachas?.reduce((acc, g) => {
            acc[g.gacha!.id!] = g.tradeableStatus || "TRADEABLE";
            return acc;
          }, {} as { [key: string]: TradeableStatus });
          return res;
        }),
        // Favorites go first because we need the mintedOutOf mapping for them too
        mergeMap(res => {
          const favorites = this.userService.userServiceGetUserFavoriteGachas({ userUuid: p['userUUID'] }).pipe(
            tap(f => {
              const setFavorites = f.favoriteGachas?.map(f => ({ favoriteGacha: f })) || [];
              this.favorites = Array.from({ length: MAX_FAVORITES }, (_, i) => {
                const favorite = setFavorites.find(f => f.favoriteGacha?.priority === i + 1);
                if (favorite) {
                  return favorite;
                }
                return { emptySlot: i + 1 };
              });
            })
          );
          const favoriteMedia = this.userService.userServiceGetUserFavoriteMedia({ userUuid: p['userUUID'] }).pipe(
            tap(f => this.favoriteMedia = f.media)
          );

          return forkJoin([of(res), favorites, favoriteMedia]);
        }),
        mergeMap(([res, favorites, favoriteMedia]) => {
          const dateMapping = this.userService.userServiceGetUserGachaDates({
            userUuid: p['userUUID'],
            body: {
              gachaIds: res.userHasGachas?.gachas?.map(g => g.gacha!.id!).concat(favorites.favoriteGachas?.map(f => f.gacha!.gacha!.id!) || [])
            },
          }).pipe(
            tap(dateMapping => this.datePulled = mapDateEntries(dateMapping.gachaIdToDate || {}))
          );
          const mintedOutOf = this.userService.userServiceGetUserGachaMintedOutOf({
            userUuid: p['userUUID'],
            body: {
              gachaIds: res.userHasGachas?.gachas?.map(g => g.gacha!.id!).concat(favorites.favoriteGachas?.map(f => f.gacha!.gacha!.id!) || []) || []
            },
          }).pipe(
            tap(mintedOutOf => this.mintedOutOf = mintedOutOf.gachaIdToCopyOutOf)
          );
          const allMedia = this.mediaService.mediaServiceGetAllMedia().pipe(
            tap(m => this.allMedia = m.media || [])
          );
          return forkJoin([of(res), dateMapping, mintedOutOf, allMedia]);
        }),
        mergeMap(([res]) => {
          const user = this.userService.userServiceGetUser({ uuid: p['userUUID'], consolidateAchievements: true }).pipe(
            tap(u => this.user = u.user),
            mergeMap(u => this.batchConvertAchievements(u.user?.achievements || [])),
            tap(htmlAchievements => {
              this.shownAchievements = this.user?.achievements?.filter(a => !this.shouldFilterAchievementForSetting(a)).map((a, i) => ({ achievement: a, html: htmlAchievements[i] }));
            })
          );
          return forkJoin([of(res), user]);
        })
      )),
    ).subscribe(([res]) => {
      if (!res.userHasGachas) {
        throw new Error('no user_has_gachas');
      }
      this.route.fragment.subscribe(f => {
        if (f === "altArt") {
          this.selectTab("altArt");
        }
      });
    });
  }

  onScrollDown() {
    this.scrollDownLock.acquire('scroll-down', (done: (error?: any) => void) => {
      if (!this.nextToken) {
        done();
        return;
      }
      this.route.params.pipe(
        tap(p => this.params['paginationOptions.page'] = this.nextToken),
        mergeMap(p => this.userService.userServiceGetUserGachasPaginated(this.params).pipe(
          map(res => {
            this.nextToken = res.paginationDetails?.nextPage;
            this.prevToken = res.paginationDetails?.previousPage;
            return res;
          }),
          mergeMap(res => {
            const dateMapping = this.userService.userServiceGetUserGachaDates({
              userUuid: p['userUUID'],
              body: {
                gachaIds: res.userHasGachas?.gachas?.map(g => g.gacha!.id!)
              }
            });
            const mintedOutOf = this.userService.userServiceGetUserGachaMintedOutOf({
              userUuid: p['userUUID'],
              body: {
                gachaIds: res.userHasGachas?.gachas?.map(g => g.gacha!.id!) || []
              }
            });
            return combineLatest([of(res), dateMapping, mintedOutOf]);
          })
        ))
      ).subscribe(([res, dateMapping, mintedOutOf]) => {
        if (!res.userHasGachas) {
          done(new Error('no user_has_gachas'));
          return;
        }

        this.mintedOutOf = { ...this.mintedOutOf, ...mintedOutOf.gachaIdToCopyOutOf };
        this.gachas = this.gachas?.concat(res.userHasGachas.gachas || []);
        this.datePulled = { ...this.datePulled, ...mapDateEntries(dateMapping.gachaIdToDate || {}) };
        this.markedForTrade = {
          ... this.markedForTrade, ...res.userHasGachas.gachas?.reduce((acc, g) => {
            acc[g.gacha!.id!] = g.tradeableStatus || "TRADEABLE";
            return acc;
          }, {} as { [key: string]: TradeableStatus })
        };
        done();
      });
    });
  }

  onScrollUp() {
  }

  trackBy(index: number, g: UserHasGachasGachaAndMediaAndStarpower): string {
    return g.gacha?.id || "undef";
  }

  trackByFavorites(index: number, f: favoriteGachaOrEmptySlot): string {
    return f.favoriteGacha?.priority?.toString() || f.emptySlot?.toString() || "undef";
  }

  // Reload the gacha list with the current filters
  reloadGachaForFilters() {
    this.scrollDownLock.acquire('scroll-down', (done: (error?: any) => void) => {
      this.route.params.pipe(
        tap(p => this.params['paginationOptions.page'] = undefined),
        tap(_ => this.loadingBar.nativeElement.style.visibility = "visible"),
        mergeMap(p => this.userService.userServiceGetUserGachasPaginated(this.params).pipe(
          map(res => {
            this.nextToken = res.paginationDetails?.nextPage;
            this.prevToken = res.paginationDetails?.previousPage;
            return res;
          }),
          mergeMap(res => {
            const dateMapping = this.userService.userServiceGetUserGachaDates({
              userUuid: p['userUUID'],
              body: {
                gachaIds: res.userHasGachas?.gachas?.map(g => g.gacha!.id!)
              },
            });
            const mintedOutOf = this.userService.userServiceGetUserGachaMintedOutOf({
              userUuid: p['userUUID'],
              body: {
                gachaIds: res.userHasGachas?.gachas?.map(g => g.gacha!.id!) || []
              },
            });
            return combineLatest([of(res), dateMapping, mintedOutOf]);
          })
        ))
      ).subscribe(([res, dateMapping, mintedOutOf]) => {
        this.loadingBar.nativeElement.style.visibility = "hidden";
        if (!res.userHasGachas) {
          done(new Error('no user_has_gachas'));
          return;
        }

        this.mintedOutOf = { ...this.mintedOutOf, ...mintedOutOf.gachaIdToCopyOutOf };
        this.gachas = res.userHasGachas.gachas;
        this.datePulled = { ...this.datePulled, ...mapDateEntries(dateMapping.gachaIdToDate || {}) };
        this.markedForTrade = {
          ... this.markedForTrade, ...res.userHasGachas.gachas?.reduce((acc, g) => {
            acc[g.gacha!.id!] = g.tradeableStatus || "TRADEABLE";
            return acc;
          }, {} as { [key: string]: TradeableStatus })
        };
        done();
      }, err => this.loadingBar.nativeElement.style.visibility = "hidden")
    });
  }


  /*************
   * Favorites *
   *************/
  removeFavorite(priority: number) {
    if (!this.loggedInUser) {
      return;
    }
    this.userService.userServiceClearUserFavoriteGachaSlot({
      userUuid: this.loggedInUser.user_uuid!,
      priority: priority,
    }).subscribe(res => {
      switch (res.status) {
        case "PRIORITY_OUT_OF_RANGE":
          this.toastr.error("Error clearing slot, try again later");
          break;
        case "SUCCESS":
          this.toastr.success("Slot cleared");
          // Set the favorite to undefined, and set the empty slot to the priority
          for (let i = 0; i < this.favorites!.length; i++) {
            if (this.favorites![i].favoriteGacha?.priority === priority) {
              this.favorites![i].favoriteGacha = undefined;
              this.favorites![i].emptySlot = priority;
            }
          }
          break;
      }
    });
  }

  setFavoriteSlot(data: { gachaID: string, priority: number }) {
    if (!this.loggedInUser) {
      return;
    }
    this.userService.userServiceSetUserFavoriteGachas({
      userUuid: this.loggedInUser.user_uuid!,
      body: {
        gacha: {
          gachaId: data.gachaID,
          priority: data.priority,
        }
      }
    }).subscribe(res => {
      switch (res.status) {
        case "GACHA_NOT_FOUND":
          this.toastr.error("Gacha not found");
          break;
        case "GACHA_NOT_OWNED":
          this.toastr.error("You don't own this gacha");
          break;
        case "PRIORITY_OUT_OF_RANGE":
          this.toastr.error("Error setting favorite, try again later");
          break;
        case "SUCCESS":
          this.toastr.success("Favorite set!");
          // Reload the favorites
          this.userService.userServiceGetUserFavoriteGachas({ userUuid: this.loggedInUser!.user_uuid! }).subscribe(f => {
            const setFavorites = f.favoriteGachas?.map(f => ({ favoriteGacha: f })) || [];
            this.favorites = Array.from({ length: MAX_FAVORITES }, (_, i) => {
              const favorite = setFavorites.find(f => f.favoriteGacha?.priority === i + 1);
              if (favorite) {
                return favorite;
              }
              return { emptySlot: i + 1 };
            });
          });
          break;
      }
    })
  }

  /*************
   * Filtering *
   *************/
  onSelectMediaFilter(mediaID: string) {
    // if we already have a media filter, remove it
    this.params['collectionFilter.byMedia.mediaId'] = undefined;
    if (mediaID !== 'reset') {
      this.params['collectionFilter.byMedia.mediaId'] = mediaID;
    }
    this.reloadGachaForFilters();
  }

  selectedRarities: { [key: number]: boolean } = {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
  };
  onSelectRarityFilter(rarity: number) {
    this.selectedRarities[rarity] = !this.selectedRarities[rarity];
    const isSelected = this.selectedRarities[rarity];

    if (rarity === 6 && isSelected) {
      // Deselect all the other checkbox rarities
      Object.keys(this.selectedRarities).forEach((r) => {
        if (parseInt(r) !== 6) {
          this.selectedRarities[parseInt(r)] = false;
        }
      });
    } else if (rarity !== 6 && this.selectedRarities[6] && isSelected) {
      this.selectedRarities[6] = false;
    }

    this.params['collectionFilter.byRarity.raritySelection.oneStar'] = this.selectedRarities[1] || false;
    this.params['collectionFilter.byRarity.raritySelection.twoStar'] = this.selectedRarities[2] || false;
    this.params['collectionFilter.byRarity.raritySelection.threeStar'] = this.selectedRarities[3] || false;
    this.params['collectionFilter.byRarity.raritySelection.fourStar'] = this.selectedRarities[4] || false;
    this.params['collectionFilter.byRarity.raritySelection.fiveStar'] = this.selectedRarities[5] || false;
    this.params['collectionFilter.byRarity.raritySelection.sixStar'] = this.selectedRarities[6] || false;

    this.reloadGachaForFilters();
  }

  onSelectShowUnownedFilter(toggled: any) {
    // if we already have a show unowned filter, remove it
    this.auth.user$.pipe(take(1)).subscribe(u => {
      if (!u) {
        return;
      }
      this.params['collectionFilter.byOwnership.userId'] = u.id;
      this.params['collectionFilter.byOwnership.wantOwned'] = !toggled.target.checked;
      this.reloadGachaForFilters();
    });
  }

  onSelectShowOpenForTradeFilter(status: string) {
    switch (status) {
      case "reset":
        this.params['collectionFilter.byOpenForTrade.tradeStatus'] = undefined;
        break;
      case "TRADEABLE":
        this.params['collectionFilter.byOpenForTrade.tradeStatus'] = TRADEABLE_STATUS.findIndex(t => t === "TRADEABLE");
        break;
      case "KINDA_TRADEABLE":
        this.params['collectionFilter.byOpenForTrade.tradeStatus'] = TRADEABLE_STATUS.findIndex(t => t === "KINDA_TRADEABLE");
        break;
      case "UNTRADEABLE":
        this.params['collectionFilter.byOpenForTrade.tradeStatus'] = TRADEABLE_STATUS.findIndex(t => t === "UNTRADEABLE");
        break;
    }
    // if we already have a show open for trade filter, remove it
    // this.params['collectionFilter.byOpenForTrade.openForTrade'] = toggled.target.checked ? true : undefined;
    this.reloadGachaForFilters();
  }

  onSelectShowSpecialEffectFilter(toggled: any) {
    // if we already have a show special effect filter, remove it
    const allEffects = GACHA_ENHANCEMENT_TYPE.filter(e => e !== "NO_GACHA_ENHANCEMENT");
    this.params['collectionFilter.byEnhancement.enhancement'] = toggled.target.checked ? allEffects : undefined;
    this.reloadGachaForFilters();
  }

  onChangeUpperBoundMint(e: any) {
    if (!e.target.value || e.target.value === "") {
      this.params['collectionFilter.byMinted.mintedUpperBoundInclusive'] = undefined;
      this.reloadGachaForFilters();
      return;
    }
    this.params['collectionFilter.byMinted.mintedUpperBoundInclusive'] = e.target.value;
    this.reloadGachaForFilters();
  }

  onChangeLowerBoundMint(e: any) {
    if (!e.target.value || e.target.value === "") {
      this.params['collectionFilter.byMinted.mintedLowerBoundInclusive'] = undefined;
      this.reloadGachaForFilters();
      return;
    }
    this.params['collectionFilter.byMinted.mintedLowerBoundInclusive'] = e.target.value;
    this.reloadGachaForFilters();
  }

  // SUPER SCUFFED ACHIEVEMENT HDIING CODE W/E
  shouldFilterAchievementForSetting(achievement: Achievement): boolean {
    if (!achievement.percentRate) {
      return false;
    }

    let threshold = achievement.percentRate.threshold || 0;
    return this.getAchievementCookie(threshold);
  }

  getAchievementCookie(threshold: number): boolean {
    if (isPlatformBrowser(this.platformId)) {
      let achievementCookie = this.cookieService.get('hideAchievements');
      if (!achievementCookie) {
        return false;
      }
      let achievementCookieObj = JSON.parse(achievementCookie);
      return achievementCookieObj[threshold] || false;
    }
    return true;
  }

  selectTab(tab: validTabs) {
    switch (tab) {
      case "gacha":
        this.currentTab = "gacha";
        break;
      case "altArt":
        this.currentTab = "altArt";
        break;
      default:
        return;
    }
    this.router.navigate([], { fragment: tab })
  }
}

function mapDateEntries(entries: { [key: string]: string }): { [key: string]: Date } {
  return Object.entries(entries).reduce((acc: { [key: string]: Date }, [key, value]) => {
    acc[key] = new Date(value);
    return acc;
  }, {});
}
type validTabs = "gacha" | "altArt";