/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { GetMarketplaceResponse } from '../../models/get-marketplace-response';

export interface MarketplaceServiceGetMarketplace$Params {

/**
 * Filter for only a certain rarity.
 */
  'filterOptions.rarity'?: number;

/**
 * Filter for a specific gacha ID. Cannot be set with media_id.
 */
  'filterOptions.gachaId'?: string;

/**
 * Filter for a specific media ID. Cannot be set with gacha_id.
 */
  'filterOptions.mediaId'?: string;

/**
 * Filter for listings posted by a specific user.
 */
  'filterOptions.userUuid'?: string;

/**
 * Filter for a specific gacha instance ID. Cannot be paired with gacha/media IDs.
 */
  'filterOptions.gachaInstanceId'?: string;

/**
 * Filter for listing ID
 */
  'filterOptions.listingId'?: string;

/**
 * Filter for cards unowned by a given user ID.
 */
  'filterOptions.unownedByUserUuid'?: string;
}

export function marketplaceServiceGetMarketplace(http: HttpClient, rootUrl: string, params?: MarketplaceServiceGetMarketplace$Params, context?: HttpContext): Observable<StrictHttpResponse<GetMarketplaceResponse>> {
  const rb = new RequestBuilder(rootUrl, marketplaceServiceGetMarketplace.PATH, 'get');
  if (params) {
    rb.query('filterOptions.rarity', params['filterOptions.rarity'], {});
    rb.query('filterOptions.gachaId', params['filterOptions.gachaId'], {});
    rb.query('filterOptions.mediaId', params['filterOptions.mediaId'], {});
    rb.query('filterOptions.userUuid', params['filterOptions.userUuid'], {});
    rb.query('filterOptions.gachaInstanceId', params['filterOptions.gachaInstanceId'], {});
    rb.query('filterOptions.listingId', params['filterOptions.listingId'], {});
    rb.query('filterOptions.unownedByUserUuid', params['filterOptions.unownedByUserUuid'], {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<GetMarketplaceResponse>;
    })
  );
}

marketplaceServiceGetMarketplace.PATH = '/api/v3/gateway/marketplace';
