<div class="container">
  <div class="columns">
    <div class="column is-2">
      <aside class="menu">
        <p class="menu-label">
          Enhancements
        </p>
        <ul class="menu-list">
          <li>
            <a class="transparent" (click)="select('BORDER')">
              Borders
            </a>
            <a class="transparent" (click)="select('EFFECT')">
              Effects
            </a>
          </li>
        </ul>
      </aside>
    </div>
    <div class="column is-1"></div>
    <div class="column is-9 has-text-centered">
      <div class="columns is-narrow is-multiline">
        <ng-container *ngIf="selectedTab == 'BORDER'; else effectsTmpl">
          <ng-container *ngFor="let border of borders" #bordersTmpl>
            <app-enhancement-component [name]="border.name!" [description]="border.description!"
              [imageURL]="border.exampleImageUrl!"></app-enhancement-component>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>


<ng-template #effectsTmpl>
  <ng-container *ngFor="let effect of effects" #effectsTmpl>
    <app-enhancement-component [name]="effect.name!" [description]="effect.description!"
      [imageURL]="effect.exampleImageUrl!"></app-enhancement-component>
  </ng-container>
</ng-template>