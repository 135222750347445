/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { GachaServiceMarkTradeableStatus2Request } from '../../models/gacha-service-mark-tradeable-status-2-request';
import { MarkTradeableStatusResponse } from '../../models/mark-tradeable-status-response';

export interface GachaServiceMarkTradeableStatus2$Params {
  userUuid: string;
  mediaId: string;
      body: GachaServiceMarkTradeableStatus2Request
}

export function gachaServiceMarkTradeableStatus2(http: HttpClient, rootUrl: string, params: GachaServiceMarkTradeableStatus2$Params, context?: HttpContext): Observable<StrictHttpResponse<MarkTradeableStatusResponse>> {
  const rb = new RequestBuilder(rootUrl, gachaServiceMarkTradeableStatus2.PATH, 'put');
  if (params) {
    rb.path('userUuid', params.userUuid, {});
    rb.path('mediaId', params.mediaId, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<MarkTradeableStatusResponse>;
    })
  );
}

gachaServiceMarkTradeableStatus2.PATH = '/api/v3/gateway/user/{userUuid}/media/{mediaId}/tradeable';
