import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/auth/auth.service';
import { Banner, Gacha, Media } from 'src/app/openapi/models';
import { AdminServiceService } from 'src/app/openapi/services';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewBannerAdminComponent {
  @Input()
  banner: Banner = {
    rateUpGachas: {},
  };

  @Input()
  buttonLabel: string = "Create";

  @Output()
  bannerCreated = new EventEmitter<Banner>();

  // Provide support for a gacha "preview" when we pick a media/gacha
  mediaPreview?: Media;
  gachaPreview?: Gacha;

  startDate?: string = this.today();
  endDate?: string = this.tomorrow();

  constructor(private adminService: AdminServiceService,
    private toastr: ToastrService,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.startDate = this.banner.startTime ? this.xformDate(new Date(this.banner.startTime)) : this.today();
    this.endDate = this.banner.endTime ? this.xformDate(new Date(this.banner.endTime)) : this.tomorrow();

    this.changeStartDate();
    this.changeEndDate();
  }

  changeStartDate() {
    if (!this.startDate)
      return;
    const startDate = new Date(this.startDate);
    this.banner.startTime = startDate.toISOString();
  }

  changeEndDate() {
    if (!this.endDate)
      return;
    const endDate = new Date(this.endDate);
    this.banner.endTime = endDate.toISOString();
  }

  addGacha(gacha: Gacha) {
    if (!gacha.id || !gacha.rarity) {
      this.toastr.error("Gacha ID or rarity is missing!");
      return;
    }
    if (this.banner.rateUpGachas![gacha.rarity] === undefined) {
      this.banner.rateUpGachas![gacha.rarity!] = {
        rateUpPercentage: 0,
        gachaIds: [gacha.id!],
      };
    } else {
      if (this.banner.rateUpGachas![gacha.rarity].gachaIds!.includes(gacha.id)) {
        this.toastr.error(`Gacha ${gacha.name} is already in the banner!`);
        return;
      }
      this.banner.rateUpGachas![gacha.rarity].gachaIds!.push(gacha.id);
    }
  }

  removeGacha(gacha: Gacha) {
    if (!gacha.id || !gacha.rarity) {
      this.toastr.error("Gacha ID or rarity is missing!");
      return;
    }
    this.banner.rateUpGachas![gacha.rarity].gachaIds = this.banner.rateUpGachas![gacha.rarity].gachaIds!
      .filter(g => g != gacha.id);
    if (this.banner.rateUpGachas![gacha.rarity].gachaIds!.length == 0) {
      delete this.banner.rateUpGachas![gacha.rarity];
    }
  }

  selectedMedia(m: Media) {
    this.mediaPreview = m;
  }

  selectedGacha(g: any) {
    this.gachaPreview = g;
  }

  xformDate(d: Date): string {
    const datePipe: DatePipe = new DatePipe('en-US');
    return datePipe.transform(d, 'yyyy-MM-dd') as string;
  }

  today(): string {
    const d = new Date();
    return this.xformDate(new Date());
  }

  tomorrow(): string {
    const d = new Date();
    d.setDate(d.getDate() + 1);
    return this.xformDate(d);
  }

  emitBanner() {
    for (let rate of Object.values(this.banner.rateUpGachas!)) {
      rate.rateUpPercentage = rate.rateUpPercentage! /= 100;
    }
    this.bannerCreated.emit(this.banner);
  }

  setThumbnailURL(url: string) {
    this.banner.thumbnailUrl = url;
  }
}
