<!-- <ng-container *ngIf="auth.user$ | async as user"> -->
<section>
  <h2 class="title">Create a new Gacha</h2>
  <div class="columns">
    <div class="column is-one-fifth">
      <div class="field">
        <label class="label">extract?</label>
        <div class="control">
          <input #extractInput [(ngModel)]="extractUrl" (ngModelChange)="extract($event)" class="input" type="text"
            placeholder="Text input">
        </div>
      </div>
      <div class="field">
        <label class="label">Gacha Name</label>
        <div class="control">
          <input [(ngModel)]="gacha.name" class="input" type="text" placeholder="Text input">
        </div>
      </div>

      <div class="field">
        <label class="label">Select Media Franchise</label>
        <div class="control">
          <div class="select">
            <select (change)="onSelectMedia(gacha.mediaId!)" [(ngModel)]="gacha.mediaId">
              <option *ngFor="let media of medias" [ngValue]="media.id">{{media.name}}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="field">
        <label class="label">Select Category</label>
        <div class="control">
          <div *ngFor="let category of categoryOptions">
            <label class="radio">
              <input type="radio" name="radio" id="radio-{{category}}" [(ngModel)]="gacha.category"
                [value]="category" />
              {{ stringifyCategory(category) }}
            </label>
          </div>
        </div>
      </div>

      <div class="field" *ngIf="gacha.category && gacha.category === 'DUO'">
        <h2 class="subtitle">Choose the parent gachas for this duo+ card</h2>
        <div class="select is-multiple">
          <mat-form-field>
            <mat-label>Parent Gachas</mat-label>
            <mat-select [(ngModel)]="parentGachas" (ngModelChange)="onSelectParentGacha($event)" [panelWidth]="null"
              multiple>
              @for (gacha of gachaForSelectedMedia; track gacha) {
              <mat-option [value]="gacha">{{ gacha.name }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="field">
        <label class="label">Select Orientation</label>
        <div class="control">
          <div *ngFor="let o of orientationOptions">
            <label class="radio">
              <input type="radio" name="radioorientation" id="radio-{{o}}" [(ngModel)]="gacha.orientation"
                [value]="o" />
              {{ o }}
            </label>
          </div>
        </div>
      </div>

      <div class="field">
        <label class="label">Select Rarity</label>
        <div class="control">
          <div *ngFor="let rarity of rarityOptions">
            <label class="radio">
              <input type="radio" name="radiorarity" id="radio-{{rarity}}" [(ngModel)]="gacha.rarity"
                [value]="rarity" />
              {{rarity}}⭐
            </label>
          </div>
        </div>
      </div>

      <div class="field">
        <div class="control">
          <label class="checkbox">
            <input type="checkbox" [(ngModel)]="isRollableGacha">
            Rollable Gacha
          </label>
        </div>
      </div>

      <div class="field" *ngIf="!isRollableGacha">
        <div class="control">
          <label class="checkbox">
            <input type="checkbox" [(ngModel)]="isTradeableGacha">
            Tradeable Gacha
          </label>
        </div>
      </div>

      <div class="field">
        <label class="label">Optional Description</label>
        <div class="control">
          <textarea [(ngModel)]="gacha.description" class="textarea"
            placeholder="Gacha description. Leave blank if not needed."></textarea>
        </div>
      </div>

      <div class="field is-grouped">
        <div class="control">
          <button (click)="submitGacha()" class="button is-link">Submit to Staging</button>
        </div>
      </div>

      <div class="field">
        <div class="control">
          <label class="checkbox">
            <input type="checkbox" [(ngModel)]="deselectAfterSubmit">
            Deselect Options After Submit
          </label>
        </div>
      </div>
    </div>
    <div class="column is-two-fifths">
      <textarea #input placeholder="Paste image here" (paste)="onPaste($event)"
        (change)="onFileChange($event)"></textarea>
      <image-cropper output="base64" [imageChangedEvent]="imgChangeEvt" [maintainAspectRatio]="true"
        [imageBase64]="imageBase64String" [aspectRatio]="calcAspectRatio(gacha)" [cropperMinWidth]="calcMinWidth(gacha)"
        format="png" (imageCropped)="cropImg($event)" (imageLoaded)="imgLoad()" (cropperReady)="initCropper()"
        (loadImageFailed)="imgFailed()" [resizeToWidth]="calcResizeToWidth(gacha)" [onlyScaleDown]="true">
      </image-cropper>
    </div>

    <div class="column is-two-fifths">
      <h6>Image Preview</h6>
      <p class="subtitle">Grid background is to show transparency, it won't appear in final image</p>
      <img class="grid-background" [src]="cropImgPreview" />
    </div>
  </div>
</section>
<hr>