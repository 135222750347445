/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { abuseServiceGetShadowbannedUsers } from '../fn/abuse-service/abuse-service-get-shadowbanned-users';
import { AbuseServiceGetShadowbannedUsers$Params } from '../fn/abuse-service/abuse-service-get-shadowbanned-users';
import { abuseServiceShadowbanUser } from '../fn/abuse-service/abuse-service-shadowban-user';
import { AbuseServiceShadowbanUser$Params } from '../fn/abuse-service/abuse-service-shadowban-user';
import { abuseServiceUnshadowbanUser } from '../fn/abuse-service/abuse-service-unshadowban-user';
import { AbuseServiceUnshadowbanUser$Params } from '../fn/abuse-service/abuse-service-unshadowban-user';
import { abuseServiceViewUserSummary } from '../fn/abuse-service/abuse-service-view-user-summary';
import { AbuseServiceViewUserSummary$Params } from '../fn/abuse-service/abuse-service-view-user-summary';
import { GetShadowbannedUsersResponse } from '../models/get-shadowbanned-users-response';
import { ShadowbanUserResponse } from '../models/shadowban-user-response';
import { UnshadowbanUserResponse } from '../models/unshadowban-user-response';
import { ViewUserSummaryResponse } from '../models/view-user-summary-response';

@Injectable({ providedIn: 'root' })
export class AbuseServiceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `abuseServiceViewUserSummary()` */
  static readonly AbuseServiceViewUserSummaryPath = '/api/v3/gateway/admin/abuse/{targetId}/summary';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `abuseServiceViewUserSummary()` instead.
   *
   * This method doesn't expect any request body.
   */
  abuseServiceViewUserSummary$Response(params: AbuseServiceViewUserSummary$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewUserSummaryResponse>> {
    return abuseServiceViewUserSummary(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `abuseServiceViewUserSummary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  abuseServiceViewUserSummary(params: AbuseServiceViewUserSummary$Params, context?: HttpContext): Observable<ViewUserSummaryResponse> {
    return this.abuseServiceViewUserSummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewUserSummaryResponse>): ViewUserSummaryResponse => r.body)
    );
  }

  /** Path part for operation `abuseServiceGetShadowbannedUsers()` */
  static readonly AbuseServiceGetShadowbannedUsersPath = '/api/v3/gateway/admin/shadowban';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `abuseServiceGetShadowbannedUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  abuseServiceGetShadowbannedUsers$Response(params?: AbuseServiceGetShadowbannedUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<GetShadowbannedUsersResponse>> {
    return abuseServiceGetShadowbannedUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `abuseServiceGetShadowbannedUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  abuseServiceGetShadowbannedUsers(params?: AbuseServiceGetShadowbannedUsers$Params, context?: HttpContext): Observable<GetShadowbannedUsersResponse> {
    return this.abuseServiceGetShadowbannedUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetShadowbannedUsersResponse>): GetShadowbannedUsersResponse => r.body)
    );
  }

  /** Path part for operation `abuseServiceShadowbanUser()` */
  static readonly AbuseServiceShadowbanUserPath = '/api/v3/gateway/admin/shadowban/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `abuseServiceShadowbanUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  abuseServiceShadowbanUser$Response(params: AbuseServiceShadowbanUser$Params, context?: HttpContext): Observable<StrictHttpResponse<ShadowbanUserResponse>> {
    return abuseServiceShadowbanUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `abuseServiceShadowbanUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  abuseServiceShadowbanUser(params: AbuseServiceShadowbanUser$Params, context?: HttpContext): Observable<ShadowbanUserResponse> {
    return this.abuseServiceShadowbanUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShadowbanUserResponse>): ShadowbanUserResponse => r.body)
    );
  }

  /** Path part for operation `abuseServiceUnshadowbanUser()` */
  static readonly AbuseServiceUnshadowbanUserPath = '/api/v3/gateway/admin/shadowban/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `abuseServiceUnshadowbanUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  abuseServiceUnshadowbanUser$Response(params: AbuseServiceUnshadowbanUser$Params, context?: HttpContext): Observable<StrictHttpResponse<UnshadowbanUserResponse>> {
    return abuseServiceUnshadowbanUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `abuseServiceUnshadowbanUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  abuseServiceUnshadowbanUser(params: AbuseServiceUnshadowbanUser$Params, context?: HttpContext): Observable<UnshadowbanUserResponse> {
    return this.abuseServiceUnshadowbanUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<UnshadowbanUserResponse>): UnshadowbanUserResponse => r.body)
    );
  }

}
