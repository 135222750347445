/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { backpackServiceGetUserBackpack } from '../fn/backpack-service/backpack-service-get-user-backpack';
import { BackpackServiceGetUserBackpack$Params } from '../fn/backpack-service/backpack-service-get-user-backpack';
import { GetUserBackpackResponse } from '../models/get-user-backpack-response';

@Injectable({ providedIn: 'root' })
export class BackpackServiceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `backpackServiceGetUserBackpack()` */
  static readonly BackpackServiceGetUserBackpackPath = '/api/v3/gateway/user/{userUuid}/backpack';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `backpackServiceGetUserBackpack()` instead.
   *
   * This method doesn't expect any request body.
   */
  backpackServiceGetUserBackpack$Response(params: BackpackServiceGetUserBackpack$Params, context?: HttpContext): Observable<StrictHttpResponse<GetUserBackpackResponse>> {
    return backpackServiceGetUserBackpack(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `backpackServiceGetUserBackpack$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  backpackServiceGetUserBackpack(params: BackpackServiceGetUserBackpack$Params, context?: HttpContext): Observable<GetUserBackpackResponse> {
    return this.backpackServiceGetUserBackpack$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetUserBackpackResponse>): GetUserBackpackResponse => r.body)
    );
  }

}
