import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { map, switchMap, take } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { AdminServiceUpdateBanner$Params } from 'src/app/openapi/fn/admin-service/admin-service-update-banner';
import { Banner, GetBannersResponseStatus, UpdateBannerResponseStatus } from 'src/app/openapi/models';
import { AdminServiceService, GachaServiceService } from 'src/app/openapi/services';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {

  banner?: Banner;

  constructor(private adminService: AdminServiceService,
    private gachaService: GachaServiceService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private authService: AuthService,
    private router: Router) { }

  ngOnInit(): void {
    this.route.params.pipe(
      switchMap(p => this.gachaService.gachaServiceGetBanners({ onlyGetCurrent: false, onlyGetVisible: false })),
      map(res => {
        if (res.status != "SUCCESS") {
          this.toastr.error(`Failed to get banners: ${res.status?.toString()}`);
          throw new Error("Failed to get banners");
        }
        return (res.banners || []).find(b => b.id == this.route.snapshot.params['bannerID']);
      }),
      map(bannerMaybe => {
        if (!bannerMaybe) {
          this.toastr.error(`Banner ${this.route.snapshot.params['bannerID']} not found!`);
          throw new Error("Banner not found");
        }
        return bannerMaybe;
      })
    ).subscribe(b => {
      for (let rate of Object.values(b.rateUpGachas || {})) {
        if (!rate.rateUpPercentage) {
          continue;
        }
        rate.rateUpPercentage = rate.rateUpPercentage *= 100;
      }
      this.banner = b;
    }, err => this.toastr.error(`Failed to get banner: ${err}`));
  }

  updateBanner(banner: Banner) {
    const req: AdminServiceUpdateBanner$Params = {
      "banner.id": banner.id!,
      body: {
        banner: banner,
      }
    }
    this.authService.user$.pipe(take(1)).subscribe(u => {
      if (!u) {
        this.toastr.error("Not logged in!");
        return;
      }
      this.adminService.adminServiceUpdateBanner(req).subscribe(b => {
        switch (b.status) {
          case "BANNER_ALREADY_RUNNING_AND_VISIBLE":
            this.toastr.error(`Banner ID ${b.banner?.id} is running AND Visible! You must disable its visibility!`);
            return;
          case "SUCCESS":
            break;
          default:
            this.toastr.error(`Unknown error: ${b.status}`);
            return;
        }
        this.toastr.success(`Updated banner ${b.banner?.name}!`);
        this.router.navigate(['/admin/banners']);
      }, err => this.toastr.error(`Failed to create banner: ${err.message}`));
    });
  }
}
