import { Location, ViewportScroller, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import AsyncLock from 'async-lock';
import { combineLatest, filter, map, mergeMap, of, take, tap } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { faAnglesUp, faAnglesDown, faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import { GachaServiceService, MediaServiceService, UserServiceService } from 'src/app/openapi/services';
import { Gacha, Media } from 'src/app/openapi/models';
import { GachaServiceGetGachasPaginated$Params } from 'src/app/openapi/fn/gacha-service/gacha-service-get-gachas-paginated';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { ToastrService } from 'ngx-toastr';

interface GachaAndMinted {
  gacha: Gacha;
  minted: number;
}

const PAGE_SIZE = 18;
declare var require: any;

@Component({
  selector: 'app-view-all-media-gacha',
  templateUrl: './view-all-media-gacha.component.html',
  styleUrls: ['./view-all-media-gacha.component.scss']
})
export class ViewAllMediaGachaComponent implements OnInit, AfterViewInit {
  faMinimize = faAnglesUp;
  faMaximize = faAnglesDown;
  faSolidStar = faStarSolid;
  faHollowStar = faStar;

  collapsible?: any;

  scrollDownLock = new AsyncLock();
  nextToken?: string;
  prevToken?: string;
  medias: Media[] = [];
  mediaDuoCardMapping: { [key: string]: boolean } = {};
  gachas: GachaAndMinted[] = [];
  selectedMedia?: Media;

  searchFilter: string = "";
  showDuos: boolean = true;

  shownCount: number = 0;
  /******************************
   * Manage User Favorite Media *
   ******************************/
  favoriteMedia?: Media;

  params: GachaServiceGetGachasPaginated$Params = { mediaId: "", "paginationOptions.pageSize": PAGE_SIZE };

  filterMedias(): Media[] {
    if (this.searchFilter === "") {
      return this.medias;
    }
    return this.medias.filter(m => m.name!.toLowerCase().includes(this.searchFilter.toLowerCase()));
  }

  constructor(
    private mediaService: MediaServiceService,
    private gachaService: GachaServiceService,
    private userService: UserServiceService,
    private scrollport: ViewportScroller,
    private route: ActivatedRoute,
    private router: Router,
    public auth: AuthService,
    private toastr: ToastrService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private cdr: ChangeDetectorRef,
    private location: Location) {

  }

  ngOnInit(): void {
    this.mediaService.mediaServiceGetAllMedia({ loadDuoCardsMap: true }).subscribe(m => {
      this.medias = m.media || [];
      this.mediaDuoCardMapping = m.mediaHasDuoCards || {};
      if (this.medias.length > 0) {
        if (!this.route.snapshot.params['mediaID']) {
          this.params.mediaId = this.medias[0].id!;
          this.selectMedia(this.medias[0]);
        } else {
          this.selectMedia(this.medias.find(m => m.id === this.route.snapshot.params['mediaID']) ? this.medias.find(m => m.id === this.route.snapshot.params['mediaID'])! : this.medias[0]);
        }
      }
    });
    this.auth.user$.pipe(
      take(1),
      filter(u => u !== undefined),
      mergeMap(u => this.userService.userServiceGetUserFavoriteMedia({ userUuid: u!.user_uuid! })),
    ).subscribe(fav => {
      if (fav.media) {
        this.favoriteMedia = fav.media;
      }
    })
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const collapsible = require('@creativebulma/bulma-collapsible/dist/js/bulma-collapsible.min.js');
      const element = document.getElementById("view-gachas-franchises-collapsible") as HTMLDivElement;
      this.collapsible = new collapsible(element);
      if (this.collapsible) {
        this.collapsible.open();
      }
      this.cdr.detectChanges();
    }
  }

  generateFilter(): any {
    return {};
  }

  reloadCount() {
    this.gachaService.gachaServiceGetGachasCountForFilter(this.params).pipe(
      tap(res => {
        this.shownCount = res.count ?? 0;
      })
    ).subscribe();
  }

  selectMedia(m: Media) {
    this.params.mediaId = m.id!;
    this.params['paginationOptions.page'] = undefined;
    this.reloadCount();
    this.gachaService.gachaServiceGetGachasPaginated(this.params).pipe(
      map(res => {
        this.nextToken = res.paginationDetails?.nextPage;
        this.prevToken = res.paginationDetails?.previousPage;
        return res;
      }),
      mergeMap(res => {
        if (res.gachas === undefined) {
          res.gachas = [];
        }
        const mintedMapping = this.gachaService.gachaServiceGetGachaMinted({
          body: {
            gachaIds: res.gachas.map(g => g.id!),
          }
        });
        return combineLatest([of(res), mintedMapping]);
      }),
    ).subscribe(([res, minted]) =>
      this.gachas = res.gachas!.map(g => ({
        gacha: g,
        minted: minted.gachaMinted?.[g.id!]?.mintedTotal ?? 0,
      }))
    );
    this.scrollport.scrollToAnchor('anchor');
    let pos = this.scrollport.getScrollPosition();
    this.scrollport.scrollToPosition([pos[0], pos[1] - 100]);
    this.selectedMedia = m;
    this.location.go(`/view/media/all/${m.id}`);
  }

  onScrollDown() {
    this.scrollDownLock.acquire('scroll-down', (done) => {
      if (!this.nextToken) {
        done();
        return;
      }
      if (!this.selectedMedia) {
        done();
        return;
      }

      this.params['paginationOptions.page'] = this.nextToken;
      this.params.mediaId = this.selectedMedia.id!;
      this.gachaService.gachaServiceGetGachasPaginated(this.params).pipe(
        map(res => {
          this.nextToken = res.paginationDetails?.nextPage;
          this.prevToken = res.paginationDetails?.previousPage;
          return res;
        }),
        mergeMap(res => {
          if (res.gachas === undefined) {
            res.gachas = [];
          }
          const mintedMapping = this.gachaService.gachaServiceGetGachaMinted({ body: { gachaIds: res.gachas.map(g => g.id!) } });
          return combineLatest([of(res), mintedMapping]);
        }),
      ).subscribe(([res, minted]) => {
        this.gachas = this.gachas.concat(res.gachas!.map(g => ({
          gacha: g,
          minted: minted.gachaMinted?.[g.id!]?.mintedTotal ?? 0,
        })));
        done();
      }
      );
    });
  }

  reloadGachaForFilters() {
    this.scrollDownLock.acquire('scroll-down', (done) => {
      if (!this.selectedMedia) {
        done();
        return;
      }

      this.params['paginationOptions.page'] = undefined;
      this.reloadCount();
      this.gachaService.gachaServiceGetGachasPaginated(this.params).pipe(
        map(res => {
          this.nextToken = res.paginationDetails?.nextPage;
          this.prevToken = res.paginationDetails?.previousPage;
          return res;
        }),
        mergeMap(res => {
          if (res.gachas === undefined) {
            res.gachas = [];
          }
          const mintedMapping = this.gachaService.gachaServiceGetGachaMinted({ body: { gachaIds: res.gachas.map(g => g.id!) } });
          return combineLatest([of(res), mintedMapping]);
        }),
      ).subscribe(([res, minted]) => {
        this.gachas = res.gachas!.map(g => ({
          gacha: g,
          minted: minted.gachaMinted?.[g.id!]?.mintedTotal ?? 0,
        }));
        done();
      }
      );
    });
  }

  setFavoriteMedia(mediaID: string) {
    this.auth.user$.pipe(
      take(1),
      filter(u => u !== undefined),
      mergeMap(u => this.userService.userServiceSetUserFavoriteMedia({
        userUuid: u!.user_uuid!,
        body: { mediaId: mediaID }
      })),
    ).subscribe(fav => {
      if (fav.media) {
        this.favoriteMedia = fav.media;
        this.toastr.success(`Set favorite media to ${fav.media.name}`);
      }
    })
  }

  removeFavoriteMedia() {
    this.auth.user$.pipe(
      take(1),
      filter(u => u !== undefined),
      mergeMap(u => this.userService.userServiceDeleteUserFavoriteMedia({
        userUuid: u!.user_uuid!,
      })),
    ).subscribe(res => {
      switch (res.status) {
        case "NOT_FOUND":
          this.toastr.error("No favorite media found");
          break;
        case "SUCCESS":
          this.favoriteMedia = undefined;
          this.toastr.info("Removed favorite media");
          break;
        default:
          this.toastr.error("Unknown error");
      }
    })
  }

  onScrollUp() {
  }

  trackBy(index: number, g: Gacha): string {
    return g.id!;
  }

  // activeFilters: CollectionFilter[] = [];
  onSelectShowUnownedFilter(toggled: any, userID: string) {
    this.params['collectionFilter.byOwnership.userId'] = toggled.target.checked ? userID : undefined;
    this.params['collectionFilter.byOwnership.wantOwned'] = toggled.target.checked ? false : undefined;
    this.reloadGachaForFilters();
  }

  onSelectShowDuoFilter(toggled: any) {
    this.params['collectionFilter.byHideCategories.categories'] = toggled.target.checked ? undefined : ['DUO'];
    this.reloadGachaForFilters();
  }
}
