<div class="view-card" [ngStyle]="
  {
    'box-shadow': '10px 10px 24px -4px #e3bb42',
    'background-color': '#e3bb4250',
    'border-color': '#e3bb42AA'
  }">
  <div class="view-card-inset">
    <img class="view-card-image" src="{{ imageURL }}" loading="lazy" />
  </div>

  <div class="view-card-details">
    <p class="view-card-gacha-name is-size-4">{{ name }}</p>
    <span class="view-card-gacha-name is-size-6">{{ description }}</span>
  </div>
</div>