/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { GetUserGachaMintedOutOfResponse } from '../../models/get-user-gacha-minted-out-of-response';

export interface UserServiceGetUserGachaMintedOutOf2$Params {
  userUuid: string;

/**
 * Optional. If set, only select the given.
 */
  gachaIds: Array<string>;
  discordId?: string;
}

export function userServiceGetUserGachaMintedOutOf2(http: HttpClient, rootUrl: string, params: UserServiceGetUserGachaMintedOutOf2$Params, context?: HttpContext): Observable<StrictHttpResponse<GetUserGachaMintedOutOfResponse>> {
  const rb = new RequestBuilder(rootUrl, userServiceGetUserGachaMintedOutOf2.PATH, 'get');
  if (params) {
    rb.path('userUuid', params.userUuid, {});
    rb.path('gachaIds', params.gachaIds, {"style":"simple","explode":false});
    rb.query('discordId', params.discordId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<GetUserGachaMintedOutOfResponse>;
    })
  );
}

userServiceGetUserGachaMintedOutOf2.PATH = '/api/v3/gateway/user/{userUuid}/gacha/minted/{gachaIds}';
