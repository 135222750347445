/* tslint:disable */
/* eslint-disable */
import { GachaAcquisitionMethod } from './gacha-acquisition-method';

/**
 * Each possible value of `GachaAcquisitionMethod`
 */
export const GACHA_ACQUISITION_METHOD: GachaAcquisitionMethod[] = [
  'UNDEFINED_ACQUISITION_METHOD',
  'ACQUISITION_CLAIM',
  'ACQUISITION_DROP',
  'ACQUISITION_TRADE',
  'ACQUISITION_SELECTOR_TICKET',
  'ACQUISITION_SACRIFICE',
  'ACQUISITION_BOOSTER_PACK'
];