/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CreateAuctionResponse } from '../../models/create-auction-response';

export interface AuctionServiceCreateAuction$Params {

/**
 * The user that is creating the auction.
 */
  userId?: string;
  'auctionItem.gachaCard.gachaInstanceId'?: string;

/**
 * - STANDARD: The auction is a standard auction and has no reserve price.
 *  - RESERVE: The auction is a reserve auction and has a reserve price.
 */
  'auctionSpec.auctionType'?: 'AUCTION_TYPE_UNSPECIFIED' | 'STANDARD' | 'RESERVE';

/**
 * The reserve price of the auction. Only applicable if the auction type is RESERVE.
 */
  'auctionSpec.reservePrice'?: number;

/**
 * The starting bid of the auction in stardust.
 */
  'auctionSpec.startingBid'?: number;

/**
 * The minimum increment in stardust.
 */
  'auctionSpec.absolute'?: number;

/**
 * The percentage of the current bid that must be added to the current bid to place a new bid.
 */
  'auctionSpec.percentage'?: number;
}

export function auctionServiceCreateAuction(http: HttpClient, rootUrl: string, params?: AuctionServiceCreateAuction$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateAuctionResponse>> {
  const rb = new RequestBuilder(rootUrl, auctionServiceCreateAuction.PATH, 'post');
  if (params) {
    rb.query('userId', params.userId, {});
    rb.query('auctionItem.gachaCard.gachaInstanceId', params['auctionItem.gachaCard.gachaInstanceId'], {});
    rb.query('auctionSpec.auctionType', params['auctionSpec.auctionType'], {});
    rb.query('auctionSpec.reservePrice', params['auctionSpec.reservePrice'], {});
    rb.query('auctionSpec.startingBid', params['auctionSpec.startingBid'], {});
    rb.query('auctionSpec.absolute', params['auctionSpec.absolute'], {});
    rb.query('auctionSpec.percentage', params['auctionSpec.percentage'], {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CreateAuctionResponse>;
    })
  );
}

auctionServiceCreateAuction.PATH = '/api/v3/gateway/auctions/create';
