/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { GetRandomGachaResponse } from '../../models/get-random-gacha-response';

export interface GachaServiceGetRandomGacha$Params {

/**
 * The rarity of a gacha to retrieve.
 * Optional.
 */
  rarity?: number;
}

export function gachaServiceGetRandomGacha(http: HttpClient, rootUrl: string, params?: GachaServiceGetRandomGacha$Params, context?: HttpContext): Observable<StrictHttpResponse<GetRandomGachaResponse>> {
  const rb = new RequestBuilder(rootUrl, gachaServiceGetRandomGacha.PATH, 'get');
  if (params) {
    rb.query('rarity', params.rarity, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<GetRandomGachaResponse>;
    })
  );
}

gachaServiceGetRandomGacha.PATH = '/api/v3/gateway/gacha/random';
