import { InjectionToken, NgModule, Provider, forwardRef, isDevMode } from '@angular/core';
import { BrowserModule, provideClientHydration, withNoHttpTransferCache } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UploadFormComponent } from './admin/upload-form/upload-form.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { HttpClientModule, HTTP_INTERCEPTORS, provideHttpClient, withFetch, withXsrfConfiguration, HttpClientXsrfModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthButtonComponent } from './auth/auth-button.component';
import { ViewGachaComponent as AdminViewGachaComponent } from './admin/view-gacha/view-gacha.component';
import { ClipboardModule } from 'ngx-clipboard';
import { NavbarComponent } from './view/navbar/navbar.component';
import { MultiUploadFormComponent } from './admin/multi-upload-form/multi-upload-form.component';
import { ViewUserComponent } from './view/view-user/view-user.component';
import { ViewGachaComponent as PublicViewGachaComponent } from './view/view-gacha/view-gacha.component';
import { ViewMediaComponent } from './view/view-media/view-media.component';
import { HomepageComponent } from './view/homepage/homepage.component';
import { InviteRedirectGuard } from './inviteredirect.guard';
import { HelpComponent } from './view/help/help.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ViewBannersComponent } from './view/view-banners/view-banners.component';
import { AuthInterceptor } from './auth/auth-interceptor';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ViewStagingComponent } from './admin/view-staging/view-staging.component';
import { ViewLeaderboardComponent } from './view/view-leaderboard/view-leaderboard.component';
import { PoliciesComponent } from './view/policies/policies.component';
import { FooterComponent } from './view/components/footer/footer.component';
import { BannerManagementComponent } from './admin/banner-management/banner-management.component';
import { CreateComponent } from './admin/banner-management/create/create.component';
import { GachaPickerComponent } from './admin/banner-management/gacha-picker/gacha-picker.component';
import { GetGachaPipe } from './admin/banner-management/create/get-gacha.pipe';
import { UpdateComponent } from './admin/banner-management/update/update.component';
import { ViewBannerAdminComponent } from './admin/banner-management/view-banner-admin/view-banner-admin.component';
import { ViewBannerComponent } from './view/view-banners/view-banner/view-banner.component';
import { GenComponent } from './achievements/gen/gen.component';
import { ViewAllMediaGachaComponent } from './view/view-all-media-gacha/view-all-media-gacha.component';
import { UploadEquipmentComponent } from './admin/equipment/upload-equipment/upload-equipment.component';
import { ViewStagingEquipmentComponent } from './admin/equipment/view-staging-equipment/view-staging-equipment.component';
import { ViewAltArtComponent } from './admin/view-alt-art/view-alt-art.component';
import { StageAltArtComponent } from './admin/stage-alt-art/stage-alt-art.component';
import { SettingsComponent } from './view/settings/settings.component';
import { CookieModule } from 'ngx-cookie';
import { ViewAltArtCardComponent } from './view/view-alt-art-card/view-alt-art-card.component';
import { ViewAllAltArtGachasComponent } from './view/view-all-alt-art-gachas/view-all-alt-art-gachas.component';
import { AchievementrendererPipe } from './view/view-user/achievementrenderer.pipe';
import { ViewCommandsComponent } from './view/view-commands/view-commands.component';
import { UploadMediaComponent } from './admin/upload-media/upload-media.component';
import { ViewEditMediaComponent } from './admin/view-edit-media/view-edit-media.component';
import { ViewGuildComponent } from './view/view-guild/view-guild.component';
import { ApiModule } from './openapi/api.module';
import { GachaV2Component } from './view/components/gacha-v2/gacha-v2.component';
import { ViewUserOwnedAltArtComponent } from './view/view-user/view-user-owned-alt-art/view-user-owned-alt-art.component';
import { UpaApiInterceptor } from './upa-api-interceptor';
import { ViewAdminAllAltArtComponent } from './admin/view-admin-all-alt-art/view-admin-all-alt-art.component';
import { ViewAllGuildsComponent } from './view/view-all-guilds/view-all-guilds.component';
import { ViewGuildCardComponent } from './view/view-all-guilds/view-guild-card/view-guild-card.component';
import { EmptyEditFavoriteSlotComponent } from './view/view-user/empty-edit-favorite-slot/empty-edit-favorite-slot.component';
import { HelpV2Component, TypeTemplateDirective } from './view/help-v2/help-v2.component';
import { MeRedirectGuard } from './me-redirect.guard';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { AbuseComponent } from './admin/abuse/abuse.component';
import { LiveRollsComponent } from './view/components/live-rolls/live-rolls.component';
import { LiveRollsBottomPinComponent } from './view/components/live-rolls-bottom-pin/live-rolls-bottom-pin.component';
import { ViewAllEnhancementsComponent } from './view/view-all-enhancements/view-all-enhancements.component';
import { EnhancementComponentComponent } from './view/view-all-enhancements/enhancement-component/enhancement-component.component';
import { GiveawayItemFormComponent } from './admin/giveaway-item-form/giveaway-item-form.component';
import { ViewMarketplaceComponent } from './view/view-marketplace/view-marketplace.component';
import { ReactionComponent } from './view/components/gacha-v2/reaction/reaction.component';
import { CsrfInterceptor } from './auth/csrf-interceptor';
import { environment } from 'src/environments/environment';

export const SSR_BYPASS_TOKEN = new InjectionToken<string>('SSR_BYPASS_TOKEN');
@NgModule({
  declarations: [
    AppComponent,
    UploadFormComponent,
    AuthButtonComponent,
    AdminViewGachaComponent,
    NavbarComponent,
    MultiUploadFormComponent,
    GachaV2Component,
    ViewUserComponent,
    PublicViewGachaComponent,
    ViewMediaComponent,
    HomepageComponent,
    HelpComponent,
    ViewBannersComponent,
    ViewStagingComponent,
    ViewLeaderboardComponent,
    PoliciesComponent,
    FooterComponent,
    BannerManagementComponent,
    CreateComponent,
    GachaPickerComponent,
    GetGachaPipe,
    UpdateComponent,
    ViewBannerAdminComponent,
    ViewBannerComponent,
    GenComponent,
    ViewAllMediaGachaComponent,
    UploadEquipmentComponent,
    ViewStagingEquipmentComponent,
    ViewAltArtComponent,
    StageAltArtComponent,
    SettingsComponent,
    ViewAltArtCardComponent,
    ViewAllAltArtGachasComponent,
    AchievementrendererPipe,
    ViewCommandsComponent,
    UploadMediaComponent,
    ViewEditMediaComponent,
    ViewGuildComponent,
    ViewUserOwnedAltArtComponent,
    ViewAdminAllAltArtComponent,
    ViewAllGuildsComponent,
    ViewGuildCardComponent,
    EmptyEditFavoriteSlotComponent,
    HelpV2Component,
    TypeTemplateDirective,
    AbuseComponent,
    LiveRollsComponent,
    LiveRollsBottomPinComponent,
    ViewAllEnhancementsComponent,
    EnhancementComponentComponent,
    GiveawayItemFormComponent,
    ViewMarketplaceComponent,
    ReactionComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: "XSRF-TOKEN",
      headerName: "X-XSRF-TOKEN"
    }),
    AppRoutingModule,
    ImageCropperModule,
    FormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    ClipboardModule,
    InfiniteScrollModule,
    FontAwesomeModule,
    CookieModule.withOptions(),
    MatSelectModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    ApiModule.forRoot({ rootUrl: environment.apiRoot })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CsrfInterceptor, multi: true },
    { provide: SSR_BYPASS_TOKEN, useValue: '' },
    InviteRedirectGuard,
    MeRedirectGuard,
    provideHttpClient(
      withFetch(),
      withXsrfConfiguration({
        cookieName: "XSRF-TOKEN",
        headerName: "X-XSRF-TOKEN"
      })
    ),
    provideClientHydration(withNoHttpTransferCache()),
    provideAnimationsAsync(),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
