/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { GetNumberOfGachaResponse } from '../../models/get-number-of-gacha-response';

export interface GachaServiceGetNumberOfGacha2$Params {
  gachaId: Array<string>;
}

export function gachaServiceGetNumberOfGacha2(http: HttpClient, rootUrl: string, params: GachaServiceGetNumberOfGacha2$Params, context?: HttpContext): Observable<StrictHttpResponse<GetNumberOfGachaResponse>> {
  const rb = new RequestBuilder(rootUrl, gachaServiceGetNumberOfGacha2.PATH, 'get');
  if (params) {
    rb.path('gachaId', params.gachaId, {"style":"simple","explode":false});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<GetNumberOfGachaResponse>;
    })
  );
}

gachaServiceGetNumberOfGacha2.PATH = '/api/v3/gateway/gacha/copies/{gachaId}';
