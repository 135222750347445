<div class="container">
  <ng-container *ngIf="gacha && media">

    <div class="columns is-centered">
      <div class="column is-full has-text-centered">
        <app-gacha-v2 [gacha]="gacha" [media]="media" [outOf]="numberOfCopies"></app-gacha-v2>
        <div class="tags is-centered has-addons">
          <span class="tag is-dark">added on</span>
          <span class="tag is-dark is-primary is-normal">
            {{ addedAt(gacha) }}</span>
        </div>
      </div>

    </div>

    <ng-container *ngIf="gacha.altArts && gacha.altArts.length > 0">
      <div class="columns is-centere">
        <div class="column is-full has-text-centered">
          <h1 class="title is-4 is-centered">Alternate Art</h1>
        </div>
      </div>
      <div class="columns is-centered is-multiline">
        <div *ngFor="let altArt of gacha.altArts" class="column has-text-centered">
          <app-view-alt-art-card [gacha]="gacha" [altArt]="altArt"></app-view-alt-art-card>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="gachaOwners && gachaOwners.length > 0">
      <hr />
      <div class="columns is-centered">
        <div class="column has-text-centered is-half">
          <h1 class="title is-4">Claimed by...</h1>
          <table class="table center">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Claimed</th>
              </tr>
            </thead>
            <tbody infiniteScroll [infiniteScrollDistance]="2" (scrolled)="onScrollDown()" (scrolledUp)="onScrollUp()">
              <ng-container *ngFor="let owner of gachaOwners; let i = index">
                <ng-container *ngIf="owner.user">
                  <tr>
                    <th>{{i + 1}}</th>
                    <th>
                      <span title="Last active since {{generateUserActivity(owner.user)}}"
                        [class]="generateUserActivityCssClass(owner.user)" class="tag is-light is-normal">{{
                        generateUserActivity(owner.user) }}</span>&nbsp;
                      <span *ngIf="owner.tradeableStatus && owner.tradeableStatus !== 'TRADEABLE'">{{
                        buildTradeableStatusLock(owner.tradeableStatus) }}&nbsp;</span>
                      <a [routerLink]="['/view/user', owner.user.userUuid]">{{ owner.user.displayName }}</a>
                    </th>
                    <th>
                      {{ owner.acquisitionDate! | date: 'longDate' }}
                    </th>
                  </tr>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>