<div class="container">
  <div class="columns">
    <img *ngIf="selectedMediaAndAltArts && selectedMediaAndAltArts.media.bannerImageUrl" class="bg-image"
      src="{{selectedMediaAndAltArts.media.bannerImageUrl}}">
    <div class="column is-2">
      <aside class="menu">
        <p class="menu-label">
          Franchises
          <a href="#alt-art-franchises-collapsible" class="no-decoration-a white" data-action="collapse">
            <span class="icon">
              <fa-icon *ngIf="collapsible && !collapsible.collapsed()" [icon]="faMinimize"></fa-icon>
              <fa-icon *ngIf="collapsible && collapsible.collapsed()" [icon]="faMaximize"></fa-icon>
            </span>
          </a>
        </p>
        <ul class="menu-list is-collapsible" id="alt-art-franchises-collapsible">
          <input [(ngModel)]="searchFilter" class="input" type="text" placeholder="Search">
          <ng-container *ngFor="let m of filterMedias()">
            <li>
              <a class="transparent" (click)="selectMedia(m)">
                {{ m.media.name }}
              </a>
            </li>
          </ng-container>
        </ul>
      </aside>
    </div>
    <div class="column is-1"></div>
    <div id="anchor" class="column is-9 has-text-centered">
      <ng-container *ngIf="selectedMediaAndAltArts">
        <h1 class="is-size-1">
          <a #anchor class="link" [routerLink]="['/view/media', selectedMediaAndAltArts.media.id]">{{
            selectedMediaAndAltArts.media.name }}</a>
        </h1>
        <div class="columns is-narrow is-multiline">
          <ng-container *ngFor="let gacha of selectedMediaAndAltArts.altArts">
            <div *ngFor="let altArt of gacha.altArts">
              <app-view-alt-art-card [buyable]="buyableDict[gacha.id!] && buyableDict[gacha.id!][altArt.artId!]"
                [altArtTokens]="altArtTokens" (boughtRefresh)="forceLoadAltArtAndSetBuyableStatus()"
                class="has-text-centered" [gacha]="gacha" [altArt]="altArt"></app-view-alt-art-card>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>