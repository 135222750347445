/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { GetGachasPaginatedResponse } from '../../models/get-gachas-paginated-response';

export interface GachaServiceGetGachasPaginated$Params {

/**
 * The Media to retrieve gacha for.
 */
  mediaId: string;

/**
 * Optional.
 *
 *  - ALPHABETICAL: Applicable to all sorts.
 *  - GACHA_RARITY_AND_ALPHABETICAL: Applicable to gacha retrieval - both user gacha and just gacha.
 *  - LEADERBOARD_ROLL_COUNT: Applicable to leaderboard retrieval.
 *  - LEADERBOARD_GACHA_OWNED: Applicable to leaderboard retrieval.
 *  - LEADERBOARD_UPA_SCORE: Applicable to leaderboard retrieval.
 *  - USER_GACHA_MINT: Applicable to user gacha retrieval.
 *  - USER_GACHA_DATE_ACQUIRED: Applicable to user gacha retrieval.
 *  - TRADE_DATE: Applicable to trade retrieval.
 *  - UPA_GUILD_SCORE: Applicable to Upa guild retrieval.
 *  - AUCTION_END_DATE: Applicable to Auctions retrieval
 */
  'paginationOptions.sortType'?: 'UNDEFINED' | 'ALPHABETICAL' | 'GACHA_RARITY_AND_ALPHABETICAL' | 'LEADERBOARD_ROLL_COUNT' | 'LEADERBOARD_GACHA_OWNED' | 'LEADERBOARD_UPA_SCORE' | 'USER_GACHA_MINT' | 'USER_GACHA_DATE_ACQUIRED' | 'TRADE_DATE' | 'UPA_GUILD_SCORE' | 'AUCTION_END_DATE';

/**
 * Optional.
 */
  'paginationOptions.page'?: string;

/**
 * Optional.
 */
  'paginationOptions.orderBy'?: 'ASC' | 'DESC';

/**
 * Optional.
 */
  'paginationOptions.pageSize'?: number;
  'collectionFilter.byRarity.rarityRange.lowerBoundInclusive'?: number;
  'collectionFilter.byRarity.rarityRange.upperBoundInclusive'?: number;
  'collectionFilter.byRarity.rarity'?: number;
  'collectionFilter.byRarity.raritySelection.oneStar'?: boolean;
  'collectionFilter.byRarity.raritySelection.twoStar'?: boolean;
  'collectionFilter.byRarity.raritySelection.threeStar'?: boolean;
  'collectionFilter.byRarity.raritySelection.fourStar'?: boolean;
  'collectionFilter.byRarity.raritySelection.fiveStar'?: boolean;
  'collectionFilter.byRarity.raritySelection.sixStar'?: boolean;
  'collectionFilter.byMinted.mintedLowerBoundInclusive'?: number;
  'collectionFilter.byMinted.mintedUpperBoundInclusive'?: number;

/**
 * - ACQUISITION_CLAIM: Gacha was obtained through a pull.
 *  - ACQUISITION_DROP: Gacha was obtained through a drop.
 *  - ACQUISITION_TRADE: Gacha was obtained through a trade.
 * This might not even be used anywhere, since the trade tables
 * can track down how a user got a gacha.
 *  - ACQUISITION_SELECTOR_TICKET: Gacha was obtained through a selector ticket.
 *  - ACQUISITION_SACRIFICE: Gacha was acquired through a sacrifice.
 *  - ACQUISITION_BOOSTER_PACK: Gacha was acquired through a booster pack.
 */
  'collectionFilter.byAcquisitionMethod.acquisitionMethod'?: 'UNDEFINED_ACQUISITION_METHOD' | 'ACQUISITION_CLAIM' | 'ACQUISITION_DROP' | 'ACQUISITION_TRADE' | 'ACQUISITION_SELECTOR_TICKET' | 'ACQUISITION_SACRIFICE' | 'ACQUISITION_BOOSTER_PACK';
  'collectionFilter.byCategory.category'?: 'UNDEFINED_CATEGORY' | 'WAIFU' | 'HUSBANDO' | 'OTHER' | 'DUO';
  'collectionFilter.byMedia.mediaId'?: string;

/**
 * - RAINBOW_HOLO: Holo pattern
 *  - SAKURA_FALLING_GIF: Falling sakura gif
 *  - MOSAIC_CENSOR: Mosaic censorship png
 *  - FIRST_ANNIVERSARY_BORDER: First anniversary (2024) CNY border
 *  - SP3_SPARKLE_FALLING_GIF: Starpower 3 falling sparkle gif
 *  - COMPETITION_1_BORDER_1: Competition 1 winner border #1
 *  - COMPETITION_1_BORDER_2: Competition 1 winner border #2
 *  - COMPETITION_1_BORDER_3: Competition 1 winner border #3
 *  - COMPETITION_1_BORDER_4: Competition 1 winner border #4
 *  - COMPETITION_1_BORDER_5: Competition 1 winner border #5
 *  - COMPETITION_1_EFFECT_1: Competition 1 winner effect #1
 *  - COMPETITION_1_EFFECT_2: Competition 1 winner effect #2
 *  - COMPETITION_1_EFFECT_3: Competition 1 winner effect #3
 */
  'collectionFilter.byEnhancement.enhancement'?: Array<'NO_GACHA_ENHANCEMENT' | 'RAINBOW_HOLO' | 'SAKURA_FALLING_GIF' | 'MOSAIC_CENSOR' | 'FIRST_ANNIVERSARY_BORDER' | 'SP3_SPARKLE_FALLING_GIF' | 'COMPETITION_1_BORDER_1' | 'COMPETITION_1_BORDER_2' | 'COMPETITION_1_BORDER_3' | 'COMPETITION_1_BORDER_4' | 'COMPETITION_1_BORDER_5' | 'COMPETITION_1_EFFECT_1' | 'COMPETITION_1_EFFECT_2' | 'COMPETITION_1_EFFECT_3'>;
  'collectionFilter.byOwnership.userId'?: string;
  'collectionFilter.byOwnership.wantOwned'?: boolean;
  'collectionFilter.byOpenForTrade.openForTrade'?: boolean;

/**
 * 0 = tradeable, 1 = not tradeable, 2 = kinda
 * Int32 because of import loop can't import user.proto
 */
  'collectionFilter.byOpenForTrade.tradeStatus'?: number;
  'collectionFilter.byHideCategories.categories'?: Array<'UNDEFINED_CATEGORY' | 'WAIFU' | 'HUSBANDO' | 'OTHER' | 'DUO'>;
}

export function gachaServiceGetGachasPaginated(http: HttpClient, rootUrl: string, params: GachaServiceGetGachasPaginated$Params, context?: HttpContext): Observable<StrictHttpResponse<GetGachasPaginatedResponse>> {
  const rb = new RequestBuilder(rootUrl, gachaServiceGetGachasPaginated.PATH, 'get');
  if (params) {
    rb.path('mediaId', params.mediaId, {});
    rb.query('paginationOptions.sortType', params['paginationOptions.sortType'], {});
    rb.query('paginationOptions.page', params['paginationOptions.page'], {});
    rb.query('paginationOptions.orderBy', params['paginationOptions.orderBy'], {});
    rb.query('paginationOptions.pageSize', params['paginationOptions.pageSize'], {});
    rb.query('collectionFilter.byRarity.rarityRange.lowerBoundInclusive', params['collectionFilter.byRarity.rarityRange.lowerBoundInclusive'], {});
    rb.query('collectionFilter.byRarity.rarityRange.upperBoundInclusive', params['collectionFilter.byRarity.rarityRange.upperBoundInclusive'], {});
    rb.query('collectionFilter.byRarity.rarity', params['collectionFilter.byRarity.rarity'], {});
    rb.query('collectionFilter.byRarity.raritySelection.oneStar', params['collectionFilter.byRarity.raritySelection.oneStar'], {});
    rb.query('collectionFilter.byRarity.raritySelection.twoStar', params['collectionFilter.byRarity.raritySelection.twoStar'], {});
    rb.query('collectionFilter.byRarity.raritySelection.threeStar', params['collectionFilter.byRarity.raritySelection.threeStar'], {});
    rb.query('collectionFilter.byRarity.raritySelection.fourStar', params['collectionFilter.byRarity.raritySelection.fourStar'], {});
    rb.query('collectionFilter.byRarity.raritySelection.fiveStar', params['collectionFilter.byRarity.raritySelection.fiveStar'], {});
    rb.query('collectionFilter.byRarity.raritySelection.sixStar', params['collectionFilter.byRarity.raritySelection.sixStar'], {});
    rb.query('collectionFilter.byMinted.mintedLowerBoundInclusive', params['collectionFilter.byMinted.mintedLowerBoundInclusive'], {});
    rb.query('collectionFilter.byMinted.mintedUpperBoundInclusive', params['collectionFilter.byMinted.mintedUpperBoundInclusive'], {});
    rb.query('collectionFilter.byAcquisitionMethod.acquisitionMethod', params['collectionFilter.byAcquisitionMethod.acquisitionMethod'], {});
    rb.query('collectionFilter.byCategory.category', params['collectionFilter.byCategory.category'], {});
    rb.query('collectionFilter.byMedia.mediaId', params['collectionFilter.byMedia.mediaId'], {});
    rb.query('collectionFilter.byEnhancement.enhancement', params['collectionFilter.byEnhancement.enhancement'], {"style":"form","explode":true});
    rb.query('collectionFilter.byOwnership.userId', params['collectionFilter.byOwnership.userId'], {});
    rb.query('collectionFilter.byOwnership.wantOwned', params['collectionFilter.byOwnership.wantOwned'], {});
    rb.query('collectionFilter.byOpenForTrade.openForTrade', params['collectionFilter.byOpenForTrade.openForTrade'], {});
    rb.query('collectionFilter.byOpenForTrade.tradeStatus', params['collectionFilter.byOpenForTrade.tradeStatus'], {});
    rb.query('collectionFilter.byHideCategories.categories', params['collectionFilter.byHideCategories.categories'], {"style":"form","explode":true});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<GetGachasPaginatedResponse>;
    })
  );
}

gachaServiceGetGachasPaginated.PATH = '/api/v3/gateway/media/{mediaId}/gacha';
