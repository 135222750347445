<div class="container is-fluid">
  <div class="columns">
    <div class="column is-6">
      <section>
        <div *ngIf="medias" class="field">
          <label class="label">Select Media Franchise</label>
          <div class="control">
            <div class="select">
              <select #s [(ngModel)]="selectedMediaID" (change)="onSelectMedia(s.value)">
                <option *ngFor="let media of medias" [ngValue]="media.id">{{media.name}}</option>
              </select>
            </div>
          </div>
        </div>

        <p *ngIf="gachas" class="has-text-weight-semibold">Total: {{gachas.length}}</p>

        <div class="buttons">
          <button class="button is-info" (click)="renderImages = !renderImages">
            {{ renderImages ? "Hide Images" : "Show Images" }}
          </button>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th>Link</th>
              <th>ID</th>
              <th>Name</th>
              <th>Rarity</th>
              <th>Category</th>
              <th>Image</th>
              <th>Edit</th>
              <th>Create Edition</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let gacha of gachas">
              <tr>
                <th class="has-text-weight-light"><a [routerLink]="['/view/gacha', gacha.gacha.id]">Link</a></th>
                <th class="has-text-weight-light"><a ngxClipboard cbContent="{{gacha.gacha.id}}">copy id</a></th>
                <th class="has-text-weight-normal">{{ gacha.gacha.name }}</th>
                <th class="has-text-weight-normal">{{ gacha.gacha.rarity }}</th>
                <th class="has-text-weight-normal">{{ stringifyCategory(gacha.gacha.category!) }}</th>

                <ng-container *ngIf="!gacha.isEditing">
                  <th *ngIf="!renderImages" class="has-text-weight-light">
                    <a href="{{ gacha.gacha.imageUrl }}" target="_blank">here</a>
                  </th>
                  <th *ngIf="renderImages" class="has-text-weight-light">
                    <figure class="image preview">
                      <img src="{{ gacha.gacha.imageUrl }}">
                    </figure>
                  </th>
                </ng-container>
                <ng-container *ngIf="gacha.isEditing">
                  <textarea #input placeholder="Paste image here" (paste)="onPaste($event, gacha)"
                    (change)="onFileChange($event, gacha)"></textarea>
                  <image-cropper output="base64" [imageChangedEvent]="gacha.ngxCropperData.imgChangeEvt"
                    [maintainAspectRatio]="true" [imageBase64]="gacha.ngxCropperData.imageBase64String"
                    [aspectRatio]="calcAspectRatio(gacha.gacha)" [cropperMinWidth]="calcMinWidth(gacha.gacha)"
                    format="png" (imageCropped)="cropImg($event, gacha)" (imageLoaded)="imgLoad()"
                    (cropperReady)="initCropper()" (loadImageFailed)="imgFailed()"
                    [resizeToWidth]="calcResizeToWidth(gacha.gacha)" [onlyScaleDown]="true">
                  </image-cropper>
                  <img class="grid-background" [src]="gacha.ngxCropperData.croppedBase64String" />
                </ng-container>

                <th>
                  <ng-container *ngIf="gacha.isEditing">
                    <div class="buttons">
                      <button class="button is-success is-light" (click)="saveEdit(gacha)">Save</button>
                      <button class="button is-danger is-light" (click)="cancelEdit(gacha)">Cancel</button>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="!gacha.isEditing">
                    <button class="button" (click)="startEdit(gacha)">Edit</button>
                  </ng-container>
                </th>

                <th>
                  <button *ngIf="!gacha.gacha.editionDetails" class="button" (click)="createEdition(gacha.gacha)">Create
                    Edition</button>
                </th>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </section>
    </div>
    <div *ngIf="creatingEditionFor && newEdition && editions" class="column is-6">
      <div class="columns">
        <div class="column is-12">
          <div class="columns">
            <div class="column is-6">
              <div class="field">
                <label class="label">Gacha Name (Preferably leave the same as the base)</label>
                <div class="control">
                  <input [(ngModel)]="newEdition.name" class="input" type="text" placeholder="Text input">
                </div>
              </div>
            </div>

            <div class="column is-6">
              <div class="field">
                <label class="label">Select Edition</label>
                <div class="control">
                  <div class="select">
                    <select #s [(ngModel)]="selectedEdition">
                      <option *ngFor="let edition of editions" [ngValue]="edition.editionId">{{edition.name}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-5">
              <div class="field">
                <label class="label">Max # Of Copies</label>
                <div class="control">
                  <input [(ngModel)]="newEditionCopies" class="input" type="number" placeholder="Number input">
                </div>
              </div>
            </div>
            <div class="column is-7">
              <div class="notification is-danger is-light">
                Editions will be limited in # of copies. Please ask what the "current guidance" is in the chat before
                putting a number in.<br />
                Generally speaking, higher rarity = less copies. It'll depend on the target # of gachas as well. The
                more gachas in an edition,
                the less of each individual one we'll mint.
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <textarea #input placeholder="Paste image here" (paste)="editionOnPaste($event)"
                (change)="editionOnFileChange($event)"></textarea>
              <image-cropper output="base64" [imageChangedEvent]="editionImgChangeEvt" [maintainAspectRatio]="true"
                [imageBase64]="editionImageBase64String" [aspectRatio]="1 / 1.41421356237" [cropperMinWidth]="384"
                format="png" (imageCropped)="editionCropImg($event)" (imageLoaded)="editionImgLoad()"
                (cropperReady)="editionInitCropper()" (loadImageFailed)="editionImgFailed()" [resizeToWidth]="576"
                [onlyScaleDown]="true">
              </image-cropper>
            </div>

            <div class="column is-6">
              <h6>Image Preview</h6>
              <p class="subtitle">Grid background is to show transparency, it won't appear in final image</p>
              <img class="grid-background" [src]="editionCropImgPreview" />
            </div>
          </div>
          <div class="buttons">
            <button class="button is-success is-light" (click)="createEditionSubmit()">Create Edition</button>
            <button class="button is-warning is-light"
              (click)="creatingEditionFor = undefined; newEditionCopies = 0;">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>