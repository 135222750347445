import { Inject, inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ResolveFn } from '@angular/router';
import { faV } from '@fortawesome/free-solid-svg-icons';
import { forkJoin, map } from 'rxjs';
import { GetUserFavoriteGachasResponse, Media, User } from 'src/app/openapi/models';
import { UserServiceService } from 'src/app/openapi/services';

export interface ViewUserGachaResolveUserData {
  user?: User;
  favorites?: GetUserFavoriteGachasResponse;
  favoriteMedia?: Media
}

export const resolveUser: ResolveFn<ViewUserGachaResolveUserData> = (route, state) => {
  const m = inject(Meta);
  const u = inject(UserServiceService);
  return forkJoin([
    u.userServiceGetUser({ uuid: route.params['userUUID'] }),
    u.userServiceGetUserFavoriteGachas({ userUuid: route.params['userUUID'] }),
    u.userServiceGetUserFavoriteMedia({ userUuid: route.params['userUUID'] }),
  ]).pipe(
    map(([user, favorites, favoriteMedia]) => ({ user: user.user, favorites: favorites, favoriteMedia: favoriteMedia.media }) as ViewUserGachaResolveUserData),
    map((data) => {
      if (data.user) {
        m.removeTag('name="title"');
        m.removeTag('name="description"');
        m.removeTag('name="og:title"');
        m.removeTag('name="og:description"');
        m.addTag({ name: 'title', content: `Upa - ${data.user.displayName}'s collection` })
        m.addTag({ name: 'og:title', content: `Upa - ${data.user.displayName}'s collection` });
        m.addTag({ name: 'description', content: `View ${data.user.displayName}'s Upa gacha collection!` });
        m.addTag({ name: 'og:description', content: `View ${data.user.displayName}'s Upa gacha collection!` });
      }
      if (data.favorites && data.favorites.favoriteGachas && data.favorites.favoriteGachas.length > 0 && data.favorites.favoriteGachas[0].gacha) {
        m.removeTag('name="og:image"');
        m.addTag({ name: 'og:image', content: `${data.favorites.favoriteGachas[0].gacha.gacha?.imageUrl}` });
        m.removeTag('name="twitter:image:src"');
        m.addTag({ name: 'twitter:image:src', content: `${data.favorites.favoriteGachas[0].gacha.gacha?.imageUrl}` });
        m.addTag({ name: 'twitter:card', content: 'summary_large_image' });
      }
      return data;
    })
  );
};
