import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { map, mergeMap } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { take } from 'rxjs';
import { AltArt, DeleteStagedAltArtResponseStatus, EnableAltArtResponseStatus, Gacha, GetStagedAltArtResponseGachaAndAltArts, Media, UpdateStagedAltArtResponseStatus } from 'src/app/openapi/models';
import { AdminServiceService, MediaServiceService } from 'src/app/openapi/services';
import { AdminServiceEnableAltArt$Params } from 'src/app/openapi/fn/admin-service/admin-service-enable-alt-art';
import { AdminServiceUpdateStagedAltArt$Params } from 'src/app/openapi/fn/admin-service/admin-service-update-staged-alt-art';

interface altArtEditState {
  altArt: GetStagedAltArtResponseGachaAndAltArts;
  // Map editing state of each alt_art_id => editing or not
  isEditing: Map<number, boolean>;
}

@Component({
  selector: 'app-view-alt-art',
  templateUrl: './view-alt-art.component.html',
  styleUrls: ['./view-alt-art.component.scss']
})
export class ViewAltArtComponent implements OnInit {

  selectedMediaID?: string;
  allAltArts?: altArtEditState[];
  shownAltArts?: altArtEditState[];
  medias?: Media[];

  constructor(private adminService: AdminServiceService,
    private mediaService: MediaServiceService,
    private toastr: ToastrService,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.adminService.adminServiceGetStagedAltArt().pipe(
      map(res => this.allAltArts = (res.altArts || []).map(a => ({
        altArt: a,
        isEditing: new Map(),
      }))),
      mergeMap(altArts => this.mediaService.mediaServiceGetAllMedia().pipe(
        map(medias => (medias.media || []).filter(m => altArts.map(a => a.altArt.gacha?.mediaId).includes(m.id))),
      )),
    ).subscribe(media => {
      this.medias = media;
      this.shownAltArts = this.allAltArts;
    });
  }

  showDeleteAlert(gacha: Gacha, altArt: AltArt) {
    if (confirm(`Are you sure you want to delete ${altArt.name}?`)) {
      this.deleteAltArt(gacha, altArt);
    }
  }

  deleteAltArt(gacha: Gacha, altArt: AltArt) {
    if (!gacha.id || !altArt.artId) {
      this.toastr.error("Gacha ID or Alt Art ID is missing!");
      return;
    }
    this.adminService.adminServiceDeleteStagedAltArt({ gachaId: gacha.id, altArtId: altArt.artId }).subscribe(res => {
      switch (res.status) {
        case "SUCCESS":
          this.toastr.success("Successfully deleted alt art");
          this.reload();
          break;
        default:
          this.toastr.error("Failed to delete alt art");
          break;
      }
    })
  }

  enableAltArt(gacha: Gacha, altArt: AltArt) {
    let req: AdminServiceEnableAltArt$Params = {
      body: {
        announce: true,
        altArts: [],
      }
    };
    this.authService.user$.pipe(take(1)).subscribe(u => {
      if (!u) {
        this.toastr.error("Not logged in!");
        return;
      }
      if (!gacha.id || !altArt.artId) {
        this.toastr.error("Gacha ID or Alt Art ID is missing!");
        return;
      }
      req.body.altArts = [{
        gachaId: gacha.id,
        altArtId: altArt.artId,
      }]
      this.adminService.adminServiceEnableAltArt(req).subscribe(res => {
        switch (res.status) {
          case "SUCCESS":
            this.toastr.success("Successfully enabled alt art");
            this.reload();
            break;
          default:
            this.toastr.error(`Unknown error: ${res.status}`);
            break;
        }
      })
    });
  }

  startEditAltArt(altArt: altArtEditState, art: AltArt) {
    if (!art.artId) {
      this.toastr.error("Alt Art ID is missing!");
      return;
    }
    altArt.isEditing.set(art.artId, true);
  }

  saveAltArt(altArt: altArtEditState, art: AltArt) {
    const req: AdminServiceUpdateStagedAltArt$Params = {
      gachaId: altArt.altArt.gacha!.id!,
      altArtId: art.artId!,
      body: {
        name: art.name,
        description: art.description,
      }
    }
    if (art.description === "") {
      art.description = undefined;
    }
    this.adminService.adminServiceUpdateStagedAltArt(req).subscribe(res => {
      switch (res.status) {
        case "SUCCESS":
          this.toastr.success("Successfully updated alt art");
          this.reload();
          break;
        default:
          this.toastr.error(`Unknown error: ${res.status}`);
          break;
      }
      altArt.isEditing.set(art.artId!, false);
    });
  }

  cancelEditAltArt(altArt: altArtEditState, art: AltArt) {
    if (!art.artId) {
      this.toastr.error("Alt Art ID is missing!");
      return;
    }
    altArt.isEditing.set(art.artId, false);
  }

  reload() {
    this.adminService.adminServiceGetStagedAltArt().pipe(
      map(res => this.allAltArts = (res.altArts || []).map(a => ({
        altArt: a,
        isEditing: new Map(),
      }))),
      mergeMap(altArts => this.mediaService.mediaServiceGetAllMedia().pipe(
        map(medias => (medias.media || []).filter(m => altArts.map(a => a.altArt.gacha?.mediaId).includes(m.id))),
      )),
    ).subscribe(media => {
      this.medias = media;
      this.shownAltArts = this.allAltArts?.
        filter(a => (!this.selectedMediaID) || a.altArt.gacha?.mediaId === this.selectedMediaID);
    });
  }

  onSelectMedia(e: string) {
    const spl = e.split(" ");
    var mediaID = e;
    if (spl.length > 1) {
      mediaID = spl[1];
    }

    this.shownAltArts = this.allAltArts?.
      filter(a => a.altArt.gacha?.mediaId === mediaID);
    this.selectedMediaID = mediaID;
  }

  getMediaName(mediaID: string): string {
    return this.medias?.find(m => m.id === mediaID)?.name || "";
  }

  gachaTrackBy(index: number, art: altArtEditState) {
    return art.altArt.gacha!.id;
  }

  altArtTrackBy(index: number, altArt: AltArt) {
    return altArt.artId;
  }

  calcAltArtsCount(): number {
    // Calculate nested alt_arts within each shownAltArts
    return this.shownAltArts?.reduce((acc, curr) => acc + (curr.altArt.altArts || []).length, 0) || 0;
  }
}
