/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { GetAllMediaResponse } from '../models/get-all-media-response';
import { GetMediaOriginsResponse } from '../models/get-media-origins-response';
import { GetMediaResponse } from '../models/get-media-response';
import { GetMediasPaginatedResponse } from '../models/get-medias-paginated-response';
import { mediaServiceGetAllMedia } from '../fn/media-service/media-service-get-all-media';
import { MediaServiceGetAllMedia$Params } from '../fn/media-service/media-service-get-all-media';
import { mediaServiceGetMedia } from '../fn/media-service/media-service-get-media';
import { MediaServiceGetMedia$Params } from '../fn/media-service/media-service-get-media';
import { mediaServiceGetMediaOrigins } from '../fn/media-service/media-service-get-media-origins';
import { MediaServiceGetMediaOrigins$Params } from '../fn/media-service/media-service-get-media-origins';
import { mediaServiceGetMediasPaginated } from '../fn/media-service/media-service-get-medias-paginated';
import { MediaServiceGetMediasPaginated$Params } from '../fn/media-service/media-service-get-medias-paginated';

@Injectable({ providedIn: 'root' })
export class MediaServiceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `mediaServiceGetMediasPaginated()` */
  static readonly MediaServiceGetMediasPaginatedPath = '/api/v3/gateway/media';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mediaServiceGetMediasPaginated()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediaServiceGetMediasPaginated$Response(params?: MediaServiceGetMediasPaginated$Params, context?: HttpContext): Observable<StrictHttpResponse<GetMediasPaginatedResponse>> {
    return mediaServiceGetMediasPaginated(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mediaServiceGetMediasPaginated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediaServiceGetMediasPaginated(params?: MediaServiceGetMediasPaginated$Params, context?: HttpContext): Observable<GetMediasPaginatedResponse> {
    return this.mediaServiceGetMediasPaginated$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetMediasPaginatedResponse>): GetMediasPaginatedResponse => r.body)
    );
  }

  /** Path part for operation `mediaServiceGetAllMedia()` */
  static readonly MediaServiceGetAllMediaPath = '/api/v3/gateway/media/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mediaServiceGetAllMedia()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediaServiceGetAllMedia$Response(params?: MediaServiceGetAllMedia$Params, context?: HttpContext): Observable<StrictHttpResponse<GetAllMediaResponse>> {
    return mediaServiceGetAllMedia(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mediaServiceGetAllMedia$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediaServiceGetAllMedia(params?: MediaServiceGetAllMedia$Params, context?: HttpContext): Observable<GetAllMediaResponse> {
    return this.mediaServiceGetAllMedia$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetAllMediaResponse>): GetAllMediaResponse => r.body)
    );
  }

  /** Path part for operation `mediaServiceGetMediaOrigins()` */
  static readonly MediaServiceGetMediaOriginsPath = '/api/v3/gateway/media/origins';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mediaServiceGetMediaOrigins()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediaServiceGetMediaOrigins$Response(params?: MediaServiceGetMediaOrigins$Params, context?: HttpContext): Observable<StrictHttpResponse<GetMediaOriginsResponse>> {
    return mediaServiceGetMediaOrigins(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mediaServiceGetMediaOrigins$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediaServiceGetMediaOrigins(params?: MediaServiceGetMediaOrigins$Params, context?: HttpContext): Observable<GetMediaOriginsResponse> {
    return this.mediaServiceGetMediaOrigins$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetMediaOriginsResponse>): GetMediaOriginsResponse => r.body)
    );
  }

  /** Path part for operation `mediaServiceGetMedia()` */
  static readonly MediaServiceGetMediaPath = '/api/v3/gateway/media/{mediaId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mediaServiceGetMedia()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediaServiceGetMedia$Response(params: MediaServiceGetMedia$Params, context?: HttpContext): Observable<StrictHttpResponse<GetMediaResponse>> {
    return mediaServiceGetMedia(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mediaServiceGetMedia$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediaServiceGetMedia(params: MediaServiceGetMedia$Params, context?: HttpContext): Observable<GetMediaResponse> {
    return this.mediaServiceGetMedia$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetMediaResponse>): GetMediaResponse => r.body)
    );
  }

}
