<div class="view-card" [ngStyle]="
{
  'box-shadow': '10px 10px 24px -4px #444444'
}">
  <div class="view-card-inset">
    <img class="view-card-image" src="/assets/empty-card-bg.png" loading="lazy" />

    <div class="view-card-details">
      <button (click)="showSearchModal()" class="button is-light is-primary">Set Favorite</button>
    </div>
  </div>
</div>

<div class="modal" #cardSearchModal>
  <div class="modal-background"></div>
  <div class="modal-content">
    <div class="box">
      <h1 class="is-size-4 is-subtitle">Search for gacha cards</h1>
      <div class="field">
        <input (keyup)="searchUserGacha(getValue($event))" class="input is-rounded" type="text"
          placeholder="Search your collection">
      </div>
      <div class="field is-narrow">
        <div class="select is-rounded">
          <select #s (change)="selectMedia(s.value)">
            <option value="reset">Choose Franchise (optional)</option>
            <option *ngFor="let media of medias" [value]="media.id">{{media.name}}</option>
          </select>
        </div>
      </div>
      <div class="field is-narrow">
        <div class="control">
          <ng-container *ngFor="let item of [].constructor(5); let i = index">
            <label class="radio">
              <input type="radio" name="rarity" [value]="i+1" (click)="selectRarity(i+1)">
              {{ i + 1 }}⭐
            </label>
          </ng-container>
        </div>
      </div>
      <button class="button is-danger" (click)="closeSearchModal()">Cancel</button>

      <div class="columns is-multiline">
        <div class="column is-12">
          <ng-container *ngFor="let g of gachaResults$ | async">
            <app-gacha-v2 style="width: 50px !important;" [gacha]="g.gacha" [media]="g.media" [showFavoriteSet]="true"
              (favoriteSet)="setFavorite($event)"></app-gacha-v2>
          </ng-container>
        </div>
      </div>
      <button (click)="closeSearchModal()" class="modal-close is-large" aria-label="close"></button>
    </div>
  </div>
</div>